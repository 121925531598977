import React, { useEffect } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next';
import { onfidoDocumentSupport } from '../constants/onfido'
import { usePublicSettings } from '../myHooks'
import { getCurrentLocalLcid } from '../utils/lang'
import ModalInnerMaterial from './ModalInnerMaterialDialog'
import {
  Dialog,
} from '@material-ui/core';
import CardInformation from './InformationCard'

const GET_ONFIDO_INITIAL_DATA = gql`
  query getOnfidoInitialData {
    getOnfidoInitialData {
      sdk_token
      user {
        id
        email
        name
        birth_date
        phone
        is_active
        customer {
          id
          nationality
        }
        customerCompany {
          id
        }
      }
      allow_new_check
    }
  }
`;

const SUBMIT_CHECK = gql`
  mutation submitACheck($report_names: [String]!) {
    submitACheck(report_names: $report_names)
  }
`;

const PULL_ONFIDO_DOCUMENT_AND_FACE = gql`
  mutation pullOnfidoDocumentAndFace($input: OnfidoDocumentAndFace) {
    pullOnfidoDocumentAndFace(input: $input)
  }
`;

const KycCustomerVideoIdentOnfido = ({
  customer,
  forceCrossDevice,
  onComplete,
}) => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const isOnfidoSupportedBrowser = () => {
    const userAgent = navigator.userAgent
    if (userAgent.match(/(msie|fxios)/i)) {
      return false
    }

    if (userAgent.match(/android.+firefox/i)) {
      return false
    }

    return true
  }
  const isBrowserNotSupportedDialogOpen = !isOnfidoSupportedBrowser();
  const [submitACheck] = useMutation(SUBMIT_CHECK);
  const [pullOnfidoDocumentAndFace] = useMutation(
    PULL_ONFIDO_DOCUMENT_AND_FACE
  );

  if (!customer) {
    window.location.href = window.location.origin;
  }

  const { loading, error, data } = useQuery(GET_ONFIDO_INITIAL_DATA, {
    variables: {
      id: +((customer || { id: 0 }).id),
    }
  });

  useEffect(() => {
    if (!publicSettings) {
      return
    }

    const initOnfido = async (sdk_token) => {
      Onfido.init({
        // the JWT token that you generated earlier on
        token: sdk_token,
        // ID of the element you want to mount the component on
        containerId: "onfido-mount",
        language: getCurrentLocalLcid(publicSettings.default_locale),

        onComplete: function (data) {
          console.log('data', data)

          const report_names =
            data["face"]["variant"] === "standard"
              ? ["document", "facial_similarity_photo"]
              : ["document", "facial_similarity_video"];

          if (publicSettings.enable_proof_of_residence_checking) {
            report_names.push('proof_of_address')
          }

          const awaitPromises = [];

          awaitPromises.push(submitACheck({ variables: { report_names: report_names } }).catch(() => {}));

          const onfidoDocumentAndFaceInput = {
            documents: [],
            face: {},
          };

          const keys = Object.keys(data);

          // eslint-disable-next-line
          for (const key of keys) {
            if (key === "document_front" || key === "document_back") {
              onfidoDocumentAndFaceInput.documents.push(data[`${key}`].id);
            } else if (key === "face") {
              onfidoDocumentAndFaceInput.face = data[`${key}`];
            } else if (key === "poa") {
              onfidoDocumentAndFaceInput.poa = data[`${key}`];
            }
          }

          awaitPromises.push(
            pullOnfidoDocumentAndFace({
              variables: { input: onfidoDocumentAndFaceInput },
            }).catch(() => {})
          );

          if (onComplete) {
            Promise.all(awaitPromises).then(() => {
              onComplete()
            });
          }
        },

        steps: [
          {
            type: "welcome",
            options: {
              title: t('Please verify your identity.'),
              descriptions: [t('It only takes a few minutes.')],
              nextButton: t('Proceed'),
            },
          },
          {
            type: "document",
            options: {
              documentTypes: {
                passport: true,
                national_identity_card: onfidoDocumentSupport('national_identity_card', customer.nationality),
              },
              forceCrossDevice,
            },
          },
          {
            type: "face",
            options: {
              requestedVariant: "video",
            },
          },
          publicSettings.enable_proof_of_residence_checking ? {
            type: "poa",
          } : {},
          {
            type: "complete",
            options: {
              message: publicSettings.force_user_till_first_transaction
                ? t('Verification Complete. Stay still, you will be redirected to the KYC Status page.')
                : t('Verification complete. Stay still, you will be redirected to the dashboard when the process complete.'),
            },
          },
        ],
      });
    };

    if (
      isOnfidoSupportedBrowser() &&
      data &&
      data.getOnfidoInitialData &&
      data.getOnfidoInitialData.sdk_token &&
      data.getOnfidoInitialData.allow_new_check
    ) {
      initOnfido(data.getOnfidoInitialData.sdk_token);
    }
    // eslint-disable-next-line
  }, [data, customer, publicSettings]);

  if (loading) return <></>;
  if (error) return <Redirect to="/" />;
  if (!data.getOnfidoInitialData.allow_new_check) return <Redirect to="/" />;
  if (!data.getOnfidoInitialData.user.customer && !data.getOnfidoInitialData.user.customerCompany) return <></>;
  if (!data.getOnfidoInitialData.sdk_token) return <></>;

  return (
    <div className="card card__onfido">
      <div id="onfido-mount" className="onfido-mount" />
      <br />
      <Dialog
        open={isBrowserNotSupportedDialogOpen}
      >
        <ModalInnerMaterial
          modalSize="popup__kyc-medium"
          modalTitle="Browser is not supported"
        >
          <div className="description__row flex__row flex__wrap">
            <div className="refund-content w-full">
              <CardInformation color="card__information-red">
                <p>
                  {t('The web browser you\'re using has not been fully supported, which may cause issue on processing the liveness check.')}
                </p>
              </CardInformation>
              <br />
              <CardInformation color="card__information-primary">
                <p>{t('For the best experience, please try using one of the suggested browsers below')}:</p>
                <ul>
                  <li>Chrome</li>
                  <li>Firefox (not supported on mobile devices)</li>
                  <li>Internet Explorer 11</li>
                  <li>Microsoft Edge</li>
                  <li>Safari</li>
                </ul>
              </CardInformation>
              <br />
              <NavLink to="/" className="button button-stroke" activeClassName="">{t('Back to Dashboard')}</NavLink>
            </div>
          </div>
        </ModalInnerMaterial>
      </Dialog>
    </div>
  )
}

KycCustomerVideoIdentOnfido.propTypes = {
  customer: PropTypes.object.isRequired,
  forceCrossDevice: PropTypes.bool,
  onComplete: PropTypes.func,
}

export default KycCustomerVideoIdentOnfido
