import routes from './routes'
import Maintain from './Maintain'
import { usePublicSettings } from './myHooks/useSettings'
import { setTitle } from './utils'
import React from "react";
import { useQuery } from "@apollo/client";
import { ME } from "./queriesAndMutations";
import i18n from "i18next";
import * as languageHelper from './utils/lang'

const Root = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const hotjarId = publicSettings.hotjar_id
  const { intercom_app_id, intercom_region } = publicSettings

  const {
    data,
  } = useQuery(ME);

  React.useEffect(() => {
    if (hotjarId) {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: hotjarId, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }, [hotjarId])

  React.useEffect(() => {
    if (intercom_app_id && data && data.me && data.me.email) {
      window.intercomSettings = {
        api_base: intercom_region === "EU" ? "https://api-iam.eu.intercom.io" : "https://api-iam.intercom.io",
        app_id: intercom_app_id,
        name: data.me.first_name,
        email: data.me.email,
        created_at: data.me.created_at
      };

      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${intercom_app_id}}`;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          l()
        }
      })();
    }
  }, [intercom_app_id, data, intercom_region])

  if (publicSettings && publicSettings.company) {
    setTitle(publicSettings.company.name)
  }

  if (publicSettings && publicSettings.default_locale) {
    const locale = languageHelper.getLocale(publicSettings.default_locale);
    i18n.changeLanguage(locale);
  }

  if (publicSettings && publicSettings.system_status === 'DISABLE') {
    return Maintain(publicSettings.company.email)
  }

  return routes()
}

export default Root
