import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CHANGE_USER_STATUS_VIDEO_IDENTIFICATION,
  CHANGE_USER_VIDEO_IDENTIFICATION_ID,
  CREATE_LOG_VIDEO_IDENT,
} from "../../queriesAndMutations";
import { USER_VIDEO_IDENT_STATUS } from '../../constants/user'
import { useMe, useVideoAttended, useVideoUnAttended } from "../../myHooks";
import "../../styles/legacy/style.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { VIDEO_ID_TYPES } from "../../constants/video_id_types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import passportImg from '../../media/images/vector-passport.png';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { uniqBy, sortBy } from 'lodash'
import { usePublicSettings } from '../../myHooks/useSettings'
import { useTranslation } from 'react-i18next'

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  videoIDClass: {
    maxHeight: "900px",
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonVerify: {
    marginTop: theme.spacing(2),
  }
}));

const detectVideoIdType = (nationality, documentType) => {
  let videoIdTypeDetected = "";

  if (documentType === "DRIVER_LICENSE") {
    videoIdTypeDetected = VIDEO_ID_TYPES.find(
      (ele) =>
        ele["countryName"] === nationality &&
        ele["type"] === "DriverLicense"
    );
  } else {
    if (documentType === "NATIONAL_ID_CARD") {
      videoIdTypeDetected = VIDEO_ID_TYPES.find(
        (ele) =>
          ele["countryName"] === nationality &&
          ele["type"] === "IdCard"
      );
    } else {
      videoIdTypeDetected = VIDEO_ID_TYPES.find(
        (ele) =>
          ele["countryName"] === nationality &&
          ele["type"] === "Passport"
      );
    }
  }

  return videoIdTypeDetected;
}

const titleizeDocumentType = (documentType) => {
  switch (documentType) {
    case "DRIVER_LICENSE":
      return "Driver License";
    case "PASSPORT":
      return "Passport";
    case "NATIONAL_ID_CARD":
      return "National Id Card";
    default:
      return "Passport";
  }
}

const VideoScanWithVideoFull = ({values, setValues, addressValues, isInvestingKycLevel1, isInvestingKycLevel2, isInvestingKycLevel3, refetchWhenReceivedVideoIdentId }) => {
  const { t } = useTranslation();
  let videoIdentVerifiedTime = (values || {}).videoIdentVerifiedTime;
  const nationality = (addressValues || {}).nationality;
  const documentType = (values || {}).documentType;
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const videoIdentType = (publicSettings.identification || {}).video_ident_attended_type || 'attended';

  const { data: { me: userData } = {} } = useMe();
  const { data: { VideoIDAttendAuthorization: dataAttended } = {} } = useVideoAttended();
  const { data: { VideoIDUnAttendAuthorization: dataUnattended } = {} } = useVideoUnAttended();

  let data = null;

  if (videoIdentType === 'attended'){
    console.log('detect from setting: video id with Attended')
    data = dataAttended
  } else {
    console.log('detect from setting: video id with Un-Attended')
    data = dataUnattended
  }

  const [changeUserVideoIdentStatus] = useMutation(CHANGE_USER_STATUS_VIDEO_IDENTIFICATION);
  const [changeUserVideoIdentId] = useMutation(CHANGE_USER_VIDEO_IDENTIFICATION_ID);
  const [startingVideoId, setStartingVideoId] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [videoIdentSuccess, setVideoIdentSuccess] = useState(false);
  const [userNationalityForVideoIdent, setUserNationalityForVideoIdent] = useState(nationality);
  const [listCountryIdentification, setListCountryIdentification] = useState([])
  const classes = useStyles();
  const videoIdTypeDetected = detectVideoIdType(userNationalityForVideoIdent, documentType);
  const [createLogVideoIdent] = useMutation(CREATE_LOG_VIDEO_IDENT);

  const getDataOfRecordedVideo = async (video_record_id) => {
    const response = await axios.get(`${window.location.origin}/video-id/get-video/${video_record_id}`)

    let videoStatus = null;

    if (videoIdentType === 'attended') {
      videoStatus = response.data.status === "Completed"
        ? USER_VIDEO_IDENT_STATUS.PASSED
        : USER_VIDEO_IDENT_STATUS.DENIED;
    } else {
      videoStatus = response.data.status === "Completed"
        ? USER_VIDEO_IDENT_STATUS.PENDING
        : USER_VIDEO_IDENT_STATUS.DENIED;
    }

    try {
      await changeUserVideoIdentStatus({
        variables: {
          id: +userData.id,
          videoIdentificationStatus: videoStatus,
        },
      })
      setVideoIdentSuccess(true)

      await changeUserVideoIdentId({
        variables: {
          id: +userData.id,
          videoIdentificationId: video_record_id,
        },
      });

      refetchWhenReceivedVideoIdentId()
    } catch (e) {}
  };

  const requestVerify = async (video_record_id) => {
    await axios.post(`${window.location.origin}/video-id/verify/${video_record_id}`)
  };

  const loadScriptForLevel_1_2 = (data) => {
    const script = document.createElement("script");
    if (videoIdentType === 'attended'){
      // Sandbox
      console.log('Init loadScriptForLevel_1_2 with video Attended')
      script.src = "https://etrust-sandbox.electronicid.eu/v2/js/videoidattended.js";
      // Live
      // script.src = "https://etrust-live.electronicid.eu/v2/js/videoidattended.js";
    } else {
      // Sandbox
      console.log('Init loadScriptForLevel_1_2 with video Un-Attended')
      script.src = "https://etrust-sandbox.electronicid.eu/v2/js/videoid.js";
      // Live
      // script.src = "https://etrust-live.electronicid.eu/v2/js/videoid.js";
    }

    document.head.appendChild(script);

    script.onload = function () {
      let EID = window.EID;
      let videoId = null;
      if (videoIdentType === 'attended'){
        videoId = EID.videoIDAttended("#videoIdentApplication", {
          lang: "en",
        });
      } else {
        videoId = EID.videoId("#videoIdentApplication", {
          lang: "en",
        });
      }
      videoId.turnOn();
      videoId.start({
        authorization: data.authorization,
        idType: videoIdTypeDetected.id,
      });
      videoId.on("phaseStarting", function (phase) {
        if (phase.name === "FRONT") {
          console.log(phase.name + " - Starting");
          setScriptLoaded(true);
        }
        phase.continue();
      });
      videoId.on("completed", function (video) {
        videoId.turnOff();
        getDataOfRecordedVideo(video.id);
        requestVerify(video.id);
        alert("VideoId record successfully!");
      });
      videoId.on("failed", function (error) {
        videoIdentVerifiedTime += 1;
        setValues({ ...values, videoIdentVerifiedTime: videoIdentVerifiedTime })
        console.log('error VideoId')
        const error_data = error.error;
        console.log(error_data)
        createLogVideoIdent({
          variables: {
            email: userData.email,
            error: error_data.error,
            message: error_data.message,
          }
        }).catch(() => {})
        alert("VideoId Failed");
        // reloadVideoIdent(data);
      });
    };
  };

  const loadScriptForLevel_3 = (data) => {
    const script = document.createElement("script");
    if (videoIdentType === 'attended'){
      // Sandbox
      console.log('Init loadScriptForLevel_3 with video Attended')
      script.src = "https://etrust-sandbox.electronicid.eu/v2/js/videoidattended.js";
      // Live
      // script.src = "https://etrust-live.electronicid.eu/v2/js/videoidattended.js";
    } else {
      // Sandbox
      console.log('Init loadScriptForLevel_3 with video Un-Attended')
      script.src = "https://etrust-sandbox.electronicid.eu/v2/js/videoid.js";
      // Live
      // script.src = "https://etrust-live.electronicid.eu/v2/js/videoid.js";
    }
    document.head.appendChild(script);
    script.onload = function () {
      let EID = window.EID;
      let videoId = null;
      if (videoIdentType === 'attended'){
        videoId = EID.videoIDAttended("#videoIdentApplication", {
          lang: "en",
        });
      } else {
        videoId = EID.videoId("#videoIdentApplication", {
          lang: "en",
        });
      }
      videoId.turnOn();
      videoId.start({
        authorization: data.authorization,
        idType: videoIdTypeDetected.id,
      });
      videoId.on("phaseStarting", function (phase) {
        if (phase.name === "FRONT") {
          console.log(phase.name + " - Starting");
          setScriptLoaded(true);
        }
        phase.continue();
      });
      videoId.on("completed", function (video) {
        videoId.turnOff();
        getDataOfRecordedVideo(video.id);
        requestVerify(video.id);
        alert("VideoId record successfully!");
      });
      videoId.on("failed", function (error) {
        console.log('error VideoId')
        const error_data = error.error;
        console.log(error_data)
        createLogVideoIdent({
          variables: {
            email: userData.email,
            error: error_data.error,
            message: error_data.message,
          }
        }).catch(() => {})
        alert("VideoId Failed");
      });
    };
  };

  const onOpenBtnClick = () => {
    setStartingVideoId(true);
  }

  useEffect(() => {
    if (nationality) {
      setUserNationalityForVideoIdent(nationality)
    }
  }, [nationality])

  useEffect(() => {
    axios.get('https://etrust-live.electronicid.eu/v2/videoid.idtypes')
      .then((res) => setListCountryIdentification(res.data))
  }, [setListCountryIdentification])

  const countryNameOptions = sortBy(
    uniqBy(
      listCountryIdentification.filter((country) => country.countryName),
      'countryName'
    ), 'countryName')
    .map((country) => country.countryName)

  useEffect(() => {
    if (data && startingVideoId) {
      if (isInvestingKycLevel3) {
        console.log('loading script for level 3')
        loadScriptForLevel_3(data);
      } else {
        console.log('loading script for level 1 & 2')
        loadScriptForLevel_1_2(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data && startingVideoId]);

  const handleChangeUserNationality = (event) => {
    console.log("handleChangeUserNationality")
    console.log(event.target.value)
    setUserNationalityForVideoIdent(event.target.value);
  }

  return (
    <div key='mainDocumentPhoto'>
      <h5 className='font-mid'>
        { t('Verify your') } {titleizeDocumentType(documentType)} { t('with Liveness Check Service from ElectronicID') }
        <span className='text-danger'> *</span>
      </h5>
      <Grid container spacing={2} justify='center' alignItems='center'>
        <Grid item md={9} xs={12}>
          <div className="container">
            <Grid item md={6} xs={12}>
              <FormControl disabled={scriptLoaded || startingVideoId}>
                <Select
                  value={userNationalityForVideoIdent}
                  onChange={handleChangeUserNationality}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    { t('Select nationality for liveness check') }
                  </MenuItem>
                  {
                    countryNameOptions.map((item) => (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              !startingVideoId
                ?
                <button
                  type="button"
                  disabled={!userNationalityForVideoIdent || scriptLoaded || startingVideoId}
                  onClick={onOpenBtnClick}
                  className={`btn btn-primary ${classes.buttonVerify}`}>
                    { t('Click here to verify with Liveness Check') }
                </button>
                : scriptLoaded ? (
                    ""
                  ) : (
                    <div className={classes.circularProgressWrapper}>
                      <CircularProgress />
                    </div>
                  )
            }
            {
              (startingVideoId && scriptLoaded && videoIdentSuccess) ?
                "VideoId record successfully!" : ""
            }
            <div id="videoIdentApplication" className={classes.videoIDClass} />
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <img src={passportImg} alt='vector' />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

export default VideoScanWithVideoFull;
