import React, { useEffect } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import { onfidoDocumentSupport } from '../../constants/onfido'
import { getCurrentLocalLcid } from '../../utils/lang'
import ModalInnerMaterial from '../ModalInnerMaterialDialog'
import {
  Dialog,
} from '@material-ui/core';
import CardInformation from '../InformationCard'

const GET_ONFIDO_INITIAL_DATA_FOR_DIRECTOR = gql`
  query getOnfidoInitialDataForDirector($id: ID!) {
    getOnfidoInitialDataForDirector(id: $id) {
      sdk_token
      user {
        id
        email
        name
        birth_date
        phone
        is_active
        customer {
          id
        }
        customerCompany {
          id
        }
      }
      allow_new_check
    }
  }
`;

const SUBMIT_CHECK_FOR_DIRECTOR = gql`
  mutation submitACheckForDirector($director_id: ID!, $report_names: [String]!) {
    submitACheckForDirector(director_id: $director_id, report_names: $report_names)
  }
`;

const PULL_ONFIDO_DOCUMENT_AND_FACE_FOR_DIRECTOR = gql`
  mutation pullOnfidoDocumentAndFaceForDirector($director_id: ID!, $input: OnfidoDocumentAndFace) {
    pullOnfidoDocumentAndFaceForDirector(director_id: $director_id, input: $input)
  }
`;

const KycDirectorVideoIdentOnfido = ({
  director,
  forceCrossDevice,
  onComplete,
 }) => {
  const { t } = useTranslation();
  const isOnfidoSupportedBrowser = () => {
    const userAgent = navigator.userAgent
    if (userAgent.match(/(msie|fxios)/i)) {
      return false
    }

    if (userAgent.match(/android.+firefox/i)) {
      return false
    }

    return true
  }
  const isBrowserNotSupportedDialogOpen = !isOnfidoSupportedBrowser();
  const [submitACheckForDirector] = useMutation(SUBMIT_CHECK_FOR_DIRECTOR);
  const [pullOnfidoDocumentAndFaceForDirector] = useMutation(
    PULL_ONFIDO_DOCUMENT_AND_FACE_FOR_DIRECTOR
  );

  const { loading, error, data } = useQuery(GET_ONFIDO_INITIAL_DATA_FOR_DIRECTOR, {
    variables: {
      id: +(director.id),
    }
  });

  useEffect(() => {
    const initOnfido = async (sdk_token) => {
      if (director) {
        Onfido.init({
          // the JWT token that you generated earlier on
          token: sdk_token,
          // ID of the element you want to mount the component on
          containerId: "onfido-mount",
          language: getCurrentLocalLcid(),

          onComplete: function (data) {
            const report_names =
              data["face"]["variant"] === "standard"
                ? ["document", "facial_similarity_photo"]
                : ["document", "facial_similarity_video"];
            const awaitPromises = [];

            awaitPromises.push(submitACheckForDirector({ variables: { director_id: +(director.id), report_names: report_names } }).catch(() => {}));

            const onfidoDocumentAndFaceInput = {
              documents: [],
              face: {},
            };

            const keys = Object.keys(data);

            // eslint-disable-next-line
            for (const key of keys) {
              if (key === "document_front" || key === "document_back") {
                onfidoDocumentAndFaceInput.documents.push(data[`${key}`].id);
              } else if (key === "face") {
                onfidoDocumentAndFaceInput.face = data[`${key}`];
              }
            }

            awaitPromises.push(
              pullOnfidoDocumentAndFaceForDirector({
                variables: {
                  director_id: +(director.id),
                  input: onfidoDocumentAndFaceInput
                },
              }).catch(() => {})
            );

            if (onComplete) {
              Promise.all(awaitPromises).then(() => {
                onComplete()
              });
            }
          },

          steps: [
            {
              type: "welcome",
              options: {
                title: t('Please verify your identity.'),
                descriptions: [t('It only takes a few minutes.')],
                nextButton: t('Proceed'),
              },
            },
            {
              type: "document",
              options: {
                documentTypes: {
                  passport: true,
                  national_identity_card: onfidoDocumentSupport('national_identity_card', director.nationality),
                },
                forceCrossDevice,
              },
            },
            {
              type: "face",
              options: {
                requestedVariant: "video",
              },
            },
            {
              type: "complete",
              options: {
                message:
                  t('Verification complete. Stay still, you will be redirected to the dashboard when the process complete.'),
              },
            },
          ],
        });
      }
    };

    if (
      isOnfidoSupportedBrowser() &&
      data &&
      data.getOnfidoInitialDataForDirector &&
      data.getOnfidoInitialDataForDirector.sdk_token &&
      data.getOnfidoInitialDataForDirector.allow_new_check
    ) {
      initOnfido(data.getOnfidoInitialDataForDirector.sdk_token);
    }
    // eslint-disable-next-line
  }, [data, director]);

  if (loading) return <></>;
  if (error) return <></>;
  if (!data.getOnfidoInitialDataForDirector.allow_new_check) return <Redirect to="/" />;
  if (!data.getOnfidoInitialDataForDirector.sdk_token) return <></>;

  return (
    <div>
      <div id="onfido-mount" className="onfido-mount" />
      <br />
      <Dialog
        open={isBrowserNotSupportedDialogOpen}
      >
        <ModalInnerMaterial
          modalSize="popup__kyc-medium"
          modalTitle="Browser is not supported"
        >
          <div className="description__row flex__row flex__wrap">
            <div className="refund-content w-full">
              <CardInformation color="card__information-red">
                <p>
                  The web browser you're using has not been fully supported,
                  which may cause issue on processing the liveness check.
                </p>
              </CardInformation>
              <br />
              <CardInformation color="card__information-primary">
                <p>For the best experience, please try using one of the suggested browsers below:</p>
                <ul>
                  <li>Chrome</li>
                  <li>Firefox (not supported on mobile devices)</li>
                  <li>Internet Explorer 11</li>
                  <li>Microsoft Edge</li>
                  <li>Safari</li>
                </ul>
              </CardInformation>
              <br />
              <NavLink to="/" className="button button-stroke" activeClassName="">Back to dashboard</NavLink>
            </div>
          </div>
        </ModalInnerMaterial>
      </Dialog>
    </div>
  )
}

KycDirectorVideoIdentOnfido.propTypes = {
  director: PropTypes.object.isRequired
}

export default KycDirectorVideoIdentOnfido
