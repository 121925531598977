import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import client from './apolloClient'

Sentry.init({
  dsn: "https://95ac2d62f188491a9fa7f0e68fbf1f36@o547063.ingest.sentry.io/6778725",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

ReactDOM.render(<App apolloClient={client} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
