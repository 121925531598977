import React from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import { hasUserEnoughRights } from '../../../../utils/index'
import ImageUpload from '../../../../components/ImageUpload'
import { useMe } from '../../../../myHooks'
import { USER_RIGHT_TYPES } from '../../../../constants/user'
import {
  PUBLIC_UPLOAD,
} from '../../../../queriesAndMutations'

const UploadingImage = ({
  refetch
}) => {
  const { t } = useTranslation();
  const { data: { me: user } = {} } = useMe();
  const [uploadImage, { loading: uploadLoading }] = useMutation(PUBLIC_UPLOAD);

  const onDropFile = async (files) => {
    if (files.length > 0) {
      const [upload] = files;
      try {
        await uploadImage({ variables: { upload } });
        refetch();
      } catch (e) {}
    }
  };

  return (
    <>
      {hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN) && (
        <>
          {uploadLoading === false ? (
            <>
              <div className="summary__preview">
                <div className="summary__preview_aspect_ratio">
                  <ImageUpload
                    buttonText="Choose document"
                    onChange={onDropFile}
                    maxFileSize={40000000}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={[]}
                  >
                    <div className="file__wrap_text text__center">
                      { t('New Document') }
                      <br />
                      { t('Drag and drop the document') }
                    </div>
                  </ImageUpload>
                </div>
              </div>
            </>
          ) : (
            <div className="summary__preview">
              <div>
                <CircularProgress />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UploadingImage;
