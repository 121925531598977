import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Redirect, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import {
  remove,
  pick,
  reduce,
} from 'lodash'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Radio,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  InputAdornment,
} from '@material-ui/core'
import InputThemeField from '../../../components/InputThemeField'
import InputThemeLite from '../../../components/InputThemeLite'
import RadioTheme from '../../../components/Radio'
import SwitchCheckboxTheme from '../../../components/SwitchCheckbox'
import ImageUpload from '../../../components/ImageUpload'
import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DateFnsUtils from '@date-io/date-fns/build/index'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  PROSPECTUS_OR_ISSUING,
  TOKEN_TYPES,
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
} from '../../../constants/settings'
import {
  extractMsgFromErrByPropertyName,
  getExternalFileUrl,
  mapSchema,
  numberWithCommas,
  getCountryList,
  getNationalityList,
} from '../../../utils'
import { ethToFiat, tokenToEth, isFiat, tokenToUsdc } from '../../../utils/rate'
import {
  useExchangeRates,
  useMe,
  usePublicSettings,
} from '../../../myHooks'
import {
  CUSTOMER_TYPES,
  COUNTRY_LIST,
  INDUSTRY_OPTIONS,
  RESTRICTED_NATIONALITY,
  RESTRICTED_COUNTRY_LIST,
} from '../../../constants/customer'
import { getSendingWalletTypeOptions, walletLabelTextMapping } from '../../../constants/walletLabelTextMapping'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import InfoIcon from '../../../components/Icon'

import {
  GET_PREFILL_KYC_INFO,
  CHECK_WALLET_COMPENSATION,
  GET_MY_TOKEN_SWAP,
  GET_MY_PERSONAL_DISCOUNT,
  GET_USER_COMPENSATION_RATE
} from '../../../queriesAndMutations'
import KycVerificationFormSourceOfFunds from '../../../components/KycVerificationFormSourceOfFunds'
import KycVerificationFormSourceOfWealth from '../../../components/KycVerificationFormSourceOfWealth'
import KycVerificationFormLevelTwo from '../../../components/KycVerificationFormLevels/KycVerificationFormLevelTwo'
import KycFormSupportedSourceOfFund from '../../../components/KycFormSupportedSourceOfFund'
import KycFormDocumentProofOfResidence from '../../../components/KycFormDocumentProofOfResidence'
import KycVerificationFormDisclaimer from '../../../components/KycVerificationFormDisclaimer'
import KycCompanyVerificationForm from '../../../components/KycCompanyVerificationForm'
import DropdownMaterial from '../../../components/DropdownMaterial'
import WalletRequest from '../../../components/WalletRequest'
import Styles from './styles.module.scss'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  itemBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  info: {
    color: "#758698",
    "&>svg": {
      margin: "0 5px 0 0",
    },
  },
  listCheck: {
    "&>li": {
      display: "flex",
      alignItems: "center",
      "&>svg": {
        margin: "0 5px 0 0",
        color: "#6e81a9",
      },
    },
  },
  title: {
    marginBottom: "40px",
  },
  inputText: {
    marginBottom: "5px",
  },
  controlIcon: {
    cursor: "pointer",
  },

  equalsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  sourceOfFundsExample: {
    marginLeft: 10,
  },

  documentTypeButton: {
    paddingBottom: "22px !important",
  },

  cardHead: {
    minHeight: 0,
    marginBottom: 0,
  },
}));

const KYC_USER_TYPE_ENUM = {
  notPoliticalPerson: {
    value: "NOT_POLITICAL_PERSON",
    label:
      "I am not a Politically Exposed Person, nor a family member of a Politically Exposed Person, and I am not known" +
      " to a Politically Exposed Person.",
  },
  politicalPerson: {
    value: "POLITICAL_PERSON",
    label:
      "I am a Politically Exposed Person who exercises or has exercised a high-level public office at" +
      " international, European or national level, or who has a public office below the national level whose" +
      " political significance is comparable, or has exercised, a family member of such Politically Exposed" +
      " Person or person known to be related to such Politically Exposed Person.",
  },
  anotherPoliticalPerson: {
    value: "ANOTHER_POLITICAL_PERSON",
    label:
      "I am another Politically Exposed Person, a family member of such a Politically Exposed Person or" +
      " someone known to such a Politically Exposed Person.",
  },
};

const KYC_WALLET_ENUM = {
  ethereum: {
    value: "ETHEREUM",
    label: "Ethereum",
    currencies: {
      usd: 165,
    },
  },
};

const MAX_AMOUNT_USD_WITHOUT_PROOF_IDENTITY = 20625;

const NET_INVESTABLE_ASSETS_OPTIONS = [
  "Up to 5.000 CHF",
  "5001 CHF - 25.000 CHF",
  "25001 CHF - 100.000 CHF",
  "100.001 CHF - 500.000 CHF",
  "500.001 CHF - 1 Mio CHF",
  "> 1 Mio - 5 Mio",
  "> 5 Mio",
];

const WHAT_WILL_YOU_USE_OPTIONS = [
  "Investing",
  "Reselling-related activities (broker/dealer)",
];

const WHAT_WILL_YOU_USE_OPTIONS_UTILITY_TOKEN = [
  "Staking",
  "Resale",
  "Usage of the Network",
  "Other",
];

const WHAT_WILL_YOU_USE_OPTIONS_XDCTEQ = [
  'Investing',
  'Other',
]

const useTokenForOptionsQdev = [
  'Blockchain Validation',
]

const KycVerificationForm = ({
  mainInfoSchemas,
  addressInfoSchemas,
  filesSchemas,
  documentTypeEnum,
  kycType,
  registerCustomer,
  registerCustomerData,
  kycFormStepNumber,
  setKycFormStepNumber,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch(`${window.location.pathname}${window.location.search}`)
  const { data: { exchangeRates: baseExchangeRate } = {} } = useExchangeRates();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { getPrefillKycInfo: prefillData } = {} } = useQuery(GET_PREFILL_KYC_INFO)
  const { data: { getMyTokenSwap: tokenSwap } = {} } = useQuery(GET_MY_TOKEN_SWAP)
  const [checkWalletCompensation, checkWalletCompensationStatus] = useLazyQuery(CHECK_WALLET_COMPENSATION)
  const { data: { me: userData = {} } = {} } = useMe();
  const { loading, error, data } = registerCustomerData;
  const is_gto_sales = userData.is_gtoswiss || userData.is_internal_sales;
  const { data: { getMyPersonalDiscount: myPersonalDiscount } = {} } = useQuery(GET_MY_PERSONAL_DISCOUNT);
  const { data: { getUserCompensationRate: compensationRate } = {} } = useQuery(GET_USER_COMPENSATION_RATE);
  const currentHost = window.location.host;
  const isXDCTEQ = currentHost.includes('xdcteq');
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';
  const isCryptoadvisory = currentHost === 'kyc.cryptoadvisory.li' || currentHost === 'dev-kyc.cryptoadvisory.li';
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';
  const [showNationalityTwo, setShowNationalityTwo] = useState(false);
  const [showNationalityThree, setShowNationalityThree] = useState(false);
  const countryList = getCountryList(publicSettings, isCryptoadvisory);
  const nationalityList = getNationalityList(publicSettings, isCryptoadvisory);

  let whatWillYouUseOptions = publicSettings.token_type === TOKEN_TYPES.UTILITY ? WHAT_WILL_YOU_USE_OPTIONS_UTILITY_TOKEN : WHAT_WILL_YOU_USE_OPTIONS;

  if (isXDCTEQ) {
    whatWillYouUseOptions = WHAT_WILL_YOU_USE_OPTIONS_XDCTEQ;
  } else if (isQdev) {
    whatWillYouUseOptions = useTokenForOptionsQdev;
  }

  const exchangeRates = publicSettings && publicSettings.enable_personal_discount && myPersonalDiscount
    ? myPersonalDiscount
    : baseExchangeRate

  const INTENDED_INVESTMENT = (
    publicSettings.kyc_levels &&
    Object.values(publicSettings.kyc_levels).filter((level) => {
      if (isQdev && level.min_invest_amount > 25000) {
        return false;
      }
      return !level.enable || level.enable.enable_lvl;
    })
    .map((item, index) => {
      const unit = publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF';

      if (item.enable && item.enable.enable_open_end) {
        return `> ${item.min_invest_amount} ${unit}`;
      }

      return `${item.min_invest_amount} - ${item.max_invest_amount} ${unit}`;
    })) || [];

  const formatIntendedInvestment = (numberStr) => {
    return numberStr.replace(/\d+/g, (str) => numberWithCommas(str, 0));
  }

  const textConfig = publicSettings &&
    publicSettings.token_type === "UTILITY"
      ? {
          issuer: "Seller",
          subscription: "Token Purchase",
          subscriber: "purchaser",
        }
      : {
          issuer: "Issuer",
          subscription: "subscription",
          subscriber: "subscriber",
        };

  const [values, setValues] = useState({
    // wallets and terms
    wallet: userData.wallet_type || '',
    sending_wallet: userData.wallet_type || '',
    tokenAddress: userData.wallet_address || userData.eth_receiving_wallet || "",
    tokenSendingAddress: userData.wallet_sending_address || "",
    generatedWallet: '',
    personType: KYC_USER_TYPE_ENUM.notPoliticalPerson.value,
    duties: "",
    relationshipToPEP: "",
    documentType: documentTypeEnum[Object.keys(documentTypeEnum)[0]].value,
    amount: is_gto_sales ? String(userData.token_invest_amount || 0) : "0",
    currency: (publicSettings || {}).base_currency,
    isAddEtherWalletLater: false,
    videoIdentVerifiedTime: 0,
    intended_investment: INTENDED_INVESTMENT[0],
    exactIntendedInvestmentAmount: 0,
    sourceOfFunds: '',
    sourceOfWealth: '',
    prefer_language: 'en',
    gdpr_opt_in: false,
    liquidation_upload_email: null,
    has_legal_protection_insurance: false,
    referal_code: match.params.referalCode || localStorage.getItem("referalCode") || userData.referral_code || '',
    proof_of_residence_type: '',
  });

  const kycAcceptedWallets = useCallback(() => (
    publicSettings.kyc_accepted_wallets
      ? Object.entries(
        publicSettings.kyc_accepted_wallets
      ).filter((ele) => ele[1])
        .map((ele) => ele[0])
      : []
  ), [publicSettings])

  const baseCurrency =
    (publicSettings && publicSettings.kyc_use_base_currency && publicSettings.base_currency) || "chf";

  const [exactIntendedInvestmentAmountValue, setExactIntendedInvestmentAmountValue] = useState(0)

  useEffect(() => {
    if (publicSettings && publicSettings.token_type === TOKEN_TYPES.NONE) {
      const autoSelect = INTENDED_INVESTMENT.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= exactIntendedInvestmentAmountValue
          : range[0] < exactIntendedInvestmentAmountValue
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, exactIntendedInvestmentAmountValue])

  const [amountValue, setAmountValue] = useState(0)

  useEffect(() => {
    if (exchangeRates && publicSettings.token_type !== TOKEN_TYPES.NONE && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION) {
      const amountToBaseCurrency = amountValue
        ? exchangeRates && ethToFiat(
          baseCurrency,
          tokenToEth(amountValue, exchangeRates),
          exchangeRates
        )
        : 0
      const autoSelect = INTENDED_INVESTMENT.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= amountToBaseCurrency
          : range[0] <= amountToBaseCurrency
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line
  }, [amountValue, exchangeRates])

  useEffect(() => {
    if (publicSettings && !values.wallet) {
      setValues({
        ...values,
        wallet: kycAcceptedWallets()[0]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings]);

  useEffect(() => {
    if (publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && publicSettings.enable_swap_token && tokenSwap) {
      setValues({
        ...values,
        amount: `${tokenSwap.newTokenAmount}`,
      })

      setAmountValue(tokenSwap.newTokenAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, tokenSwap])

  const accepted_field_application_individual_fiat =
    (publicSettings && publicSettings.kyc_levels[1].acceptedFieldIndividualFiat) || {};

  const accepted_field_application_individual_crypto =
    (publicSettings && publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto) || {};

  const acceptedCurrencies =
    (publicSettings && publicSettings.accepted_currencies) || {};

  const addEtherWalletSetting =
    (publicSettings && publicSettings.add_ether_wallet_later_option) || false;

  const isShowingOccupation =
    (publicSettings && publicSettings.kyc_occupation) || false;

  const isShowingYearlySalary =
    (publicSettings && publicSettings.kyc_yearly_salary) || false;

  const isShowingEmployerCompanyName =
    (publicSettings && publicSettings.kyc_employer_company_name) || false;

  const isShowingEducationLevel =
    (publicSettings && publicSettings.kyc_education_level) || false;

  const isShowingPhoneNumber =
    (publicSettings && publicSettings.kyc_phone_number) || false;

  const isShowingTotalWealth =
    (publicSettings && publicSettings.kyc_net_investable_assets) || false;

  const isShowingWhatWillYouUse =
    (publicSettings && publicSettings.kyc_what_will_you_use) || false;

  const isShowPreferCurrency =
    (publicSettings && publicSettings.kyc_prefer_currency) || false;

  const isShowIntendedInvestment =
    (publicSettings && publicSettings.kyc_intended_investment && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION) || false;

  const isShowSupportSourceOfFunds =
    (publicSettings && publicSettings.kyc_support_source_of_funds) || false;

  // const isShowSupportSourceOfWealth =
  //   (publicSettings && publicSettings.kyc_support_source_of_wealth) || false;

  const isShowReceivingWallet =
    (publicSettings && (publicSettings.kyc_show_receiving_wallet || publicSettings.kyc_require_receiving_wallet)) || false;

  const isShowIndustry =
    (publicSettings && publicSettings.kyc_require_industry) || false;

  const isShowingLegalProtectionInsurance =
    (publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && publicSettings.kyc_require_legal_protection_insurance) || false;

  const [checkboxValues, setCheckboxValues] = useState({
    IActOnMyOwnAccount: false,
    clientStatement: false,
    acknowledgementOfReceiptTerm1: false,
    acknowledgementOfReceiptTerm2: false,
    acknowledgementOfReceiptTerm3: false,
    confirmationTerms1: false,
    declarationOfConsent1: false,
    declarationOfConsent2: false,
    declarationOfConsent3: false,
    declarationOfConsent4: false,
    declarationOfConsent5: false,
    declarationOfConsent6: false,
    usExportControl: false,
  });

  const [mainInfoValues, setMainInfoValues] = useState({
    ...reduce(mainInfoSchemas, mapSchema, {}),
    birthDate: userData.birth_date,
    email: userData.email,
    firstName: userData.first_name,
    lastName: userData.last_name,
    middleName: userData.middle_name,
    phoneNumber: userData.phone || '',
  });

  const [addressValues, setAddressValues] = useState(
    reduce(addressInfoSchemas, mapSchema, {})
  );
  const [fileValues, setFileValues] = useState(
    reduce(filesSchemas, mapSchema, {})
  );

  const [supportedSourceOfFundFiles, setSupportedSourceOfFundFiles] = useState(
    []
  );

  const [documentProofOfResidence, setDocumentProofOfResidence] = useState(null);

  let prospectusOrIssuingDate, prospectusOrIssuing, paperLink;

  switch ((publicSettings || {}).prospectus_or_issuing) {
    case PROSPECTUS_OR_ISSUING.ISSUING:
      prospectusOrIssuing = "Issuing Guidelines";
      prospectusOrIssuingDate = moment(
        (publicSettings || {}).issuing_guidelines
      ).format("YYYY-MM-DD");
      break;
    case PROSPECTUS_OR_ISSUING.PROSPECTUS:
      prospectusOrIssuing = "Prospectus";
      prospectusOrIssuingDate = moment((publicSettings || {}).prospectus_date).format(
        "YYYY-MM-DD"
      );
      paperLink = publicSettings.prospectus_url
      break;
    default:
      prospectusOrIssuing = "Token Sale Agreement";
      prospectusOrIssuingDate = moment(
        (publicSettings || {}).token_sale_agreement_date
      ).format("YYYY-MM-DD");
      break;
  }

  const getDownloadUrl = (path) =>
    `${getExternalFileUrl(path)}`;

  const handleChange = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.value });

    if (name === 'exactIntendedInvestmentAmount') {
      setExactIntendedInvestmentAmountValue(event.target.value);
    }
  };

  const isAmountEquallyTwoLVLCurrency =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    ) >= +publicSettings.kyc_levels[1].min_invest_amount;

  const isInvestingKycLevel1 =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    ) >= +publicSettings.kyc_levels[1].min_invest_amount;

  const isInvestingKycLevel2 =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    ) >= +publicSettings.kyc_levels[2].min_invest_amount;

  const isInvestingKycLevel3 =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    ) >= +publicSettings.kyc_levels[3].min_invest_amount;

  const tokenToBaseCurrency =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    );

  const currentLevel = publicSettings.kyc_levels ? Object.values(publicSettings.kyc_levels).filter((item) => {
    return !item.enable || item.enable.enable_lvl;
  }).reduce((all, item) => all + (tokenToBaseCurrency >= item.min_invest_amount ? 1 : 0), 0) : 0;

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = value ? String(parseInt(value)) : String(value);
    setValues({ ...values, amount });
    setAmountValue(amount);
  };

  const onChangeCheckbox = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const onChangeTab =
    (name, state, setState, value = "") =>
    () => {
      if (value) {
        setState({ ...state, [name]: value });
      }
    };

  const onDropFile = (name) => (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    };

    setFileValues({ ...fileValues, [name]: newImage });
  };

  const onSend = () => {
    const files = Object.keys(fileValues).reduce(
      (prev, current) => ({
        ...prev,
        [current]: fileValues[current].img,
      }),
      {}
    );

    const supportedSourceOfFundUploadFiles = supportedSourceOfFundFiles.map(
      (fileValues) => {
        return fileValues.img;
      }
    );

    let inputData = {
      ...pick(mainInfoValues, [...Object.keys(mainInfoSchemas)]),
      ...pick(values, [
        "wallet",
        "sending_wallet",
        "tokenAddress",
        "tokenSendingAddress",
        "personType",
        "duties",
        "relationshipToPEP",
        "documentType",
        "amount",
        "currency",
        "intended_investment",
        'prefer_language',
        'gdpr_opt_in',
        'has_legal_protection_insurance',
        'referal_code',
        'proof_of_residence_type',
      ]),
      ...pick(files, [...Object.keys(filesSchemas)]),
      wallet: values.wallet,
      isAddEtherWalletLater: values.isAddEtherWalletLater || isFiat(values.currency),
      legal_protection_insurance: values.has_legal_protection_insurance
        ? mainInfoValues.legal_protection_insurance
        : '',
      policy_number: values.has_legal_protection_insurance
        ? mainInfoValues.policy_number
        : '',
    };

    inputData = {
      ...inputData,
      ...pick(values, [
        "sourceOfFunds",
        "sourceOfFundsOther",
        "sourceOfWealth",
        "sourceOfWealthOther",
        "videoIdentVerifiedTime",
      ]),
      supportedSourceOfFundUploadFiles: supportedSourceOfFundUploadFiles,
      documentProofOfResidence: (documentProofOfResidence && documentProofOfResidence.img) || null,
      exactIntendedInvestmentAmount: +values.exactIntendedInvestmentAmount,
      liquidation_upload_email: (values.liquidation_upload_email && values.liquidation_upload_email.img) || null,
    };
    inputData.birthDate = moment(mainInfoValues.birthDate).format(
      "YYYY-MM-DD"
    );

    if (addressInfoSchemas) {
      inputData = {
        ...inputData,
        ...pick(addressValues, [...Object.keys(addressInfoSchemas)]),
      };
    }

    registerCustomer({ variables: { input: inputData } }).catch(() => {});
    // ReactPixel.track("SubmitApplication");
  };

  const onDropSupportedSourceOfFundFiles = (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    };
    setSupportedSourceOfFundFiles([...supportedSourceOfFundFiles, newImage]);
  };

  const removeDropSupportedSourceOfFundFiles = (index) => {
    const newSupportedSourceOfFundFiles = [...supportedSourceOfFundFiles];
    const removedEle = remove(newSupportedSourceOfFundFiles, (file, indx) => {
      return indx === index;
    });

    if (removedEle) {
      setSupportedSourceOfFundFiles(newSupportedSourceOfFundFiles);
    }
  };

  const onDropDocumentProofOfResidence = (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    };
    setDocumentProofOfResidence(newImage);
  };

  const removeDropDocumentProofOfResidence = () => {
    setDocumentProofOfResidence(null);
  };

  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type = "text",
    isRequired,
    helperText,
    state,
    setState,
    disabled = false,
    setter = null,
    labelPostfix = null,
    options = [],
    classWrapper = "",
    InputProps
  } = {}) => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, propertyName);

    let input = null;

    switch (type) {
      case "phone":
        input = (
          <div>
            <PhoneInput
              defaultCountry="us"
              onChange={
                setter ||
                ((value) => {
                  setState({
                    ...state,
                    [propertyName]: value,
                  });
                })
              }
              variant="outlined"
              disabled={disabled || loading}
              fullWidth
              value={state[propertyName] || ""}
            />
            <FormHelperText error={errorsTexts.length !== 0}>
              {helperText || (errorsTexts && errorsTexts.join(". ")) || ""}
            </FormHelperText>
          </div>
        );
        break;
      case "select":
        input = (
          <TextField
            className="input-bordered"
            select
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            margin="none"
            variant="outlined"
            fullWidth
            error={errorsTexts.length !== 0}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {propertyName === 'intended_investment' ? formatIntendedInvestment(option) : option}
              </MenuItem>
            ))}
          </TextField>
        );
        break;
      default:
        input = (
          <InputThemeLite
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            type={type}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
            fullWidth
          />
        );
    }

    return (
      <div className="field">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="field__label" key={`label-${propertyName}`}>
          {t(label)}
          {isRequired && <span className="text__red"> *</span>}
          {labelPostfix}
        </div>
        <div className={classNames('field__wrap', classWrapper)} key={propertyName}>
          {input}
        </div>
      </div>
    );
  };

  const renderInputFieldNoWrap = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type = "text",
    isRequired,
    helperText,
    state,
    setState,
    disabled = false,
    setter = null,
    labelPostfix = null,
    options = [],
    classWrapper = "",
    InputProps
  } = {}) => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, propertyName);

    let input = null;

    switch (type) {
      case "phone":
        input = (
          <div>
            <PhoneInput
              defaultCountry="li"
              preferredCountries={["li", "de"]}
              excludeCountries={["us", "ca"]}
              onChange={
                setter ||
                ((value) => {
                  setState({
                    ...state,
                    [propertyName]: value,
                  });
                })
              }
              disabled={disabled || loading}
              fullWidth
              value={state[propertyName] || "+423"}
            />
            <FormHelperText error={errorsTexts.length !== 0}>
              {helperText || (errorsTexts && errorsTexts.join(". ")) || ""}
            </FormHelperText>
          </div>
        );
        break;
      case "select":
        input = (
          <TextField
            className="input-bordered"
            select
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            margin="none"
            variant="outlined"
            fullWidth
            error={errorsTexts.length !== 0}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
                {propertyName === 'industry' && option === 'Other' && t(' (please describe)')}
              </MenuItem>
            ))}
          </TextField>
        );
        break;
      default:
        input = (
          <InputThemeLite
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            type={type}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
            fullWidth
          />
        );
    }

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="field__label" key={`label-${propertyName}`}>
          {t(label)}
          {isRequired && <span className="text__red"> *</span>}
          {labelPostfix}
        </div>
        <div className={classNames('field__wrap', classWrapper)} key={propertyName}>
          {input}
        </div>
      </>
    );
  };

  const renderInputArrayNoWrap = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type,
    isRequired,
    state,
    setState,
  } = {}) => (
    <>
      <div className="field__label" key={`label-${propertyName}`}>
        {label}
        {isRequired && <span className="text__red"> *</span>}

        <div className="field__label_action">
          <AddIcon
            className={classes.controlIcon}
            onClick={() => {
              if (state[propertyName].length < 15) {
                const newArray = state[propertyName];
                setState({
                  ...state,
                  [propertyName]: [...newArray, ""],
                });
              }
            }}
          />
          <RemoveIcon
            className={classes.controlIcon}
            onClick={() => {
              if (state[propertyName].length > 1) {
                const newArray = state[propertyName].slice(
                  0,
                  state[propertyName].length - 1
                );
                setState({
                  ...state,
                  [propertyName]: newArray,
                });
              }
            }}
          />
        </div>
      </div>

      <div className="field__wrap" key={propertyName}>
        {state[propertyName].map((v, i) => {
          const errorsTexts = extractMsgFromErrByPropertyName(
            error,
            `${propertyName}.${i}`
          );

          return (
            <TextField
              // eslint-disable-next-line react/no-array-index-key
              key={`${propertyName}-${i}`}
              className={classNames("input-bordered", classes.inputText)}
              value={v || ""}
              onChange={(event) => {
                const arrayValues = state[propertyName];
                arrayValues[i] = event.target.value;

                setState({
                  ...state,
                  [propertyName]: arrayValues,
                });
              }}
              margin="none"
              type={type || "text"}
              error={errorsTexts.length !== 0}
              helperText={(errorsTexts && errorsTexts.join(". ")) || ""}
              variant="outlined"
              disabled={loading}
              fullWidth
            />
          );
        })}
      </div>
    </>
  );

  const helperTextForWalletCompliant = () => {
    switch (values.wallet) {
      case "eth":
        return "Note: Address should be ERC20-compliant.";
      case "ixo_id":
        return "Note: Address should be IXO-ID-compliant.";
      case "cosmos":
        return "Note: Address should be Cosmos-compliant.";
      default:
        return '';
    }
  };

  const renderEqualTo = () => {
    switch (values.currency) {
      case "eth":
        return (
          <>
            { t('Equals') } {numberWithCommas(tokenToEth(values.amount, exchangeRates))}{" "}
            ETH
          </>
        );
      case "usdc":
        return (
          <>
            { t('Equals') } {numberWithCommas(tokenToUsdc(values.amount, exchangeRates))}{" "}
            USDC
          </>
        );
      default:
        return (
          <>
            { t('Equals') }{" "}
            {numberWithCommas(
              ethToFiat(
                values.currency,
                tokenToEth(values.amount, exchangeRates),
                exchangeRates
              )
            )}{" "}
            {(values.currency || "").toUpperCase()}
          </>
        );
    }
  };

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  const isDescriptionOfFundsRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return getIntendedInvestmentOptions().indexOf(values.intended_investment) > 0
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      const tokenInBaseCurrency = exchangeRates && ethToFiat(
        baseCurrency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      )

      const currentLevel = Object.values(publicSettings.kyc_levels).filter((item) => {
        return !item.enable || item.enable.enable_lvl;
      }).reduce((all, item) => all + (tokenInBaseCurrency >= item.min_invest_amount ? 1 : 0), 0)

      return currentLevel > 1
    }

    return false
  }

  const isDescriptionOfWealthRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return getIntendedInvestmentOptions().indexOf(values.intended_investment) > 0
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      const tokenInBaseCurrency = exchangeRates && ethToFiat(
        baseCurrency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      )

      const currentLevel = Object.values(publicSettings.kyc_levels).filter((item) => {
        return !item.enable || item.enable.enable_lvl;
      }).reduce((all, item) => all + (tokenInBaseCurrency >= item.min_invest_amount ? 1 : 0), 0)

      return currentLevel > 1
    }

    return false
  }

  const setGeneratedWallet = (generatedWallet) => {
    setValues({
      ...values,
      generatedWallet,
      tokenAddress: generatedWallet,
      wallet: !values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet,
    })
  }

  const renderPaymentsForm = () => {
    const minimumInvestAmount =
      (publicSettings && publicSettings.minimum_investment_amount) || 15000;

    let prospectusOrIssuingDate, prospectusOrIssuing, paperLink;

    switch ((publicSettings || {}).prospectus_or_issuing) {
      case PROSPECTUS_OR_ISSUING.ISSUING:
        prospectusOrIssuing = "Issuing Guidelines";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).issuing_guidelines_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.issuing_guidelines;
        break;
      case PROSPECTUS_OR_ISSUING.PROSPECTUS:
        prospectusOrIssuing = "Prospectus label";
        prospectusOrIssuingDate = moment((publicSettings || {}).prospectus_date).format(
          "YYYY-MM-DD"
        );
        paperLink = publicSettings.prospectus_url;
        break;
      case PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM:
        prospectusOrIssuing = "Token Sale Agreement";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).memorandum
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.memorandum_url;
        break;
      case PROSPECTUS_OR_ISSUING.SAFE:
        prospectusOrIssuing = "Token Safe";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).token_safe_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.token_safe_url;
        break;
      default:
        prospectusOrIssuing = "Token Sale Agreement";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).token_sale_agreement_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.token_sale_agreement_url;
        break;
    }

    const renderHelperMinimumText = () => {
      return <>
        {t('The minimum CONFIG_SUBSCRIPTION amount per investor is BASE_CURRENCY MINIMUMINVESTAMOUNT or the equivalent in ', {
          CONFIG_SUBSCRIPTION: textConfig.subscription,
          BASE_CURRENCY: baseCurrency.toUpperCase(),
          MINIMUMINVESTAMOUNT: minimumInvestAmount,
          })}
        {' '}
        {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
          .filter((name) => acceptedCurrencies[name])
          .map((name) => name.toUpperCase())
          .join("/")
        }
        {' '}
        {publicSettings && publicSettings.token_type !== "UTILITY"
          ? <>
              {t('according to the')}
              {' '}
              {paperLink ? <a href={paperLink} target="_blank" rel="noopener noreferrer" className="text__primary">{t(prospectusOrIssuing)}</a> : prospectusOrIssuing}
              {' '}
              {t('dated')}
              {' '}
              {prospectusOrIssuingDate}
            </>
          : t('according. All purchases are subject to the specific terms of the Token Purchase Agreement.')
        }
      </>
    }

    return typeof exchangeRates === "undefined" ? null : (
      <>
        {publicSettings && publicSettings.token_type === "UTILITY" ? (
          <div className="refund__content">
            { t('I wish to purchase tokens to the value I have selected below. I understand that this purchase must be paid for within 10 days of my order being accepted and after I have signed a Token Purchase Agreement. If I do not send my payment within this period, my order will be cancelled.') }
            <span className="space space__32">{' '}</span>
          </div>
        ) : (
          <>
            {(() => {
              if (!publicSettings) {
                return null;
              }

              if (publicSettings.token_type === TOKEN_TYPES.NONE && kycType === CUSTOMER_TYPES.INDIVIDUAL) {
                return null;
              }

              if (publicSettings.token_type === TOKEN_TYPES.LIQUIDATION) {
                return (
                  <div className="refund__content">
                    {`After you submitted the KYC, we will send you a contract to Sign. Once the claim was approved by the Lawyer, we will send you USDC to the same wallet, which you have used to send in your ${publicSettings.token_name} Tokens to the Lawyer. `}
                    <span className="space space__32">{' '}</span>
                  </div>
                );
              }

              return (
                <div className="refund__content">
                  {`At the same time as the ${textConfig.subscription} is accepted, the ${textConfig.subscriber} is requested to transfer the ${textConfig.subscription} amount to the following special account of the ${textConfig.issuer} within 10 days. These details will be used to complete your purchase, after your account has been activated.`}
                  <span className="space space__32">{' '}</span>
                </div>
              )
            })()}
          </>
        )}

        <div className="settings__item">

          {publicSettings && publicSettings.token_type === TOKEN_TYPES.UTILITY
            ? (
            <div className="title-primary settings__title">
                { t('My order to purchase tokens as “the Purchaser”') }
              </div>
            )
            : (
              publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION
              ? (
                <div className="title-primary settings__title">
                  { t('Transfer of Claim to COMPANY_NAME', {
                    COMPANY_NAME: publicSettings.company.name
                  }) }
                </div>
              )
              : (
                <div className="title-primary settings__title">
                  Here with subscribe (the “{textConfig.subscriber}”): in{" "}
                  {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
                    .filter((name) => acceptedCurrencies[name])
                    .map((name) => name.toUpperCase())
                    .join("/")}
                </div>
              )
            )
          }

          <div className="settings__fieldset">
            {publicSettings && publicSettings.token_type !== TOKEN_TYPES.NONE && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
              <>
                {publicSettings.enable_swap_token && tokenSwap && (
                  <div className="settings__row flex__row flex__wrap">
                    <div className="field">
                      <div className="field__label">
                        {publicSettings && publicSettings.old_token_symbol
                          ? `${publicSettings.old_token_symbol} amount`
                          : 'Old token amount'
                        }
                      </div>
                      <div className="field__wrap">
                        <InputThemeLite
                          value={tokenSwap.oldTokenAmount}
                          disabled
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="settings__row flex__row flex__wrap">
                  {renderInputField({
                    propertyName: "amount",
                    type:
                      publicSettings.token_type === TOKEN_TYPES.BOND
                        ? "text"
                        : "number",
                    label: publicSettings.enable_swap_token && tokenSwap
                      ? `${publicSettings.token_symbol} amount`
                      : 'Tokens',
                    isRequired: false,
                    helperText: renderHelperMinimumText(),
                    state: values,
                    setState: setValues,
                    setter: handleAmountChange,
                    disabled: publicSettings.enable_swap_token && tokenSwap
                  })}

                  {values.amount && (
                    <div className="field d__flex flex__row flex__wrap flex__justify_start flex__align_center">
                      <div className="create__line remove__line">
                        <div className="create__info text__primary">
                          {renderEqualTo()}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {publicSettings && publicSettings.token_type === "NONE" && publicSettings.require_exact_investment_amount_on_none && (
              <>
                {renderInputField({
                  propertyName: "exactIntendedInvestmentAmount",
                  type: "number",
                  label: "Exact investment amount",
                  classWrapper: "field__wrap-select",
                  isRequired: true,
                  state: values,
                  setState: setValues,
                  InputProps: {
                    endAdornment: <InputAdornment position="end">
                      <strong>{publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF'}</strong>
                    </InputAdornment>,
                  }
                })}
              </>
            )}
            {publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && (
              <div className="settings__row flex__row flex__wrap">
                {renderInputField({
                    propertyName: 'amount',
                    type:'text',
                    label: `${publicSettings.token_name} Tokens`,
                    isRequired: false,
                    state: values,
                    setState: setValues,
                    disabled: window.location.pathname === '/transfer-claim/claim-with-liquidator',
                  })
                }
                <div className="field d__flex flex__row flex__wrap flex__justify_start flex__align_center">
                  <div className="create__line remove__line">
                    <div className="create__info text__green">
                      {+values.amount && publicSettings
                        ? `= ${(values.amount * compensationRate).toFixed(18)} USDC`
                        : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Intended investment */}
            {isShowIntendedInvestment && (
              <>
                <DropdownMaterial
                  label={`${
                    publicSettings && publicSettings.token_type === "UTILITY"
                      ? "Intended Purchase Value"
                      : "Intended investment"
                  }`}
                  state={values}
                  setState={setValues}
                  propertyName="intended_investment"
                >
                  {INTENDED_INVESTMENT.map((option) => (
                    <MenuItem key={option} value={(option || "")}>
                      {option}
                    </MenuItem>
                  ))}
                </DropdownMaterial>

              </>
            )}

            {/* Payment currency */}
            {isShowPreferCurrency && (
              <div className="field field-radio__material">
                <div className="field__label">
                  { t('My preferred payment currency') }
                </div>

                <div className={classNames('field__wrap field__wrap-radio field__wrap-radio_text_gray', Styles.radio_fixed)}>
                  <RadioGroup
                    value={(values || {}).currency}
                    onChange={({ target: { value } }) =>
                      setValues({ ...values, currency: value })
                    }
                  >
                    {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
                      .filter((name) => acceptedCurrencies[name])
                      .map((name) => (
                        <FormControlLabel
                          key={name}
                          label={name.toUpperCase()}
                          value={name}
                          control={<Radio color="primary" />}
                        />
                      ))}
                  </RadioGroup>
                </div>
              </div>
            )}

            {/* Receiving Wallet */}
            {isShowReceivingWallet && (
              <>
                {kycAcceptedWallets().length > 0 && (
                  <DropdownMaterial
                    label={ t('Select a Wallet Address type') }
                    propertyName="wallet"
                    state={values}
                    setState={setValues}
                  >
                    {kycAcceptedWallets().map((option) => (
                      <MenuItem key={option} value={(option || "")}>
                        {walletLabelTextMapping[option] || option.toUpperCase()}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                )}
              </>
            )}

            {/* Add receiving wallet address input */}
            {isShowReceivingWallet && (
              <>
                {!publicSettings.force_generate_wallet && renderInputField({
                  propertyName: "tokenAddress",
                  type: "text",
                  label: "My receiving wallet address",
                  isRequired: publicSettings && (publicSettings.enable_cashlink || publicSettings.kyc_require_receiving_wallet),
                  helperText: (publicSettings && !publicSettings.hide_wallet_format_note && t(helperTextForWalletCompliant())) || undefined,
                  state: values,
                  setState: setValues,
                  disabled: publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && window.location.pathname === '/transfer-claim/claim-with-liquidator',
                })}
                {publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
                  <div className="field">
                    <WalletRequest
                      type={!values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet}
                      generatedWallet={values.generatedWallet}
                      onComplete={setGeneratedWallet}
                    />
                  </div>
                )}
                <br />
              </>
            )}

            {!isFiat(values.currency) && (
              <>
                {/* Add wallet address later checkbox */}
                {addEtherWalletSetting ? (
                  <div className="create__line">
                    <div className="create__info">
                      {t('I will add my Sending Wallet address later through my profile')}
                    </div>

                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch__input"
                        checked={values.isAddEtherWalletLater}
                        onChange={onChangeCheckbox(
                          "isAddEtherWalletLater",
                          values,
                          setValues
                        )}
                        value="isAddEtherWalletLater"
                      />

                      <div className="switch__inner">
                        <div className="switch__box">{' '}</div>
                      </div>
                    </label>
                  </div>
                ) : null}

                {/* Sending Wallet */}
                <DropdownMaterial
                  label={ t('Select a Wallet Address type') }
                  propertyName="sending_wallet"
                  state={values}
                  setState={setValues}
                  isRequired={!values.isAddEtherWalletLater}
                >
                  {getSendingWalletTypeOptions(values.currency).map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                      {option[1]}
                    </MenuItem>
                  ))}
                </DropdownMaterial>

                {/* Add sending wallet address input */}
                <>
                  {renderInputField({
                    propertyName: "tokenSendingAddress",
                    type: "text",
                    label: "My sending wallet address",
                    isRequired: !values.isAddEtherWalletLater,
                    helperText: (publicSettings && !publicSettings.hide_wallet_format_note && t(helperTextForWalletCompliant())) || undefined,
                    state: values,
                    disabled: values.isAddEtherWalletLater,
                    setState: setValues,
                    labelPostfix: (publicSettings || {})
                      .source_of_address_for_sending_tokens_example_path && (
                      <a
                        className={classes.sourceOfFundsExample}
                        href={getDownloadUrl(
                          publicSettings.source_of_address_for_sending_tokens_example_path
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        { t('Show Example') }
                      </a>
                    ),
                  })}
                </>
              </>
            )}

            {/* Referal code */}
            <>
              {values.referal_code &&
                publicSettings &&
                publicSettings.kyc_referal_code && (
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={ t('Referral Code') }
                    value={values.referal_code}
                    disabled
                  />
                )}
            </>
          </div>
        </div>
      </>
    );
  };

  const renderPersonalDetailForm = () => (
    <>
      <div className="refund__content">
        {publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && (
          <>
            Please make sure, to use the same Data as you used back during the ICO of {publicSettings.token_name} tokens,
            so that the Lawyer will have it easy to match the Claim Transfer from you to {publicSettings.company.name}.
          </>
        )}
        {publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
          <>
            {publicSettings.kyc_text || ""}
            {'. '}
            { t('Please type carefully, as these details cannot be edited after you have submitted the form.') }
          </>
        )}
        <span className="space space__32">{' '}</span>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Personal details')}
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields">
            {Object.keys(mainInfoSchemas).map((key) => {
              if (mainInfoSchemas[key].type === "array") {
                return mainInfoSchemas[key].adminOnly ? null : (
                  <div className="field" key={key}>
                    {renderInputArrayNoWrap({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: mainInfoSchemas[key].label || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </div>
                );
              }

              if (
                mainInfoSchemas[key].isOver900USDField &&
                !isAmountEquallyTwoLVLCurrency
              ) {
                return null;
              }

              if (isFiat(values.currency)) {
                if (
                  !accepted_field_application_individual_fiat[key] &&
                  accepted_field_application_individual_fiat[key] !== undefined
                ) {
                  return null;
                }
              }

              if (!isFiat(values.currency)) {
                if (
                  !accepted_field_application_individual_crypto[key] &&
                  accepted_field_application_individual_crypto[key] !== undefined
                ) {
                  return null;
                }
              }

              if (mainInfoSchemas[key].type === "date") {
                const errorsTexts = extractMsgFromErrByPropertyName(error, key);

                return mainInfoSchemas[key].adminOnly ? null : (
                  <div className="field" key={key}>
                    <div className="field__label" key="birth_date">
                      {t(mainInfoSchemas[key].label)}
                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          views={["year", "month", "date"]}
                          openTo="year"
                          disableFuture
                          variant="outlined"
                          format="dd.MM.yyyy"
                          margin="none"
                          className="input_border_date"
                          id="date-picker-dialog"
                          value={mainInfoValues[key]}
                          error={errorsTexts.length !== 0}
                          helperText={
                            (errorsTexts && errorsTexts.join(". ")) || ""
                          }
                          onChange={(date) =>
                            setMainInfoValues({ ...mainInfoValues, [key]: date })
                          }
                          fullWidth={true}
                          InputProps={{ className: 'field__input'}}
                          invalidDateMessage={t('Invalid Date Format')}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                );
              }

              if (key === "descriptionOfFunds") {
                if (isDescriptionOfFundsRequired()) {
                  const postfix = (publicSettings || {})
                    .source_of_funds_example_path && (
                      <div className={classNames("card__head", classes.cardHead)}>
                        <div className="card__title card__title-has_icon">
                          { t('Source Of Funds') }
                          { mainInfoSchemas[key].isRequired && <span className="text__red"> *</span> }
                          {" "}
                          <PopupState popupId="declaration-relevant" variant="popover" >
                            {(popupState) => (
                              <>
                                <span {...bindTrigger(popupState)} className="actions__popover-trigger">
                                  <InfoIcon
                                    className={classNames('info')}
                                    name='info'
                                    size="16"
                                  />
                                </span>

                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  className="actions__popover"
                                >
                                  <div className="actions__body actions__body_l actions__body-text">
                                    <div className="tips__action">
                                      <p>
                                        {t('Where does the funds used for this transaction come from?')}
                                      </p>
                                    </div>
                                  </div>
                                </Popover>
                              </>
                            )}
                          </PopupState>

                          {(publicSettings || {}).source_of_funds_example_path && (
                            <>
                              <a
                                className={classes.sourceOfFundsExample}
                                href={getDownloadUrl(
                                  publicSettings.source_of_funds_example_path
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {"   "}
                                { t('Show Example') }
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                  );
                  return mainInfoSchemas[key].adminOnly ? null : (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        isRequired: false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                        labelPostfix: postfix,
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "descriptionOfWealth") {
                if (!isDescriptionOfWealthRequired()) {
                  return null;
                }
                const postfix = (publicSettings || {})
                  .source_of_funds_example_path && (
                    <div className={classNames("card__head", classes.cardHead)}>
                      <div className="card__title card__title-has_icon">
                        { t('Source Of Wealth') }
                        { mainInfoSchemas[key].isRequired && <span className="text__red"> *</span> }
                        {" "}
                        <PopupState popupId="declaration-relevant" variant="popover" >
                          {(popupState) => (
                            <>
                              <span {...bindTrigger(popupState)} className="actions__popover-trigger">
                                <InfoIcon
                                  className={classNames('info')}
                                  name='info'
                                  size="16"
                                />
                              </span>

                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                className="actions__popover"
                              >
                                <div className="actions__body actions__body_l actions__body-text">
                                  <div className="tips__action">
                                    <p>
                                      {t('How did you make your wealth?')}
                                    </p>
                                  </div>
                                </div>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      </div>
                    </div>
                );
                return mainInfoSchemas[key].adminOnly ? null : (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                      labelPostfix: postfix,
                    })}
                  </React.Fragment>
                );
              }

              if (mainInfoSchemas[key].id_name === "yearly_salary") {
                if (isShowingYearlySalary) {
                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: "select",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                        options: mainInfoSchemas[key].options.map(option => option.replaceAll('CHF', baseCurrency.toUpperCase())),
                        classWrapper: "field__wrap-select"
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "education_level") {
                if (isShowingEducationLevel) {
                  return (
                    <DropdownMaterial
                      key={key}
                      label={mainInfoSchemas[key].label || ""}
                      isRequired={mainInfoSchemas[key].isRequired || false}
                      state={mainInfoValues}
                      setState={setMainInfoValues}
                      propertyName="education_level"
                    >
                      {mainInfoSchemas[key].options.map((option) => (
                        <MenuItem key={option} value={(option)}>
                          {option}
                        </MenuItem>
                      ))}
                    </DropdownMaterial>
                  );
                } else {
                  return null;
                }
              }

              if (mainInfoSchemas[key].id_name === "education_level_other") {
                if (isShowingEducationLevel && mainInfoValues.education_level === 'Other') {
                  return (
                    <React.Fragment key={key}>
                      {renderInputField({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              }

              if (mainInfoSchemas[key].id_name === "occupation") {
                if (isShowingOccupation) {
                  return (
                    <DropdownMaterial
                      key={key}
                      label={mainInfoSchemas[key].label || ""}
                      isRequired={mainInfoSchemas[key].isRequired || false}
                      state={mainInfoValues}
                      setState={setMainInfoValues}
                      propertyName="occupation"
                    >
                      {mainInfoSchemas[key].options.map((option) => (
                        <MenuItem key={option} value={(option)}>
                          {option}
                        </MenuItem>
                      ))}
                    </DropdownMaterial>
                  );
                } else {
                  return null;
                }
              }

              if (mainInfoSchemas[key].id_name === "occupation_other") {
                if (isShowingOccupation && mainInfoValues.occupation === 'Other') {
                  return (
                    <React.Fragment key={key}>
                      {renderInputField({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              }

              if (key === "industry") {
                if (isShowIndustry) {
                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                        options: INDUSTRY_OPTIONS,
                        classWrapper: "field__wrap-select"
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "industryOther") {
                if (isShowIndustry && mainInfoValues.industry === 'Other') {
                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "employer_company_name") {
                if (isShowingEmployerCompanyName) {
                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (mainInfoSchemas[key].id_name === "position") {
                if (!!publicSettings && publicSettings.kyc_show_position) {
                  return (
                    <DropdownMaterial
                      key={key}
                      label={mainInfoSchemas[key].label || ""}
                      isRequired={mainInfoSchemas[key].isRequired || false}
                      state={mainInfoValues}
                      setState={setMainInfoValues}
                      propertyName="position"
                    >
                      {mainInfoSchemas[key].options.map((option) => (
                        <MenuItem key={option} value={(option)}>
                          {option}
                        </MenuItem>
                      ))}
                    </DropdownMaterial>
                  );
                } else {
                  return null;
                }
              }

              if (mainInfoSchemas[key].id_name === "position_other") {
                if (!!publicSettings && publicSettings.kyc_show_position && mainInfoValues.position === 'Other') {
                  return (
                    <React.Fragment key={key}>
                      {renderInputField({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              }

              if (key === "website") {
                if (!!publicSettings && publicSettings.kyc_show_website) {
                  return (
                    <React.Fragment key={key}>
                      {renderInputField({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: currentLevel && currentLevel > 2,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              }

              if (key === "net_investable_assets") {
                if (isShowingTotalWealth) {
                  return (
                    <>
                      <div className="field" key={key}>
                        {renderInputFieldNoWrap({
                          propertyName: key,
                          type: "select",
                          label: mainInfoSchemas[key].label || "",
                          isRequired: mainInfoSchemas[key].isRequired || false,
                          state: mainInfoValues,
                          setState: setMainInfoValues,
                          options: NET_INVESTABLE_ASSETS_OPTIONS.map(option => option.replaceAll('CHF', baseCurrency.toUpperCase())),
                          classWrapper: "field__wrap-select"
                        })}
                      </div>
                    </>

                  );
                } else {
                  return null;
                }
              }

              if (key === "what_will_you_use") {
                if (isShowingWhatWillYouUse) {
                  const tokenName = publicSettings && publicSettings.token_name;

                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: "select",
                        label: t('What is the purpose of your token purchase?'),
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                        options: whatWillYouUseOptions,
                        classWrapper: "field__wrap-select"
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "what_will_you_use_other") {
                if (isShowingWhatWillYouUse && mainInfoValues.what_will_you_use === "Other") {
                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "phoneNumber") {
                if (isShowingPhoneNumber) {
                  return mainInfoSchemas[key].adminOnly ? null : (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        classWrapper: "field__wrap-phone",
                        setState: setMainInfoValues,
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              if (key === "legal_protection_insurance") {
                if (isShowingLegalProtectionInsurance) {
                  return (
                    <React.Fragment key={key}>
                      <div className="field">
                        <SwitchCheckboxTheme
                          defaultChecked={values.has_legal_protection_insurance}
                          onChange={({ target: { checked }}) => setValues({
                            ...values,
                            has_legal_protection_insurance: checked
                          })}
                          value="IActOnMyOwnAccount"
                          switchText="I have legal protection insurance"
                          isRemoveLine
                          isGrayText
                          isLeftSwitch
                        />
                      </div>

                      {values.has_legal_protection_insurance && (
                        <div className="field">
                          {renderInputFieldNoWrap({
                            propertyName: key,
                            type: mainInfoSchemas[key].type || "text",
                            label: mainInfoSchemas[key].label || "",
                            isRequired: mainInfoSchemas[key].isRequired || false,
                            state: mainInfoValues,
                            setState: setMainInfoValues,
                          })}
                        </div>
                      )}
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              }

              if (key === "policy_number") {
                if (isShowingLegalProtectionInsurance && values.has_legal_protection_insurance) {
                  return (
                    <div className="field" key={key}>
                      {renderInputFieldNoWrap({
                        propertyName: key,
                        type: mainInfoSchemas[key].type || "text",
                        label: mainInfoSchemas[key].label || "",
                        isRequired: mainInfoSchemas[key].isRequired || false,
                        state: mainInfoValues,
                        setState: setMainInfoValues,
                      })}
                    </div>
                  );
                } else {
                  return null;
                }
              }

              return mainInfoSchemas[key].adminOnly ? null : (
                <div className="field" key={key}>
                  {renderInputFieldNoWrap({
                    propertyName: key,
                    type: mainInfoSchemas[key].type || "text",
                    label: mainInfoSchemas[key].label || "",
                    isRequired: mainInfoSchemas[key].isRequired || false,
                    state: mainInfoValues,
                    setState: setMainInfoValues,
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="settings__item">
        {
          (
            (isFiat(values.currency) && accepted_field_application_individual_fiat.descriptionOfFunds) ||
            (!isFiat(values.currency) && accepted_field_application_individual_crypto.descriptionOfFunds)
          ) && (
            <>
              <div className="title-primary settings__title">
                {t('Primary source of funds for this purchase')}
              </div>
              <div className="settings__content text__red">
                <FormHelperText
                  error={
                    extractMsgFromErrByPropertyName(error, "sourceOfFunds")
                      .length !== 0
                  }
                >
                  {(extractMsgFromErrByPropertyName(error, "sourceOfFunds") &&
                    extractMsgFromErrByPropertyName(
                      error,
                      "sourceOfFunds"
                    ).join(". ")) ||
                    ""}
                </FormHelperText>
              </div>

              <div className="settings__fieldset">
                <KycVerificationFormSourceOfFunds
                  values={values}
                  setValues={setValues}
                  loading={loading}
                  handleChange={handleChange}
                  publicSettings={publicSettings}
                />
              </div>
            </>
          )
        }
      </div>

      <div className="settings__item">
        {
          (
            (isFiat(values.currency) && accepted_field_application_individual_fiat.descriptionOfWealth) ||
            (!isFiat(values.currency) && accepted_field_application_individual_crypto.descriptionOfWealth)
          ) && (
            <>
              <div className="title-primary settings__title">{t('Primary source of wealth for this purchase')}</div>
              <div className="settings__content text__red">
                <FormHelperText
                  error={
                    extractMsgFromErrByPropertyName(error, "sourceOfWealth")
                      .length !== 0
                  }
                >
                  {(extractMsgFromErrByPropertyName(error, "sourceOfWealth") &&
                    extractMsgFromErrByPropertyName(
                      error,
                      "sourceOfWealth"
                    ).join(". ")) ||
                    ""}
                </FormHelperText>
              </div>

              <div className="settings__fieldset">
                <KycVerificationFormSourceOfWealth
                  values={values}
                  setValues={setValues}
                  loading={loading}
                  handleChange={handleChange}
                  publicSettings={publicSettings}
                />
              </div>
            </>
          )
        }
      </div>

      <KycFormDocumentProofOfResidence
        publicSettings={publicSettings}
        onDropDocumentProofOfResidence={onDropDocumentProofOfResidence}
        documentProofOfResidence={documentProofOfResidence}
        removeDropDocumentProofOfResidence={removeDropDocumentProofOfResidence}
        currency={values.currency}
        currentLevel={currentLevel}
        values={values}
        setValues={setValues}
      />

      {isShowSupportSourceOfFunds && (
        <KycFormSupportedSourceOfFund
          tokenToBaseCurrency={tokenToBaseCurrency}
          publicSettings={publicSettings}
          onDropSupportedSourceOfFundFiles={onDropSupportedSourceOfFundFiles}
          supportedSourceOfFundFiles={supportedSourceOfFundFiles}
          removeDropSupportedSourceOfFundFiles={removeDropSupportedSourceOfFundFiles}
          intendedInvestment={values.intended_investment}
        />
      )}

      {publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && (
        <div className="settings__item">
          <div className="title-primary settings__title">{t('Preference')}</div>

          <div className="settings__fieldset">
            <div className="field__label">
              {t('Which language do you prefer to use for the documents')}:
            </div>
            <div className="field">
              <div className="field__wrap d__flex flex__row flex__wrap field__wrap-radio-500 field__wrap-radio-cleartop">
                <RadioTheme
                  isGrayText
                  value="en"
                  onChange={handleChange("prefer_language", values, setValues)}
                  nameGroup="personType"
                  defaultChecked={values.prefer_language === 'en'}
                  radioText="English"
                />
                <RadioTheme
                  isGrayText
                  value="de"
                  onChange={handleChange("prefer_language", values, setValues)}
                  nameGroup="personType"
                  defaultChecked={values.prefer_language === 'de'}
                  radioText="Deutsch"
                />
              </div>
            </div>
          </div>

          <br />

          <div className="settings__fieldset">
            <div className="field">
              <SwitchCheckboxTheme
                defaultChecked={values.gdpr_opt_in}
                onChange={({ target: { checked }}) => setValues({
                  ...values,
                  gdpr_opt_in: checked
                })}
                value="IActOnMyOwnAccount"
                switchText="GDPR opt-in"
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>

          {values.gdpr_opt_in && (
            <>
              <div className="space space__32">{' '}</div>

              <div className="settings__fieldset">
                <div className="field__label">
                  {t('Please upload all Emails you received from “liquidation-upgrade-program.org” as PDF')}
                  <span className="text__red"> *</span>
                </div>
                <div className="field">
                  <ImageUpload
                    buttonText="Choose Document"
                    buttonClassName="btn btn-primary"
                    imgExtension={['.pdf']}
                    accept="application/pdf"
                    onChange={(img, imgURI) => {
                      const newImage = {
                        img: img.length > 0 ? img[0] : null,
                        imgURI: imgURI.length > 0 ? imgURI[0] : "",
                      };
                      setValues({...values, liquidation_upload_email: newImage });
                    }}
                    maxFileSize={50000000}
                    withPreview
                    singleFile
                    defaultImages={[]}
                  >
                    <div className="summary__title">
                      <p>{ t('Drag and drop the document') }</p>
                    </div>
                  </ImageUpload>

                  {values.liquidation_upload_email && (
                    <>
                      <div className="space space__32">{' '}</div>
                      <div className="settings__top">
                        <div className="settings__label">
                          {values.liquidation_upload_email.img.name || 'Cannot load file'}
                        </div>

                        <button
                          className="button-stroke button-small settings__button"
                          onClick={(e) => {
                            setValues({...values, liquidation_upload_email: null });
                          }}
                        >
                          { t('Remove') }
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Your Address') }
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields">
            {addressInfoSchemas &&
              Object.keys(addressInfoSchemas).map((key) => {
                if (key === "countryOfResidence") {
                  return (
                    <>
                      <div className="field" key={key}>
                        {renderInputFieldNoWrap({
                          propertyName: key,
                          type: "select",
                          label: addressInfoSchemas[key].label || "",
                          isRequired: addressInfoSchemas[key].isRequired || false,
                          state: addressValues,
                          setState: setAddressValues,
                          options: countryList,
                          classWrapper: "field__wrap-select",
                        })}
                      </div>
                    </>
                  );
                }

                if (key === "nationality") {
                  return (
                    <>
                      <div className="field" key={key}>
                        {renderInputFieldNoWrap({
                          propertyName: key,
                          type: "select",
                          label: addressInfoSchemas[key].label || "",
                          isRequired: addressInfoSchemas[key].isRequired || false,
                          state: addressValues,
                          setState: setAddressValues,
                          options: nationalityList,
                          classWrapper: "field__wrap-select",
                        })}
                      </div>
                    </>
                  );
                }

                if (key === "nationality_two") {
                  return (
                    <>
                      { !showNationalityTwo &&
                        <button
                          className="button-stroke w-full text__center"
                          type="button"
                          onClick={() => setShowNationalityTwo(true)}
                        >
                          <InfoIcon
                            name='check'
                            size="24"
                          />{" "}
                          {t('Add new nationality')}
                        </button>
                      }

                      { showNationalityTwo &&
                        <div className="field" key={key}>
                          {renderInputFieldNoWrap({
                            propertyName: key,
                            type: "select",
                            label: addressInfoSchemas[key].label || "",
                            isRequired: addressInfoSchemas[key].isRequired || false,
                            state: addressValues,
                            setState: setAddressValues,
                            options: nationalityList,
                            classWrapper: "field__wrap-select",
                          })}
                        </div>
                      }
                    </>
                  )
                }

                if (key === "nationality_three") {
                  return (
                    <>
                      { showNationalityTwo && !showNationalityThree &&
                        <button
                          className="button-stroke w-full text__center"
                          type="button"
                          onClick={() => setShowNationalityThree(true)}
                        >
                          <InfoIcon
                            name='check'
                            size="24"
                          />{" "}
                          {t('Add new nationality')}
                        </button>
                      }

                      { showNationalityThree &&
                        <div className="field" key={key}>
                          {renderInputFieldNoWrap({
                            propertyName: key,
                            type: "select",
                            label: addressInfoSchemas[key].label || "",
                            isRequired: addressInfoSchemas[key].isRequired || false,
                            state: addressValues,
                            setState: setAddressValues,
                            options: nationalityList,
                            classWrapper: "field__wrap-select",
                          })}
                        </div>
                      }
                    </>
                  )
                }

                return (
                  <div className="field" key={key}>
                    {renderInputFieldNoWrap({
                      propertyName: key,
                      type: addressInfoSchemas[key].type || "text",
                      label: addressInfoSchemas[key].label || "",
                      isRequired: addressInfoSchemas[key].isRequired || false,
                      state: addressValues,
                      setState: setAddressValues,
                    })}
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>

    </>
  );

  const renderDocumentForm = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">
        { t('Legal statements') }
      </div>

      <div className="settings__fieldset">
        {!publicSettings.kyc_video_ident_two_steps && (
          <KycVerificationFormLevelTwo
            documentTypeEnum={documentTypeEnum}
            onChangeTab={onChangeTab}
            setValues={setValues}
            KYC_WALLET_ENUM={KYC_WALLET_ENUM}
            kycType={kycType}
            filesSchemas={filesSchemas}
            onDropFile={onDropFile}
            fileValues={fileValues}
            values={values}
            error={error}
            settings={publicSettings}
            MAX_AMOUNT_USD_WITHOUT_PROOF_IDENTITY={
              MAX_AMOUNT_USD_WITHOUT_PROOF_IDENTITY
            }
            isAmountEquallyTwoLVLCurrency={isAmountEquallyTwoLVLCurrency}
            accepted_field_application_individual_fiat={
              accepted_field_application_individual_fiat
            }
            accepted_field_application_individual_crypto={
              accepted_field_application_individual_crypto
            }
            addressValues={addressValues}
            isInvestingKycLevel1={isInvestingKycLevel1}
            isInvestingKycLevel2={isInvestingKycLevel2}
            isInvestingKycLevel3={isInvestingKycLevel3}
          />
        )}
      </div>
    </div>
  );
  const renderLegalStatementForm = () => (
    <>
      <KycVerificationFormDisclaimer
        publicSettings={publicSettings}
        kycType={kycType}
        textConfig={textConfig}
        checkboxValues={checkboxValues}
        onChangeCheckbox={onChangeCheckbox}
        setCheckboxValues={setCheckboxValues}
        loading={loading}
        values={values}
        addressValues={addressValues}
        setValues={setValues}
        customer={{
          first_name: mainInfoValues.firstName,
          middle_name: mainInfoValues.middleName,
          last_name: mainInfoValues.lastName,
        }}
        prospectusOrIssuingDate={prospectusOrIssuingDate}
        prospectusOrIssuing={prospectusOrIssuing}
        paperLink={paperLink}
        handleChange={handleChange}
        error={error}
        onSend={onSend}
      />
    </>
  );
  const isDisabledProcessVerifyButton = () => {
    if (publicSettings && publicSettings.kyc_skip_legal_disclaimer) {
      return false;
    }

    let tokenTypeCheckboxValues;
    // const tokenType = publicSettings && publicSettings.token_type;

    // if (tokenType === TOKEN_TYPES.UTILITY) {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     "IActOnMyOwnAccount",
    //     "acknowledgementOfReceiptTerm1",
    //     "acknowledgementOfReceiptTerm2",
    //     "acknowledgementOfReceiptTerm3",
    //     "confirmationTerms1",
    //     "declarationOfConsent1",
    //     "declarationOfConsent2",
    //     "declarationOfConsent3",
    //     "declarationOfConsent4",
    //   ]);
    // } else if (tokenType === TOKEN_TYPES.NONE) {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     "acknowledgementOfReceiptTerm1",
    //     "acknowledgementOfReceiptTerm2",
    //     "acknowledgementOfReceiptTerm3",
    //     "declarationOfConsent2",
    //     "declarationOfConsent5",
    //     "declarationOfConsent6",
    //   ]);
    // }  else if (tokenType === TOKEN_TYPES.LIQUIDATION) {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     'IActOnMyOwnAccount',
    //     'acknowledgementOfReceiptTerm1',
    //     'acknowledgementOfReceiptTerm2',
    //     'acknowledgementOfReceiptTerm3',
    //     'confirmationTerms1',
    //     'declarationOfConsent5',
    //     'declarationOfConsent6',
    //   ]);
    // } else {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     "declarationOfConsent5",
    //     "declarationOfConsent6",
    //   ]);
    // }

    // if (!publicSettings.kyc_acknowledgement_of_receipt) {
    //   tokenTypeCheckboxValues = omit(tokenTypeCheckboxValues, [
    //     'IActOnMyOwnAccount'
    //   ]);
    // }

    if (publicSettings.kyc_client_statement) {
      tokenTypeCheckboxValues = pick(checkboxValues, [
        'clientStatement'
      ]);
    }

    if (values.referal_code === 'BlackMantaCapital') {
      tokenTypeCheckboxValues = pick(checkboxValues, [
        'declarationOfConsent4'
      ]);
    }

    // if (!publicSettings.kyc_confirmations) {
    //   tokenTypeCheckboxValues = omit(tokenTypeCheckboxValues, [
    //     'confirmationTerms1',
    //   ]);
    // }

    if (isAwearetech) {
      tokenTypeCheckboxValues = tokenTypeCheckboxValues || {}
      tokenTypeCheckboxValues["usExportControl"] = checkboxValues.usExportControl
      tokenTypeCheckboxValues["applicableLaw"] = checkboxValues.applicableLaw
      tokenTypeCheckboxValues["placeOfJurisdiction"] = checkboxValues.placeOfJurisdiction
      tokenTypeCheckboxValues["severability"] = checkboxValues.severability
    }

    return !!Object.keys(tokenTypeCheckboxValues).find(
      (v) => !tokenTypeCheckboxValues[v]
    );
  };

  // NEW UI IMPLEMENT - START
  const scrollToTopForm = () => {
    const form = document.getElementById('form_wrapper')

    if (form) {
      window.scrollTo(0, form.offsetTop);
    }
  }

  const renderFirstStep = () => {
    const onNext = () => {
      scrollToTopForm();
      setKycFormStepNumber(1);
    };

    return (
      <div className="settings__wrapper">
        <div className="settings__list">
          {publicSettings && renderPaymentsForm()}

          <button className="button settings__button" onClick={onNext}>
            { t('Next') }
          </button>
        </div>
      </div>
    );
  };

  const renderSecondStep = () => {
    const onBack = () => {
      setKycFormStepNumber(0);
    };
    const onNext = () => {
      scrollToTopForm();
      setKycFormStepNumber(publicSettings.kyc_video_ident_two_steps ? 3 : 2);
    };

    return (
      <div className="settings__wrapper">
        <div className="settings__list">
          {renderPersonalDetailForm()}

          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
            <button className="button-stroke" onClick={onBack}>
              { t('Previous') }
            </button>

            {(
              publicSettings.kyc_politically_exposed_persons ||
              !publicSettings.kyc_skip_legal_disclaimer ||
              !publicSettings.kyc_video_ident_two_steps
            ) ? (
              <button className="button" onClick={onNext}>
                { t('Next') }
              </button>
            ) : (
              <>
                {loading ? (
                  <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
                    <CircularProgress />
                  </div>
                ) : (
                  <button
                    className="button"
                    disabled={isDisabledProcessVerifyButton()}
                    onClick={onSend}
                    type="button"
                  >
                    { t('Submit') }
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderThirdStep = () => {
    const onBack = () => {
      setKycFormStepNumber(1)
    };
    const onNext = () => {
      scrollToTopForm();
      setKycFormStepNumber(4)
    };

    return (
      <div className="settings__wraper">
        <div className="settings__list">
          {renderDocumentForm()}

          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
            <button className="button-stroke" onClick={onBack}>
              { t('Previous') }
            </button>

            <button className="button" onClick={onNext}>
              { t('Next') }
            </button>

            {(
              publicSettings.kyc_politically_exposed_persons ||
              !publicSettings.kyc_skip_legal_disclaimer
            ) ? (
              <button className="button" onClick={onNext}>
                { t('Next') }
              </button>
            ) : (
              <>
                {loading ? (
                  <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
                    <CircularProgress />
                  </div>
                ) : (
                  <button
                    className="button"
                    disabled={isDisabledProcessVerifyButton()}
                    onClick={onSend}
                    type="button"
                  >
                    { t('Submit') }
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderFourthStep = () => {
    const onBack = () => {
      setKycFormStepNumber(publicSettings.kyc_video_ident_two_steps ? 1 : 2)
    };

    return (
      <div className="settings__wrapper">
        <div className="settings__list">
          {renderLegalStatementForm()}

          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
            <button className="button-stroke" onClick={onBack}>
              { t('Previous') }
            </button>

            {loading ? (
              <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
                <CircularProgress />
              </div>
            ) : (
              <button
                className="button"
                disabled={isDisabledProcessVerifyButton()}
                onClick={onSend}
                type="button"
              >
                { t('Submit') }
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };
  // NEW UI IMPLEMENT - END

  useEffect(() => {
    if (prefillData) {
      const cleanPrefillData = Object.keys(prefillData).reduce((all, key) => (
        prefillData[key]
          ? {
            ...all,
            [key]: prefillData[key]
          }
          : all
      ), {})
      setValues({
        ...values,
        ...pick(cleanPrefillData, Object.keys(values)),
      })
      setMainInfoValues({
        ...mainInfoValues,
        ...pick(cleanPrefillData, Object.keys(mainInfoValues)),
      })
      setAddressValues({
        ...addressValues,
        ...pick(cleanPrefillData, Object.keys(addressValues)),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefillData])

  useEffect(() => {
    if (publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && userData && userData.wallet_address) {
      checkWalletCompensation({
        variables: {
          walletAddress: userData.wallet_address,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, userData])

  useEffect(() => {
    if (
      publicSettings &&
      publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
      checkWalletCompensationStatus.data &&
      checkWalletCompensationStatus.data.checkWalletCompensation
    ) {
      setValues({
        ...values,
        amount: `${checkWalletCompensationStatus.data.checkWalletCompensation.token}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkWalletCompensationStatus])

  if (data) {
    return <Redirect to="/liveness-check-portal" />;
  }

  if (!publicSettings) {
    return <CircularProgress />
  }

  return kycType === CUSTOMER_TYPES.INDIVIDUAL ? (
    <div id="form_wrapper">
      <div className="h3 nospace__bottom">{ t('KYC Application') }</div>
      <div className="shop__info">{ t('Please make sure your latest data is correct.') }</div>
      <div className="page__title">{' '}</div>

      <div className="card">
        <div className="kyc__stepper kyc__stepper_32">
          <Stepper activeStep={kycFormStepNumber}>
            <Step key={0}>
              {publicSettings && publicSettings.token_type === TOKEN_TYPES.UTILITY
                ? (
                  <StepLabel>{ t('Purchase Order')}</StepLabel>
                )
                : (
                  publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION ? (
                    <StepLabel>{ t('Claim Transfer') }</StepLabel>
                  )
                  : (
                    <StepLabel>{ t('Payments') }</StepLabel>
                  )
                )
              }
            </Step>

            <Step key={1}>
              <StepLabel>{ t('Personal Details') }</StepLabel>
            </Step>

            {!publicSettings.kyc_video_ident_two_steps && (
              <Step key={2}>
                <StepLabel>{ t('Document') }</StepLabel>
              </Step>
            )}

            {
              (
                publicSettings.kyc_politically_exposed_persons ||
                !publicSettings.kyc_skip_legal_disclaimer
              ) && (
                <Step key={3}>
                  <StepLabel>{ t('Legal Statement') }</StepLabel>
                </Step>
              )
            }
          </Stepper>
        </div>
      </div>

      <div className="card">
        {kycFormStepNumber === 0 && renderFirstStep()}
        {kycFormStepNumber === 1 && renderSecondStep()}
        {!publicSettings.kyc_video_ident_two_steps && kycFormStepNumber === 2 && renderThirdStep()}
        {
          (
            publicSettings.kyc_politically_exposed_persons ||
            !publicSettings.kyc_skip_legal_disclaimer
          ) &&
          kycFormStepNumber === 3 &&
          renderFourthStep()
        }
      </div>
    </div>
  ) : (
    <KycCompanyVerificationForm
      publicSettings={publicSettings}
      userData={userData}
      kycFormStepNumber={kycFormStepNumber}
      setKycFormStepNumber={setKycFormStepNumber}
      intendedInvestmentOptions={INTENDED_INVESTMENT}
    />
  );
};

KycVerificationForm.propTypes = {
  mainInfoSchemas: PropTypes.object.isRequired,
  addressInfoSchemas: PropTypes.object,
  filesSchemas: PropTypes.object.isRequired,
  documentTypeEnum: PropTypes.object.isRequired,
  kycType: PropTypes.string.isRequired,
  registerCustomer: PropTypes.func.isRequired,
  registerCustomerData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

export default KycVerificationForm;
