import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const SAVE_SETTINGS = gql`
  mutation settingsSave($input: SettingsInput!) {
    settingsSave(input: $input)
  }
`;
export const DROP_CASHLINK_CONTRACT = gql`
  mutation dropCashlinkContract {
    dropCashlinkContract
  }
`;
export const VERIFY_EVOLVE_DOMAIN = gql`
  mutation verifyEvolveDomain($input: VerifyDomainInput!) {
    verifyEvolveDomain(input: $input)
  }
`;
export const CREATE_EVOLVE_DOMAIN = gql`
  mutation createEvolveDomain($input: CreateEvolveDomainInput!) {
    createEvolveDomain(input: $input)
  }
`;
