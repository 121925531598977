import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'

import InputThemeField from '../InputThemeField'
import { ADMIN_UPDATE_TRANSACTION } from '../../queriesAndMutations'
import Icon from '../Icon'
import { toaster } from '../../utils/toaster'

const ChangeTokenAmountTransactionModal = ({
  transaction,
  openModalChangeTokenAmount = false,
  setOpenModalChangeTokenAmount,
  refetch,
}) => {
  const { t } = useTranslation();
  const [adminUpdateTransaction] = useMutation(ADMIN_UPDATE_TRANSACTION);

  const [tokenAmount, setTokenAmount] = React.useState("");

  const [isDisabledConfirmTransactionBtn, setIsDisabledConfirmTransactionBtn] = useState(false);

  const handleConfirmTransaction = async () => {
    setIsDisabledConfirmTransactionBtn(true);
    try {
      await adminUpdateTransaction({
        variables: {
          id: transaction.id,
          token_amount: tokenAmount,
        },
      });

      toaster.success('Success')
      setOpenModalChangeTokenAmount(false);
      setIsDisabledConfirmTransactionBtn(false);
      refetch();
    } catch (e) {}
  };

  return (
    <Dialog
      open={openModalChangeTokenAmount}
      onClose={() => {
        setOpenModalChangeTokenAmount(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <div className="popup js-popup animation visible popup__kyc-medium">
        <div onClick={() => {setOpenModalChangeTokenAmount(false);}} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-red description__title">{t('Confirm Approved Transaction')}</div>

            <div className="settings__fieldset description__row flex__row flex__wrap">
              <div className="w-full">
                <div className="field__label">
                  {`Name: ${transaction.user.first_name} ${transaction.user.last_name}`}
                </div>
              </div>

              <div className="field w-full">
                <div className="field__wrap field__wrap-select">
                  <InputThemeField
                    classWrapper="field w-full"
                    classLabel="field__label"
                    classInput="field__input"
                    disabled
                    label={t('Old Token Amount')}
                    value={transaction.token_amount}
                  />
                </div>
              </div>

              <div className="field w-full">
                <div className="field__wrap field__wrap-select">
                  <InputThemeField
                    classWrapper="field w-full"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('New Token Amount')}
                    value={tokenAmount}
                    onChange={(e) => {
                      setTokenAmount(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="field">
                <button
                  className="button-stroke"
                  onClick={handleConfirmTransaction}
                  disabled={isDisabledConfirmTransactionBtn}
                >
                  {t('Confirm Transaction')}
                </button>
              </div>
            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={() => {setOpenModalChangeTokenAmount(false);}}
          >
            <Icon
              className={classNames('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeTokenAmountTransactionModal;
