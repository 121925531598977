import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  CircularProgress
} from '@material-ui/core'

import {
  ADD_TOKEN_SWAP,
} from '../../queriesAndMutations'
import { usePublicSettings } from '../../myHooks'
import { toaster } from '../../utils'
import InputTheme from '../../components/InputTheme'

const AddTokenSwapForm = ({
  onComplete,
}) => {
  const [values, setValues] = useState({
    email: '',
    oldTokenAmount: '',
    newTokenAmount: '',
  })
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [addTokenSwap, addTokenSwapStatus] = useMutation(ADD_TOKEN_SWAP);

  const submitAddTokenSwap = () => {
    addTokenSwap({
      variables: {
        input: {
          email: values.email,
          oldTokenAmount: +values.oldTokenAmount,
          newTokenAmount: +values.newTokenAmount,
        }
      }
    }).then(({ data: { addTokenSwap: success }}) => {
      if (success) {
        setValues({
          email: '',
          oldTokenAmount: '',
          newTokenAmount: '',
        })
        toaster.success('Token swap saved')
        onComplete()
      }
    }).catch(() => {})
  }

  return (

    <div className="settings__fieldset">
      <div className="settings__row flex__row flex__wrap">
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label="Email"
          propertyName="email"
          state={values}
          setState={setValues}
          required
        />
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label={publicSettings.old_token_symbol ? `${publicSettings.old_token_symbol} amount` : 'Old token amount'}
          propertyName="oldTokenAmount"
          state={values}
          setState={setValues}
          required
          type="number"
        />
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label={`${publicSettings.token_symbol} amount`}
          propertyName="newTokenAmount"
          state={values}
          setState={setValues}
          required
          type="number"
        />
      </div>

      <div>
        {!addTokenSwapStatus.loading && (
          <button className="button" onClick={submitAddTokenSwap}>Add</button>
        )}
        {addTokenSwapStatus.loading && (
          <CircularProgress />
        )}
      </div>
    </div>
  )
}

export default AddTokenSwapForm
