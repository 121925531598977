import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from 'react-i18next';
import { CUSTOMER_COMPANY } from "../queriesAndMutations";
import KybStatusLog from '../components/KybStatusLog'
import KybDirectorStatus from '../components/KybDirectorStatus'
import KybUboStatus from '../components/KybUboStatus'

const gotoDashboard = () => {
  window.location.href = window.location.origin;
}

const KycStatusCustomerCompany = ({ match }) => {
  const { t } = useTranslation();
  const { error, data } = useQuery(
    CUSTOMER_COMPANY,
    {
      variables: {
        id: match.params.customerId,
      },
      fetchPolicy: "network-only",
    }
  );
  const [status, setStatus] = useState('')
  useEffect(() => {
    if (data) {
      setStatus('loaded')
    } else if (error) {
      setStatus('error')
    } else {
      setStatus('loading')
    }
  }, [data, error])

  return (
    <>
      <div className="page-dashboard__content">
        <div className="card">
          {status === 'loading' && (
            <div className="page-dashboard__content-header">
              <span className="success-dot" />
              <div className="h3 entry__title">
                { t('Please wait...')}
              </div>
            </div>
          )}
          {status === 'error' && (
            <div className="page-dashboard__content-header">
              <div className="h3 entry__title">
                { t('Somethings went wrong')}
              </div>
              <br />
              <p>{error.message.replace('GraphQL error: ', '')}</p>
            </div>
          )}
          { status === 'loaded' && (
            <>
              <div className="page-dashboard__content-header">
                <div className="h3 entry__title">
                  { t('Thank you for your Submission')}
                </div>
              </div>
              <div className="page-dashboard__content-header">
                <KybStatusLog />
                <KybDirectorStatus title={t('Directors-application-success')} />
                <KybUboStatus title={t('UBOs-application-success')} />
              </div>

              <div className="page-dashboard__content-inner">
                <div className="inner-pagination__content-footer">
                  <button className="button button-stroke" onClick={gotoDashboard}>
                    {t('Back to Dashboard')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
    );
};

export default KycStatusCustomerCompany;
