import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { useHistory, NavLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ApplicationIndividual from "../../media/images/individual.png";
import ApplicationCompany from "../../media/images/company.png";
import { toaster } from "../../utils";
import KycVerificationForm from "./KycVerificationForm";
import PackageItem from "./PackageItem";
import KycStatusLog from "../../components/KycStatusLog";
import KybStatusLog from "../../components/KybStatusLog";
import KybDirectorStatus from "../../components/KybDirectorStatus";
import KybUboStatus from "../../components/KybUboStatus";
import { TOKEN_TYPES } from "../../constants/settings";

import {
  MAIN_INFO_SCHEMAS,
  ADDRESS_INFO_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  INDIVIDUAL_DOCUMENT_TYPE_ENUM,
  MAIN_INFO_COMPANY_SCHEMAS,
  COMPANY_FILES_SCHEMAS,
  COMPANY_DOCUMENT_TYPE_ENUM,
} from "../../schemas/kycVerification";
import { USER_KYC_STATUS_TYPES } from "../../constants/user";
import {
  REGISTRATION_INDIVIDUAL_CUSTOMER,
  REGISTRATION_COMPANY_CUSTOMER,
} from "../../queriesAndMutations";
import { useMe } from "../../myHooks";
import { usePublicSettings } from "../../myHooks/useSettings";
import { useTranslation } from 'react-i18next';

const KycVerification = ({ match }) => {
  const { t } = useTranslation();
  const { data } = useMe({ fetchPolicy: 'network-only' });
  const history = useHistory();

  const [registerIndividualCustomer, registerIndividualCustomerData] =
    useMutation(REGISTRATION_INDIVIDUAL_CUSTOMER);
  const [registerCompanyCustomer, registerCompanyCustomerData] = useMutation(
    REGISTRATION_COMPANY_CUSTOMER
  );

  const [values, setValues] = useState({
    open: true,
    tab: "INDIVIDUAL",
    success: false,
  });

  const [kycFormStepNumber, setKycFormStepNumber] = useState(0);
  const [isReturnHome, setIsReturnHome] = React.useState(false);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [newui_values, setValueNew] = useState({
    open: true,
    verifyType: "INDIVIDUAL",
    success: false,
  });
  const onOpenBtnClickIndividual = () => {
    setValueNew({ ...values, open: false, verifyType: "INDIVIDUAL" });
  };

  const onOpenBtnClickCompany = () => {
    setValueNew({ ...values, open: false, verifyType: "COMPANY" });
  };

  useEffect(() => {
    if (publicSettings && data) {
      if (publicSettings.kyc_require_referral) {
        if (!match.params.referalCode && !localStorage.getItem("referalCode") && !data.me.referral_code) {
          toaster.warning(
            <div style={{wordBreak: 'break-word'}}>
              You are trying to access the Application form without a working referral code, please use {' '}
              <div>
                <strong>
                  <span style={{whiteSpace: 'nowrap'}}>{window.location.host}</span>
                  /
                  <span style={{whiteSpace: 'nowrap'}}>application/%ReferralCode%</span>
                </strong>
              </div>
              to set your Referral Code!<br />
              Please note that the %ReferralCode% has to be the real referral code which the project gave you.
            </div>,
            10000)
          setIsReturnHome(true)
        }
      }

      if (!data.me.customer && !data.me.customerCompany) {
        if (publicSettings.onboarding_type === 'easy') {
          history.push('/easy-onboarding-options')
        } else {
          if (!publicSettings.kyc_enable_kyb) {
            setKycFormStepNumber(1);
            onOpenBtnClickIndividual();
          }
        }
      }
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, match, data])

  const onSuccess = (data, kycType) => {
    setValues({ ...values, success: true });
  };

  const renderNewKycVerifyForm = () => (
    <>
      {newui_values.verifyType === "INDIVIDUAL" && (
        <KycVerificationForm
          mainInfoSchemas={MAIN_INFO_SCHEMAS}
          addressInfoSchemas={ADDRESS_INFO_SCHEMAS}
          filesSchemas={INDIVIDUAL_FILES_SCHEMAS}
          documentTypeEnum={INDIVIDUAL_DOCUMENT_TYPE_ENUM}
          kycType="INDIVIDUAL"
          registerCustomer={registerIndividualCustomer}
          registerCustomerData={registerIndividualCustomerData}
          match={match}
          kycFormStepNumber={kycFormStepNumber}
          setKycFormStepNumber={setKycFormStepNumber}
        />
      )}
      {newui_values.verifyType === "COMPANY" && (
        <KycVerificationForm
          mainInfoSchemas={MAIN_INFO_COMPANY_SCHEMAS}
          filesSchemas={COMPANY_FILES_SCHEMAS}
          documentTypeEnum={COMPANY_DOCUMENT_TYPE_ENUM}
          kycType="COMPANY"
          registerCustomer={registerCompanyCustomer}
          registerCustomerData={registerCompanyCustomerData}
          onSuccess={onSuccess}
          match={match}
          kycFormStepNumber={kycFormStepNumber}
          setKycFormStepNumber={setKycFormStepNumber}
        />
      )}
    </>
  );

  if (isReturnHome) {
    return <Redirect to="/" />;
  }

  if (data && data.me && data.me.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED
   && data.me.customerCompanyDirector && data.me.customerCompanyDirector.length > 0) {
    const customerCompanyDirector = data.me.customerCompanyDirector[0]
    if (customerCompanyDirector.status === 'NOT_SUBMITTED') {
      return (<Redirect to={`/director-profile/${customerCompanyDirector.id}`} />)
    } else if (customerCompanyDirector.status === 'VIDEO_IDENT') {
      return (<Redirect to={`/director-profile/${customerCompanyDirector.id}/video-ident`} />)
    } else {
      return (<Redirect to="/" />)
    }
  }

  if (data && data.me && data.me.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED) {
    const meAsDirector = data.me.customerCompany ? data.me.customerCompany.customerCompanyDirector.find(director => director.email === data.me.email) : null;
    const isCompleteLivenessCheck = data.me.customer &&
      (
        (
          data.me.customer.onfidoApplicant &&
          data.me.customer.onfidoApplicant.onfidoChecks.length > 0 &&
          data.me.customer.onfidoApplicant.onfidoChecks.filter(checks => checks.onfidoReports.length > 1).length > 0
        ) || (
          data.me.customer.livenessCheckApplicant &&
          data.me.customer.livenessCheckApplicant.status === 'SUBMITTED'
        ) || (
          data.me.customer.idnowIdent &&
          data.me.customer.idnowIdent.status === 'COMPLETED'
        ) || (
          data.me.customer.complycubeClient &&
          data.me.customer.complycubeClient.complycubeChecks.length > 0
        )
      )

    return (
      <div className="card">
        <div className="h3 entry__title">
          { t('Thank you for your Submission')}
        </div>

        {data.me.customer && (
          <KycStatusLog />
        )}

        {data.me.customerCompany && (
          <>
            <KybStatusLog />
            <KybDirectorStatus title="Directors" />
            <KybUboStatus title="UBOs" />
          </>
        )}

        <div className="answer__btns application-answer__btns">
          <NavLink to="/" className="button-stroke answer__button" activeClassName="">{t('Back to Dashboard')}</NavLink>
          {' '}

          {data.me.customer && !isCompleteLivenessCheck && (
            <a
              href="/liveness-check-portal"
              target="_blank"
              rel="noopener noreferrer"
              className="button text__center answer__button"
            >
              {t('Liveness check not started')}
            </a>
          )}
          {meAsDirector && meAsDirector.status === 'VIDEO_IDENT' && (
            <a
              href={`/director-profile/${meAsDirector.id}/video-ident`}
              target="_blank"
              rel="noopener noreferrer"
              className="button text__center answer__button"
            >
              {t('Liveness check not started')}
            </a>
          )}
          {meAsDirector && meAsDirector.status === 'NOT_SUBMITTED' && (
            <a
              href={`/director-profile/${meAsDirector.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="button text__center answer__button"
            >
              {t('AUTHORIZED SIGNATORY PROFILE NOT SUBMITTED')}
            </a>
          )}
        </div>
      </div>
    );
  }

  if (data && data.me && publicSettings) {
    if (!data.me.is_valid_liquidation_wallet && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION) {
      return <Redirect to="/wallet-validate" />;
    }
  }

  return (
    <>
      {!newui_values.success && data && data.me && data.me.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED &&
        (
          <>
            {newui_values.open && (
              <>
                <div className="h3 nospace__bottom">{ t('KYC Application') }</div>
                <div className="shop__info">{t('Start your KYC process, it only takes up to 5 minutes.')}</div>
                <div className="page__title">{' '}</div>

                <div className="packages">
                  <div className="packages__group">
                    {newui_values.open && (
                      <PackageItem
                        onClick={() => {
                          if (publicSettings.onboarding_type === 'easy') {
                            if (data.me.phone_verified) {
                              history.push('/easy-onboarding')
                            } else {
                              history.push('/easy-onboarding-2fa')
                            }
                          } else {
                            setKycFormStepNumber(0);
                            onOpenBtnClickIndividual();
                          }
                        }}
                        classTitle="title-primary"
                        title={t('Individual')}
                        content={ t('Please continue as an individual if you are a natural person.') }
                        imgPath={ApplicationIndividual}
                        buttonPackage={ t(
                          publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION
                            ? 'Transfer claim'
                            : 'Signup now'
                        ) }
                      />
                    )}

                    {newui_values.open && publicSettings && publicSettings.kyc_enable_kyb && (
                      <PackageItem
                        onClick={() => {
                          setKycFormStepNumber(0);
                          onOpenBtnClickCompany();
                        }}
                        classTitle="title-primary"
                        title={ t('Company') }
                        content={ t('Please continue as an entity if you are a juristic person.') }
                        imgPath={ApplicationCompany}
                        buttonPackage={ t(
                          publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION
                            ? 'Transfer claim'
                            : 'Signup now'
                        ) }
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {!newui_values.open && renderNewKycVerifyForm()}
          </>
        )
      }
    </>
  );
};

export default KycVerification;
