import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon'
import { useMutation } from "@apollo/react-hooks";
import { GET_EVOLVE_ACCESS_TOKEN } from "../../queriesAndMutations";
import { toaster, isProduction } from '../../utils'
import Style from './Style.module.scss'
import { useSettings } from "../../myHooks/useSettings"

const paladisRedirectUrl = 'https://dev-crypto.paladis.com'
const evolveRedirectUrl = isProduction ? 'evolve.investhub.io' : 'dev-evolve.investhub.io'
const investhubRedirectUrl = isProduction ? 'https://app.investhub.io/login' : 'https://dev-app.investhub.io/login'

const SubApp = () => {
  const { t } = useTranslation()
  const [getEvolveAccessToken] = useMutation(GET_EVOLVE_ACCESS_TOKEN);
  const { data, refetch } = useSettings({ fetchPolicy: "network-only" });
  const [values, setValues] = useState({
    isPopupSubAppActive: false,
  })
  const settings = data?.settings

  const handlePopupSubAppClick = () => {
    setValues({ ...values, isPopupSubAppActive: !values.isPopupSubAppActive })
  }

  const handlePopupSubAppAwayClick = () => {
    setValues({ ...values, isPopupSubAppActive: false })
  }

  const onRedirectToEvolve = async () => {
    try {
      const host = window.location.host
      const { data } = await getEvolveAccessToken()
      let evolveUrl = evolveRedirectUrl
      if (settings?.is_white_label === 'true' && settings?.evolve_host) {
        evolveUrl = settings?.evolve_host + settings?.sub_domain
      }
      const { volveAccessToken: token, expires } = data.getEvolveAccessToken;
      const currentHost = window.location.host;
      const isPaladis = currentHost.includes('paladis')
      if (token) {
        window.open(`https://${evolveUrl}/sso?token=${token}&expires=${expires}`, "_blank")
      } else {
        toaster.error("Can not get the token")
      }
    } catch (e) {
      console.log(e)
      toaster.error("Something went wrong")
    }
  }

  const onRedirectToInvesthub = async () => {
      window.open(`${investhubRedirectUrl}`, "_blank")
    }

  return (
      <>
        <ClickAwayListener onClickAway={handlePopupSubAppAwayClick}>
          <div className={`header__item header__item_notifications ${values.isPopupSubAppActive ? "active" : ""}`}>
            <button
              className={`header__head ${values.isPopupSubAppActive ? "active" : ""} ${Style.subapp__head}`}
              onClick={handlePopupSubAppClick}
            >
              <Icon
                className={classNames('grid', 'icon-24')}
                name="grid"
                size="24"
              />
            </button>

            <div className={classNames(
              'header__body',
              Style.subapp__body
            )}>
              <div className="header__list">
                <Link
                  href="/cockpit"
                  className={classNames(
                    'header__notification',
                    Style.subapp__notification
                  )}
                >
                  <div className={classNames(
                    'header__details',
                    Style.subapp__details
                  )}>
                    <div className="header__line">
                      <div className="header__subtitle">Raise</div>
                    </div>
                  </div>
                </Link>

                <button
                  onClick={onRedirectToEvolve}
                  className={classNames(
                    'header__notification',
                    Style.subapp__notification
                  )}
                >
                  <div className={classNames(
                    'header__details',
                    Style.subapp__details
                  )}>
                    <div className="header__line">
                      <div className="header__subtitle">Evolve</div>
                    </div>
                  </div>
                </button>

                {/* <Link
                href="/cockpit"
                className={classNames(
                  'header__notification',
                  Style.subapp__notification
                )}
              >
                <div className={classNames(
                  'header__details',
                  Style.subapp__details
                )}>
                  <div className="header__line">
                    <div className="header__subtitle">Custody</div>
                  </div>
                </div>
              </Link> */}

                <button
                  href="/cockpit"
                  className={classNames(
                    'header__notification',
                    Style.subapp__notification
                  )}
                    onClick={onRedirectToInvesthub}
                >
                  <div className={classNames(
                    'header__details',
                    Style.subapp__details
                  )}>
                    <div className="header__line">
                      <div className="header__subtitle">Investhub</div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </>
    )
  }

  export default SubApp
