import React from 'react'
import { useQuery } from '@apollo/client'
import { CircularProgress } from '@material-ui/core'
import {
  ADMIN_GET_CUSTOMER_COMPANY,
} from '../../queriesAndMutations'
import KompanySearchMapping from './KompanySearchMapping'
import KompanyRequestStatus from './KompanyRequestStatus'
import KompanyMapping from './KompanyMapping'

const AdminCompanyKycCompare = ({ match }) => {
  const { data: { adminGetCustomerCompany: company } = {}, refetch } = useQuery(ADMIN_GET_CUSTOMER_COMPANY, {
    variables: {
      id: match.params.kycId,
    },
  })

  if (!company) {
    return <CircularProgress />
  }

  return (
    <>
      <div className="page__title h3">Company data comparison</div>
      <div className="card">
        <div className="card card__information-red text__white">
          <p>* Requests for searching and getting company data from Kompany will be extra charged</p>
          <p>
            * Refer
            {' '}
            <a
              href="https://www.kompany.com/static/cms-media/2022/05/KYC_API_Data_Endpoints_May2022.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kompany document
            </a>
            {' '}
            for more information
          </p>

        </div>
        {!company.kompanyMapping && (
          <KompanySearchMapping company={company} onMapped={() => { refetch() }}/>
        )}
        {company.kompanyMapping && (
          <KompanyMapping company={company} />
        )}
        {company.kompanyMapping && company.kompanyMapping.kompanyId && (
          <KompanyRequestStatus company={company} />
        )}
      </div>
    </>
  )
}

export default AdminCompanyKycCompare
