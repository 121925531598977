import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from 'react-i18next';

import {
  GET_AVAILABLE_FEATURES,
} from "../../queriesAndMutations";
import { isFeatureEnabled, isAllRequiredFeaturesEnabled } from "../../utils";
import LockedFeatureIcon from '../LockedFeatureIcon';
import FeatureNotAvailableMessage from '../FeatureNotAvailableMessage';

const AdminSettingsKYClvl = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const isChangeKycLvlEnabled = isFeatureEnabled('CHANGE_KYC_LVL_BASE_ON_AML', availableFeatures);
  const isLvl5Enabled = isFeatureEnabled('ALLOW_LVL_5', availableFeatures);
  const { t } = useTranslation();
  const nameFields = {
    min_invest_amount: t('Minimum invest amount'),
    max_invest_amount: t('Maximum invest amount'),
    descriptionOfFunds: t('Description Of Funds'),
    descriptionOfWealth: t('Description Of Wealth'),
    taxNumber: t('Tax number'),
    taxOffice: t('Tax office'),
    photoWithMeUpload: t('Upload photo proof document'),
    proofOfResidenceUpload: t('Upload proof Residence'),
    show_source_signup: t('Show source registered'),
    mandatoryKYCOrganic: t('Mandatory KYC organic to create transaction'),
    mandatoryKYCReferral: t('Mandatory KYC referral to create transaction'),
    acceptedFieldIndividualCrypto: "Crypto",
    acceptedFieldIndividualFiat: "Fiat",
    video_ident_value_individual: t('Liveness check value (CHF) individual'),
    video_ident_value_company: t('Liveness check value (CHF) company'),
    enable: t('Enable'),
    enable_lvl: t('Activate Level 5 KYC'),
    enable_open_end: t('Enable open end KYC Lvl 5'),
  };

  const [kycVideoIdentTwoSteps, setKycVideoIdentTwoSteps] = useState(
    settings.kyc_video_ident_two_steps
  );

  const [kycUseBaseCurrency, setKycUseBaseCurrency] = useState(
    settings.kyc_use_base_currency || false
  );

  const valueOrUndefined = (value, valueIfUndefined) => (value === undefined ? valueIfUndefined : value);

  const [kycLevels, setKycLevels] = useState({
    1: {
      min_invest_amount: valueOrUndefined(settings.kyc_levels[1].min_invest_amount, 0),
      max_invest_amount: valueOrUndefined(settings.kyc_levels[1].max_invest_amount, 900),
      acceptedFieldIndividualCrypto: {
        taxNumber: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualCrypto.taxNumber, false),
        taxOffice: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualCrypto.taxOffice, false),
        descriptionOfFunds: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualCrypto.descriptionOfFunds, true),
        descriptionOfWealth: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualCrypto.descriptionOfWealth, false),
        photoWithMeUpload: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualCrypto.photoWithMeUpload, true),
        proofOfResidenceUpload: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualCrypto.proofOfResidenceUpload, false),
      },
      acceptedFieldIndividualFiat: {
        taxNumber: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualFiat.taxNumber, false),
        taxOffice: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualFiat.taxOffice, false),
        descriptionOfFunds: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualFiat.descriptionOfFunds, true),
        descriptionOfWealth: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualFiat.descriptionOfWealth, false),
        photoWithMeUpload: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualFiat.photoWithMeUpload, true),
        proofOfResidenceUpload: valueOrUndefined(settings.kyc_levels[1].acceptedFieldIndividualFiat.proofOfResidenceUpload, false),
      },
    },
    2: {
      min_invest_amount: valueOrUndefined(settings.kyc_levels[2].min_invest_amount, 1000),
      max_invest_amount: valueOrUndefined(settings.kyc_levels[2].max_invest_amount, 14900),
    },
    3: {
      min_invest_amount: valueOrUndefined(settings.kyc_levels[3].min_invest_amount, 15000),
      max_invest_amount: valueOrUndefined(settings.kyc_levels[3].max_invest_amount, 99000),
      video_ident_value_individual: valueOrUndefined(settings.kyc_levels[3].video_ident_value_individual, 15000),
      video_ident_value_company: valueOrUndefined(settings.kyc_levels[3].video_ident_value_company, 15000),
    },
    4: {
      min_invest_amount: valueOrUndefined(settings.kyc_levels[4].min_invest_amount, 100000),
      max_invest_amount: valueOrUndefined(settings.kyc_levels[4].max_invest_amount, 250000),
      enable: {
        enable_open_end: valueOrUndefined((settings.kyc_levels[4] && settings.kyc_levels[4].enable && settings.kyc_levels[4].enable.enable_open_end) || undefined, false),
      },
    },
    5: {
      min_invest_amount: valueOrUndefined((settings.kyc_levels[5] && settings.kyc_levels[5].min_invest_amount) || undefined, 250001),
      max_invest_amount: valueOrUndefined((settings.kyc_levels[5] && settings.kyc_levels[5].max_invest_amount) || undefined, 500000),
      enable: {
        enable_lvl: valueOrUndefined((settings.kyc_levels[5] && settings.kyc_levels[5].enable && settings.kyc_levels[5].enable.enable_lvl) || undefined, false),
        enable_open_end: valueOrUndefined((settings.kyc_levels[5] && settings.kyc_levels[5].enable && settings.kyc_levels[5].enable.enable_open_end) || undefined, false),
      },
    },
  });

  const onChangeCheckbox =
    (level, nameField, name, state, setState) => (event) => {
      const newState = state;
      newState[level][nameField][name] = event.target.checked;
      setState({ ...newState });
    };

  const onChangeInputNumber = (level, name, state, setState) => (event) => {
    const newState = state;
    newState[level][name] = +event.target.value;
    setState({ ...newState });
  };

  const onUpdateBtnClick = () => {
    const dataUpdate = { ...kycLevels};
    dataUpdate[4].enable.enable_lvl = true

    onUpdate({
      kyc_levels: dataUpdate,
      kyc_video_ident_two_steps: kycVideoIdentTwoSteps,
      kyc_use_base_currency: kycUseBaseCurrency,
    });
  };

  const renderCheckboxLabel = (name, level) => {
    if (name === 'enable_lvl') {
      return `${t('Activate Level')} ${level} KYC`;
    }
    if (name === 'enable_open_end') {
      return t(`Enable open end KYC Lvl ${level}`);
    }
    if (name === 'useBaseCurrency') {
      return nameFields[name];
    }
    return `${t('Accept')} ${nameFields[name]}`;
  }

  const renderCheckbox = (checkbox, level, nameField, setCheckbox, isFeatureEnabled) => (
    <>
      {Object.keys(checkbox).map((name, key) => (
        <label key={key} className="checkbox">
          <input
            type="checkbox"
            className="checkbox__input"
            checked={checkbox[name]}
            disabled={!editingAllowed || !isFeatureEnabled}
            onChange={onChangeCheckbox(
              level,
              nameField,
              name,
              kycLevels,
              setCheckbox
            )}
            value={checkbox[name]}
          />

          <div className="checkbox__inner">
            <div className="checkbox__tick">{' '}</div>
            <div className="checkbox__text">
                {renderCheckboxLabel(name, level)}
                {isFeatureEnabled || (<LockedFeatureIcon />)}
            </div>
          </div>
        </label>
      ))}
    </>
  );

  const renderInput = (nameField, value, isFeatureEnabled) => (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <div
        className="field__label"
        key={`label-${nameFields[nameField]}`}
      >
        {nameFields[nameField]}
        {isFeatureEnabled || (<LockedFeatureIcon />)}
      </div>

      <div className="field__wrap" key={nameFields[nameField]}>
        <input
          type="number"
          className="field__input"
          value={kycLevels[value[0]][nameField]}
          onChange={onChangeInputNumber(
            value[0],
            nameField,
            kycLevels,
            setKycLevels
          )}
          disabled={!editingAllowed || !isFeatureEnabled}
        />
      </div>
    </>
  );

  const renderFieldKYCLevel = (value) => (
    <>
      {Object.keys(value[1]).map((nameField) => (
        <div
          className="field"
          key={ ['acceptedFieldIndividualCrypto', 'acceptedFieldIndividualFiat', 'enable'].includes(nameField)
            ? nameField
            : `${nameField}_${value[0]}`
          }
        >
          {nameField === "acceptedFieldIndividualCrypto" ||
          nameField === "acceptedFieldIndividualFiat" ? (
            <div
              className="field"
            >
              <div className="field__label">
                {nameFields[nameField]}
              </div>

              <div className="field__wrap d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
                {renderCheckbox(
                  kycLevels[value[0]][nameField],
                  value[0],
                  nameField,
                  setKycLevels,
                  isLvl5Enabled,
                )}
              </div>
            </div>

          ) : nameField === "enable" ? (
            <>
              <div className="field__label">
                {nameFields[nameField]}
              </div>

              <div className="field__wrap d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
                {renderCheckbox(
                  kycLevels[value[0]][nameField],
                  value[0],
                  nameField,
                  setKycLevels,
                  isChangeKycLvlEnabled
                )}
              </div>
            </>
          ) : (
            <>
              {renderInput(nameField, value, isChangeKycLvlEnabled)}
            </>
          )}
        </div>
      ))}
    </>
  );

  const renderKYCLevelsSettings = () => (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Liveness check flow & Currency') }</div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">{ t('Two steps') }</div>
            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={kycVideoIdentTwoSteps}
                disabled={!editingAllowed}
                onChange={() => {
                  setKycVideoIdentTwoSteps(!kycVideoIdentTwoSteps);
                }}
                value={kycVideoIdentTwoSteps}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="field create__line remove__line">
            <div className="create__info">{ t('Use base currency') }</div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={kycUseBaseCurrency}
                disabled={!editingAllowed}
                onChange={() => {
                  setKycUseBaseCurrency(!kycUseBaseCurrency);
                }}
                value={kycUseBaseCurrency}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Levels') }</div>

        <div className="settings__fieldset">
          {Object.entries(kycLevels).map((value) => (
            <>
              <div className="create__line" key={value[0]}>
                <div className="create__info">
                  { t('Level') }
                  {' '}
                  {value[0]}
                </div>
              </div>
              {renderFieldKYCLevel(value)}
            </>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      {renderKYCLevelsSettings()}

      {isAllRequiredFeaturesEnabled([
        'CHANGE_KYC_LVL_BASE_ON_AML',
        'ALLOW_LVL_5',
      ], availableFeatures) || (
        <div className="settings__item">
          <FeatureNotAvailableMessage />
        </div>
      )}

      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
          className="button settings__button"
        >
          { t('Update') }
        </button>
      )}
    </>
  );
};

AdminSettingsKYClvl.propTypes = {
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsKYClvl;
