import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import ReactTooltip from 'react-tooltip'

import { usePublicSettings } from "../../../myHooks/useSettings";
import { useExchangeRates, useMe } from "../../../myHooks";
import { getFileUrl } from "../../../utils";
import { tokenToEth } from "../../../utils/rate";
import { ME_INVEST_IN_BASE_CURRENCY } from "../../../queriesAndMutations";
import { useTranslation } from 'react-i18next';
import InfoIcon from "../../../components/Icon";
import { Button } from "@material-ui/core";
import RedeemModal from "../../../components/RedeemModal";

const TokenBalance = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation();
  const { data: { me } = {} } = useMe()
  const {
    data: { meInvestInBaseCurrency = {} } = {},
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);
  const currentHost = window.location.host;
  const isCalidrisfintech = currentHost === 'kyc.calidrisfintech.com' || currentHost === 'dev-kyc.calidrisfintech.com';
  const offChainTooltip = isCalidrisfintech
    ? t('CLT tokens were successfully subscribed, making the investor the owner of the CLT token and will be sent to the investor in full after the capital increase is completed.')
    : t('This token has been allocated in our Database after you paid for it.')
  const oChainTooltip = isCalidrisfintech
    ? t('CLT tokens were successfully subscribed and fully shipped to the investor by Calidris.')
    : t('This token has been allocated on the Blockchain and should be visible in your wallet.')
  const renderImageOrSymbol = () => {
    if (publicSettings && publicSettings.brief_logo_path !== null) {
      return (
        <img
          src={publicSettings && getFileUrl(publicSettings.brief_logo_path)}
          alt={publicSettings && publicSettings.token_symbol}
        />
      );
    } else {
      return (
        <span>
          {publicSettings && publicSettings.token_symbol}
        </span>
      );
    }
  }

  const renderRedeemButton = () => {
    if (me?.rights === 'COMMON' && +meInvestInBaseCurrency.allocated_invest) {
      return <div className="tips__item mt__0">
        <Button variant="outlined" color="primary" onClick={() => {
          setIsOpen(true)
        }}>{t('Redeem')}</Button>
      </div>
    } else return null
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }
  return (
    <div className="card">
      <div className="card__head">
        <div className="title-primary card__title">
          {t('Token Balance')}
        </div>
      </div>

      <div className="tips">
        <div className="tips__list mt__0 flex__align_center">
          <div className="tips__item mt__0">
            <div className="tips__icon">
              {renderImageOrSymbol()}
            </div>

            <div className="tips__details">
              <div className="tips__title mb__0 field field__label field">
                <div className="field__label">
                  <strong>
                    {t('My Token Balance (Off-Chain)')}
                    <span
                      data-tip={offChainTooltip}
                      data-for="token_offchain"
                      className="tooltip"
                    >
                      <InfoIcon
                        className='info'
                        name='info'
                        size="16"
                      />
                    </span>
                    <ReactTooltip id="token_offchain" place="bottom" padding="24px" className="popover-tooltip" />
                  </strong>
                </div>
              </div>

              <div className="tips__line">
                <div className="review__user pb__0">
                  <span className="review__title">
                    {meInvestInBaseCurrency.approved_invest || "0"}{" "}
                  </span>

                  <span className="review__login">
                    {publicSettings && publicSettings.token_symbol}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="tips__item mt__0">
            <div className="tips__icon">
              {renderImageOrSymbol()}
            </div>

            <div className="tips__details">
              <div className="tips__title mb__0 field">
                <div className="field__label">
                  <strong>
                    {t('My Token Balance (On-Chain)')}
                    <span
                      data-tip={oChainTooltip}
                      data-for="token_onchain"
                      className="tooltip"
                    >
                      <InfoIcon
                        className='info'
                        name='info'
                        size="16"
                      />
                    </span>
                    <ReactTooltip id="token_onchain" place="bottom" padding="24px" className="popover-tooltip" />
                  </strong>
                </div>
              </div>

              <div className="tips__line">
                <div className="review__user pb__0">
                  <span className="review__title">
                    {meInvestInBaseCurrency.allocated_invest || "0"}{" "}
                  </span>

                  <span className="review__login">
                    {publicSettings && publicSettings.token_symbol}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {renderRedeemButton()}
        </div>
      </div>
      <RedeemModal isOpen={isOpen} setIsOpen={setIsOpen} amount={+meInvestInBaseCurrency.allocated_invest}/>
    </div>
  );
};

export default TokenBalance;
