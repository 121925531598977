import React, { useState } from 'react'
import { CircularProgress, Dialog, Input } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ModalInnerMaterialDialog from "../ModalInnerMaterialDialog";
import metamaskService from '../../services/metamask'
import Web3 from 'web3'
import { toaster } from '../../utils/toaster'
import { useMe, usePublicSettings } from "../../myHooks";
import { isProduction } from '../../utils/app'
import contractAbi from '../../constants/contractAbi'
import { useMutation } from '@apollo/client'
import {
    UPDATE_ALLOCATE_TRANSACTION_HASH,
} from '../../queriesAndMutations'
import { REDEMPTION_TRANSACTION } from "../../queriesAndMutations/burnTransactionMutation";
import { PAYMENT_TYPES } from "../../constants/transaction";

const RedeemModal = ({ isOpen, setIsOpen, amount }) => {
    const { t } = useTranslation();
    const [transferingToken, setTransferingToken] = useState(false);
    const { data: { publicSettings = {} } = {} } = usePublicSettings();
    const { data: { me } = {} } = useMe()
    const [redemption, redemptionStatus] = useMutation(REDEMPTION_TRANSACTION);

    const handleCloseModal = () => {
        setIsOpen(false)
    }

    const handleCreateRedemptionTransaction = async (transactionHash, network) => {
        const input = {
            amount,
            currency: 'usd',
            paymentType: PAYMENT_TYPES.REDEMPTION,
            isContributionGto: false,
            network,
            hash: transactionHash,
        }
        const {
            data: { redemption: dataRedemption },
        } = await redemption({ variables: { input } });
    }

    const burnToken = async (wallet_address) => {
        // Break if metamask not available
        if (metamaskService.isMetamaskNotAvailable()) {
            toaster.error('You need to install Metamask to use this feature')

            return
        }

        // Break if metamask not available
        if (!wallet_address) {
            toaster.error('No wallet address')

            return
        }

        // // Break if smart contract is not enable
        if (!publicSettings.enable_smart_contract) {
            toaster.error('Smart contract has not been enabled')

            return
        }

        if (!['mainnet', 'polygon', 'xinfin'].includes(publicSettings.smart_contract_network)) {
            toaster.error('Smart contract network is not supported')

            return
        }

        // Break if user is trying to do multiple allocation on token contract
        // if (publicSettings.smart_contract_type === 'token_contract' && transactions.length > 1) {
        //   toaster.error('Token contract does not support for multiple allocation')

        //   return
        // }

        try {
            const address = metamaskService.formatAddress(wallet_address)
            if (!Web3.utils.isAddress(address)) {
                toaster.error(`User's wallet is invalid`)
                return
            }
        } catch (error) {
            return
        }

        try {
            await metamaskService.requestAccounts()

            let network

            switch (publicSettings.smart_contract_network) {
                case 'mainnet':
                    network = isProduction ? 'mainnet' : 'sepolia'

                    break

                case 'polygon':
                    network = isProduction ? 'polygon' : 'amoy'

                    break

                case 'xinfin':
                    network = isProduction ? 'xinfin' : 'apothem'

                    break

                default:
                    toaster.error('Network not supported');
                    return
            }

            await metamaskService.requireNetwork(network, () => {
                window.location.reload()
            })
            const web3 = new Web3(window.ethereum)
            const account = metamaskService.getCurrentAddress()
            const contractAddress = metamaskService.formatAddress(publicSettings.smart_contract_address)
            const contract = new web3.eth.Contract(
                publicSettings.smart_contract_type === 'crowdsale_contract'
                    ? contractAbi.crowdsale
                    : contractAbi.token,
                contractAddress
            );
            const decimal = publicSettings.smart_contract_type === 'crowdsale_contract'
                ? +(await metamaskService.getContractDecimal(await metamaskService.getTokenAddressOfCrowldsaleContract(contractAddress)))
                : +(await metamaskService.getContractDecimal(contractAddress))
            const allocateAmount = `${Math.ceil(+(amount) * (10 ** decimal))}`;

            if (publicSettings.smart_contract_type === 'token_contract') {
                /* eslint-disable no-await-in-loop */
                if (account !== metamaskService.formatAddress(wallet_address).toLowerCase()) {
                    toaster.error(`Please switch to your receiving wallet to continue burning token`)
                    return
                }
                // In case burn
                const data = await contract.methods
                    .burn(BigInt(allocateAmount))
                    .send({
                        from: account,
                        value: 0
                    });
                const transaction = await handleCreateRedemptionTransaction(data.transactionHash, network)
                setIsOpen(false)
            } else if (publicSettings.smart_contract_type === 'crowdsale_contract') {
                //TODO: create transactions
            }
        } catch (error) {
            console.log(error)
        }
    }
    const allocateTokenForMultipleTransaction = async () => {
        await burnToken(me?.wallet_address)
    }
    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <ModalInnerMaterialDialog
                modalSize="popup__kyc-medium"
                onClickClose={handleCloseModal}
                modalTitle={t('Redemption')}
                modalTitleClassColor="title-primary"
            >{transferingToken ? (
                <div className="settings__fieldset">
                    <CircularProgress />
                    {' '}
                    Please confirm and wait until the transaction processed.
                </div>
            ) :
                <div className="settings__fieldset">
                    <div className="field">
                        <div className="field__label">
                            To receive your invested money back together with any interest due to you please follow these steps:
                        </div>
                        <div className="field__wrap field__wrap-textarea">
                            Please click "Redeem Now" below to redeem your TRADA tokens.  Make sure that you have your token wallet (Metamask etc.) holding the TRADA tokens connected and follow the prompts that appear.
                            Please send us an e-mail at info@xdcteq.io when you are done. In that e-mail please confirm your bank details (beneficiary name, bank account number, sort code, IBAN) or your wallet address to which we should send your funds.
                            We will confirm redemption of your TRADA tokens and will make payment to you as instructed.
                            In case of any questions please drop us a line at info@xdcteq.io.                        </div>
                    </div>

                    <div className='field'>
                        <Input
                            propertyName='amount'
                            type="number"
                            disabled={true}
                            value={amount}
                        />
                    </div>
                    <div className='field'>
                        <button
                            className="button-stroke"
                            onClick={() => {
                                setTransferingToken(true)
                                allocateTokenForMultipleTransaction().finally(() => {
                                    setTransferingToken(false)
                                })
                            }}

                        >
                            {t('Redeem Now')}
                        </button>
                    </div>
                </div>}
            </ModalInnerMaterialDialog>
        </Dialog>);
};

export default RedeemModal;
