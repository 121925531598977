import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import {
  MAIN_INFO_SCHEMAS,
  ADDRESS_INFO_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  INDIVIDUAL_DOCUMENT_TYPE_ENUM,
} from '../../../schemas/kycVerification'
import {
  REGISTRATION_INDIVIDUAL_CUSTOMER
} from '../../../queriesAndMutations'
import { useMe } from '../../../myHooks'
import WalletValidationForm from '../../../components/WalletValidationForm'
import KycVerificationForm from '../../KycVerification/KycVerificationForm'

const TransferClaimLiquidator = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const { data: { me } = {}, loading, refetch } = useMe()

  const [registerIndividualCustomer, registerIndividualCustomerData] =
    useMutation(REGISTRATION_INDIVIDUAL_CUSTOMER);

  if (!me || loading) {
    return <CircularProgress />
  }

  if (!me.is_valid_liquidation_wallet) {
    return (
      <>
        <div className="h3 nospace__bottom">{ t('Wallet Validator') }</div>
        <div className="page__title">{' '}</div>
        <div className="card">

          <WalletValidationForm
            onValidateSuccess={() => { refetch() }}
          />
        </div>
      </>
    )
  }

  return (
    <KycVerificationForm
      mainInfoSchemas={MAIN_INFO_SCHEMAS}
      addressInfoSchemas={ADDRESS_INFO_SCHEMAS}
      filesSchemas={INDIVIDUAL_FILES_SCHEMAS}
      documentTypeEnum={INDIVIDUAL_DOCUMENT_TYPE_ENUM}
      kycType="INDIVIDUAL"
      registerCustomer={registerIndividualCustomer}
      registerCustomerData={registerIndividualCustomerData}
      kycFormStepNumber={step}
      setKycFormStepNumber={setStep}
    />
  )
}

export default TransferClaimLiquidator
