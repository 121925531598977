import gql from 'graphql-tag'

export const TRANSACTION = gql`
  query transaction($id: ID!) {
    transaction(id: $id) {
      id
      payment_ref
      to
      type
      token_amount
      approved_token
      currency_amount
      currency
      usd_amount
      status
      payment_type
      details
      tx_hash
      deposit_from
      deposit_to
      approved_note
      is_contribution_gto
      mandatory_kyc
      createdAt
      updatedAt
      wallet_transaction_id
      volt_transaction_id
      sendwyre_transfer_id
      crypto_payment_id
      discount_percent
      cashlink_id
      cashlink_info
      allocate_tx_hash
      allocate_network
      investhub_ref
      user {
        id
        email
        eth_receiving_wallet
        is_gtoswiss
        is_internal_sales
        kyc_status
        wallet_address
        wallet_type
      }
      bankTransferUpload {
        id
        storage_key
        preview_storage_key
        mime_type
        original_name
        is_public
      }
    }
  }
`;

export const TRANSACTION_SUM = gql`
  query transactionsSum {
    transactionsSum {
      approved
      pending
    }
  }
`;

export const TRANSACTIONS_ADMIN = gql`
  query transactionsAdmin($input: TransactionListInput) {
    transactionsAdmin(input: $input) {
      meta {
        page
        pageSize
        totalPages
        total
      }
      objects {
        id
        payment_ref
        to
        type
        token_amount
        approved_token
        currency_amount
        currency
        usd_amount
        status
        payment_type
        details
        tx_hash
        allocate_tx_hash
        deposit_from
        deposit_to
        approved_note
        is_contribution_gto
        mandatory_kyc
        createdAt
        updatedAt
        discount_percent
        issued_date
        original_transaction_id
        expense_id
        interestTransactions {
          id
          issued_date
          original_transaction_id
        }
        user {
          id
          email
          first_name
          last_name
          is_gtoswiss
          is_internal_sales
          eth_receiving_wallet
          kyc_status
          wallet_address
          wallet_type
          referral_code
          old_wallet_address
        }
        capitalIncrease {
          id
          date
          commercial_register_url
        }
      }
    }
  }
`;

export const TRANSACTIONS = gql`
    query transactions($input: TransactionListInput) {
      transactions(input: $input) {
        meta {
          page
          pageSize
          totalPages
          total
        }
        objects {
          id
          payment_ref
          to
          type
          token_amount
          approved_token
          currency_amount
          currency
          usd_amount
          status
          payment_type
          details
          tx_hash
          deposit_from
          deposit_to
          approved_note
          is_contribution_gto
          mandatory_kyc
          createdAt
          updatedAt
          discount_percent
          interestTransactions {
            id
            issued_date
            original_transaction_id
          }
          user {
            id
            email
            eth_receiving_wallet
            is_gtoswiss
            is_internal_sales
            kyc_status
            wallet_address
            wallet_type
            referral_code
          }
          capitalIncrease {
            id
            date
            commercial_register_url
          }
        }
      }
    }
`;

export const TRANSACTIONS_USER = gql`
    query transactionsUser($input: TransactionUserListInput) {
      transactionsUser(input: $input) {
        meta {
          page
          pageSize
          totalPages
          total
        }
        objects {
          id
          payment_ref
          to
          type
          token_amount
          approved_token
          currency_amount
          currency
          usd_amount
          status
          payment_type
          details
          tx_hash
          deposit_from
          deposit_to
          approved_note
          is_contribution_gto
          mandatory_kyc
          createdAt
          updatedAt
          volt_transaction_id
          sendwyre_transfer_id
          was_business_profile_the_same
          approved_note
          risk_evaluate_id
          discount_percent
          user {
            id
            email
            name
            eth_receiving_wallet
            wallet_address
            wallet_type
          }
          logEvent {
            status_type_after
            user {
              email
            }
          }
        }
      }
    }
`;

export const TRANSACTION_RISK_EVALUATE = gql`
  query transactionRiskEvaluate($input: transactionRiskEvaluateInput) {
    transactionRiskEvaluate(input: $input)
  }
`;

export const GET_TOKEN_REGISTRY = gql`
  query getTokenRegistry {
    getTokenRegistry
  }
`;

export const CAPITAL_INCREASES = gql`
  query capitalIncreases {
    capitalIncreases {
      id
      date
      commercial_register_url
      totalAmount
      totalToken
      pdf_url
      amount
    }
  }
`;
