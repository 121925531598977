import React, { useState } from 'react'
import { NavLink, Redirect, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { CUSTOMER_STATUS_TYPES, CUSTOMER_TYPES } from '../../constants/customer'
import { USER_KYC_STATUS_TYPES } from '../../constants/user'
import { usePublicSettings, useMe } from '../../myHooks'
import { ME_INVEST_IN_BASE_CURRENCY, CHECK_FIELDS_IS_FILLED } from '../../queriesAndMutations'
import { mandatoryKyc, isCrypto, isProduction } from '../../utils'

import Icon from '../../components/Icon'
import AlertUpdateKyc from '../../components/AlertUpdateKyc'
import AlertUpdateDirector from '../../components/AlertUpdateDirector'
import { TOKEN_TYPES } from '../../constants/settings'
import ReceiveWallet from '../../components/ReceiveWallet'
import SendWallet from '../../components/SendWallet'
import WalletAddress from '../../components/profile/WalletAddress'
import KycAccountLevel from './KycAccountLevel'
import TokenBalance from './TokenBalance'
import Contributions from './Contributions'
import KybDirectorStatus from '../../components/KybDirectorStatus'
import KybUboStatus from '../../components/KybUboStatus'

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      padding: "10px 0 26px 0",
    },
  },
  "page-dashboard__content-director-list" : {
    marginLeft: '30px'
  },
  "page-dashboard__content-director" : {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid var(--color__border-hr)',
  },
  "page-dashboard__content-ubo" : {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: '1px solid var(--color__border-hr)',
  },
}));

const NEED_UDPATE_ATTRIBUTES = [
  "first_name",
  "last_name",
  "email",
  "phone",
  "birth_date",
  "bankName",
  "iban",
  "bankAddress",
];

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory()

  const {
    data: { me: userData } = {},
    loading,
  } = useMe();
  const { data: { publicSettings = {} } = {}, loadingPublicSetting } = usePublicSettings();
  const { data: { meInvestInBaseCurrency } = {} } = useQuery(ME_INVEST_IN_BASE_CURRENCY);
  const currentDirectorProfile = (userData && userData.customerCompanyDirector && userData.customerCompanyDirector[0]) || null;

  const [openAddWalletDialog, setOpenAddWalletDialog] = React.useState(false);

  const [values, setValues] = useState({
    ethAddressDialogOpened: false,
  });

  const [signnow, setSignnow] = React.useState(false);
  const [signnowLinkInvite, setSignnowLinkInvite] = React.useState(null);
  const eDocument = userData.customer && userData.customer.documentESignature && userData.customer.documentESignature.length
    ? userData.customer.documentESignature[userData.customer.documentESignature.length - 1]
    : {}

  let fields = ["Signature_1"];

  // if (eDocument.document_type === DOCUMENT_TYPES.INDIVIDUAL_FORM) {
  //   fields = ["Signature_1"]
  // } else if (
  //   [
  //     DOCUMENT_TYPES.TOKEN_SAFE,
  //     DOCUMENT_TYPES.KYB_SINGLE_FORM,
  //     DOCUMENT_TYPES.KYB_COLLECTIVE_FORM,
  //     DOCUMENT_TYPES.LIQUIDATION_FORM
  //   ].includes(eDocument.document_type)) {
  //   fields = ["Signature_1", "Signature_2"]
  // } else {
  //   fields = ["Signature_1", "Signature_2", "Signature_3"]
  // }

  const { data: { checkFieldsIsFilled = {} } = {} } = useQuery(
    CHECK_FIELDS_IS_FILLED,
    {
      variables: {
        input: {
          fields,
          filename: eDocument.remote_id,
        },
      },
    },
    {
      enabled: eDocument.remote_id
    }
  );

  React.useEffect(() => {
    if (checkFieldsIsFilled && checkFieldsIsFilled.result) {
      // eslint-disable-next-line
      if (checkFieldsIsFilled.result.Signature_1 && !checkFieldsIsFilled.result.Signature_1.fulfilled) {
        setSignnow(true)
        const fieldInvite = checkFieldsIsFilled.result.Signature_1.fieldInvite

        if (fieldInvite) {
          const host = isProduction ? 'https://app.signnow.com' : 'https://app-eval.signnow.com'
          const link = `${host}/webapp/document/${checkFieldsIsFilled.result.documentId}?fieldinvite_id=${fieldInvite.id}&mobileweb=app_only&route=fieldinvite&sign=1&source=email`
          setSignnowLinkInvite(link)
        }
      }
    }

    // eslint-disable-next-line
  }, [checkFieldsIsFilled]);

  if (loadingPublicSetting || loading) {
    return <CircularProgress />
  }

  if (!publicSettings || !userData) {
    return <div />;
  }

  if (publicSettings.force_user_till_first_transaction && userData.rights === 'COMMON') {
    if (
      mandatoryKyc(userData.is_gto_sales, publicSettings.accepted_mandatory_kyc || {}) &&
      userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED &&
      (!userData.customerCompanyDirector || !userData.customerCompanyDirector.length)
    ) {
      return <Redirect to="/application" />
    }

    if (userData.kyc_status !== USER_KYC_STATUS_TYPES.PASSED) {
      if (userData.kyc_status === USER_KYC_STATUS_TYPES.REOPEN) {
        if (userData.customer) {
          return <Redirect to={`/application-update/individual/${userData.customer.id}`} />
        }
        if (userData.customerCompany) {
          return <Redirect to={`/application-update/company/${userData.customerCompany.id}`} />
        }
      }
    } else {
      if (
        publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION &&
        meInvestInBaseCurrency &&
        meInvestInBaseCurrency.invest_token_without_kyc === 0 &&
        history.location.state && history.location.state.prevPath !== '/contribute'
      ) {
        return <Redirect to="/contribute" />
      }
    }
  }

  const todoKyc = userData && userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED;
  let todoUpdateProfile = false;
  let todoEnable2FA = false;

  NEED_UDPATE_ATTRIBUTES.forEach((key) => {
    if (userData && (userData[`${key}`] === "" || userData[`${key}`] === null)) {
      todoUpdateProfile = true;
    }
  });

  if (publicSettings && !publicSettings.disable_2fa_security && userData && !userData.is_2fa_enabled) {
    todoEnable2FA = true;
  }

  const acceptedCurrencies = publicSettings.accepted_currencies && Object.keys(publicSettings.accepted_currencies).filter(currency => publicSettings.accepted_currencies[currency])

  const walletAddress =
    userData && (userData.wallet_address || userData.eth_receiving_wallet);

  const changeEthAddressDialogState = (state) => () =>
    setValues({
      ...values,
      ethAddressDialogOpened: state,
    });

  const showAlertUpdateKyc = () => {
    return (
      (userData.customer || {}).status === CUSTOMER_STATUS_TYPES.REOPEN ||
      (userData.customerCompany || {}).status === CUSTOMER_STATUS_TYPES.REOPEN ||
      (userData.customer || {}).status === "REOPEN_WITH_VIDEOIDENT"
    );
  };

  const showAlertUpdateDirector = userData.customerCompanyDirector && userData.customerCompanyDirector.reduce((all, director) => all || director.status === 'REOPEN', false)

  const getCustomerName = () => {
    return userData.customer !== null ? "individual" : "customerCompany";
  };

  const getCustomerId = () => {
    if (userData.customer) {
      return userData.customer.id
    }

    if (userData.customerCompany) {
      return userData.customerCompany.id
    }

    return null
  };

  const RenderReceiveWallet = () => (
    <ReceiveWallet
      userData={userData}
      publicSettings={publicSettings}
      onOpenDialog={changeEthAddressDialogState(true)}
      onCloseDialog={changeEthAddressDialogState(false)}
      dialogOpen={values.ethAddressDialogOpened}
    />
  );

  const renderDirectorStatusButton = (status) => {
    let btnClass = '';
    let btnText = '';
    switch (status) {
      case 'NOT_SUBMITTED':
      case 'REJECTED':
        btnClass = 'status-red-dark'
        break
      case 'PENDING':
        btnClass = 'status-pending-dark'
        break
      case 'PASSED':
        btnClass = 'status-green-dark'
        break
      case 'VIDEO_IDENT':
        btnClass = 'button-stroke'
        btnText = 'Await for liveness check'
        break
      default:
        btnClass = 'status-idle'
        break
    }
    return (
      <span className={btnClass+" w-full status-l text__center popular__button"}>{t(btnText || status.replace('_', ' '))}</span>
    )
  }
  const renderUboStatusButton = (ubo) => {
    let btnClass = '';
    let btnText = '';
    switch (ubo.status) {
      case 'REJECTED':
        btnClass = 'status-red-dark'
        break
      case 'PENDING':
        btnClass = 'status-pending-dark'
        break
      case 'PASSED':
        btnClass = 'status-green-dark'
        break
      case 'VIDEO_IDENT':
        btnClass = 'status-purple-dark'
        btnText = 'LIVENESS CHECK'
        break
      default:
        btnClass = 'status-idle'
        break
    }
    return ubo.status === 'NOT_SUBMITTED' ? (
      <a
        href={`/ubo-profile/${ubo.id}`}
        className="status-pending-dark status-l w-full popular__button"
      >
        {t('NOT SUBMITTED')}
      </a>
    ) : (
      <span className={btnClass+" w-full status-l text__center popular__button"}>
        {t(btnText || ubo.status.replace('_', ' '))}
      </span>
    )
  }

  return (
    <>
      <div className="page__title h3">{ t('Get Started') }</div>
      <div className="page__row">
        <div className="page__col">
          {showAlertUpdateKyc() && getCustomerId() && (
            <AlertUpdateKyc
              kycId={getCustomerId()}
              typeKyc={getCustomerName()}
              customerTypeKyc={
                getCustomerName() === "individual"
                  ? CUSTOMER_TYPES.INDIVIDUAL
                  : CUSTOMER_TYPES.COMPANY
              }
            />
          )}
          {showAlertUpdateDirector && (
            <AlertUpdateDirector />
          )}

          {publicSettings && (publicSettings.token_type !== TOKEN_TYPES.NONE && !publicSettings.hide_token_balance) && (
            <TokenBalance />
          )}

          <Contributions />

          {
            (todoKyc || todoUpdateProfile || todoEnable2FA || !walletAddress || signnow) && (
              <div className="card">
                <div className="card__head">
                  <div className="title-primary card__title">
                    { t('Need to do') }
                  </div>
                </div>

                <div className="create__group">
                  {(todoUpdateProfile || todoEnable2FA) && (
                    <>
                      <div className="field">
                        <div className="field__wrap">
                          <NavLink
                            to="/profile"
                            className="button-stroke w-full"
                          >
                            {todoUpdateProfile && (
                              <>{ t('Complete Profile') }</>
                            )}
                            {todoUpdateProfile && todoEnable2FA && (
                                <> / </>
                            )}
                            {todoEnable2FA && (
                              <>{ t('Activate 2FA') }</>
                            )}
                          </NavLink>
                        </div>

                      </div>
                    </>
                  )}
                  {todoKyc && (
                    <div className="field">
                      <div className="field__wrap">
                        <NavLink
                          to={currentDirectorProfile ? `/director-profile/${currentDirectorProfile.id}` : "/application"}
                          className="button-stroke w-full"
                        >
                          { t(publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION
                            ? t('Transfer claim')
                            : t('Submit KYC Application')
                          ) }
                        </NavLink>
                      </div>
                    </div>
                  )}

                  {!walletAddress && publicSettings && (publicSettings.kyc_show_receiving_wallet || publicSettings.kyc_require_receiving_wallet) && (
                    <div className="field">
                      <div className="field__wrap">
                        <button
                          onClick={() => setOpenAddWalletDialog(true)}
                          className="button-stroke w-full"
                        >
                          { t('Add Wallet address') }
                        </button>
                      </div>
                    </div>
                  )}

                  {signnow && signnowLinkInvite && (
                    <div className="field">
                      <div className="field__wrap">
                        <div className="button-stroke w-full" target="_blank" rel="noopener noreferrer">
                          { t('Sign Signnow Form') }
                        </div>
                      </div>
                    </div>
                  )}

                  <Dialog
                    className={classes.dialog}
                    open={openAddWalletDialog}
                    onClose={() => {
                      setOpenAddWalletDialog(false);
                    }}
                  >
                    <div className="popup js-popup animation visible popup__kyc-medium">
                      <div
                        onClick={() => {
                          setOpenAddWalletDialog(false);
                        }}
                        className="popup__overlay js-popup-overlay"
                      >
                        {' '}
                      </div>
                      <div className="popup__wrap js-popup-wrap">
                        <div className="description">
                          <div className="title-primary description__title">{ t('Wallet Address') }</div>

                          <div className="description__row flex__row flex__wrap">
                            <WalletAddress />
                          </div>
                        </div>

                        <button
                          className="popup__close js-popup-close"
                          onClick={() => {
                            setOpenAddWalletDialog(false);
                          }}
                        >
                          <Icon
                            className={classNames('close', 'icon-24')}
                            name="close"
                            size="24"
                          />
                        </button>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            )
          }

          {userData && userData.customerCompany && (
            <>
              <MaterialAccordion className="card card-accordion" defaultExpanded={true}>
                <MaterialAccordionSummary
                className="card__head card-accordion__head"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="authorized_signatories-card-content"
                id="authorized_signatories-card-head"
                >
                  <div className="title-primary card__title">
                    { t('Authorized signatories') }
                  </div>
                </MaterialAccordionSummary>

                <MaterialAccordionDetails className="card-accordion__content is-full__content is-fix__space">
                  <KybDirectorStatus />
                </MaterialAccordionDetails>
              </MaterialAccordion>
            </>
          )}
          { userData && userData.customerCompany && userData.customerCompany.customerCompanyUbo && userData.customerCompany.customerCompanyUbo.length > 0 && (
            <>
              <MaterialAccordion className="card card-accordion" defaultExpanded={true}>
                <MaterialAccordionSummary
                  className="card__head card-accordion__head"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="ubo-card-content"
                  id="ubo-card-head"
                >
                  <div className="title-primary card__title">
                    { t('UBOs') }
                  </div>
                </MaterialAccordionSummary>

                <MaterialAccordionDetails className="card-accordion__content is-full__content is-fix__space">
                  <KybUboStatus />
                </MaterialAccordionDetails>

              </MaterialAccordion>
            </>
          )}
        </div>

        <div className="page__col">
          <div className="create__preview js-preview dashboard__kyc-sticky">
            <KycAccountLevel />

            {userData.customerCompany && (
              <div className="card screen-hide">
                <div className="card__head">
                  <h3 className="title-primary card__title">{t('KYB Authorized signatories')}</h3>
                </div>
                <div className="tips">
                  <div className="tips__list tips__list-clear tips__list-one-column">
                    <div className="tips__item flex__align_start">
                      <div className="tips__detail w-full">
                        {userData.customerCompany.customerCompanyDirector && userData.customerCompany.customerCompanyDirector.map(director => (
                          <div className={classes['popular__button']} key={director.id}>
                            <div className="refund__title">
                              {director.firstName} {director.lastName}
                            </div>
                            <div className="refund__content">
                              {director.email}
                            </div>
                            {renderDirectorStatusButton(director.status)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userData.customerCompanyUbo && userData.customerCompanyUbo.filter(ubo => ubo.customerCompany).length > 0 && (
              <div className="card screen-hide">
                <div className="card__head">
                  <h3 className="title-primary card__title">{t('Me as UBO')}</h3>
                </div>

                <div className="tips">
                  <div className="tips__list tips__list-clear tips__list-one-column">
                    <div className="tips__item flex__align_start">
                      <div className="tips__detail w-full">
                        {userData.customerCompanyUbo.filter(ubo => ubo.customerCompany).map(ubo => (
                          <div className={classes['popular__button']} key={ubo.id}>
                            <div className="refund__content">
                              {ubo.customerCompany.companyName}
                            </div>
                            {renderUboStatusButton(ubo)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {publicSettings && (publicSettings.kyc_show_receiving_wallet || publicSettings.kyc_require_receiving_wallet) && (
              <div className="card">
                <RenderReceiveWallet />
              </div>
            )}

            {acceptedCurrencies && acceptedCurrencies.filter((currency) => isCrypto(currency)).length && (
              <div className="card">
                <SendWallet
                  userData={userData}
                  publicSettings={publicSettings}
                  onOpenDialog={changeEthAddressDialogState(true)}
                  onCloseDialog={changeEthAddressDialogState(false)}
                  dialogOpen={values.ethAddressDialogOpened}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
