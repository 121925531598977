import React from 'react'
import { NavLink } from 'react-router-dom'
import { usePublicSettings } from '../myHooks/useSettings'
import { useTranslation, Trans } from 'react-i18next'
import SidebarOuter from '../components/SidebarOuter'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

import '../styles/app.min.css'
import '../styles/style.css'

const Terms = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page pt-0">
      <SidebarOuter />

      <div className="page__inner">
        <div className="page__container">
          <div className="product__wrapper product__wrapper_v2">
            <div className="product__details">
              <div className="product__overview">
                <div className="product__title h4">
                  { t('Terms of use') }
                </div>

                <div className="product__row">
                  <div className="product__col pr__0">
                    <div className="product__content">
                      <p>
                        { t('Please read the following Terms of Use carefully, which provide the necessary information on accessing and using this Platform.')}
                      </p>
                      <p>
                        { t('With your subsequent consent, you confirm that you have read, acknowledged and agree to the terms of use.')}
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>{t('General provisions') }</strong>
                      </p>
                      <p>
                        <Trans
                          i18nKey={ t('COMPANYNAME (the "Token Issuer") operates an internet platform (the "Platform") under TENANTURL through which exclusively the TOKEN_SYMBOL Tokens (the "Tokens") of the Token Issuer can be acquired.',
                            {
                              COMPANYNAME: publicSettings && publicSettings.company && publicSettings.company.name,
                              TENANTURL: window.location.hostname,
                              TOKEN_SYMBOL: publicSettings && publicSettings.token_symbol,
                            }
                          )}
                          t={t}
                        />
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>{t('Scope of application')}</strong>
                      </p>
                      <p>
                        <Trans
                          i18nKey={t('Any use of the Platform by (potential) token acquirers (the "<strong>Users</strong>") shall be governed exclusively by these Terms of Use.')}
                        />
                      </p>

                      <p>
                        <Trans
                          i18nKey={t('For the proper operation of the Platform, the Token Issuer shall use third parties who may in turn use other third parties (the "<strong>Service Providers</strong>").')}
                        />
                      </p>

                      <p>
                        <strong>{t('The corresponding terms of use and business conditions of the Service Providers are an integral part of these Terms of Use.')}</strong>
                      </p>

                      <p>
                        {t('The use of the Platform is free of charge for the Users.')}
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>{t('Registration')}</strong>
                      </p>
                      <p>{t('In order to be able to acquire the Tokens of the Token Issuer, a potential token acquirer must first register on the Platform.')}</p>

                      <p>
                        {t('The registration must be made by or through a natural person who has reached the age of 18 or has full legal capacity.')}
                      </p>

                      <p>
                        {t('The registration must be carried out by providing the requested data truthfully and in full.')}
                      </p>
                      <p>
                        {t('There is no entitlement to registration and use of the Platform. The Token Issuer is free to reject a User at any time without giving reasons.')}
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>{t('Verification')}</strong>
                      </p>
                      <p>
                        {t('The potential token acquirer must verify himself before acquiring the Token for legal reasons (due diligence).')}
                      </p>

                      <p>
                        {t('The verification must be carried out by providing complete and truthful information about the data requested.')}
                      </p>

                      <p>
                        {t('Verification with incorrect data is inadmissible and leads to the exclusion of the User from the Platform.')}
                      </p>

                      <p>
                        {t('The User undertakes to ensure that all information provided during the use of the Platform is always true and up to date.')}
                      </p>
                      <p>
                        {t('The User undertakes to ensure that his access data, in particular his password, is not made accessible to third parties. The User is solely responsible for all actions carried out via his user account. If there are indications of misuse of the user account or if third parties have nevertheless gained knowledge of the access data, the User is obliged to report this immediately to the Token Issuer.')}
                      </p>

                      <p>
                        {t('The Token Issuer shall not disclose the User\'s access data to third parties and shall not request such data from the User by e-mail or telephone.')}
                      </p>
                      <p>
                        {t('If the verification should fail, for whatever reason, a token acquisition is not possible.')}
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Service Provider</strong>
                      </p>

                      <ol>
                        <li>
                          <span style={{ fontWeight: 400 }}>
                            a) Equanimity AG provides the token issuer with the KYC compliance
                            software for the platform directly. The terms of use and business are at{" "}
                            <a href="https://equanimity.li/terms-of-service/" target="_blank" rel="noopener noreferrer">
                              <span style={{ fontWeight: 400 }}>
                                https://equanimity.li/terms-of-service/
                              </span>
                            </a>
                            .
                          </span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            b) Tangany GmbH provides the automatically generated crypto wallet. The
                            terms and conditions are
                            <NavLink to="/tangany-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                              <span style={{ fontWeight: 400 }}>
                                /tangany-terms-and-conditions
                              </span>
                            </NavLink>.
                          </span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            c) Volt Technologies Limited, provides payment services. The terms and
                            conditions of use are available at{" "}
                          </span>
                          <a href="https://www.volt.io/legal/user-terms/" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.volt.io/legal/user-terms/
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            d) Foris DAX Asia Pte. ltd (Crypto.com) provides payment services. The
                            terms and conditions of use are at{" "}
                          </span>
                          <a href="https://crypto.com/document/entity_singapore.pdf" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://crypto.com/document/entity_singapore.pdf
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            e) Wyre Inc. provides payment services. The Terms of Use and Conditions are
                            available at{" "}
                          </span>
                          <a href="https://www.sendwyre.com/legal/user-agreement" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.sendwyre.com/legal/user-agreement
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            f) Stripe Inc. provides payment services. The Terms of Use and Conditions
                            are available at{" "}
                          </span>
                          <a href="https://stripe.com/legal/payment-terms" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://stripe.com/legal/payment-terms
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            g) Acronis International GmbH in connection with GMG AG provide data
                            backups in Liechtenstein. The terms and conditions of use are available
                            at{" "}
                          </span>
                          <a href="https://www.acronis.com/de-de/legal/#terms_of_use" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.acronis.com/de-de/legal/#terms_of_use
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            h) Coinfirm Limited, provides wallet screening services. The terms and
                            conditions of use are at{" "}
                          </span>
                          <a href="https://www.coinfirm.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.coinfirm.com/terms-of-use/
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            i) Blockchain Intelligence Group (Bitrank), provides wallet screening
                            services. The Terms of Use and Conditions are at{" "}
                          </span>
                          <a href="https://blockchaingroup.io/disclaimer/" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://blockchaingroup.io/disclaimer/
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            j) Cashlink Technologies GmbH offers tokenization. The terms of use and
                            business can be found at{" "}
                          </span>
                          <a href="https://cashlink.de/de/kwp-register-rechtliches/" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://cashlink.de/de/kwp-register-rechts/
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            k) Signnow enables documents to be signed digitally. The Terms of Use and
                            Conditions are at{" "}
                          </span>
                          <a href="https://www.signnow.com/terms" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>https://www.signnow.com/terms</span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            l) Skribble allows you to sign documents digitally. The terms of use and
                            business can be found at{" "}
                          </span>
                          <a href="https://www.skribble.com/de-de/nutzungsbedingungen/" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.skribble.com/de-de/bedingungen/
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            m) Intercom.io provides a live chat. The Terms of Use and Conditions are at{" "}
                          </span>
                          <a href="https://www.intercom.com/legal/terms-and-policies" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.intercom.com/legal/terms-and-policies
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            n) Onfido provides the biometric comparison of the conclusive ID document.
                            The Terms of Use and Conditions are at{" "}
                          </span>
                          <a href="https://onfido.com/termsofuse/" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>https://onfido.com/termsofuse/</span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            o) IDnow provides the biometric comparison of the evidentiary ID document.
                            The terms and conditions of use are at{" "}
                          </span>
                          <a href="https://www.idnow.io/wp-content/uploads/GTU_IDnow_2019-12-10.pdf" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.idnow.io/wp-content/uploads/GTU_IDnow_2019-12-10.pdf
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            p) Twilio Inc. provides email and SMS services. The Terms of Use and
                            Conditions are at{" "}
                          </span>
                          <a href="https://www.twilio.com/legal/tos" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.twilio.com/legal/tos
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>

                        <li style={{ fontWeight: 400, marginTop: 16 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            q) Mailgun Technologies provides email services. The Terms of Use and Conditions are available at{" "}
                          </span>
                          <a href="https://www.mailgun.com/legal/terms" target="_blank" rel="noopener noreferrer">
                            <span style={{ fontWeight: 400 }}>
                              https://www.mailgun.com/legal/terms/
                            </span>
                          </a>
                          <span style={{ fontWeight: 400 }}> callable.</span>
                        </li>
                      </ol>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Availability</strong>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The Token Issuer shall endeavour to offer comprehensive availability of
                          the Platform within the scope of what is technically feasible and
                          economically reasonable. However, the Token Issuer does not assume any
                          guarantee for this. In particular, maintenance work, security and capacity
                          reasons, technical circumstances and events outside the control of the
                          Token Issuer may result in the temporary or permanent inaccessibility of
                          the Platform. The Token Issuer reserves the right to restrict access to
                          the Platform at any time and to the extent necessary, e.g. to carry out
                          maintenance work.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Documents</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The User is not entitled to pass on or reproduce any documents,
                          information and records which the User has downloaded from the Platform.
                          Excluded from this obligation are information and documents that are
                          publicly accessible. This obligation shall continue to apply for an
                          unlimited period of time even beyond the temporary use of the Platform as
                          well as upon termination of this user agreement. If a user violates this
                          obligation, this may lead to a liability for damages.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Use of the Internet</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The use of the internet is at the User's own risk. In particular, the
                          Token Issuer shall not be liable for the technical failure of the Internet
                          or access to the Internet.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Protected information</strong>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The Platform contains data and information of all kinds
                          which are protected by trademark and/or copyright in favour of the Token
                          Issuer or, in individual cases, in favour of third parties. It is therefore
                          not permitted to download, reproduce and/or distribute the Platform in whole
                          or in part or to use the Platform or the information and documents contained
                          therein for illegal purposes. The technically conditional reproduction for the
                          purpose of browsing is permitted, provided that this action does not serve any
                          commercial purposes.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>No link to this website</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          It is not permitted to link to this Platform without the prior written
                          consent of the Token Issuer.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>
                          Limited reliability of information and content&nbsp;
                        </strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The Token Issuer regularly checks and updates the information and content
                          on this Platform. However, no guarantee or liability can be assumed for
                          the up-to-dateness, reliability or completeness of the information
                          provided.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>
                          No offer, invitation or solicitation to purchase Tokens.
                        </strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The content of this Platform is for information purposes only and does not
                          establish a business relationship. In particular, the information and
                          documents provided on the Tokens do not constitute legal, investment or
                          tax advice or investment recommendations. Only the respective Token
                          offering documents (including any supplements) are legally binding. A
                          decision to purchase Tokens should only be made after submission of the
                          complete documents and risk information and after obtaining legal, tax and
                          investment advice. The information provided on this Platform is not
                          tailored or targeted to personal situations. Therefore, the information
                          may be completely unsuitable or inaccurate for personal purchase
                          decisions. All information is provided for information purposes only and
                          does not constitute an offer, invitation or solicitation to purchase
                          Tokens.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Liability</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The Token Issuer excludes, to the extent permitted by law, any liability,
                          including negligence and liability towards third parties, for loss or
                          damage of any kind, whether direct or indirect, consequential or
                          incidental, arising out of or in connection with the access to, use of,
                          browsing or downloading of data from the information and documents on the
                          Platform.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The Token Issuer cannot be held liable for inaccurate, incomplete or
                          outdated data as well as for the falsification of data. Furthermore, the
                          information on this Platform has only been checked for its compatibility
                          with Liechtenstein law.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In some foreign jurisdictions, the Platform may be subject to legal
                          restrictions.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The use of this Platform is therefore not permitted for persons or
                          organisations whose domicile, registered office, branch or origin is
                          subject to a foreign jurisdiction, would violate applicable law or
                          regulations in the use of this Platform and would impose additional
                          registration obligations on the Token Issuer.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Data protection</strong>
                      </p>
                      <p>
                        <br />
                        <span style={{ fontWeight: 400 }}>
                          Information on data processing can be found in the
                        </span>
                        {' '}
                        <NavLink to="/privacy-policy">
                          Privacy Policy
                        </NavLink>
                        <span style={{ fontWeight: 400 }}>.</span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Changes</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The Terms of Use may be amended at any time without prior notice. The
                          Token Issuer therefore recommends that Users regularly consult and re-read
                          these Terms of Use in order to familiarise with possible changes. The
                          Terms of Use must therefore not be understood to mean that circumstances
                          have not changed since publication or that the Terms of Use are still
                          current.
                        </span>
                      </p>

                      <div className="space space__32">{' '}</div>
                      <p>
                        <strong>Final provisions</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          These Terms of Use shall be governed by Liechtenstein law, excluding the
                          conflict of laws rules.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The exclusive place of jurisdiction for all disputes arising from this
                          contractual relationship is the registered office of the Token Issuer.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The German language version of these Terms of Use shall prevail. The
                          non-binding English language translation of these Terms of Use does not
                          supersede the German language version.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Should any provision of these Terms of Use be invalid, this shall not
                          affect the validity of the remaining provisions.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In the event that the user is a consumer (not an entrepreneur), reference
                          is made to the possibility of out-of-court dispute resolution in
                          accordance with the ODR Regulation. Details can be found in the
                          aforementioned regulation and at <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
