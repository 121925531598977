import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useMe, usePublicSettings } from '../myHooks'
import { CREATE_NEW_WALLET } from '../queriesAndMutations'

const tanganyWalletSupports = ['btc', 'eth', 'usdt', 'usdc', 'polygon', 'xdc'];

const WalletRequest = ({
  type,
  generatedWallet,
  onComplete,
  isKycFlow
}) => {
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [createNewWallet, createNewWalletStatus] = useMutation(CREATE_NEW_WALLET)
  const requestCreateNewWallet = () => {
    createNewWallet({
      variables: {
        type,
      },
    }).then(({ data: { createNewWallet: wallet } }) => {
      onComplete(wallet)
    }).catch(() => {})
  }

  if (!tanganyWalletSupports.includes(type) || !publicSettings || !publicSettings.enable_tangany || !me || me.wallet_address) {
    if (me && me.wallet_address && publicSettings.force_generate_wallet) {
      return (
        <div>
          {`${t('Your wallet')}: ${me.wallet_address}`}
        </div>
      )
    }

    return null
  }

  if (generatedWallet) {
    return (
      <div>
        {`${t('Your wallet')}: ${generatedWallet}`}
      </div>
    )
  }

  if (isKycFlow) {
    return null;
  }

  return (<>{me.customer && me.customer.status === "APPROVED" &&
    <div>
      {!publicSettings.force_generate_wallet && t('Don\'t have any wallets?')}
      {publicSettings.force_generate_wallet && t('Please generate the new wallet')}
      {' '}
      {createNewWalletStatus.loading && (
        <CircularProgress />
      )}
      {!createNewWalletStatus.loading && (
        <span className="text__primary cursor-pointer" onClick={requestCreateNewWallet}>{t('Generate one')}</span>
      )}
    </div>}
  </>
  )
}

WalletRequest.default = {
  type: 'eth'
}

WalletRequest.propTypes = {
  type: PropTypes.string,
  generatedWallet: PropTypes.string,
  onComplete: PropTypes.func.isRequired
}

export default WalletRequest
