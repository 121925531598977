import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import {
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import ModalInnerMaterialDialog from '../../components/ModalInnerMaterialDialog'
import { useTranslation } from 'react-i18next'

import {
  UPDATE_TOKEN_SWAP,
} from '../../queriesAndMutations'
import { toaster } from '../../utils'
import InputTheme from '../../components/InputTheme'

const EditTokenSwapModal = ({
  choosedTokenSwap,
  setChoosedTokenSwap,
  publicSettings,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    id: '',
    email: '',
    oldTokenAmount: '',
    newTokenAmount: '',
  })
  const [updateTokenSwap, updateTokenSwapStatus] = useMutation(UPDATE_TOKEN_SWAP);

  useEffect(() => {
    if (choosedTokenSwap) {
      const newState = {
        id: choosedTokenSwap.id,
        email: choosedTokenSwap.email,
        oldTokenAmount: choosedTokenSwap.oldTokenAmount,
        newTokenAmount: choosedTokenSwap.newTokenAmount,
      }
      setValues(newState)
    }
  }, [choosedTokenSwap])

  const submitUpdateTokenSwap = () => {
    updateTokenSwap({
      variables: {
        input: {
          id: values.id,
          email: values.email,
          oldTokenAmount: +values.oldTokenAmount,
          newTokenAmount: +values.newTokenAmount,
        }
      }
    }).then(({ data: { updateTokenSwap: success }}) => {
      if (success) {
        setValues({
          id: '',
          email: '',
          oldTokenAmount: '',
          newTokenAmount: '',
        })
        toaster.success('Token swap saved');
        onComplete();
        setChoosedTokenSwap(null);
      }
    }).catch(() => {})
  }

  return (
    <Dialog
      open={!!choosedTokenSwap}
      onClose={() => {
        setChoosedTokenSwap(null)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalInnerMaterialDialog
        onClickClose={() => setChoosedTokenSwap(null)}
        modalTitle={t('Update Token Swap')}
        modalTitleClassColor="title-primary"
      >
        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              label="Email"
              propertyName="email"
              state={values}
              setState={setValues}
              required
            />
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              label={publicSettings.old_token_symbol ? `${publicSettings.old_token_symbol} amount` : 'Old token amount'}
              propertyName="oldTokenAmount"
              state={values}
              setState={setValues}
              required
              type="number"
            />
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              label={`${publicSettings.token_symbol} amount`}
              propertyName="newTokenAmount"
              state={values}
              setState={setValues}
              required
              type="number"
            />
          </div>

          <div className="field">
            {updateTokenSwapStatus.loading && (
              <CircularProgress />
            )}
            {!updateTokenSwapStatus.loading && (
              <button
                className="button"
                onClick={submitUpdateTokenSwap}
              >
                {t('Submit')}
              </button>
            )}
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  )
}

export default EditTokenSwapModal
