import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import classNames from 'classnames'
import { PROSPECTUS_OR_ISSUING, TOKEN_TYPES } from '../../constants/settings';
import ReactTooltip from 'react-tooltip'
import InputThemeField from '../InputThemeField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import CompanyStep5DisclaimerCaag from './CompanyStep5DisclaimerCaag'
import CompanyStep5DisclaimerNone from './CompanyStep5DisclaimerNone'
import CompanyStep5DisclaimerLiquidation from './CompanyStep5DisclaimerLiquidation'
import CompanyStep5DisclaimerUtility from './CompanyStep5DisclaimerUtility'
import CompanyStep5DisclaimerEquityBond from './CompanyStep5DisclaimerEquityBond'
import { useTranslation } from 'react-i18next'
import SwitchCheckboxTheme from '../SwitchCheckbox'
import Icon from '../Icon'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}))

const CompanyStep5 = ({
  publicSettings,
  values,
  setValues,
  checkboxValues,
  setCheckboxValues,
  toPrevStep,
  submitForm,
  loading,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [enableSubmit, setEnableSubmit] = useState(false);
  const isCryptoadvisory = window.location.host === 'kyc.cryptoadvisory.li' || window.location.host === 'dev-kyc.cryptoadvisory.li';
  const isAwearetech = window.location.host === 'kyc.awearetech.li' || window.location.host === 'aweare-dev.investhub.io' || window.location.host === 'localhost:3000';

  const awearetechDisclaimerText = {
    usExportControl:
    `Due to United States of America export control and SEC regulations, this offering is not available for those with jurisdiction and/or domicile in the United States, Iran, Russia, North Korea, Belarus, Syria, and any territories thereof. If any prospective investor with jurisdiction and/or domicile in these nations or territories make an incorrect or false answer regarding jurisdiction and/or domicile outside of these nations or territories, that prospective investor understands that making incorrect/false statements may be considered felony offences including but not limited to perjury, wire fraud, and making false statements, depending on their specific local laws and circumstances.
    <br><br>
    The undersigned hereby declares that they are a bona fide resident of the country of ${values.companyCountry} and reside in and maintain at least one place of abode in the Domiciled Country which they recognize and intend to maintain as their permanent home. If maintaining one or more other places of abode outside of the Domiciled Country, the undersigned also declares that the Domiciled Country constitutes their predominant and principal home.
    <br><br>
    The undersigned affirms and attests to the fact that they are not domiciled in the United States of America, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof.
    <br><br>
    The undersigned hereby declares that they will inform AWEARE Global AG administration through electronic means: admin@awearetech.li if at any time they intend to change their Domiciled Country to the United States, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof for the express purpose of reviewing held securities as subject to repurchase.
    <br><br>
    I further certify that I understand there is a penalty for perjury; and that perjury is a felony and is punishable by fines and/or incarceration in accordance with national and/or state laws.`,
    applicableLaw: `The offer and the agreement for the purchase of AWEARE Global Tokens as well as all rights and obligations arising in connection with or from the offer and the agreement for the purchase of AWEARE Global Tokens shall be governed exclusively by Liechtenstein law, excluding the conflict of law rules and referral provisions.`,
    placeOfJurisdiction: `The courts of the Principality of Liechtenstein shall have exclusive jurisdiction for all disputes arising out of or in connection with the offer or the agreement for the purchase of AWEARE Global Tokens, including those concerning the valid conclusion, legal validity, amendment or cancellation.`,
    severability: `If at any time one or more of the provisions of the AWEARE Global Token Offer and the agreement for its purchase is or becomes unlawful, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.`,
  }

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked});
  }

  useEffect(() => {
    if (isCryptoadvisory) {
      return
    }

    let listConfirm = [
      'pep_beneficial_owner',
      'clientStatement',
    ];

    if (isAwearetech) {
      listConfirm.push('usExportControl')
      listConfirm.push('applicableLaw')
      listConfirm.push('placeOfJurisdiction')
      listConfirm.push('severability')
    }

    const enable = listConfirm.reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  const handleSubmit = () => {
    submitForm();
  }

  const changePersonType = (value) => {
    setValues({
      ...values,
      personType: value,
      duties: value === 'NOT_POLITICAL_PERSON' ? '' : values.duties,
      relationshipToPEP: value === 'NOT_POLITICAL_PERSON' ? '' : values.relationshipToPEP,
    })
  }

  let prospectusOrIssuingDate, prospectusOrIssuing;

  switch ((publicSettings || {}).prospectus_or_issuing) {
    case PROSPECTUS_OR_ISSUING.ISSUING:
      prospectusOrIssuing = "Issuing Guidelines";
      prospectusOrIssuingDate = moment(
        publicSettings.issuing_guidelines
      ).format("YYYY-MM-DD");
      break;
    case PROSPECTUS_OR_ISSUING.PROSPECTUS:
      prospectusOrIssuing = "Prospectus";
      prospectusOrIssuingDate = moment(publicSettings.prospectus_date).format(
        "YYYY-MM-DD"
      );
      break;
    default:
      prospectusOrIssuing = "Token Sale Agreement";
      prospectusOrIssuingDate = moment(
        publicSettings.token_sale_agreement_date
      ).format("YYYY-MM-DD");
      break;
  }

  return (
    <div className="settings__wrapper">
      <div className="settings__item">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">
            {t('Legal disclaimers')}
          </div>
        </div>

        <div className="settings__fieldset">
          <SwitchCheckboxTheme
            switchText={t('The beneficial owner (plural inclusive) of the company is neither a politically exposed person (PEP), nor an immediate family member or a person known to be close to him.')}
            value="pep_beneficial_owner"
            onChange={changeCheckbox('pep_beneficial_owner')}
            isGrayText
            isLeftSwitch
            isRemoveLine
          />

          <div className="space space__24">{' '}</div>
        </div>
      </div>

      <div className="settings__list">
        {publicSettings.kyc_politically_exposed_persons && (
          <div className="settings__item" style={{ display: "none"}}>
            <div className="title-primary settings__title">
              {t('Politically Exposed Persons')}:
            </div>

            <div className="settings__fieldset">
              <div className="field">
                <div className="field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray">
                  <RadioGroup
                    aria-label="acknowledgement-of-receipt"
                    name="acknowledgement-of-receipt"
                    value={values.personType}
                    onChange={({target: {value}}) => changePersonType(value) }
                  >
                    <FormControlLabel
                      value="NOT_POLITICAL_PERSON"
                      control={<Radio color="primary" />}
                      label={
                        t('The Legal Entity is not a Politically Exposed Person, nor a family member of a Politically Exposed Person, and The Legal Entity is not known to a Politically Exposed Person.')
                      }
                    />

                    <FormControlLabel
                      value="POLITICAL_PERSON"
                      control={<Radio color="primary" />}
                      label={
                        t('The Legal Entity is a politically exposed person who exercises or has exercised a high-level public office at international, European or national level, or who has a public office below the national level whose political significance is comparable, or has exercised, a family member of such politically exposed Person or person known to be related to such politically exposed person.')
                      }
                    />

                    <FormControlLabel
                      value="ANOTHER_POLITICAL_PERSON"
                      control={<Radio color="primary" />}
                      label={t('The Legal Entity is another politically exposed person, a family member of such a politically exposed person or someone known to such a politically exposed person.')} />
                  </RadioGroup>
                </div>
              </div>

              {values.personType !== 'NOT_POLITICAL_PERSON' && (
                <div className="settings__row flex__row flex__wrap">
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t("Exact description of the function")}
                    value={values.duties}
                    onChange={({target: {value}}) => setValues({...values, duties: value})}
                  />
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t("Relationship to PEP")}
                    value={values.relationshipToPEP}
                    onChange={({target: {value}}) => setValues({...values, relationshipToPEP: value})}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {
          (isCryptoadvisory)
          ? (
            <CompanyStep5DisclaimerCaag
              publicSettings={publicSettings}
              prospectusOrIssuing={prospectusOrIssuing}
              checkboxValues={checkboxValues}
              setCheckboxValues={setCheckboxValues}
              setEnableSubmit={setEnableSubmit}
            />
          )
          : (
            <>
              <div style={{display: "none"}}>
                {publicSettings.token_type === TOKEN_TYPES.NONE && (
                  <CompanyStep5DisclaimerNone
                    publicSettings={publicSettings}
                    prospectusOrIssuing={prospectusOrIssuing}
                    checkboxValues={checkboxValues}
                    setCheckboxValues={setCheckboxValues}
                    setEnableSubmit={setEnableSubmit}
                  />
                )}

                {publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && (
                  <CompanyStep5DisclaimerLiquidation
                    publicSettings={publicSettings}
                    prospectusOrIssuing={prospectusOrIssuing}
                    checkboxValues={checkboxValues}
                    setCheckboxValues={setCheckboxValues}
                    setEnableSubmit={setEnableSubmit}
                    values={values}
                  />
                )}

                {(
                  publicSettings.token_type === TOKEN_TYPES.EQUITY
                  || publicSettings.token_type === TOKEN_TYPES.BOND
                ) && (
                  <CompanyStep5DisclaimerEquityBond
                    publicSettings={publicSettings}
                    prospectusOrIssuing={prospectusOrIssuing}
                    prospectusOrIssuingDate={prospectusOrIssuingDate}
                    checkboxValues={checkboxValues}
                    setCheckboxValues={setCheckboxValues}
                    setEnableSubmit={setEnableSubmit}
                  />
                )}

                {publicSettings.token_type === TOKEN_TYPES.UTILITY && (
                  <CompanyStep5DisclaimerUtility
                    publicSettings={publicSettings}
                    prospectusOrIssuing={prospectusOrIssuing}
                    checkboxValues={checkboxValues}
                    setCheckboxValues={setCheckboxValues}
                    setEnableSubmit={setEnableSubmit}
                  />
                )}
              </div>

              <div className="settings__item">
                <div className="card__head">
                  <div className="title-primary card__title card__title-has_icon">
                    { t('Declarations relevant to due diligence') }
                    {" "}

                    <span
                      data-tip={t('The intentional provision of false information is a punishable offence under the Liechtenstein Criminal Code. Any changes must be notified immediately.')}
                      data-for="info-due-diligence"
                    >
                      <Icon
                        className={classNames('info')}
                        name='info'
                        size="16"
                      />
                    </span>

                    <ReactTooltip id="info-due-diligence" place="bottom" padding="24px" className="popover-tooltip" />
                  </div>
                </div>

                <div className="settings__fieldset">
                  <div className="field">
                    <div className="field__wrap">
                      <SwitchCheckboxTheme
                        value="clientStatement"
                        defaultChecked={checkboxValues.clientStatement}
                        onChange={changeCheckbox('clientStatement')}
                        isRemoveLine
                        isGrayText
                        isLeftSwitch
                        switchText={t('We hereby confirm that the company itself is exclusively beneficially entitled to the assets for the acquisition of the tokens and is acting for its own account, in its own economic interest and not on behalf of others.')}
                      />
                    </div>
                  </div>
                </div>
              </div>

              { isAwearetech &&
                  <>
                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Declaration of Domicile')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="usExportControl"
                              defaultChecked={checkboxValues.usExportControl}
                              onChange={changeCheckbox('usExportControl')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['usExportControl']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Applicable Law')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="applicableLaw"
                              defaultChecked={checkboxValues.applicableLaw}
                              onChange={changeCheckbox('applicableLaw')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['applicableLaw']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Place of Jurisdiction')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="placeOfJurisdiction"
                              defaultChecked={checkboxValues.placeOfJurisdiction}
                              onChange={changeCheckbox('placeOfJurisdiction')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['placeOfJurisdiction']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Severability')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="severability"
                              defaultChecked={checkboxValues.severability}
                              onChange={changeCheckbox('severability')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['severability']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
            </>
          )
        }
      </div>

      <div className='settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between'>
        <button className="button-stroke" onClick={toPrevStep}>
          {t('Previous')}
        </button>

        { loading ? (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <button className="button" onClick={handleSubmit} disabled={!enableSubmit}>
            {t('Submit')}
          </button>
        )}
      </div>
    </div>
  )
}

export default CompanyStep5
