import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import {
  CircularProgress,
} from '@material-ui/core';

import {
  LIST_SPOT_CHECK,
  RESCAN_SPOT_CHECK_FOR_ALL_KYC,
  GET_SPOT_CHECK_STATISTIC,
} from '../../queriesAndMutations'
import {
  toaster,
  createQueryString,
} from '../../utils'
import { usePublicSettings, useMe } from '../../myHooks'

import PageDashboard from '../../components/PageDashboard'
import TablePaginationActions from '../../components/TablePagination';
import SpotCheckStatistic from './SpotCheckStatistic';
import { hasUserEnoughRights } from '../../utils/me'
import { USER_RIGHT_TYPES } from '../../constants/user'

const SpotCheck = () => {
  const { data: { me } = {} } = useMe();
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [rescanSpotCheckForAllKyc, rescanSpotCheckForAllKycStatus] = useMutation(RESCAN_SPOT_CHECK_FOR_ALL_KYC)
  const { data: { listSpotCheck } = {}, loading, refetch } = useQuery(LIST_SPOT_CHECK, {
    variables: {
      input: {
        page: page + 1,
        pageSize: rowsPerPage,
      },
    },
  })
  const { data: { getSpotCheckStatistic: statisticData } = {}, loading: statisticLoading, refetch: statisticRefetch } = useQuery(GET_SPOT_CHECK_STATISTIC)

  const changePage = (event, page) => { setPage(page) }
  const changeRowsPerPage = ({ currentTarget: { value } }) => {
    setPage(0)
    setRowsPerPage(+value)
  }
  const sendRescanSpotCheckForAllKycRequest = () => {
    rescanSpotCheckForAllKyc().then(({ data: { rescanSpotCheckForAllKyc: totalSpotCheckAdded } }) => {
      if (totalSpotCheckAdded > 0) {
        toaster.success(`${totalSpotCheckAdded} spot check request(s) has been added.`)
        refetch()
        statisticRefetch()
      } else {
        toaster.success('Spot check threshold is preserved.')
      }
    }).catch(() => {})
  }

  const isDisableRescan = () => {
    if (!publicSettings ||!statisticData) {
      return true
    }

    if ((statisticData.totalKyc * publicSettings.spot_check_threshold) / 100 <= statisticData.total) {
      return true
    }

    return false
  }

  const isAdmin = me && hasUserEnoughRights(
      me.rights,
      USER_RIGHT_TYPES.COMPLIANCE_OFFICER
    );

  return (
    <PageDashboard>
      <div className="page__container">
        <div className="page__title h3">{ t('Spot Check') }</div>

        <SpotCheckStatistic
          data={statisticData}
          loading={statisticLoading}
        />

        {isAdmin && (
          <div className="total card">
            <div className="card__head">
              <div className="title-primary card__title">
                { t('Actions') }
              </div>
            </div>

            <div className="income">
              {rescanSpotCheckForAllKycStatus.loading && (<CircularProgress />)}
              {!rescanSpotCheckForAllKycStatus.loading && (
                <button
                  className="button button-primary button-small"
                  onClick={sendRescanSpotCheckForAllKycRequest}
                  disabled={isDisableRescan()}
                  title={isDisableRescan() ? 'Spot check threshold is preserved.' : ''}
                >
                  Rescan spot check for all KYC
                </button>
              )}
            </div>
          </div>
        )}

        <div className="transactions card">
          <div className="products__head">
            <div className="title-blue card__title">{ t('Log Event Lists') }</div>
          </div>

          <div className="products__wrapper">
            <div className="products__table products__table-logevent">

              <div className="products__row">
                <div className="products__col">
                  { t('ID') }
                </div>

                <div className="products__col">
                  { t('KYC') }
                </div>

                <div className="products__col">
                  { t('Status') }
                </div>

                <div className="products__col">
                  { t('Approved By') }
                </div>

                <div className="products__col">
                  { t('Approved At')}
                </div>

                <div className="products__col">
                  { t('Actions') }
                </div>
              </div>

              { loading && (
                <div className="products__row">
                  <div className="transactions__col transactions__col-full">
                    <CircularProgress />
                  </div>
                </div>
              )}

              { listSpotCheck && listSpotCheck.data.map((spotCheck) => (
                <div
                  className="products__row"
                  key={spotCheck.id}
                >
                  <div className="products__col">
                    <div className="products__category">
                      {`#${spotCheck.id}`}
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__item products__item-normal flex__column flex__wrap flex__justify_start flex__align_start">
                      <div className="products__category">
                        {`#${spotCheck.customerId} ${spotCheck.customer ? spotCheck.customer.email : ''}`}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    {spotCheck.status === 'CHECKED' && (
                      <span className="status-green-dark">CHECKED</span>
                    )}
                    {spotCheck.status !== 'CHECKED' && (
                      <span className="status-red">{spotCheck.status.toUpperCase()}</span>
                    )}
                  </div>

                  <div className="products__col">
                    <div className="products__category">
                      {spotCheck.approvedBy && (
                        <>
                          {`User #${spotCheck.approvedBy}`}
                          {' '}
                          {spotCheck.approver && spotCheck.approver.email}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__category">
                      {spotCheck.approvedAt && moment(+spotCheck.approvedAt).tz('Europe/Vaduz').format('YYYY-MM-DD HH:mm z')}
                    </div>
                  </div>

                  <div className="products__col">
                    {!spotCheck.customer && (
                      <span className="status-red">Customer removed</span>
                    )}
                    {spotCheck.customer && (
                      <a
                        className={`button button-small ${(spotCheck.approvedBy || !isAdmin) && 'button-stroke'}`}
                        href={`${window.location.origin}/admin/kyc-list/individual?${createQueryString({id: spotCheck.customer.id, openDrawer: 1})}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t(spotCheck.approvedBy || !isAdmin ? 'View KYC' : 'Check')}
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          { listSpotCheck && listSpotCheck.data && (
            <TablePaginationActions
              count={listSpotCheck.total}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={changePage}
              onChangeRows={changeRowsPerPage}
              rowsPerPageOptions={[1, 5, 10, 25]}
            />
          )}
        </div>
      </div>
    </PageDashboard>
  )
}

export default SpotCheck
