import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useCookies } from 'react-cookie'
import { useMutation } from "@apollo/client";
import cn from 'classnames'
import { logout, getTextAvatarUrl } from '../../../utils'
import { usePublicSettings, useMe } from '../../../myHooks'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import * as languageHelper from '../../../utils/lang'
import Icon from '../../Icon'
import { SET_USER_LOCALE } from "../../../queriesAndMutations";

const HeaderTranslation = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    isMenuActive: false,
  });
  const [languageValues, setLanguageValues] = useState({
    isLanguageActive: false,
  })
  const [, setCookie] = useCookies(["jwt"]);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();
  const [setUserLocale] = useMutation(SET_USER_LOCALE)

  const fullName = me ? `${me.first_name} ${me.last_name}` : '';

  const siteLocales = ((publicSettings || {}).site_locales || '').split(',').filter(Boolean).map(x => String(x).trim())

  const onUserLogoClick = () => {
    setValues({ ...values, isMenuActive: !values.isMenuActive });
  };

  const onUserLogoAwayClick = () => {
    setValues({ ...values, isMenuActive: false });
  };

  const onLanguageClick = () => {
    setLanguageValues({ ...languageValues, isLanguageActive: !languageValues.isLanguageActive });
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <ClickAwayListener onClickAway={onUserLogoAwayClick}>
        <div
          className={`header__item header__item_user ${
            values.isMenuActive ? "active" : ""
          } ${fullName ? "" : "no_user"}`}
        >
          {fullName && (
            <>
              <button
                className="header__head"
                onClick={onUserLogoClick}
              >
                <img
                  src={getTextAvatarUrl(fullName)}
                  alt={fullName}
                />
              </button>

              <div className="header__body">
                {!(publicSettings && publicSettings.token_type === 'NONE') && (
                  <div className="header__nav">
                    {/* eslint-disable-next-line */}
                    <NavLink
                      to="/profile"
                      className="header__link header__link-block"
                    >
                    <Icon
                      className={cn('profile-circle', 'icon-24')}
                      name="profile-circle"
                      size="24"
                    />
                      { t('My profile') }
                    </NavLink>

                  </div>
                )}

                <div className="header__nav">
                  {/* eslint-disable-next-line */}
                  <a
                    className="header__link color header__link-block"
                    onClick={onLanguageClick}
                  >
                    <span className="icon-flag">
                      <img
                        src={languageHelper.getCurrentFlagIcon((publicSettings || {}).default_locale)}
                        alt={languageHelper.getCurrentLocalName((publicSettings || {}).default_locale)}
                        className="icon-flag__img"
                      />
                    </span>
                    {languageHelper.getCurrentLocalName((publicSettings || {}).default_locale)}
                  </a>
                </div>

                <div className="header__nav">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                  <a
                    className="header__link header__link-block"
                    onClick={() => {
                      setCookie("jwt", "", { path: "/" });
                      logout()();
                    }}
                  >
                    { t('Logout') }
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>

      {fullName && (
        <div
          className={`popup js-popup ${
            languageValues.isLanguageActive ? "animation visible" : ""
          }`}
        >
          <div onClick={onLanguageClick} className="popup__overlay js-popup-overlay">{' '}</div>

          <div className="popup__wrap js-popup-wrap">
            <div className="description">
              <div className="title-purple description__title">{ t('Change Languages') }</div>
              <div className="description__row flag__row">
                {Object.values(languageHelper.supportedLanguages).filter((lang) => siteLocales.includes(lang.code)).map(lang => (
                  <div
                    className="description__col"
                    key={lang.code}
                    onClick={() => {
                      languageHelper.setLocale(lang.code)
                      i18n.changeLanguage(lang.code)
                      setUserLocale({ variables: { locale: lang.code } })
                      setLanguageValues(false)
                    }}
                  >
                    <div className="flag__box">
                      <img src={lang.flag} alt={lang.label} height="40" />
                      <span>{lang.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button
              className="popup__close js-popup-close"
              onClick={onLanguageClick}
            >
              <Icon
                className={cn('close', 'icon-24')}
                name="close"
                size="24"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderTranslation;
