import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import TablePaginationActions from '../../components/TablePagination'
import { TOKEN_TYPES } from '../../constants/settings'
import { formatDate } from '../../utils/table'
import { toaster } from '../../utils/toaster'
import { getQueryVars } from '../../utils/url'
import { usePublicSettings } from '../../myHooks/useSettings'
import { useMe } from '../../myHooks/index'

import { useTranslation } from 'react-i18next'
import Icon from '../../components/Icon'
import TableRowTheme from '../../components/TableRow'
import TableColumnTheme from '../../components/TableColumn'
import moment from 'moment'
import InputTheme from "../../components/InputTheme";
import VolveApi from '../../services/volveApi'
import { NavLink, useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

const volveApi = new VolveApi();

const useStyles = makeStyles(() => {
  const defaultStatusIcon = {
    margin: "0 8px 0 0",
    opacity: ".8",
  };

  const statusBadge = {
    padding: "6px 12px",
    border: "1px solid #000",
    borderRadius: "8%",
    textTransform: "lowercase",
  };

  return {
    table: {
      margin: "0",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px",
      },
    },
    tableBody: {
      "& .MuiTableRow-root": {
        minHeight: "75px",
      },
      "& .MuiTableCell-body": {
        padding: "10px",
      },
    },
    circularProgressWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    activeStatusBadge: {
      textTransform: "capitalize",
      padding: "6px 12px",
      border: "1px solid #009f65",
      borderRadius: "8%",
    },
    disabledStatusBadge: {
      textTransform: "lowercase",
      padding: "6px 12px",
      border: "1px solid #ea0b0b",
      borderRadius: "8%",
    },
    actionBtnPaper: {
      zIndex: 10,
      position: "absolute",
      "&>div": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&>svg": {
          marginRight: "5px",
        },
        padding: "8px 10px",
      },
    },
    actionMenuOption: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&>svg": {
        marginLeft: "5px",
      },
    },
    txNum: {
      display: "flex",
      alignItems: "center",
    },
    cellText: {
      opacity: ".8",
      fontWeight: "bold",
      fontSize: "14px",
    },
    cellSubText: {
      opacity: ".6",
      fontSize: "12px",
    },
    successIcon: {
      ...defaultStatusIcon,
      color: "#009f65",
    },
    pendingIcon: {
      ...defaultStatusIcon,
      color: "#ffc100",
    },
    errorIcon: {
      ...defaultStatusIcon,
      color: "#f00",
    },
    idleIcon: {
      ...defaultStatusIcon,
      color: "#000",
    },
    buttonPay: {
      color: "#fff",
      fontSize: "11px",
      padding: "5px 15px",
      margin: "8px",
      textTransform: "capitalize",
      backgroundColor: "#007bff",
      "&:hover": {
        backgroundColor: "#253992",
      },
      "& svg": {
        margin: "0 0 0 8px",
      },
    },
    buttonDelete: {
      borderRadius: "4px",
      color: "#ff6868",
      backgroundColor: "#ffd8d8",
      transition: ".3s",
      "&:hover": {
        transition: ".3s",
        color: "#fff",
        backgroundColor: "#ff6868",
      },
    },
    buttonInfo: {
      color: "#495463",
      backgroundColor: "#e6effb",
      borderRadius: "4px",
      transition: ".3s",
      "&:hover": {
        transition: ".3s",
        color: "#fff",
        backgroundColor: "#495463",
      },
    },
    buttonBar: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      padding: "8px 10px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    actionIcon: {
      marginRight: "5px",
    },
    idleStatusBadge: statusBadge,
    pendingStatusBadge: {
      ...statusBadge,
      borderColor: "#ffc100",
    },
    rejectedStatusBadge: {
      ...statusBadge,
      borderColor: "#ea0b0b",
    },
    controlBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchField: {
      margin: "8px",
    },
    buttonsBar: {
      display: "flex",
      alignItems: "center",
    },
    settingsPepper: {
      right: "46px",
      padding: "10px 12px",
      position: "absolute",
      zIndex: 10,
      "& span": {
        textTransform: "lowercase",
      },
    },
  };
});

const Expenses = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryParam = getQueryVars();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();
  const [expenses, setExpenses] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const res = await volveApi.getExpenses({ page: page + 1, pageSize })
        console.log(res)
        setExpenses(res.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    if (page >= 0 && pageSize) {
      setLoading(true)
      fetchExpenses()
    }
  }, [page, pageSize])

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  return (
    <>
      <div className="page__title h3">
        { t('Expenses')}
        {' '}
        <NavLink
          to="/evolve/expenses/add"
          className="button-circle button-small"
          data-tip="Create User"
          data-for="tooltip-create-user"
        >
          <Icon className="plus" name="plus" />
        </NavLink>
      </div>

      <div className="transactions customer card">
        <div className="transactions__wrapper overflow-x__auto">
          <div className="transactions__table kyc__table_transactions">
            <TableRowTheme typeRow="transactions__row">

              <TableColumnTheme typeColumn="transactions__col" isFirstColumn={true}>
                { t('Date') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Merchant') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Category') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Payment Method') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Amount') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Status') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                {' '}
              </TableColumnTheme>
            </TableRowTheme>

            {expenses && expenses.result && expenses.result.map(expense => {
              return (
                <TableRowTheme typeRow="transactions__row" key={expense.id}>
                  <div className="transactions__col">
                    <div className="transactions__label">{ t('Date') }</div>
                    {expense.date}
                  </div>

                  <div className="transactions__col">
                    <div className="transactions__label">{ t('Merchant') }</div>
                    {expense.merchant}
                  </div>

                  <div className="transactions__col">
                    <div className="transactions__label">{ t('Category') }</div>
                    {expense.category}
                  </div>

                  <div className="transactions__col">
                    <div className="transactions__label">{ t('Payment Method') }</div>
                    {expense.paidWith}
                  </div>

                  <div className="transactions__col">
                    <div className="transactions__label">{ t('Amount') }</div>
                    {expense.amount}
                  </div>

                  <div className="transactions__col">
                    <div className="transactions__label">{ t('Status') }</div>
                    {expense.status}
                  </div>

                  <div className="transactions__col transactions__col-action vert__middle">
                    <div className="transactions__label">{ t('Action') }</div>
                    {expense.status === 'submitted' && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push({
                            pathname: "/evolve/balances",
                            search: "?" + new URLSearchParams({
                              expenseId: expense.id,
                              iban: expense.merchant,
                              amount: expense.amount,
                            }).toString()
                          })
                        }}
                      >
                        { t('Pay') }
                      </Button>
                    )}
                  </div>
                </TableRowTheme>
              )
            })}

            {(!expenses.result || expenses.result.length === 0) && (
              <div className="transactions__row">
                <div className="transactions__col transactions__col-full">
                  {' '}
                </div>
              </div>
            )}

            {loading && (
              <div className="transactions__row">
                <div className="transactions__col transactions__col-full">
                  <CircularProgress />
                </div>
              </div>
            )}
          </div>
        </div>
        <TablePaginationActions
          count={expenses.info ? expenses.info.total : 0}
          page={page}
          rowsPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRows={onChangePageSize}
          rowsPerPageOptions={[1, 5, 10, 25, 100]}
        />
      </div>
    </>
  )
}

export default Expenses;
