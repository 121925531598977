import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import {
  MAIN_INFO_SCHEMAS,
  ADDRESS_INFO_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  INDIVIDUAL_DOCUMENT_TYPE_ENUM,
} from '../../../schemas/kycVerification'
import {
  REGISTRATION_INDIVIDUAL_CUSTOMER,
  HAS_LIQUIDATION_PROOF_UPLOADED,
  ADD_LIQUIDATION_PROOF,
} from '../../../queriesAndMutations'
import { toaster } from '../../../utils'
import InputThemeField from '../../../components/InputThemeField'
import KycVerificationForm from '../../KycVerification/KycVerificationForm'

const TransferClaimBitcoin = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [proofOfPayment, setProofOfPayment] = useState('')

  const { data: { hasLiquidationProofUploaded } = {}, loading, refetch } = useQuery(HAS_LIQUIDATION_PROOF_UPLOADED, {
    variables: { type: 'tx_hash' }
  })
  const [registerIndividualCustomer, registerIndividualCustomerData] = useMutation(REGISTRATION_INDIVIDUAL_CUSTOMER)
  const [addLiquidationProof, addLiquidationProofStatus] = useMutation(ADD_LIQUIDATION_PROOF)

  if (loading) {
    return <CircularProgress />
  }

  const uploadProof = () => {
    const hashes = proofOfPayment.split(',')
      .map(hash => hash.trim())
      .filter(hash => !!hash)

    if (hashes.length === 0) {
      toaster.error('Transaction hash must not be empty')

      return
    }

    addLiquidationProof({
      variables: {
        input: {
          type: 'tx_hash',
          txHashes: hashes
        }
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  if (!hasLiquidationProofUploaded) {
    return (
      <>
        <div className="h3 nospace__bottom">{ t('Proof of payment') }</div>
        <div className="page__title">{' '}</div>
        <div className="card">
          <div className="field">
            <div className="field__label">
              Please send your transaction hash from the Bitcoin transfer to Envion AG
              <span className="text__red"> *</span>
            </div>
            <InputThemeField
              classWrapper="field w-full"
              classLabel="field__label"
              classInput="field__input"
              required
              value={proofOfPayment}
              onChange={({ target: { value }}) => {
                setProofOfPayment(value)
              }}
              helperText="Separated by comma"
            />
          </div>

          {addLiquidationProofStatus.loading && <CircularProgress />}
          {!addLiquidationProofStatus.loading && (
            <div className="field">
              <button className="button" onClick={uploadProof}>Submit</button>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <KycVerificationForm
      mainInfoSchemas={MAIN_INFO_SCHEMAS}
      addressInfoSchemas={ADDRESS_INFO_SCHEMAS}
      filesSchemas={INDIVIDUAL_FILES_SCHEMAS}
      documentTypeEnum={INDIVIDUAL_DOCUMENT_TYPE_ENUM}
      kycType="INDIVIDUAL"
      registerCustomer={registerIndividualCustomer}
      registerCustomerData={registerIndividualCustomerData}
      kycFormStepNumber={step}
      setKycFormStepNumber={setStep}
    />
  )
}

export default TransferClaimBitcoin
