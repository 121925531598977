/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Link, NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import classNames from 'classnames'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InfoIcon from '@material-ui/icons/Info'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import pick from 'lodash/pick'
import copy from 'copy-to-clipboard'
import qrcode from 'qrcode'
// import ReactPixel from 'react-facebook-pixel'
import payBankIcon from '../../../media/images/pay-bank-2.svg'
import paySendwyreIcon from '../../../media/images/sendwyre.svg'
import cryptoIcon from '../../../media/images/crypto.com.svg'
import stripeIcon from '../../../media/images/logo-stripe.svg'
import payCryptoManual from '../../../media/images/pay-crypto-manual.png'
import voltLogo from '../../../media/images/logo-volt.svg'
import {
  tokenToEth,
  tokenToUsdc,
  tokenToUsdt,
  tokenToXdc,
  tokenToPolygon,
  tokenToDai,
  ethToFiat,
  fiatToToken,
  sendwyreFiatToEth,
  isFiat,
  tokenToAnything,
} from '../../../utils/rate'
import { useExchangeRates, useMe } from '../../../myHooks/index'
import currencyIcons from '../../../media/images/currencyIcons/index'
import { numberWithCommas, toaster, getFileUrl, isProduction } from '../../../utils'
import { mandatoryKyc } from '../../../utils/me'
import CircularProgress from '@material-ui/core/CircularProgress'
import gql from 'graphql-tag'
import Icon from '../../../components/Icon'
import ModalInnerMaterialDialog from '../../../components/ModalInnerMaterialDialog'
import {
  CONTRIBUTE,
  GET_UNALLOCATED_TOKEN_AMOUNT,
  ME_INVEST_IN_BASE_CURRENCY,
  VOLT_PAYMENT_INIT_REQUEST,
  UPDATE_VOLT_TRANSACTION_ID,
  CREATE_CRYPTO_DOT_COM_PAYMENT,
  GET_SENDWYRE_RATES,
  LOOKUP_SENDWYRE_WALLET,
  UPDATE_CROWDSALE_BUY_TOKEN_TRANSACTION_HASH,
  CHECK_FIELDS_IS_FILLED,
} from '../../../queriesAndMutations'
import {
  CREATE_SENDWYRE_TRANSFER,
  CREATE_SENDWYRE_WALLET_ORDER_RESERVATION
} from '../../../queriesAndMutations/sendwyreMutation'
import {
  WALLET_ENGINE_WITHDRAW_BANK,
  WALLET_ENGINE_CURRENT_KYC_DETAILS,
} from '../../../queriesAndMutations/walletEngineMutation'
import { usePublicSettings } from '../../../myHooks/useSettings'
import { PAYMENT_TYPES, NETWORK } from '../../../constants/transaction'
import { USER_KYC_STATUS_TYPES } from '../../../constants/user'
import { PROSPECTUS_OR_ISSUING } from '../../../constants/settings'
import { DOCUMENT_TYPES } from '../../../constants/customer'

import metamaskService from '../../../services/metamask'
import WalletEngineCreateNewAndTopUpLink from '../../../components/WalletEngine/CreateNewAndTopUpLink'
import Radio from '@material-ui/core/Radio'
import { useTranslation, Trans } from 'react-i18next'
import UpgradeLevelDialog from '../../../components/UpgradeLevelDialog'
import InformationCard from '../../../components/InformationCard'
import RadioTheme from '../../../components/Radio'
import SwitchCheckbox from '../../../components/SwitchCheckbox'
import SendwyreCreateNewAndTopUpLink from '../../../components/Sendwyre/CreateNewAndTopUpLink'
import CardInformation from '../../../components/InformationCard'
import { payViaMetamask } from '../../../components/Transaction'
import styles from './styles.module.scss'
import { COUNTRY_BY_ALPHA_2_CODE } from '../../../constants/country'
import moment from 'moment'

const SENDWYRE_SUPPORTED_CURRENCIES = ['usdc', 'usdt', 'dai', 'polygon', 'usd', 'eur', 'chf'];

const DISCOUNT_LEVEL = [
  {
    id: 1,
    percent: 10,
    content:
      "10%  $2-5k with no lock-up period",
    min_amount: 2000,
    max_amount: 5000,
    currency: 'USD',
    phase: 1,
  },
  {
    id: 2,
    percent: 20,
    content:
      "20%  $5-50k with 6 month lock-up. Eligible for Delegation rewards. Linear unlock.",
    min_amount: 5000,
    max_amount: 50000,
    currency: 'USD',
    phase: 1,
  },
  {
    id: 3,
    percent: 30,
    content:
      "30%  $50-500k  with12 month lock-up. Eligible for Delegation rewards. Linear unlock.",
    min_amount: 50000,
    max_amount: 500000,
    currency: 'USD',
    phase: 1,
  },
  {
    id: 4,
    percent: 50,
    content:
      "50%  $50-150k with 24 month lock-up. Eligible for Delegation rewards.",
    min_amount: 50000,
    max_amount: 150000,
    currency: 'USD',
    phase: 1,
  },
  {
    id: 5,
    percent: 10,
    content:
      "10%  $1,000 Minimum Order",
    min_amount: 1000,
    currency: 'USD',
    phase: 2,
  },
  {
    id: 6,
    percent: 10,
    content:
      "10%  $1,000 Minimum Order",
    min_amount: 1000,
    currency: 'USD',
    phase: 3,
  },
];

const AWEARE_DISCOUNT_LEVEL = [
  {
    id: 0,
    percent: 0,
    content: 'No discount for investments under 10,000 USDC',
    min_amount: 0,
    max_amount: 9999.99,
    currency: 'USD',
    phase: 1,
  },
  {
    id: 1,
    percent: 10,
    content:
      'For investments between 10,000 USDC and 49,999.99 USDC, investors will receive a discount of 10%, making the price per AWEARE Token USD10.80.',
    min_amount: 10000,
    max_amount: 49999.99,
    currency: 'USD',
    phase: 1,
  },
  {
    id: 2,
    percent: 15,
    content:
      'For investments of 50,000 USDC or more, investors will receive a discount of 15%, making the price per AWEARE Token USD10.20.',
    min_amount: 50000,
    currency: 'USD',
    phase: 1,
  },
];

const CHECK_TRANSACTION_LIMIT = gql `
  query checkTransactionLimit {
    checkTransactionLimit
  }
`;

const useStyles = makeStyles(() => ({
  cursorPointer: {
    cursor: "pointer",
  },
  kycLvlUp: {
    fontSize: "1rem",
  },
  currencyIcon: {
    width: 22,
    height: 22,
    marginRight: 5,
  },
  radioInputLabel: {
    position: "relative",
  },
  payButtonIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: "16px",
  },
  paymentSuccessInfo: {
    width: "100%",
  },
  sendAmount: {
    textAlign: "left",
  },
  ethInputIcon: {
    width: "20px",
    height: "19px",
    position: "absolute",
    top: '13px',
    left: '8px',
  },
  qrCode: {
    backgroundColor: "#fff",
    border: "1px solid #dee2e6",
    borderRadius: ".25rem",
    maxWidth: "100%",
    height: "auto",
  },
  infoIcon: {
    left: "-17px !important",
  },
  closeButton: {
    position: "absolute",
    right: 1,
    top: 1,
    color: "#000",
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  voltBankLogo: {
    width: "80px",
    height: "auto",
    marginRight: "10px",
  },
  voltBankName: {
    fontWeight: "bold",
  },
  copyWrap: {
    position: 'relative',
    border: '1px solid #cccccc',
    display: 'inline-block',
    padding: '10px 10px 10px 35px',
  },
  swapBtn: {
    height: '30px',
  }
}));

const ContributeForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {}, loading: loadingPublicSetting } =
    usePublicSettings();
  const cryptoSettings = (publicSettings && publicSettings.crypto) || {};
  const acceptedCurrencies =
    (publicSettings && publicSettings.accepted_currencies) || {};

  const [contribute, contributeStatus] = useMutation(CONTRIBUTE);
  const { data: { exchangeRates } = {}, loading } = useExchangeRates();
  const { data: { getUnallocatedTokenAmount } = {} } = useQuery(GET_UNALLOCATED_TOKEN_AMOUNT, { fetchPolicy: 'network-only' });
  const {
    data: { me },
  } = useMe();
  const currentHost = window.location.host;
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io';
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

  const [invertTokenForm, setInvertTokenForm] = useState({
    invert: isQdev,
    amount: 0,
  })

  const eDocument = me.customer && me.customer.documentESignature && me.customer.documentESignature.length
    ? me.customer.documentESignature[me.customer.documentESignature.length - 1]
    : {};
  const [signnowAllSigned, setSignnowAllSigned] = React.useState(isQdev ? !eDocument.remote_id : true);
  let fields = [];

  if (eDocument.document_type === DOCUMENT_TYPES.INDIVIDUAL_FORM) {
    fields = ["Signature_1"]
  } else if (
    [
      DOCUMENT_TYPES.TOKEN_SAFE,
      DOCUMENT_TYPES.KYB_SINGLE_FORM,
      DOCUMENT_TYPES.KYB_COLLECTIVE_FORM,
      DOCUMENT_TYPES.LIQUIDATION_FORM
    ].includes(eDocument.document_type)) {
    fields = ["Signature_1", "Signature_2"]
  } else {
    fields = ["Signature_1", "Signature_2", "Signature_3"]
  }

  const { data: { checkFieldsIsFilled = {} } = {}, loading: checkFieldsIsFilledLoading } = useQuery(
    CHECK_FIELDS_IS_FILLED,
    {
      variables: {
        input: {
          fields,
          filename: eDocument.remote_id || '',
        },
      },
    },
    {
      enabled: !!eDocument.remote_id && isQdev
    }
  );

  React.useEffect(() => {
    if (checkFieldsIsFilled && checkFieldsIsFilled.result && isQdev) {
      const signers = pick(checkFieldsIsFilled.result, fields);

      if (Object.values(signers).filter(signer => !signer.fulfilled).length) {
        setSignnowAllSigned(false);
        changeMandatoryKYCNoticeDialogState(true)();
      } else {
        setSignnowAllSigned(true);
      }
    }

    // eslint-disable-next-line
  }, [checkFieldsIsFilled]);

  const [createCryptoPaymentDotCom] = useMutation(
    CREATE_CRYPTO_DOT_COM_PAYMENT
  );

  React.useEffect(() => {
    if (publicSettings && publicSettings.crypto_api_pk) {
      const script = document.createElement("script");
      script.src = `https://js.crypto.com/sdk?publishable-key=${publicSettings.crypto_api_pk}`;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [publicSettings]);

  const [openVideoIdentDialog, setOpenVideoIdentDialog] = React.useState(false);
  const [isTransactionPaid, setIsTransactionPaid] = React.useState(false);
  const [loadingBuyToken, setLoadingBuyToken] = React.useState(false);

  const [updateCrowdsaleBuyTokenTransactionHash] =
    useMutation(UPDATE_CROWDSALE_BUY_TOKEN_TRANSACTION_HASH);

  const onfidoChecks =
    (me &&
      me.customer &&
      me.customer.onfidoApplicant &&
      me.customer.onfidoApplicant.onfidoChecks) ||
    [];

  onfidoChecks.sort(function compare(a, b) {
    var dateA = new Date(a.created_at);
    var dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const completedCheck = onfidoChecks.find(
    (check) => check.status === "complete"
  );

  useEffect(() => {
    if (me && me.kyc_status !== "PASSED" && completedCheck) {
      setOpenVideoIdentDialog(true);
    }
  }, [completedCheck, me]);

  const {
    data: { meInvestInBaseCurrency = {} } = {},
    loading: loadingMeInvestInBaseCurrency,
    refetch: refetchMeInvestInBaseCurrency,
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);

  const {
    data: { getSendwyreExchangeRates = {} } = {},
    loading: loadingSendwyreExchangeRates,
  } = useQuery(GET_SENDWYRE_RATES);

  const {
    data: transactionLimitStatus,
    loading: loadingTransactionLimitStatus,
    refetch: refetchTransactionLimitStatus,
  } = useQuery(CHECK_TRANSACTION_LIMIT);

  const baseCurrency = publicSettings.base_currency
    ? publicSettings.base_currency
    : 'chf';

  let canInvesting =
    !loadingMeInvestInBaseCurrency && !loading
      ? meInvestInBaseCurrency.current_lvl.is_open_end
        ? Infinity
        : +fiatToToken(
            baseCurrency,
            meInvestInBaseCurrency.current_lvl.max_invest_amount -
              meInvestInBaseCurrency.invest_amount,
            exchangeRates
          )
      : 0;

  canInvesting = canInvesting < 0 ? 0 : canInvesting;

  const fiatList = ["usd", "eur", "chf"];
  const cryptoList = ["eth", "usdc", "usdt", "xdc", 'polygon', 'dai', 'eure', 'busd'];
  const allCurrency = [...fiatList, ...cryptoList];

  const acceptedCurrency = allCurrency.filter(
    (name) => acceptedCurrencies[name]
  );

  if (
    !loadingMeInvestInBaseCurrency &&
    meInvestInBaseCurrency.current_lvl.type_customer
  ) {
    var urlRedirect = `/application-lvl-increase/${meInvestInBaseCurrency.current_lvl.type_customer.toLowerCase()}/${
      meInvestInBaseCurrency.current_lvl.customer_id
    }`;
  }

  const getTokenValueInAnything = (currency, value = 1, keepOriginal = false) => {
    return tokenToAnything(currency, value, exchangeRates, keepOriginal)
  };

  const is_gto_sales = me.is_gtoswiss || me.is_internal_sales;
  const [initFirstSelectCurrency, setInitFirstSelectCurrency] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [disabledVoltPaymentBtn, setDisabledVoltPaymentBtn] = useState(false);
  const [voltPaymentUrl, setVoltPaymentUrl] = useState("");
  const [cryptoDotComPaymentUrl, setCryptoDotComPaymentUrl] = useState("");
  const [isUpgradeLvlDialogOpen, setIsUpgradeLvlDialogOpen] = useState(false);
  const [values, setValues] = useState({
    currency: (publicSettings || {}).base_currency || "chf",
    amount:
      me.amount_contribution_gto !== null
        ? String(parseInt(me.amount_contribution_gto))
        : 0,
    isContributionGto: me.amount_contribution_gto !== null,
    paymentType: PAYMENT_TYPES.BANK_TRANSFER,
    isMandatoryKYCNoticeDialogOpened: false,
    isMandatoryKYCNoticeDialogAlreadyClosed: false,
    isPaymentMethodDialogOpened: false,
    isPaymentConfirmationDialogOpened: false,
    isNoSendwyreWalletDialogOpened: false,
    isNoBalanceSendwyreWalletDialogOpened: false,
    isNonExchangesDialogOpen: false,
    isNonKycDialogAlreadyClosed: false,
    isNoKycDialogOpen: false,
    agreeWithConditions: false,
    agreeWithSubscriptionLink: false,
    confirmBeforeSubscription: false,
    legalDocumentAgreement: false,
    qrCodeUrl: null,
    transaction: {},
    selectedTransferType: PAYMENT_TYPES.BANK_TRANSFER,
    isPaymentViaVoltDialogOpened: false,
    isTransactionLimitReached: false,
    isTransactionLimitReachedDialogOpened: false,
    totalTransaction: 0,
    volt_banks: null,
    discountLevel: '',
  });

  React.useEffect(() => {
    if (publicSettings && publicSettings.enable_transaction_limit && transactionLimitStatus && transactionLimitStatus.checkTransactionLimit.reach_limit) {
      setValues({
        ...values,
        isTransactionLimitReached: true,
        isTransactionLimitReachedDialogOpened: true,
        totalTransaction: transactionLimitStatus.checkTransactionLimit.totalTransaction,

      })
    }
  }, [transactionLimitStatus, publicSettings])

  React.useEffect(() => {
    if (publicSettings && publicSettings.minimum_investment_amount && exchangeRates) {

      handleAmountChange({target: {value: publicSettings.minimum_investment_amount}})
    }
  }, [publicSettings, exchangeRates, values.discountLevel]);

  const payViaCryptoDotCom = async () => {
    const currencyAmount = calculateDiscount(
      getTokenValueInAnything(values.currency, values.amount)
    );
    try {
      const paymentObj = await createCryptoPaymentDotCom({
        variables: {
          input: {
            amount: +currencyAmount,
            currency: values.currency.toUpperCase(),
          },
        },
      });
      const paymentId = paymentObj.data.createPayment.paymentId;

      if (paymentId) {
        const input = contributeInput();

        input.paymentType = "CRYPTO.COM";
        input.cryptoPaymentId = paymentId;

        await handleCreatePaymentTransaction(false, false, input);
        setCryptoDotComPaymentUrl(paymentObj.data.createPayment.paymentUrl)
      } else {
        setValues({
          ...values,
          isPaymentMethodDialogOpened: false,
        });
        toaster.error("Cannot create cypto.com payment");
      }
    } catch (e) {}
  }

  const [onPayViaWalletEngineLoading, setOnPayViaWalletEngineLoading] =
    useState(false);

  // WalletEngine Variables
  const walletEngineAppID = "5db2a50be7664";
  const bodyData = { app_id: walletEngineAppID };
  const [
    getCurrentKYCDetails,
    { data: { walletEngineCurrentKYCDetails } = {} },
  ] = useMutation(WALLET_ENGINE_CURRENT_KYC_DETAILS);
  const [submitWalletEngineWithDrawBank] = useMutation(
    WALLET_ENGINE_WITHDRAW_BANK
  );

  // VOLT.IO
  const [updateVoltTransactionId] = useMutation(UPDATE_VOLT_TRANSACTION_ID);
  const [makeVoltPaymentInitRequest, makeVoltPaymentInitRequestStatus] = useMutation(VOLT_PAYMENT_INIT_REQUEST);

  // SENDWYRE
  const [createSendwyreTransfer] = useMutation(CREATE_SENDWYRE_TRANSFER);

  const handleSelectedTransferType = () => (event) =>
    setValues({
      ...values,
      selectedTransferType: event.target.value,
    });

  const [getCurrentSendwyreWallet] = useMutation(LOOKUP_SENDWYRE_WALLET);
  const [createWalletOrderReservation, createWalletOrderReservationStatus] = useMutation(
    CREATE_SENDWYRE_WALLET_ORDER_RESERVATION
  );

  const submitCreateWalletOrderReservation = async () => {
    const currencyAmount = calculateDiscount(
      getTokenValueInAnything(values.currency, values.amount)
    );

    const bodyData = {
      amount: +currencyAmount,
      sourceCurrency: 'USD',
      dest: `account:${publicSettings.sendwyre_account_id}`,
      destCurrency: mapSendwyreCurrency(values.currency),
      referrerAccountId: publicSettings && publicSettings.sendwyre_account_id,
      email: me.email,
      redirectUrl: window.location.origin,
      failureRedirectUrl: window.location.origin,
      paymentMethod: 'debit-card',
      firstName: me.first_name,
      lastName: me.last_name,
      phone: me.phone,
      country: me.customer && me.customer.countryOfResidence && COUNTRY_BY_ALPHA_2_CODE[me.customer.countryOfResidence] ?
        COUNTRY_BY_ALPHA_2_CODE[me.customer.countryOfResidence] :
        null,
      postalCode: me.customer && me.customer.postalCode ? me.customer.postalCode : null,
      street1: me.customer && me.customer.residentalAddress ? me.customer.residentalAddress : null,
      city: me.customer && me.customer.city ? me.customer.city : null,
      paymentMethod: 'debit-card',
      lockFields: ['destCurrency', 'dest', 'sourceCurrency', 'amount'],
    };

    const response = await createWalletOrderReservation({
      variables: { input: { data: bodyData } },
    })

    return response.data
  };

  if (
    acceptedCurrency.length > 0 &&
    !loadingPublicSetting &&
    !initFirstSelectCurrency
  ) {
    const randomInitCurrencyIndex = Math.floor(
      Math.random() * acceptedCurrency.length
    );
    const initCurrency = acceptedCurrency[randomInitCurrencyIndex];
    setValues({
      ...values,
      currency: initCurrency,
      paymentType:
        fiatList.indexOf(initCurrency) !== -1
          ? PAYMENT_TYPES.BANK_TRANSFER
          : PAYMENT_TYPES.CRYPTO,
      selectedTransferType:
        fiatList.indexOf(initCurrency) !== -1
          ? PAYMENT_TYPES.BANK_TRANSFER
          : PAYMENT_TYPES.CRYPTO,
    });
    setInitFirstSelectCurrency(true);
  }

  const agreementMapping = {
    [PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT]: {
      text: t('Token sale agreement'),
      url: publicSettings && publicSettings.token_sale_agreement_url,
    },
    [PROSPECTUS_OR_ISSUING.PROSPECTUS]: {
      text: t('Prospectus'),
      url: publicSettings && publicSettings.prospectus_url,
    },
    [PROSPECTUS_OR_ISSUING.ISSUING]: {
      text: t('Issuing guidelines'),
      url: publicSettings && publicSettings.issuing_guidelines,
    },
    [PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM]: {
      text: t('Token Offering Memorandum'),
      url: publicSettings && publicSettings.memorandum_url,
    },
    [PROSPECTUS_OR_ISSUING.SAFE]: {
      text: t('Token Safe'),
      url: publicSettings && publicSettings.token_safe_url,
    },
  }

  const bankDetails =
    ((publicSettings || {}).bank || {})[values.currency] || {};

  const ethAddress = cryptoSettings.eth_address;
  const usdcAddress = cryptoSettings.usdc_address;
  const usdtAddress = cryptoSettings.usdt_address;
  const xdcAddress = cryptoSettings.xdc_address;
  const polygonAddress = cryptoSettings.polygon_address;
  const daiAddress = cryptoSettings.dai_address;

  useEffect(() => {
    async function setQrCode() {
      let qrCodeUrl;
      if (values.transaction.currency === "eth") {
        qrCodeUrl = await qrcode.toDataURL(ethAddress);
      } else if (values.transaction.currency === "usdc") {
        qrCodeUrl = await qrcode.toDataURL(usdcAddress);
      } else if (values.transaction.currency === "usdt") {
        qrCodeUrl = await qrcode.toDataURL(usdtAddress);
      } else if (values.transaction.currency === "xdc") {
        qrCodeUrl = await qrcode.toDataURL(xdcAddress);
      } else if (values.transaction.currency === "polygon") {
        qrCodeUrl = await qrcode.toDataURL(polygonAddress);
      } else if (values.transaction.currency === "dai") {
        qrCodeUrl = await qrcode.toDataURL(daiAddress);
      }
      if (qrCodeUrl) {
        setValues((v) => {
          return { ...v, qrCodeUrl: qrCodeUrl };
        });
      }
    }

    setQrCode();
  }, [values.transaction.currency, ethAddress, usdcAddress]);

  const changeMandatoryKYCNoticeDialogState = (state) => () =>
    setValues({
      ...values,
      ...(state === false
        ? { isMandatoryKYCNoticeDialogAlreadyClosed: true }
        : {}),
      isMandatoryKYCNoticeDialogOpened: state,
    });

  const changePaymentMethodDialogState = (state) => () =>
    setValues({
      ...values,
      isPaymentMethodDialogOpened: state,
    });

  const changePaymentConfirmationDialogState = (state) => () =>
    setValues({
      ...values,
      isPaymentConfirmationDialogOpened: state,
    });

  const changePaymentViaVoltDialogState = (state) => () =>
    setValues({
      ...values,
      isPaymentViaVoltDialogOpened: state,
    });

  const changeTransactionLimitReachedDialogState = (state) => () =>
    setValues({
      ...values,
      isTransactionLimitReachedDialogOpened: state,
    });

  const changeNonExchangesDialogState = (state) => () =>
    setValues({
      ...values,
      isNonExchangesDialogOpen: state,
    });

  const changeNoKycDialogState = (state) => () =>
    setValues({
      ...values,
      ...(state === false ? { isNonKycDialogAlreadyClosed: true } : {}),
      isNoKycDialogOpen: state,
    });

  const handleCheckboxChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const formatPaymentRef = (transaction) =>
    transaction.payment_ref != null
      ? `TNX${transaction.id}-${transaction.payment_ref}`
      : `TNX${transaction.id}`;

  const handleAmountChange = (e) => {
    const { value } = e.target;
    let amount = isNaN(+value) ? 0 : +value;

    if (publicSettings.maximum_investment_amount && amount > publicSettings.maximum_investment_amount) {
      amount = publicSettings.maximum_investment_amount;
    }

    setValues({ ...values, amount });

    let invertAmount = getTokenValueInAnything(
      values.currency,
      amount
    )

    if (publicSettings && publicSettings.enable_kyc_discount_level) {
      invertAmount = calculateDiscount(invertAmount);
    }

    setInvertTokenForm({...invertTokenForm, amount: invertAmount})
  };

  const handleCurrencyRadioChange = (currency) => () => {
    setValues({
      ...values,
      currency,
      paymentType:
        fiatList.indexOf(currency) !== -1
          ? PAYMENT_TYPES.BANK_TRANSFER
          : PAYMENT_TYPES.CRYPTO,
      selectedTransferType:
        fiatList.indexOf(currency) !== -1
          ? PAYMENT_TYPES.BANK_TRANSFER
          : PAYMENT_TYPES.CRYPTO,
    });

    let invertAmount = getTokenValueInAnything(
      currency,
      values.amount
    )

    if (publicSettings && publicSettings.enable_kyc_discount_level) {
      invertAmount = calculateDiscount(invertAmount);
    }

    setInvertTokenForm({...invertTokenForm, amount: invertAmount})
  }

  const handleCreatePaymentTransaction = async (
    isSuccessWalletTransfer,
    isSuccessSendWyreTransfer,
    input
  ) => {
    if (
      (values.selectedTransferType === PAYMENT_TYPES.WALLET && isSuccessWalletTransfer) ||
      (values.selectedTransferType === PAYMENT_TYPES.SENDWYRE && isSuccessSendWyreTransfer) ||
      values.selectedTransferType === PAYMENT_TYPES.BANK_TRANSFER ||
      values.selectedTransferType === PAYMENT_TYPES.CRYPTO ||
      values.selectedTransferType === PAYMENT_TYPES.CRYPTODOTCOM ||
      values.selectedTransferType === PAYMENT_TYPES.STRIPE
    ) {
      try {
        const {
          data: { contribute: dataContribute },
        } = await contribute({ variables: { input } });

        refetchMeInvestInBaseCurrency();

        // ReactPixel.track("Purchase", { value: input.amount, ...input });

        if (values.selectedTransferType === PAYMENT_TYPES.STRIPE) {
          window.location.href = dataContribute.url
          return
        }

        const sendwyreInstantPay = (publicSettings &&
          publicSettings.sendwyre_payment_type === 'enable_direct_purchase' &&
          values.selectedTransferType === PAYMENT_TYPES.SENDWYRE
        ) || false

        if (!sendwyreInstantPay && Object.keys(dataContribute).length > 0) {
          setDisabledBtn(false);
          setValues({
            ...values,
            isPaymentMethodDialogOpened: false,
            isNonExchangesDialogOpen: false,
            isPaymentConfirmationDialogOpened: true,
            transaction: dataContribute,
          });
        }
      } catch (e) {}
    }
  };

  const handleClosePaymentMethodDialog = () => {
    changePaymentMethodDialogState(false)();
    setOnPayViaWalletEngineLoading(false);
    setDisabledBtn(false);
  };

  const checkDiscountAvailable = () => {
    if (!isAwearetech) return true;

    if (!transactionLimitStatus || !publicSettings) return false;

    if (transactionLimitStatus.checkTransactionLimit.totalAmountInvest >= publicSettings.discount_maximum_raise) return false;

    if (moment(publicSettings.discount_end_date).isBefore(moment(), "D")) return false;

    return true;
  }

  const contributeInput = () => {
    const input = pick(values, [
      "currency",
      "amount",
      "paymentType",
      "isContributionGto",
    ]);

    input["discountPercent"] =
      (publicSettings && publicSettings.enable_kyc_discount_level && checkDiscountAvailable()
        && ((isAwearetech ? AWEARE_DISCOUNT_LEVEL : DISCOUNT_LEVEL).find(ele => ele.id == values.discountLevel) || {}).percent) ||
      0;
    input["discountLevel"] =
      (publicSettings && publicSettings.enable_kyc_discount_level && checkDiscountAvailable()
        && values.discountLevel) ||
      0;

    return { ...input };
  };

  const onPay = async () => {

    setIsTransactionPaid(false)
    setDisabledBtn(true);
    let walletEngineTransactionId = null;
    let sendwyreTransferId = null;

    const input = contributeInput();

    let isSuccessWalletTransfer = false,
      isSuccessSendWyreTransfer = false;

    if (
      values.selectedTransferType === PAYMENT_TYPES.WALLET ||
      values.selectedTransferType === PAYMENT_TYPES.SENDWYRE
    ) {
      // handle payment for wallet
      if (values.selectedTransferType === PAYMENT_TYPES.WALLET) {
        setOnPayViaWalletEngineLoading(true);
        input.paymentType = PAYMENT_TYPES.WALLET;
        const amount_in_eur = getTokenValueInAnything("eur", values.amount);
        const bodyTransferData = {
          app_id: walletEngineAppID,
          amount: amount_in_eur,
          channel_id: "sg_debit_enercom",
          variables: {
            bank_name: "Bank of Enercom",
          },
        };
        console.log(bodyTransferData);
        try {
          const response = await submitWalletEngineWithDrawBank({
            variables: { input: bodyTransferData },
          });

          setOnPayViaWalletEngineLoading(false);
          console.log(response);
          if (
            response.data &&
            response.data.walletEngineWithDrawBank &&
            response.data.walletEngineWithDrawBank.status === "success"
          ) {
            walletEngineTransactionId =
              response.data.walletEngineWithDrawBank.transaction_id;
            isSuccessWalletTransfer = true;
            console.log(walletEngineTransactionId);
          }
        } catch (e) {}
      }

      // handle payment for sendwyre
      if (
        values.selectedTransferType === PAYMENT_TYPES.SENDWYRE &&
        !loadingSendwyreExchangeRates
      ) {
        setOnPayViaWalletEngineLoading(true);
        input.paymentType = PAYMENT_TYPES.SENDWYRE;
        if (
          publicSettings && publicSettings.sendwyre_payment_type === 'enable_direct_purchase'
        ) {
          const { createWalletOrderReservation: { reservation, url }} = await submitCreateWalletOrderReservation();
          await handleCreatePaymentTransaction(
            false,
            true,
            {...input, reservation, url }
          );

          window.location.href = url
          return
        } else {
          const walletId = me && me.sendwyre_wallet_id;
          if (walletId === null || walletId === undefined) {
            setDisabledBtn(false);
            setOnPayViaWalletEngineLoading(false);
            setValues({
              ...values,
              isNoSendwyreWalletDialogOpened: true,
              isPaymentMethodDialogOpened: false,
              isNonExchangesDialogOpen: false,
            });
          } else if (walletId) {
            try {
              const currentSendwyreWallet = await getCurrentSendwyreWallet({
                variables: { input: { walletId: walletId } },
              });
              const sendwyreCurrency = mapSendwyreCurrency(values.currency)

              if (
                (currentSendwyreWallet.data.lookupSendwyreWallet.totalBalances[sendwyreCurrency] || 0) <
                +getTokenValueInAnything(values.currency, values.amount)
              ) {
                setDisabledBtn(false);
                setOnPayViaWalletEngineLoading(false);
                setValues({
                  ...values,
                  isNoBalanceSendwyreWalletDialogOpened: true,
                  isPaymentMethodDialogOpened: false,
                  isNonExchangesDialogOpen: false,
                });
              } else {
                const bodyTransferData = {
                  source: `wallet:${me.sendwyre_wallet_id}`,
                  sourceCurrency: mapSendwyreCurrency(values.currency),
                  sourceAmount: getTokenValueInAnything(values.currency, values.amount),
                  dest: `account:${publicSettings.sendwyre_account_id}`,
                  destCurrency: mapSendwyreCurrency(values.currency),
                  autoConfirm: true,
                };

                try {
                  const response = await createSendwyreTransfer({
                    variables: { input: { data: bodyTransferData } },
                  });

                  setOnPayViaWalletEngineLoading(false);
                  setDisabledBtn(false);
                  setValues({
                    ...values,
                    isNonExchangesDialogOpen: false,
                  });
                  console.log("createSendwyreTransfer response");
                  console.log(response);
                  if (
                    response.data &&
                    response.data.createSendwyreTransfer
                  ) {
                    if (response.data.createSendwyreTransfer.status === "success") {
                      isSuccessSendWyreTransfer = true;
                      toaster.success("Create transfer successfully!");
                      sendwyreTransferId = response.data.createSendwyreTransfer.id;
                    } else {
                      toaster.error(response.data.createSendwyreTransfer.message)
                    }
                  } else {
                    toaster.error('Something went wrong. Please try again later.')
                  }
                } catch (e) {
                  toaster.error('Something went wrong. Please try again later.')
                }
              }
            } catch (e) {
              toaster.error('Invalid wallet.')
            }
          }
        }
      }

      if (walletEngineTransactionId !== null) {
        input.walletTransactionId = walletEngineTransactionId;
      }
      if (sendwyreTransferId !== null) {
        input.sendwyreTransferId = sendwyreTransferId;
      }
    } else if (values.selectedTransferType === PAYMENT_TYPES.VOLT) {
      setDisabledBtn(true);
      setValues({
        ...values,
        isNonExchangesDialogOpen: false,
        isPaymentViaVoltDialogOpened: true,
      });
      onCheckoutVolt().finally(() => {
        setDisabledBtn(false);
      });

      return
    } else if (values.selectedTransferType === PAYMENT_TYPES.CRYPTODOTCOM) {
      payViaCryptoDotCom()

      return
    } else if (values.selectedTransferType === PAYMENT_TYPES.STRIPE) {
      input.paymentType = PAYMENT_TYPES.STRIPE;
    }

    handleCreatePaymentTransaction(
      isSuccessWalletTransfer,
      isSuccessSendWyreTransfer,
      input
    );
  };

  useEffect(() => {
    if (
      me.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED &&
      !values.isNoKycDialogOpen &&
      !values.isNonKycDialogAlreadyClosed
    ) {
      changeNoKycDialogState(true)();
    }
  });

  useEffect(() => {
    if (
      is_gto_sales &&
      me.kyc_status !== USER_KYC_STATUS_TYPES.PASSED &&
      (publicSettings.accepted_mandatory_kyc || {}).mandatoryKYCReferral &&
      !values.isMandatoryKYCNoticeDialogAlreadyClosed &&
      !values.isMandatoryKYCNoticeDialogOpened &&
      !completedCheck
    ) {
      changeMandatoryKYCNoticeDialogState(true)();
    }
    if (
      !is_gto_sales &&
      me.kyc_status !== USER_KYC_STATUS_TYPES.PASSED &&
      (publicSettings.accepted_mandatory_kyc || {}).mandatoryKYCOrganic &&
      !values.isMandatoryKYCNoticeDialogAlreadyClosed &&
      !values.isMandatoryKYCNoticeDialogOpened &&
      !completedCheck
    ) {
      changeMandatoryKYCNoticeDialogState(true)();
    }
  });

  useEffect(() => {
    if (
      values.isMandatoryKYCNoticeDialogOpened &&
      values.isNoKycDialogOpen &&
      !values.isNonKycDialogAlreadyClosed
    ) {
      changeNoKycDialogState(false)();
    }
  });

  useEffect(() => {
    if (me.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED) {
      getCurrentKYCDetails({
        variables: { input: bodyData },
      }).catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVoltPaymentUrl = () => {
    if (voltPaymentUrl) {
      window.location.href = voltPaymentUrl;

      return (
        <div>
          Redirecting to Volt.io Checkout...
          {' '}
          <CircularProgress />
        </div>
      );
    }

    return (
      <div>
        <p>
          { t('Open the link below on new tab and follow up to complete payment with Volt') }:
        </p>
        <a
          href={voltPaymentUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          { t('PAYMENT LINK') }
        </a>
      </div>
    )
  };

  const renderCryptoDotComPaymentUrl = () => (
    <div>
      <p>
        { t('Open the link below on new tab and follow up to complete payment with Crypto.com') }:
      </p>
      <a
        href={cryptoDotComPaymentUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        { t('PAYMENT LINK') }
      </a>
    </div>
  );

  const onCheckoutVolt = async () => {
    setDisabledVoltPaymentBtn(true);

    const input = contributeInput();
    input.paymentType = PAYMENT_TYPES.VOLT;

    let voltioAccount, checkoutUrl;
    if (publicSettings && !publicSettings.enable_voltio) {
      throw new Error("Volt IO payment is not active");
    } else if (publicSettings && publicSettings.enable_voltio) {
      voltioAccount = publicSettings.voltio_account;

      checkoutUrl =
        "https://" +
        publicSettings.voltio_url.replaceAll("https://api.", "checkout.");
    }

    try {
      const {
        data: { contribute: dataContribute },
      } = await contribute({ variables: { input } });

      const voltPaymentRequestData = {
        currencyCode: "EUR",
        amount: getTokenValueInAnything("eur", values.amount) * 100 + "",
        account: voltioAccount,
        type: "BILL",
        uniqueReference: formatPaymentRef(dataContribute),
        // bank: values.volt_selected_bank,
      };
      const response = await makeVoltPaymentInitRequest({
        variables: {
          input: {
            payment_request: voltPaymentRequestData,
            transaction_id: dataContribute.id
          },
        },
      });

      if (response && response.data && response.data.voltPaymentInitRequest) {
        if (response.data.voltPaymentInitRequest.status === "success") {
          toaster.success("Init payment request with Volt successfully!");
          const transactionId = dataContribute.id;
          const voltPaymentId = response.data.voltPaymentInitRequest.id;
          const encodedVoltPaymentId = btoa(voltPaymentId);
          const newVoltPaymentUrl = `${checkoutUrl}en/${encodedVoltPaymentId}`;

          await updateVoltTransactionId({
            variables: {
              id: transactionId,
              voltTransactionId: voltPaymentId,
            },
          }).catch(() => {});

          setVoltPaymentUrl(newVoltPaymentUrl);

          return;
        } else {
          toaster.error(response.data.voltPaymentInitRequest.message);
        }
      } else {
        toaster.error("Something went wrong when init payment from Volt.io");
      }

      refetchMeInvestInBaseCurrency();
      // ReactPixel.track("Purchase", { value: input.amount, ...input });

      if (Object.keys(dataContribute).length > 0) {
        setDisabledBtn(false);
        setValues({
          ...values,
          isPaymentMethodDialogOpened: false,
          isNonExchangesDialogOpen: false,
          isPaymentViaVoltDialogOpened: false,
          isPaymentConfirmationDialogOpened: true,
          transaction: dataContribute,
        });
      }
    } catch (e) {}
  };

  const wallet_engine_topup_amount =
    (walletEngineCurrentKYCDetails && walletEngineCurrentKYCDetails.balance) ||
    0;

  const renderMandatoryKYC = () => (
    <Dialog
      open={
        values.isMandatoryKYCNoticeDialogOpened &&
        me &&
        (me.kyc_status === "NOT_SUBMITTED"  || me.kyc_status === 'PENDING' || !signnowAllSigned)
      }
      onClose={changeMandatoryKYCNoticeDialogState(false)}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={changeMandatoryKYCNoticeDialogState(false)}
        modalTitleClassColor="title-primary"
        modalTitle={ t('Notice') }
      >
        <div className="description__row flex__row flex__wrap">
          {(me && me.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED) ? (
            <>
              <div className="refund__content">
                <p>
                { t('Please, complete KYC first.') }
                </p>
              </div>
              <div className="space space__32">{' '}</div>
              <NavLink to="/application" className="button">{t('Start here')}</NavLink>
            </>
          ) : (me && me.kyc_status === USER_KYC_STATUS_TYPES.PENDING) ? (
            <div className="refund__content">
              <p>
                { t('Your KYC is pending, please come back again once it has been approved. You will be informed by Email.') }
              </p>
            </div>
          ) : !signnowAllSigned ? (
            <div className="refund__content">
              <p>
                { t('The signing is not completed') }
              </p>
            </div>
          ) : (me && me.kyc_status) ? (
            <div className="refund__content">
              <p>
                { t('Your KYC is') } {me.kyc_status.toLowerCase()}.
              </p>
            </div>
          ) : ''}
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const renderVideoIdentPendingorApprovedModal = () => {
    return (
      <Dialog
        open={openVideoIdentDialog}
        onClose={() => {
          setOpenVideoIdentDialog(false);
        }}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={() => {
              setOpenVideoIdentDialog(false);
            }}
          modalTitle={t('Notice')}
          modalTitleClassColor="title-primary"
        >
          <div className="description__row flex__row flex__wrap">
            <div className="refund__content">
              <p>
                {t('Thank you for completing the liveness check.')}
                {' '}
                {t('Once our compliance officer will verify your entry, you will be able to purchase tokens.')}
                {' '}
                {t('This can take up to 24-48 hours and you will receive another Email once this step is completed.')}
              </p>
            </div>
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    );
  };

  const renderOptionPaymentViaWallet = () => (
    <>
      {me.wallet_engine_token &&
      wallet_engine_topup_amount !== 0 &&
      wallet_engine_topup_amount >=
        getTokenValueInAnything(values.currency, values.amount) ? (
        <>
          <RadioTheme
            nameGroup="pay-transfer"
            defaultChecked={values.selectedTransferType === PAYMENT_TYPES.WALLET}
            onChange={handleSelectedTransferType()}
            radioText={
              <>
                {t('Pay via Wallet')}
                <img
                  src={payCryptoManual}
                  className="pay-check-img"
                  alt="pay-logo"
                />
              </>
            }
          />
        </>
      ) : (
        <>
        </>
      )}
    </>
  );

  const renderOptionPaymentWithVolt = () => {
    if ((publicSettings && !publicSettings.enable_voltio) || cryptoList.includes(values.currency)) {
      return <></>;
    }
    return (
      <>
        <span className="space space__32">{' '}</span>
        <RadioTheme
          nameGroup="pay-transfer"
          value={PAYMENT_TYPES.VOLT}
          onChange={handleSelectedTransferType()}
          defaultChecked={values.selectedTransferType === PAYMENT_TYPES.VOLT}
          radioText={
            <>
              { t('Pay via Volt (instant)') }
              <img src={voltLogo} className="pay-check-img" alt="pay-logo" />
            </>
          }
        />
        <span className="space space__16">{' '}</span>
        <InformationCard color="card__information-primary">
          { t('Instant Bank Transfer with your Online Banking in BASE_CURRENCY (4-5 days) processing time. Fee: FEE_VALUE - minimum FEE_MINIMUM BASE_CURRENCY, maximum FEE_MAXIMUM BASE_CURRENCY', {
            nsSeparator: '|',
            BANK_NAME: bankDetails.bank_name ? bankDetails.bank_name : 'Bank Frick',
            FEE_VALUE: publicSettings.bank.fee ? publicSettings.bank.fee : '',
            FEE_MINIMUM: publicSettings.bank.minimum_fee ? publicSettings.bank.minimum_fee : '',
            FEE_MAXIMUM: publicSettings.bank.maximum_fee ? publicSettings.bank.maximum_fee : '',
            BASE_CURRENCY: publicSettings.base_currency ? publicSettings.base_currency.toUpperCase() : '',
          }) }
        </InformationCard>
      </>
    );
  };

  const mapSendwyreCurrency = (currency) => {
    if (currency === 'polygon') {
      return 'MATIC';
    }

    if (isFiat(currency)) {
      return 'USDC';
    }

    return currency.toUpperCase();
  }

  const renderOptionPaymentWithSendWyre = () => {
    if (!SENDWYRE_SUPPORTED_CURRENCIES.includes(values.currency)) {
      return null
    }

    const sendwyreInstantPay = (publicSettings && publicSettings.sendwyre_payment_type === 'enable_direct_purchase') || false

    const requiredAmount = getTokenValueInAnything(values.currency, values.amount)
    const topUpAmountInUsd = Math.ceil(
      requiredAmount *
      (values.currency === 'usd' ? 1 : getSendwyreExchangeRates[`${mapSendwyreCurrency(values.currency)}USD`]) *
      100
    ) / 100

    return (
      <>
        <span className="space space__32">{' '}</span>
        <RadioTheme
          nameGroup="pay-transfer"
          value={PAYMENT_TYPES.SENDWYRE}
          onChange={handleSelectedTransferType()}
          defaultChecked={values.selectedTransferType === PAYMENT_TYPES.SENDWYRE}
          disabled={!me.sendwyre_wallet_id && !sendwyreInstantPay}
          radioText={
            <>
              {t(`Pay via SendWyre ${sendwyreInstantPay ? '(instant)' : ''}`)}
              <img
                src={paySendwyreIcon}
                className="pay-check-img"
                alt="sendwyre-logo"
              />
            </>
          }
        />
        <span className="space space__16">{' '}</span>
        {!sendwyreInstantPay && me.sendwyre_wallet_id && (
          <InformationCard color="card__information-primary">
            {t('Instant purchase with credit card in Euro Instantly (10-60 seconds) / Fee 5%')}
          </InformationCard>
        )}
        {!sendwyreInstantPay && <SendwyreCreateNewAndTopUpLink
          parent="ContributeForm"
          currency={mapSendwyreCurrency(values.currency)}
          topUpAmount={topUpAmountInUsd}
          fiatCurrency={isFiat(values.currency) ? values.currency.toUpperCase() : undefined }
        />}
      </>
    )
  };

  const renderOptionPaymentWithCryptoDotCom = () => {
    return (
      publicSettings &&
      publicSettings.enable_cryptodotcom &&
      (
        isFiat(values.currency) || ['btc', 'eth', 'ltc', 'cro'].includes(values.currency)
      ) && (
        <>
          <span className="space space__32">{' '}</span>
          <RadioTheme
            nameGroup="pay-transfer"
            value={PAYMENT_TYPES.CRYPTODOTCOM}
            onChange={handleSelectedTransferType(PAYMENT_TYPES.CRYPTODOTCOM)}
            defaultChecked={
              values.selectedTransferType === PAYMENT_TYPES.CRYPTODOTCOM
            }
            radioText={
              <>
                {t('Pay via Crypto.com')}
                <img
                  src={cryptoIcon}
                  className="pay-check-img"
                  alt="crypto.com-logo"
                />
              </>
            }
          />
        </>
      )
    );
  };

  const renderOptionPaymentWithStripe = () => {
    if (
      !publicSettings ||
      !publicSettings.enable_stripe ||
      !isFiat(values.currency)
    ) return

    return <>
      <span className="space space__32">{' '}</span>
      <RadioTheme
        nameGroup="pay-transfer"
        value={PAYMENT_TYPES.STRIPE}
        onChange={handleSelectedTransferType(PAYMENT_TYPES.STRIPE)}
        defaultChecked={
          values.selectedTransferType === PAYMENT_TYPES.STRIPE
        }
        radioText={
          <>
            {t('Pay via Stripe')}
            <img
              src={stripeIcon}
              className="pay-check-img"
              alt="stripe-logo"
            />
          </>
        }
      />
    </>
  };

  const renderPaymentMethodDialog = () => (
    <Dialog
      open={values.isPaymentMethodDialogOpened}
      onClose={changePaymentMethodDialogState(false)}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={changePaymentMethodDialogState(false)}
        modalTitleClassColor="title-primary"
        modalTitle={ t('Purchase Tokens and Make Payment') }
      >
        <div className="description__row flex__row flex__wrap">
          <div className="refund__content w-full">
            <p className="lead">
              { t('To receive') }{" "}
              <strong>
                {numberWithCommas(values.amount)}{" "}
                {publicSettings && publicSettings.token_symbol}
              </strong>{" "}
              { t('you are required to pay') }{" "}
              <strong>
                {publicSettings && publicSettings.enable_kyc_discount_level
                  ? calculateDiscount(
                      getTokenValueInAnything(values.currency, values.amount)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : numberWithCommas(
                      getTokenValueInAnything(values.currency, values.amount)
                    )}{" "}
                {values.currency.toUpperCase()}
              </strong>
            </p>
            <p>
              { t('You can choose any of following payment methods to make your payment.') }
            </p>
            <p>
              { t('Your token balance will reflect after payment. Token transfers to your account will be confirmed by email.') }
            </p>
          </div>

          <div className="space space__32">{' '}</div>
          <div className="settings__wrapper w-full">
            <div className="settings__fieldset">
              <div className="field w-full">
                <div className="field__label">{ t('Select payment method') }:</div>

                <div className="field__wrap d__flex flex__row flex__wrap field__wrap-radio-500 field__wrap-radio-full">
                  {isFiat(values.currency) ? (
                    <>
                      <RadioTheme
                        nameGroup="pay-transfer"
                        value={PAYMENT_TYPES.BANK_TRANSFER}
                        onChange={handleSelectedTransferType()}
                        defaultChecked={
                          values.selectedTransferType === PAYMENT_TYPES.BANK_TRANSFER
                        }
                        radioText={
                          <>
                            { t('Bank Transfer (4-5 days)') }
                            {' '}
                            <img
                              src={
                                bankDetails && bankDetails.logo_bank_path
                                  ? getFileUrl(publicSettings.bank[values.currency].logo_bank_path)
                                  : payBankIcon
                              }
                              className={classNames('pay-check-img banktransfer', styles.module__image_icon)}
                              alt="pay-logo"
                            />
                          </>
                        }
                      />
                      <span className="space space__16">{' '}</span>
                      <InformationCard color="card__information-primary">
                        { t('Bank transfer to BANK_NAME with SEPA/SWIFT in BASE_CURRENCY (4-5 days) processing time. Fee: FEE_VALUE - minimum FEE_MINIMUM BASE_CURRENCY, maximum FEE_MAXIMUM BASE_CURRENCY', {
                          nsSeparator: '|',
                          BANK_NAME: bankDetails.bank_name ? bankDetails.bank_name : 'Bank Frick',
                          FEE_VALUE: publicSettings.bank.fee ? publicSettings.bank.fee : '',
                          FEE_MINIMUM: publicSettings.bank.minimum_fee ? publicSettings.bank.minimum_fee : '',
                          FEE_MAXIMUM: publicSettings.bank.maximum_fee ? publicSettings.bank.maximum_fee : '',
                          BASE_CURRENCY: publicSettings.base_currency ? publicSettings.base_currency.toUpperCase() : ''
                          })}

                          <br />
                          <br />

                          { t('When instructing your wire transfer please be sure to set fees to “our” as any fees charged by your bank or correspondent banks may reduce the net amount we receive and that we can credit towards your YOURTOKENNAME purchase.', {
                            nsSeparator: '|',
                            YOURTOKENNAME: publicSettings.token_name ? publicSettings.token_name : '',
                          })}
                      </InformationCard>

                      {renderOptionPaymentWithVolt()}
                    </>
                  ) : (
                    <>
                      <RadioTheme
                        nameGroup="pay-transfer"
                        value={PAYMENT_TYPES.CRYPTO}
                        onChange={handleSelectedTransferType()}
                        defaultChecked={values.selectedTransferType === PAYMENT_TYPES.CRYPTO}
                        radioText={
                          <>
                            { t('Pay With Crypto') }
                            <img
                              src={payCryptoManual}
                              className={classNames('pay-check-img crypto', styles.module__image_icon)}
                              alt="pay-logo"
                            />
                          </>
                        }
                      />
                      <span className="space space__16">{' '}</span>
                      <InformationCard color="card__information-primary">
                        { t('Crypto transfer in BASE_CURRENCY (20-60 seconds) processing time. Fee: FEE_VALUE - minimum FEE_MINIMUM BASE_CURRENCY, maximum FEE_MAXIMUM BASE_CURRENCY', {
                          nsSeparator: '|',
                          FEE_VALUE: publicSettings.bank.fee ? publicSettings.bank.fee : '',
                          FEE_MINIMUM: publicSettings.bank.minimum_fee ? publicSettings.bank.minimum_fee : '',
                          FEE_MAXIMUM: publicSettings.bank.maximum_fee ? publicSettings.bank.maximum_fee : '',
                          BASE_CURRENCY: publicSettings.base_currency ? publicSettings.base_currency.toUpperCase() : '',
                        })}
                      </InformationCard>
                    </>
                  )}
                  {publicSettings && publicSettings.wallet_service === "sendwyre" && renderOptionPaymentWithSendWyre()}
                  {publicSettings && publicSettings.wallet_service === "wallet_engine" && renderOptionPaymentViaWallet()}
                  {publicSettings && publicSettings.enable_stripe && renderOptionPaymentWithStripe()}
                  {renderOptionPaymentWithCryptoDotCom()}
                </div>
              </div>

              {publicSettings && publicSettings.enable_subscription_agreement_link && <div className="field w-full w-full__switch">
                <SwitchCheckbox
                  isLeftSwitch
                  isRemoveLine
                  defaultChecked={values.agreeWithSubscriptionLink}
                  value="rememberMe"
                  onChange={handleCheckboxChange("agreeWithSubscriptionLink")}
                  switchText={
                    <>
                      {
                        publicSettings && !!publicSettings.subscription_agreement_link && (
                          <label htmlFor="token-purchase-agreement">
                            I acknowledge and agree to the
                            <a href={publicSettings.subscription_agreement_link} target="_blank" rel="noopener noreferrer"> Subscription Agreement</a>
                          </label>
                        )
                      }
                    </>
                  }
                />
              </div>}

              { publicSettings.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM &&
                <div className="field w-full w-full__switch">
                  {/* <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={values.agreeWithConditions}
                    value="rememberMe"
                    onChange={handleCheckboxChange("agreeWithConditions")}
                    switchText={
                      <>
                        {
                          publicSettings && publicSettings.token_type === "UTILITY" ? (
                            <label htmlFor="token-purchase-agreement">
                              I hereby agree to the
                              <Link to="/purchase-agreement" target="_blank">Token Purchase agreement</Link>
                            </label>
                          ) : (
                            <label htmlFor="term-condition">
                              {t('I hereby acknowledge receipt of the')} {" "}
                              <Link to="/terms" target="_blank">{t('Token Offering Memorandum.')}</Link>
                            </label>
                          )
                        }
                      </>
                    }
                  /> */}
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    value="rememberMe"
                    defaultChecked={values.legalDocumentAgreement}
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={
                      <>
                        <label>
                          {t('I hereby acknowledge receipt of the')} {" "}
                          <a href={publicSettings.memorandum_url} target="_blank" rel="noopener noreferrer">{t('Token Offering Memorandum.')}</a>
                        </label>
                      </>
                    }
                  />
                </div>
              }

              {/* Not german translation ready */}
              { publicSettings.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.PROSPECTUS &&
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    value="rememberProspectus"
                    defaultChecked={values.legalDocumentAgreement}
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={
                      <>
                        <label>
                          {t('I hereby acknowledge receipt of the')} {" "}
                          <a href={publicSettings.prospectus_url} target="_blank" rel="noopener noreferrer">{t('Prospectus')}</a>
                        </label>
                      </>
                    }
                  />
                </div>
              }

              { publicSettings.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.ISSUING &&
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    value="rememberIssuing"
                    defaultChecked={values.legalDocumentAgreement}
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={
                      <>
                        <label>
                          {t('I hereby acknowledge receipt of the')} {" "}
                          <a href={publicSettings.issuing_guidelines} target="_blank" rel="noopener noreferrer">{t('Issuing guidelines.')}</a>
                        </label>
                      </>
                    }
                  />
                </div>
              }

              {publicSettings.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT &&
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={values.legalDocumentAgreement}
                    value="rememberMe"
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={
                      <>
                        {
                          publicSettings && !!publicSettings.token_sale_agreement_url && (
                            <label htmlFor="token-purchase-agreement">
                              {t('I acknowledge and agree to the')} {" "}
                              <a href={publicSettings.token_sale_agreement_url} target="_blank" rel="noopener noreferrer">{t('Token sale agreement')}</a>
                            </label>
                          )
                        }
                      </>
                    }
                  />
                </div>
              }

              {publicSettings.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.SAFE &&
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={values.legalDocumentAgreement}
                    value="rememberMe"
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={
                      <>
                        {
                          publicSettings && !!publicSettings.token_safe_url && (
                            <label>
                              {t('I hereby acknowledge receipt of the')} {" "}
                              <a href={publicSettings.token_safe_url} target="_blank">{t('Token Safe')}</a>
                            </label>
                          )
                        }
                      </>
                    }
                  />
                </div>
              }

              {/* Right of Withdrawal */}
              <div className="field w-full w-full__switch">
                <SwitchCheckbox
                  isLeftSwitch
                  isRemoveLine
                  value="right-of-withdrawal"
                  defaultChecked={values.agreeWithConditions}
                  onChange={handleCheckboxChange("agreeWithConditions")}
                  switchText={
                    <>
                      <label>
                        {t('I confirm that I have been informed about the')} {" "}
                        <Link to="/right-of-withdrawal" target="_blank" rel="noopener noreferrer">{t('Right of Withdrawal.')}</Link>
                      </label>
                    </>
                  }
                />
              </div>

              {/* Confirm before subscription */}
              <div className="field w-full w-full__switch nospace__bottom">
                <SwitchCheckbox
                  isLeftSwitch
                  isRemoveLine
                  value="confirmBeforeSubscription"
                  defaultChecked={values.confirmBeforeSubscription}
                  onChange={handleCheckboxChange("confirmBeforeSubscription")}
                  switchText={
                    <>
                      <label>
                        <Trans
                          i18nKey={t('I hereby confirm that, before subscribing, I have read the TOKENOFFERINGMEMORANDUM in full and I agree with the content of the TOKENOFFERINGMEMORANDUM, and in particular that', {
                            TOKENOFFERINGMEMORANDUM: agreementMapping[publicSettings.prospectus_or_issuing].text})}
                          t={t}
                          components={{
                            tokenOfferingLink: <a href={agreementMapping[publicSettings.prospectus_or_issuing].url} target="_blank" rel="noopener noreferrer" />,
                          }}
                        />
                        {': '}
                      </label>
                    </>
                  }
                />
              </div>

              <div className="field">
                <div className="space space__24">{' '}</div>
                <CardInformation
                  color="card__information-primary"
                  title=""
                >
                  <ul>
                    <li>
                      {t('I accept the subscription applications.')}
                    </li>
                    <li>
                      {t('I have duly noted the sales restrictions listed and hereby affirm that these restrictions will be complied with.')}
                    </li>
                    <li>
                      {t('I have taken note of the risks and their possible effects and hereby accept them.')}
                    </li>
                  </ul>
                </CardInformation>
              </div>
            </div>

            <div className="space space__24">{' '}</div>

            <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between nospace__top">
              <button
                disabled={ values.legalDocumentAgreement === false || values.agreeWithConditions === false || values.confirmBeforeSubscription === false || disabledBtn || (publicSettings && publicSettings.enable_subscription_agreement_link && values.agreeWithSubscriptionLink === false)}
                onClick={
                  ([PAYMENT_TYPES.BANK_TRANSFER, PAYMENT_TYPES.VOLT, PAYMENT_TYPES.CRYPTODOTCOM, PAYMENT_TYPES.SENDWYRE, PAYMENT_TYPES.STRIPE].includes(values.selectedTransferType))
                    ? onPay
                    : changeNonExchangesDialogState(true)
                }
                type="button"
                className="button"
              >
                { t('Buy Tokens Now') }
              </button>
            </div>

            {onPayViaWalletEngineLoading || contributeStatus.loading || makeVoltPaymentInitRequestStatus.loading ? (
              <div className={classes.circularProgressWrapper}>
                <CircularProgress />
              </div>
            ) : (
              ""
            )}
          </div>

        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const renderNonExchangesDialog = () => (
    <Dialog
      open={values.isNonExchangesDialogOpen}
      onClose={() => {
        setValues({ ...values, isNonExchangesDialogOpen: false });
      }}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={() => {
          setValues({ ...values, isNonExchangesDialogOpen: false });
        }}
        modalTitleClassColor="title-primary"
        modalTitle={ t('Confirmation') }
      >
        <div className="description__row flex__row flex__wrap">
          <div className="refund__content w-full">
            <p>
              {['usdc', 'usdt', 'eth', 'polygon', 'dai', 'xdc', 'eure', 'busd'].includes(values.currency) && (
                t('Please confirm one more time you are not sending from an exchange but from your own NETWORK wallet with CURRENCY like GATEWAY and NOT from an exchange like Binance, Coinbase, Kraken or other.', {
                  GATEWAY: values.currency === 'xdc' ? 'XDC Pay' : 'Metamask',
                  NETWORK: NETWORK[values.currency],
                  CURRENCY: values.currency && values.currency.toUpperCase(),
                })
              )}

            </p>

            <div className="space space__32">{' '}</div>
            <button
              className="button-stroke"
              onClick={() => {
                setValues({ ...values, isNonExchangesDialogOpen: false });
                onPay();
              }}
            >
              { t('Yes') }
            </button>
          </div>
        </div>

      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const renderNoKycDialog = () => (
    <Dialog
      open={values.isNoKycDialogOpen && !values.isMandatoryKYCNoticeDialogOpened}
      onClose={changeNoKycDialogState(false)}
    >
      <ModalInnerMaterialDialog
        onClickClose={changeNoKycDialogState(false)}
        modalTitleClassColor="title-primary"
        modalTitle={ t('Confirmation') }
      >
        <div className="description__row flex__row flex__wrap">
          <div className="refund__content w-full">
            <p>
              { t('By proceeding, I acknowledge that I will be required to complete a KYC identity verification and to provide additional information about myself, as required by Due Diligence regulations.') }
            </p>
            <p>
              { t('If I make any payment for my purchase, but do not complete the identity verification process within 30 days, this payment amount will be returned and the purchase transaction will be cancelled.') }
            </p>
            <span className="space space__32">{' '}</span>
            <button
              className="button-stroke"
              onClick={changeNoKycDialogState(false)}
            >
              { t('I confirm') }
            </button>
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const onPayViaMetamask = (transaction) => async () => {
    setLoadingBuyToken(true)

    try {
      const txHash = await payViaMetamask(publicSettings, transaction)

      await updateCrowdsaleBuyTokenTransactionHash({
        variables: {
          id: transaction.id,
          hash: txHash,
          network: publicSettings.smart_contract_network === 'mainnet'
            ? (isProduction ? 'mainnet' : 'sepolia')
            : (
              publicSettings.smart_contract_network === 'polygon'
                ? (isProduction ? 'polygon' : 'amoy')
                : (isProduction ? 'xinfin' : 'apothem')
              )
        }
      })

      setIsTransactionPaid(true)
    } catch(error) {
      console.log(error)
      toaster.error(error.message)
    }

    setLoadingBuyToken(false)
  }

  const renderPaymentConfirmationDialog = () => {
    const currencyText = (
      (values &&
        values.transaction &&
        values.transaction.currency &&
        values.transaction.currency) ||
      ""
    ).toUpperCase();
    const currencyAmount =
      values &&
      values.transaction &&
      values.transaction.currency_amount &&
      (+values.transaction.currency_amount).toFixed(2);

    return (
      <Dialog
        open={values.isPaymentConfirmationDialogOpened}
        onClose={changePaymentConfirmationDialogState(false)}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={changePaymentConfirmationDialogState(false)}
          modalTitleClassColor="title-primary"
          modalTitle={ t('Confirmation Your Payment') }
        >
          <div className="settings__fieldset">
            <div className="field">
              <div className={classNames('users__info', styles.module__field_information)}>
                { t('Your Order no.') }
                <strong>
                  { t('TNX') }
                  {values.transaction.id}
                </strong>{" "}
                { t('has been placed successfully.') }
              </div>
            </div>

            {values.selectedTransferType === PAYMENT_TYPES.BANK_TRANSFER ? (
              <div className="field">
                <div className={classNames('users__info', styles.module__field_information)}>
                  { t('Please make your payment of') }{" "}
                  <strong>{currencyAmount}</strong>{" "}
                  <strong>{currencyText}</strong>
                  {' '}
                  { t('through bank to the below bank address. The token balance will appear in your account only after your transaction gets approved by our team.') }
                </div>
              </div>

            ) : values.selectedTransferType === PAYMENT_TYPES.CRYPTO ? (
              <div className="field">
                <div className={classNames('users__info', styles.module__field_information)}>
                  { t('Please send') }{" "}
                  <strong>{currencyAmount}</strong>
                  {" "}
                  <strong>{currencyText}</strong>
                  {" "}
                  { t('to the address below.') }
                  <br />
                  {/* <strong>{ t('Important') }: </strong>
                  {" "}
                  { t('Please add your ORDER NUMBER to the transaction memo field.') }
                  <br /> */}
                  { t('Your purchase transaction will appear in your account dashboard as soon as this has been confirmed by our team') }
                </div>
              </div>
            ) : values.selectedTransferType === PAYMENT_TYPES.VOLT ? (
              <div className="field">
                {renderVoltPaymentUrl()}
              </div>

            ) : values.selectedTransferType === PAYMENT_TYPES.CRYPTODOTCOM ? (
              <div className="field">
                {renderCryptoDotComPaymentUrl()}
              </div>
            ) : null}

            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <NavLink to={`/transactions/${values.transaction.id}`} className="button button-small">
                  { t('View Transaction') }
                </NavLink>
                {
                  publicSettings.enable_smart_contract &&
                  publicSettings.smart_contract_type === 'crowdsale_contract' &&
                  values.transaction &&
                  ['eth', 'dai'].includes(values.transaction.currency) &&(
                    <>
                      {' '}
                      {/* {metamaskService.isMetamaskAvailable() && !loadingBuyToken && !isTransactionPaid && (
                        <button
                          className="button button-small"
                          onClick={onPayViaMetamask({
                            ...values.transaction,
                            user: me,
                          })}
                        >
                          Pay via Metamask
                        </button>
                      )} */}
                      {metamaskService.isMetamaskAvailable() && !loadingBuyToken && isTransactionPaid && (
                        <span className="text__green">Transaction paid</span>
                      )}
                      {metamaskService.isMetamaskAvailable() && loadingBuyToken && (
                        <CircularProgress />
                      )}
                      {metamaskService.isMetamaskNotAvailable() && (
                        <button className="button-stroke disabled">You need to have metamask to do the transfer</button>
                      )}
                    </>
                  )
                }
              </div>
            </div>

            {values.selectedTransferType === PAYMENT_TYPES.BANK_TRANSFER ? (
              <div className="field">
                <div className="field__label">
                  { t('Bank Details for Payment') }
                </div>

                <div className="field__wrap">
                  <div className="transactions__row">{' '}</div>
                  <div className="transactions__row row__force">
                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Account Name') }
                      </div>

                      {bankDetails.account_name}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Account Number') }
                      </div>
                      {bankDetails.account_number}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Bank Name') }
                      </div>
                      {bankDetails.bank_name}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Bank Address') }
                      </div>
                      {bankDetails.bank_address}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Routing Number') }
                      </div>
                      {bankDetails.routing_number}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('IBAN') }
                      </div>
                      {bankDetails.iban}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        Swift/BIC
                      </div>
                      {bankDetails["swift/bic"]}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Payment Reference') }
                      </div>
                      {formatPaymentRef(values.transaction)}
                    </div>
                  </div>
                </div>
              </div>
            ) : values.selectedTransferType === PAYMENT_TYPES.CRYPTO ? (
              <div className="field">
                <div className="field__label">
                  {values.transaction.currency === "eth" &&
                    "Payment to the following Ethereum Wallet Address"}
                  {values.transaction.currency === "usdc" &&
                    "Payment to the following USDC Wallet Address"}
                  {values.transaction.currency === "usdt" &&
                    "Payment to the following USDT Wallet Address"}
                  {values.transaction.currency === "xdc" &&
                    "Payment to the following XDC Wallet Address"}
                  {values.transaction.currency === "polygon" &&
                    "Payment to the following Polygon Wallet Address"}
                  {values.transaction.currency === "dai" &&
                    "Payment to the following DAI Wallet Address"}
                </div>

                <div className={classNames('press__item kyc-press__item', styles.module__pressitem)}>
                  {values.qrCodeUrl && (
                    <div className="press__preview">
                      <img
                        title="Scan QR code to payment."
                        className={classes.qrCode}
                        width="82"
                        src={values.qrCodeUrl}
                        alt=""
                      />
                    </div>
                  )}

                  <div className={classNames('press__details', styles.module__pressdetails)}>
                    <div className={classNames('press__post', styles.module__presspost)}>
                      { t('Send Amount') }: {" "}
                      <strong>
                        {currencyAmount} {currencyText}
                      </strong>
                    </div>

                    <div className={classNames('press__socials', styles.module__presssocials)}>
                      <div className={classNames(`copy-wrap mgb-0-5x ${classes.copyWrap}`, styles.module__copyfeedback)}>
                        <span className="copy-feedback" />
                        <img
                          src={currencyIcons.eth}
                          className={classes.ethInputIcon}
                          alt=""
                        />
                        {values.transaction.currency === "eth" && (
                          <>
                            <input
                              type="text"
                              className="copy-address ignore"
                              value={ethAddress}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              type="button"
                              className="copy-trigger copy-clipboard"
                              onClick={() => copy(ethAddress)}
                            >
                              <FileCopyIcon />
                            </button>
                          </>
                        )}
                        {values.transaction.currency === "usdc" && (
                          <>
                            <input
                              type="text"
                              className="copy-address ignore"
                              value={usdcAddress}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              type="button"
                              className="copy-trigger copy-clipboard"
                              onClick={() => copy(usdcAddress)}
                            >
                              <FileCopyIcon />
                            </button>
                          </>
                        )}
                        {values.transaction.currency === "usdt" && (
                          <>
                            <input
                              type="text"
                              className="copy-address ignore"
                              value={usdtAddress}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              type="button"
                              className="copy-trigger copy-clipboard"
                              onClick={() => copy(usdtAddress)}
                            >
                              <FileCopyIcon />
                            </button>
                          </>
                        )}
                        {values.transaction.currency === "xdc" && (
                          <>
                            <input
                              type="text"
                              className="copy-address ignore"
                              value={xdcAddress}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              type="button"
                              className="copy-trigger copy-clipboard"
                              onClick={() => copy(xdcAddress)}
                            >
                              <FileCopyIcon />
                            </button>
                          </>
                        )}
                        {values.transaction.currency === "polygon" && (
                          <>
                            <input
                              type="text"
                              className="copy-address ignore"
                              value={polygonAddress}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              type="button"
                              className="copy-trigger copy-clipboard"
                              onClick={() => copy(polygonAddress)}
                            >
                              <FileCopyIcon />
                            </button>
                          </>
                        )}
                        {values.transaction.currency === "dai" && (
                          <>
                            <input
                              type="text"
                              className="copy-address ignore"
                              value={daiAddress}
                              disabled=""
                              readOnly=""
                            />
                            <button
                              type="button"
                              className="copy-trigger copy-clipboard"
                              onClick={() => copy(daiAddress)}
                            >
                              <FileCopyIcon />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Grid
                  container
                  spacing={5}
                  className="row guttar-1px guttar-vr-15px"
                >
                  <Grid item sm={9}>
                    <div className="fake-class pl-sm-3">
                      <p className={classes.sendAmount}>
                        <strong>

                        </strong>
                      </p>

                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : null}

            <br />

            <div className="refund__content">
              {values.paymentType === PAYMENT_TYPES.BANK_TRANSFER && (
                <p>
                  { t('Use this transaction id') } (
                  {formatPaymentRef(values.transaction)}) { t('as reference. Make your payment within 24 hours, If we will not received your payment within 24 hours, then we will cancel the transaction.') }
                </p>
              )}
            </div>

          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    );
  };

  const renderNoSendwyreWalletDialog = () => (
    <Dialog
      open={values.isNoSendwyreWalletDialogOpened}
      onClose={() =>
        setValues({
          ...values,
          isNoSendwyreWalletDialogOpened: false,
        })
      }
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={() =>
          setValues({
            ...values,
            isNoSendwyreWalletDialogOpened: false,
          })
        }
        modalTitle={ t('Information') }
        modalTitleClassColor="title-primary"
      >
        <div className="description__row flex__row flex__wrap">
          <div className="refund__content">
            <p>{t('Please create a wallet first')}</p>
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const renderNoBalanceSendwyreWalletDialog = () => (
    <Dialog
      open={values.isNoBalanceSendwyreWalletDialogOpened}
      onClose={() =>
        setValues({
          ...values,
          isNoBalanceSendwyreWalletDialogOpened: false,
        })
      }
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        modalTitle={ t('Information') }
        modalTitleClassColor="title-primary"
        onClickClose={() =>
          setValues({
            ...values,
            isNoBalanceSendwyreWalletDialogOpened: false,
          })
        }
      >
        <div className="description__row flex__row flex__wrap">
          <div className="refund__content">
            <p>{t('Please top up balance first')}</p>
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const renderTransactionLimitReachedDialog = () => (
    <Dialog
      open={values.isTransactionLimitReachedDialogOpened}
      onClose={changeTransactionLimitReachedDialogState(false)}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        modalTitle={ t('Confirmation') }
        modalTitleClassColor="title-primary"
        onClickClose={changeTransactionLimitReachedDialogState(false)}
      >
        <div className="description__row flex__row flex__wrap">
          <div className="refund__content">
            <p>
              {t('You are limited to create another transaction. You Created a total of')}{' '}{values.totalTransaction}{' '}{t('Transactions')}.
              {' '}
              {t('Due to regulations the Admin limited the transactions to a total of')}{' '}{publicSettings.transaction_limit_quota}
            </p>
          </div>

          <span className="space space__32">{' '}</span>
          <button
            className="button-stroke"
            onClick={changeTransactionLimitReachedDialogState(false)}
          >
            { t('I confirm') }
          </button>
        </div>
      </ModalInnerMaterialDialog>

    </Dialog>
  );

  const getDiscountLevelTransac = () => {
    return (isAwearetech ? AWEARE_DISCOUNT_LEVEL : DISCOUNT_LEVEL).find(ele => ele.id == values.discountLevel);
  }

  const renderMinMaxAmoutTokenInfo = () => {
    const discountLevelTransac = getDiscountLevelTransac();
    if (publicSettings && publicSettings.enable_kyc_discount_level && discountLevelTransac) {
      const currency = discountLevelTransac.currency.toUpperCase();
      return (
        <div className="note-danger note-sm">
          <p>
            { t('Based on your discount level your minimum purchase amount is') }{" "}
            {numberWithCommas(discountLevelTransac.min_amount)} {currency}
            {discountLevelTransac.max_amount && (
              <> { t('and the maximum purchase amount is') }{" "}
              {numberWithCommas(discountLevelTransac.max_amount)} {currency}
              </>)}.
          </p>
        </div>
      );
    } else if (publicSettings && publicSettings.minimum_investment_amount) {
      return (
        <div className="note-danger note-sm">
          <p>
            { t('The minimum purchase quantity is') }{" "}
            {publicSettings.minimum_investment_amount}{" "}
            {publicSettings.base_currency &&
              publicSettings.base_currency.toUpperCase()}{" "}
          </p>
        </div>
      );
    }
  };

  const notSatisfyMinMaxAmount = () => {
    if (publicSettings && publicSettings.enable_kyc_discount_level) {
      const discountLevelTransac = getDiscountLevelTransac();

      if (!discountLevelTransac) {
        return false;
      }

      const tokenAmountInUsd = getTokenValueInAnything("usd", values.amount);

      return (
        tokenAmountInUsd < discountLevelTransac.min_amount ||
        tokenAmountInUsd > discountLevelTransac.max_amount
      );
    } else if (publicSettings && publicSettings.minimum_investment_amount) {
      return (
        values.amount < publicSettings.minimum_investment_amount
      );
    }
    return false;
  };

  const calculateDiscount = (amount) => {
    if (publicSettings && publicSettings.enable_kyc_discount_level && values.discountLevel && checkDiscountAvailable()) {
      const discountLevelTransac = getDiscountLevelTransac();
      return amount * ((100 - (discountLevelTransac && discountLevelTransac.percent || 0)) / 100);
    }
    else {
      return +amount;
    }
  };

  const calculateDiscountForAmount = (value, rate) => {
    if (publicSettings && publicSettings.enable_kyc_discount_level && values.discountLevel && checkDiscountAvailable()) {
      const discountLevelTransac = getDiscountLevelTransac();
      return value / (rate * (100 - (discountLevelTransac && discountLevelTransac.percent || 0)) / 100);
    }
    else {
      return +value / rate;
    }
  };

  const isDisablePaymentButton = () => {
    if(getUnallocatedTokenAmount !== null) { // When the limited is exists
      if (getUnallocatedTokenAmount !== undefined && getUnallocatedTokenAmount < values.amount) {
        return true
      }
    }

    if (values.isTransactionLimitReached) {
      return true
    }

    if (parseInt(values.amount) <= 0) {
      return true
    }

    if (values.isMandatoryKYCNoticeDialogAlreadyClosed) {
      return true
    }

    if (!signnowAllSigned) {
      return true
    }

    if (
      canInvesting !== Infinity && parseInt(values.amount) > parseInt(canInvesting) &&
      mandatoryKyc(is_gto_sales, publicSettings.accepted_mandatory_kyc || {}) &&
      parseInt(meInvestInBaseCurrency.current_lvl.max_invest_amount) !== 0
    ) {
      return true
    }

    if (
      mandatoryKyc(is_gto_sales,publicSettings.accepted_mandatory_kyc || {}) &&
      me.kyc_status !== USER_KYC_STATUS_TYPES.PASSED
    ) {
      return true
    }

    if (
      !mandatoryKyc(is_gto_sales,publicSettings.accepted_mandatory_kyc || {}) &&
      me.kyc_status === USER_KYC_STATUS_TYPES.REJECTED
    ) {
      return true
    }

    return notSatisfyMinMaxAmount()
  };

  const renderDiscountOption = () => {
    if (!publicSettings || !publicSettings.enable_kyc_discount_level) {
      return;
    }

    if (!checkDiscountAvailable()) return;

    const discountOptions = (isAwearetech ? AWEARE_DISCOUNT_LEVEL : DISCOUNT_LEVEL).filter(lvl => lvl.phase == ((publicSettings && publicSettings.kyc_discount_phase) || 1));

    return (
      <div className="card">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">
            { t('Discount') }
          </div>
        </div>

        <div className="field">
          <div className="field__label">
            { t('Discount Level') }
          </div>

          <div className="field__wrap field__radio-kyc-style-1">
            <RadioGroup
              name="discountLevel"
              value={values.discountLevel}
              onChange={({ target: { value } }) =>
                  setValues({ ...values, discountLevel: parseInt(value) })
                }
            >
              {discountOptions.map(
                (lvl) => (
                    <FormControlLabel
                      key={`discount_lvl_${lvl.id}`}
                      value={lvl.id}
                      control={<Radio color="primary" />}
                      label={lvl.content || ""}
                    />
                  )
              )}
            </RadioGroup>
          </div>
        </div>
      </div>
    );
  }

  const renderPriceSource = () => {
    if (!publicSettings) {
      return ''
    }

    if (publicSettings.enable_kyc_discount_level) {
      if (publicSettings.kyc_discount_phase === 3) {
        return `${t('Source')}: CoinGecko`
      }

      return `${t('Source')}: ${t('Private Sale List Price')}`
    }

    switch(publicSettings.prospectus_or_issuing) {
      case PROSPECTUS_OR_ISSUING.PROSPECTUS:
        if (publicSettings.prospectus_url && publicSettings.prospectus_date) {
          return(
            <>
              {t('Source')}:
              {' '}
              <a href={publicSettings.prospectus_url} target="_blank" rel="noopener noreferrer">Prospectus</a>
            </>
          )
        } else {
          return `${t('Source')}: ${t('Private Sale List Price')}`
        }
      case PROSPECTUS_OR_ISSUING.ISSUING:
        return `${t('Source')}: ${t('Issuing guidelines')}`

      case PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT:
        return `${t('Source')}: ${t('Token sale agreement')}`

      default:
        return `${t('Source')}: ${t('Private Sale List Price')}`
    }
  }

  const renderTokenLimitReached = () => {
    if (getUnallocatedTokenAmount === undefined || getUnallocatedTokenAmount === null || getUnallocatedTokenAmount > 0)  {
      return null
    }

    return (
      <>
        <div className="card">
          <CardInformation color="card__information-red"  title={t('Allocated token reachs limit!')}>
            The number of allocated token amount has reached the limit.
            You can no more place any other transactions.
          </CardInformation>
        </div>
      </>
    )
  }

  return (
    !loading && !checkFieldsIsFilledLoading && (
      <>
        {checkFieldsIsFilledLoading && (
          <CircularProgress />
        )}
        {renderTokenLimitReached()}
        {renderMandatoryKYC()}
        {renderVideoIdentPendingorApprovedModal()}
        {renderTransactionLimitReachedDialog()}
        {renderPaymentMethodDialog()}
        {renderPaymentConfirmationDialog()}
        {renderNonExchangesDialog()}
        {renderNoKycDialog()}
        {renderNoSendwyreWalletDialog()}
        {renderNoBalanceSendwyreWalletDialog()}
        {/* {renderPayWithCryptoDotComDialog()} */}

        <div className="card">
          <div className="card__head card__head-kyc-1">
            <div className="title-primary card__title">
              {" "}
              {publicSettings && publicSettings.token_symbol} {t('Quantity and Price')}
            </div>

            <div className="refund__content card__head-content">
              <p>
                { t('For your purchase of TOKEN tokens, payment may be made in your choice of CURRENCIES currencies', {
                  TOKEN: publicSettings && publicSettings.token_symbol,
                  CURRENCIES: [...fiatList, ...cryptoList]
                    .filter((name) => acceptedCurrencies[name])
                    .map((name) => name.toUpperCase())
                    .join(", ")
                }) }
              </p>
              <p>
                { t('Depending on the payment method you choose, your financial service provider may charge you additional fees.') }
              </p>
            </div>
          </div>

          <div className="tips">
            <div className="tips__list">

              <div className="tips__item tips__item-clear rwd__fullwidth_basis_1023">
                <div className="tips__icon">
                  <Icon
                    className={classNames('filter')}
                    name='filter'
                    size="24"
                  />{" "}
                </div>

                <div className="tips__details">
                  <div className="tips__title">{ t('Choose Currency') }</div>

                  <div className="field field__radio-kyc-style-1">
                    <div className="field__wrap">
                      <RadioGroup
                        aria-label="Choose currency"
                        name="discountLevel"
                        value={values.currency}
                        onChange={({target: { value }}) => handleCurrencyRadioChange(value)()}
                        row
                      >
                        {[...fiatList, ...cryptoList].filter(name => acceptedCurrencies[name]).map(name => (
                          <FormControlLabel
                            key={name}
                            value={name}
                            control={<Radio color="primary" />}
                            label={name.toUpperCase()}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tips__item tips__item-clear rwd__fullwidth_basis_1023 tips__item-token-price">
                <div className="tips__icon">
                  <Icon
                    className={classNames('ticket')}
                    name='ticket'
                    size="24"
                  />{" "}
                </div>

                <div className="tips__details">
                  <div className="tips__title">{ t('Token Price') }</div>

                  <div className="tips__line">
                    <div className="status-green-dark tips__status">
                      <strong>
                        {getTokenValueInAnything(values.currency, 1, true)}
                        <small>
                          {" "}{values.currency.toUpperCase()}
                        </small>
                        <small>
                        {' '}/{' '}{publicSettings.token_symbol.toUpperCase()}
                        </small>
                      </strong>

                    </div>
                    <div className="tips__action">
                      {renderPriceSource()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {renderDiscountOption()}

        <div className="create__card card">
          <div className="card__head card__head-kyc-1">
            <div className="title-primary card__title">
            { t('Order Quantity and Payment Amount') }
            </div>
          </div>

          {!loadingMeInvestInBaseCurrency && (
            <>
              {meInvestInBaseCurrency.current_lvl.max_invest_amount !== 0 &&
                mandatoryKyc(
                  is_gto_sales,
                  publicSettings.accepted_mandatory_kyc || {}
                ) && (
                  <div className="card-text">
                    {canInvesting !== Infinity && (
                      <p className={classNames("note-danger", classes.kycLvlUp)}>
                        <InfoIcon
                          className={classNames("fa", classes.infoIcon)}
                          fontSize="inherit"
                        />{" "}
                        { t('You can still purchase {canInvesting} tokens within your KYC level', {
                          canInvesting: parseInt(canInvesting)
                        }) }
                        {". "}
                        {meInvestInBaseCurrency.kyc_lvl_change ? (
                          <>
                            <span>
                              {t('lvl increase pending')} (lvl{" "}
                              {meInvestInBaseCurrency.current_lvl.level} {t('to lvl')}{" "}
                              {meInvestInBaseCurrency.current_lvl.level + 1})
                            </span>
                          </>
                        ) : (
                          <>
                            {meInvestInBaseCurrency.current_lvl.level !== 4 &&
                              meInvestInBaseCurrency.current_lvl.type_customer !==
                                undefined && (
                                <span
                                  className="status-primary-dark text__white-important clickable mb__8"
                                  onClick={() => setIsUpgradeLvlDialogOpen(true)}
                                >
                                  { t('Increase Level now') }
                                </span>
                              )}
                          </>
                        )}
                      </p>
                    )}

                    <UpgradeLevelDialog
                      open={isUpgradeLvlDialogOpen}
                      onClose={() => setIsUpgradeLvlDialogOpen(false)}
                    />
                  </div>
                )}
            </>
          )}

          <div className="tips__title">
            <button
              className={classNames('button button-small', classes.swapBtn)}
              onClick={() => setInvertTokenForm({...invertTokenForm, invert: !invertTokenForm.invert})}
            >
              {t('Swap')}
            </button>
          </div>

          <div className="fields__divide">
            <div className="field field__inner-divide-2">
              <div className="field__label field__label-75">
                {invertTokenForm.invert ? values.currency.toUpperCase() : publicSettings && publicSettings.token_symbol}
              </div>

              <div className="field__wrap">
                {!invertTokenForm.invert && (
                  <input
                    type="text"
                    value={values.amount}
                    onChange={handleAmountChange}
                    className="field__input"
                    id="token-base-amount"
                  />
                )}

                {invertTokenForm.invert && (
                  <input
                    type="text"
                    value={invertTokenForm.amount}
                    onChange={({ target: { value } }) => {
                      const rate = tokenToAnything(values.currency || publicSettings.base_currency, 1, exchangeRates, true);
                      let amountToToken = value / rate;
                      let invertAmount = value

                      if (publicSettings && publicSettings.enable_kyc_discount_level) {
                        console.log("amountToToken", amountToToken)
                        amountToToken = calculateDiscountForAmount(value, rate);
                      }

                      if (publicSettings.maximum_investment_amount && Math.floor(amountToToken) > publicSettings.maximum_investment_amount) {
                        amountToToken = publicSettings.maximum_investment_amount
                        invertAmount = tokenToAnything(values.currency || publicSettings.base_currency, amountToToken, exchangeRates)
                      }
                      setValues({ ...values, amount: Math.floor(amountToToken)})
                      setInvertTokenForm({...invertTokenForm, amount: invertAmount})
                    }}
                    className="field__input"
                    id="token-base-amount"
                  />
                )}
              </div>
            </div>

            <div className="field__token-eq">=</div>

            <div className="review__line">
              <div className="review__title">
                {!invertTokenForm.invert ? numberWithCommas(
                  invertTokenForm.amount
                ) : +values.amount}
              </div>
              <div className="review__login">
                {!invertTokenForm.invert ? values.currency.toUpperCase() : publicSettings && publicSettings.token_symbol}
              </div>
            </div>
          </div>

          <div className="field">
            <div className="field__helper">
              {t('Minimum order quantity is')} {Math.floor(publicSettings.minimum_investment_amount)}{" "}
                {publicSettings.token_symbol &&
                  publicSettings.token_symbol.toUpperCase()}.
            </div>
          </div>
        </div>


        {!loadingMeInvestInBaseCurrency && !checkFieldsIsFilledLoading && (
          <div className="create__card card">
            <button
              className="button panel__button"
              disabled={isDisablePaymentButton()}
              type="button"
              onClick={changePaymentMethodDialogState(true)}
            >
              { t('Choose Payment Method') }
            </button>
          </div>
        )}
      </>
    )
  );
};

export default ContributeForm;
