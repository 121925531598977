import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import LineInformation from '../../../components/InformationLine'
import { useMutation } from '@apollo/client'
import { compose } from 'recompose'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import queryString from 'query-string'
import { RESTORE_CHECK, RESTORE_CONFIRM } from '../../../queriesAndMutations'
import { useTranslation } from 'react-i18next'
import Icon from '../../../components/Icon'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkToLoginPage: {
    width: '100%',
  },
}))

const PasswordRestoreConfirmForm = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation();
  const [restoreCheck, restoreCheckData] = useMutation(RESTORE_CHECK)
  const [restoreConfirm, restoreConfirmData] = useMutation(RESTORE_CONFIRM)
  const [values, setValues] = useState({
    password: '',
    password_confirm: '',
    token: '',
  })

  const query = queryString.parse(history.location.search)
  const token = query && query.token

  useEffect(() => {
    restoreCheck({ variables: { input: { token } } }).catch(() => {})
  }, [restoreCheck, token])

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }
  const onClick = () => {
    setValues({ ...values, password_confirm: '' })
    restoreConfirm({ variables: { input: { ...values, token } } }).catch(() => {})
  }
  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName, label = '', index, type,
  } = {}) => {
    return (
      <div className="field field_icon" key={index}>
        <div className="field__wrap">
          <input
            type={type}
            placeholder={label}
            className="field__input"
            value={values[propertyName] || ''}
            onChange={handleChange(propertyName)}
            disabled={restoreConfirmData.loading}
          />
          <div className="field__icon">
            <Icon className="icon lock" size="24" />{" "}
          </div>
        </div>
      </div>
    )
  }

  if (restoreConfirmData.data) {
    return (
      <div className="page-ath-text">
        <div className="alert alert-success">{ t('Your password has been successfully changed.') }</div>
        <div className="space space__24" />
        <NavLink to="/login" className="button">{ t('Sign In') }</NavLink>
      </div>
    )
  }

  if (restoreCheckData.data) {
    return (
      <>
        <div className="h2 entry__title">
          { t('Create new password') }
        </div>

        <div className="entry__fieldset">
          {renderInputField({
            propertyName: 'password',
            label: t('Password'),
            type: 'password',
          })}
          {renderInputField({
            propertyName: 'password_confirm',
            label: t('Repeat Password'),
            type: 'password',
          })}
          {
            restoreConfirmData.loading
              ? <div className={classes.circularProgressWrapper}><CircularProgress /></div>
              : <div className="field input-submit"><button type="button" onClick={onClick} className="button entry__button">{ t('Reset Password') }</button></div>
          }
        </div>
      </>
    )
  }

  return (
    <>
      <div className="h2 entry__title">
        { t('Reset password') }
      </div>
      {
        restoreCheckData.loading
          ? <div className={classes.circularProgressWrapper}><CircularProgress /></div>
          : (
            <div className="entry__fieldset">
              <div className="space space__32">{' '}</div>
              <LineInformation
                informationColor="box__information-red"
                text={
                  <>
                    { t('Invalid password recovery link.') }
                    {' '}
                    <NavLink to="/password/restore">{ t('Go to the password recovery page') }</NavLink>
                  </>
                }
                icon="close"
              />
            </div>
          )
      }
    </>
  )
}

PasswordRestoreConfirmForm.propTypes = {
  // from HOCs
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

export default compose(
  withRouter
)(PasswordRestoreConfirmForm)
