import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { DISABLE_2FA } from '../../../queriesAndMutations'
import { useMe } from '../../../myHooks'
import { useTranslation } from 'react-i18next'
import InputTheme from '../../../components/InputTheme'
import InformationCard from '../../../components/InformationCard'

const DisableTwoFactorAuth = ({ onClose }) => {
  const [value, setValue] = useState({
    stepNumber: 0,
    token: '',
  })

  const { refetch: refetchMe } = useMe()
  const [disable2FA, { loading, error }] = useMutation(DISABLE_2FA)

  const changeStep = (step) => setValue({
    ...value,
    stepNumber: step,
  })

  const onSubmitToken = async (e) => {
    e.preventDefault()
    try {
      await disable2FA({ variables: { token: value.token } })
      changeStep(2)
      refetchMe()
    } catch (e) {}
  }

  const { t } = useTranslation();
  const renderFirstStep = () => (
    <div className="settings__wrapper">
      <div className="card__head">
        <div className="refund__content card__head-content">
          <p>{ t('To confirm disabling 2fa, please, enter 2fa token') }:</p>
        </div>
      </div>

      <form onSubmit={onSubmitToken}>
        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="token"
            label="Token"
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />
        </div>

        <div className="space space__48">{' '}</div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button onClick={onClose} className="button-stroke">{ t('Close')}</button>
          <button type="submit" className="button">{ t('Confirm') }</button>
        </div>
      </form>
    </div>
  )

  const renderSuccess = () => (
    <div className="settings__wrapper">
      <InformationCard
        color="card__information-green"
        title={t('Success!')}
      >
        <>
          { t('Success! 2FA was successfully disabled!') }
        </>
      </InformationCard>
      <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
        <button onClick={onClose} className="button">{ t('Close') }</button>
      </div>
    </div>
  )

  return (
    <>
      <div className="space space__16">{' '}</div>
      <div className="kyc__stepper kyc__stepper_32 w-full">
        <Stepper activeStep={value.stepNumber}>
          <Step key={1}>
            <StepLabel>{ t('Confirmation') }</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>{ t('Success') }</StepLabel>
          </Step>
        </Stepper>
      </div>
      <div className="space space__32">{' '}</div>
      <div className="kyc-dialog__body w-full">
        {value.stepNumber === 0 && renderFirstStep()}
        {value.stepNumber === 2 && renderSuccess()}
      </div>
    </>
  )
}

DisableTwoFactorAuth.propTypes = {
  onClose: propTypes.func.isRequired,
}

export default DisableTwoFactorAuth
