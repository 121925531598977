import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment-timezone'

import {
  ADMIN_SEND_MESSAGE,
  GET_ADMIN_CHAT_HISTORY
} from '../../queriesAndMutations'
import { toaster } from '../../utils'
import InputTheme from '../../components/InputTheme'

const useStyles = makeStyles({
  'field-row': {
    marginBottom: '5px',
  },
  'field-row__field': {
    flexGrow: 1,
  },
  'field-row__button': {
    marginLeft: '5px',
    flexShrink: 0,
    width: '100px',
  },
})

const GroupReceiver = ({
  receivers,
  max
}) => {
  const receiverContainer = [...receivers];
  const show = receiverContainer.splice(0, max);

  return (
    <div className="group-receiver">
      {show.map(receiver => (
        <React.Fragment key={receiver}>
          <span className="status-idle products__status">{receiver}</span>
          {' '}
        </React.Fragment>
      ))}
      {receiverContainer.length > 0 && (
        <span className="status-idle products__status">
          {`and ${receiverContainer.length} more`}
        </span>
      )}
    </div>
  )
}

const AdminMessagePage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [adminSendMessage, adminSendMessageStatus] = useMutation(ADMIN_SEND_MESSAGE)
  const { data: { getAdminChatHistory } = {}, loading} = useQuery(GET_ADMIN_CHAT_HISTORY)
  const [values, setValues] = useState({
    message: '',
    to: '',
  })

  const sendMessage = () => {
    adminSendMessage({
      variables: {
        message: values.message,
        to: values.to.split('\n').map(address => address.trim()).filter(address => !!address)
      }
    }).then(() => {
      toaster.success('Message sent')
      setTimeout(() => {window.location.reload() }, 500)
    }).catch(() => {})
  }

  return (
    <>
      <div className="page__title h3">{ t('Messages')}</div>

      <div className="create__card card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('Send messages to addresses') }</div>
        </div>
        <div>
          <InputTheme
            propertyName="to"
            type="textarea"
            state={values}
            setState={setValues}
            classWrapper={`field ${classes['field-row__field']}`}
            classInput="field__input"
            isRequired={true}
            disabled={adminSendMessageStatus.loading}
            helperText={t('Each address a line')}
          />
        </div>
      </div>

      <div className="create__card card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('Message') }</div>
        </div>

        <div>
          <InputTheme
            type="textarea"
            propertyName="message"
            state={values}
            setState={setValues}
            classWrapper="field"
            classInput="field__input"
            placeholder={t('Type your message here...')}
            isRequired={true}
            disabled={adminSendMessageStatus.loading}
            rows={3}
          />
          <button
            className="button"
            onClick={sendMessage}
            disabled={adminSendMessageStatus.loading}
          >
            Send
          </button>
        </div>
      </div>

      <div className="create__card card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('Message log') }</div>
        </div>
        <div>
          {loading && (
            <CircularProgress />
          )}
          { getAdminChatHistory && getAdminChatHistory.length === 0 && (
            <p>No message</p>
          )}
          { getAdminChatHistory && getAdminChatHistory.length > 0 && getAdminChatHistory.map(message => (
            <div key={message.messageId}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className="products__category">From:</span>
                    </td>
                    <td>
                      {message.from}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="products__category">To:</span>
                    </td>
                    <td>
                      <GroupReceiver receivers={message.to} max={2} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="products__category">Send at:</span>
                    </td>
                    <td>
                      {moment(message.createdAt).format('D/M/Y H:m A')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="products__category">Content:</span>
                    </td>
                    <td>
                      <p>{message.message}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AdminMessagePage
