import React from 'react'
import { useMutation } from '@apollo/client'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  MANUAL_FETCH_CHECK,
  MANUAL_FETCH_IDNOW_CHECK,
} from '../../queriesAndMutations'
import AdminIdnowViewer from '../AdminIdnowViewer'
import OnfidoApplicant from '../OnfidoApplicant'
import OnfidoApplicantAll from '../OnfidoApplicantAll'
import ComplycubeClient from '../ComplycubeClient'
import AdminKycInternalLivenessCheckViewer from '../AdminKycInternalLivenessCheckViewer'
import OnfidoApplicantReportCheck from '../OnfidoApplicantReportCheck'
import ComplycubeClientReportCheck from '../ComplycubeClientReportCheck'

const AdminLivenessCheckViewer = ({
  type,
  customer,
  refetchCustomer,
  transactionsUserList
}) => {
  const { t } = useTranslation();
  const [manualFetchCheck, manualFetchCheckStatus] = useMutation(MANUAL_FETCH_CHECK);
  const [manualFetchIdnowCheck, manualFetchIdnowCheckStatus] = useMutation(MANUAL_FETCH_IDNOW_CHECK);
  const triggerManualFetch = (service, applicantId) => () => {
    if (service === 'onfido') {
      manualFetchCheck({
        variables: {
          applicant_id: applicantId
        }
      }).then(({ data: { manualFetchCheck: hasUpdate } }) => {
        if (hasUpdate) {
          refetchCustomer();
        }
      }).catch(() => {});
    } else if (service === 'idnow') {
      manualFetchIdnowCheck({
        variables: {
          identId: applicantId
        }
      }).then(({ data: { manualFetchIdnowCheck: hasUpdate } }) => {
        if (hasUpdate) {
          refetchCustomer();
        }
      }).catch(() => {});
    }
  }

  return (
    <>
      {
        (
          customer.livenessCheckApplicant || (
            customer.allLivenessCheckApplicant &&
            customer.allLivenessCheckApplicant.length > 0
          )
        ) && (
          <>
            {customer.livenessCheckApplicant && (
              <AdminKycInternalLivenessCheckViewer applicant={customer.livenessCheckApplicant} />
            )}
            {
              customer.allLivenessCheckApplicant &&
              customer.allLivenessCheckApplicant.length > 0 &&
              customer.allLivenessCheckApplicant.map((applicant) => (
                <React.Fragment key={applicant.id}>
                  <AdminKycInternalLivenessCheckViewer applicant={applicant} collapsed />
                </React.Fragment>
              ))
            }
          </>
        )
      }

      {
        (
          customer.onfidoApplicant || (
            customer.allOnfidoApplicant &&
            customer.allOnfidoApplicant.length > 0
          )
        ) && (
          <>
            {customer.onfidoApplicant && (
              <>
                <OnfidoApplicant applicant={customer.onfidoApplicant} transactionsUserList={transactionsUserList} />
                {
                  (
                    customer.onfidoApplicant.onfidoChecks.length === 0 ||
                    customer.onfidoApplicant.onfidoChecks[0].status === 'in_progress'
                  ) && (
                    <>
                      {manualFetchCheckStatus.loading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <button
                            className="button"
                            onClick={triggerManualFetch('onfido', customer.onfidoApplicant.id)}
                          >
                            {t('Manual fetch check')}
                          </button>
                        </>
                      )}
                    </>
                  )
                }
                <OnfidoApplicantReportCheck applicant={customer.onfidoApplicant} transactionsUserList={transactionsUserList} watchlistView={false} />
              </>
            )}

            {customer.allOnfidoApplicant && customer.allOnfidoApplicant.length > 0 && (
              <>
                {customer.allOnfidoApplicant.map(applicant => (
                  <React.Fragment key={applicant.id}>
                    <OnfidoApplicantAll applicant={applicant} transactionsUserList={transactionsUserList} />
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        )
      }

      {
        customer.complycubeClient && (
          <>
            {customer.complycubeClient && (
              <>
                <ComplycubeClient client={customer.complycubeClient} transactionsUserList={transactionsUserList} />
                <ComplycubeClientReportCheck client={customer.complycubeClient} transactionsUserList={transactionsUserList} watchlistView={false} />
                {/* {
                  (
                    customer.complycubeClient.complycubeChecks.length === 0 ||
                    customer.complycubeClient.complycubeChecks[0].status === 'in_progress'
                  ) && (
                    <>
                      {manualFetchCheckStatus.loading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <div className="space space__32">{' '}</div>
                          <button
                            className="button"
                            onClick={triggerManualFetch('onfido', customer.complycubeClient.id)}
                          >
                            {t('Manual fetch check')}
                          </button>
                        </>
                      )}
                    </>
                  )
                } */}
              </>
            )}
          </>
        )
      }

      {
        (
          customer.idnowIdent || (
            customer.allIdnowIdent &&
            customer.allIdnowIdent.length > 0
          )
        ) && (
          <>
            {customer.idnowIdent && (
              <>
                <AdminIdnowViewer idnowIdent={customer.idnowIdent} />
                {
                  (
                    customer.idnowIdent.status === 'NOT_STARTED' ||
                    customer.idnowIdent.status === 'PENDING'
                  ) && (
                    <>
                      {manualFetchIdnowCheckStatus.loading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <button
                            className="button"
                            onClick={triggerManualFetch('idnow', customer.idnowIdent.id)}
                          >
                            {t('Manual fetch check')}
                          </button>
                        </>
                      )}
                    </>
                  )
                }
              </>
            )}

            {customer.allIdnowIdent && customer.allIdnowIdent.length > 0 && (
              <>
                {customer.allIdnowIdent.map(idnowIdent => (
                  <React.Fragment key={idnowIdent.id}>
                    <AdminIdnowViewer idnowIdent={idnowIdent} />
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        )
      }

    </>
  )
}

export default AdminLivenessCheckViewer
