import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import reduce from "lodash/reduce";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import {
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'

import {
  GET_AVAILABLE_FEATURES,
} from "../../queriesAndMutations";
import InputTheme from "../InputTheme";
import LockedFeatureIcon from '../LockedFeatureIcon';
import FeatureNotAvailableMessage from '../FeatureNotAvailableMessage';
import { isFeatureEnabled, isAllRequiredFeaturesEnabled } from "../../utils";
import { useTranslation } from 'react-i18next';

const SETS = {
  setting_option: {
    label: "Setting option",
    type: "radio",
    default: "own_setting",
    options: [
      {
        label: 'Predefined',
        value: 'predefined',
      },
      {
        label: 'Own setting',
        value: 'own_setting',
      }
    ],
  },
  host: {
    label: "MailGun host",
    type: "text",
    default: "",
    setting_type: ['own_setting']
  },
  port: {
    label: "MailGun port",
    type: "text",
    default: "",
    setting_type: ['own_setting']
  },
  user: {
    label: "MailGun user",
    type: "text",
    default: "",
    setting_type: ['own_setting']
  },
  pass: {
    label: "MailGun password",
    type: "password",
    default: "",
    setting_type: ['own_setting']
  },
  from: {
    label: "From",
    type: "text",
    default: "",
    setting_type: ['own_setting', 'predefined']
  },
  from_for_comment: {
    label: "From for comment",
    type: "text",
    default: "",
    setting_type: ['own_setting', 'predefined']
  },
  from_dublicate: {
    label: "From duplicate",
    type: "text",
    default: "",
    setting_type: ['own_setting', 'predefined']
  },
  from_kyc_approval_email: {
    label: "KYC approval email",
    helperText: "Only the email at KYC submission should be sent",
    type: "text",
    default: "",
    setting_type: ['own_setting', 'predefined']
  },
  auto_approve_email: {
    label: 'Auto Approve Email',
    helperText: "Email to receive notification when KYC is auto-approved",
    type: "text",
    default: "",
    setting_type: ['own_setting', 'predefined'],
  },
  subject_prefix: {
    label: "Email subject prefix",
    type: "text",
    default: "",
    setting_type: ['own_setting', 'predefined']
  },
  secure: {
    default: false,
    setting_type: ['own_setting']
  },
  email_video_ident_failed_twice: {
    label: 'Notify Compliance Email',
    default: '',
    setting_type: ['own_setting', 'predefined']
  },
  email_new_user_registration: {
    label: 'Email new user registration',
    default: '',
    setting_type: ['own_setting', 'predefined']
  },
  days_to_send_video_ident_email: {
    label: "Days which email will be sent on liveness check",
    type: "number",
    default: 1,
    setting_type: ['own_setting', 'predefined']
  },
};

const AdminSettingsMailgun = ({
  mailgunSettings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation();
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] =
          (mailgunSettings && mailgunSettings[key]) || SETS[key].default;
        return memo;
      },
      {}
    ),
  });

  const onUpdateBtnClick = () => {
    onUpdate({ mailgun: values });
  };

  const handleChangeCheckBox = (propertyName) => (event) => {
    setValues({ ...values, [propertyName]: event.target.checked });
  };

  const handleChangeRadio = (propertyName) => ({ target: { value } }) => {
    setValues({ ...values, [propertyName]: value });
  };

  return (
    <>
      <div className="settings__item">
        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">{ t('What type of setting do you want to use?') }</div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                value={values.setting_option}
                onChange={handleChangeRadio('setting_option')}
                row
              >
                <FormControlLabel
                  label={t('Predefined')}
                  value="predefined"
                  control={<Radio />}
                />
                <FormControlLabel
                  label={
                    <>
                      {t('Own setting')}
                      {isFeatureEnabled('USE_OWN_MAILGUN', availableFeatures) || (<LockedFeatureIcon />)}
                    </>
                  }
                  value="own_setting"
                  control={<Radio />}
                  disabled={!editingAllowed || !isFeatureEnabled('USE_OWN_MAILGUN', availableFeatures)}
                />
              </RadioGroup>
            </div>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields have__space">
            {Object.keys(omit(SETS, ["secure", "setting_option"]))
              // .filter(setting => SETS[setting].setting_type.includes(values.setting_option))
              .map((item) => {
                const isDisabled = !editingAllowed || !SETS[item].setting_type.includes(values.setting_option);
                return (
                  <InputTheme
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    propertyName={item}
                    label={SETS[item].label}
                    state={isDisabled ? {[item]: 'xxxxxxxxxxxx'} : values}
                    type={isDisabled ? 'password' : SETS[item].type}
                    setState={setValues}
                    helperText={SETS[item].helperText}
                    error={error}
                    disabled={isDisabled}
                    loading={loading}
                  />
                )
              })}
          </div>

          {values.setting_option === 'own_setting' && (
            <div className="field create__line remove__line">
              <div className="create__info">
              { t('Secure') }
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  className="switch__input"
                  checked={values.secure}
                  onChange={handleChangeCheckBox("secure")}
                  color="primary"
                  value="secure"
                  disabled={!editingAllowed}
                />
                <div className="switch__inner">
                  <div className="switch__box">{' '}</div>
                </div>
              </label>
            </div>
          )}
          {!isAllRequiredFeaturesEnabled(['USE_OWN_MAILGUN'], availableFeatures) && (
            <FeatureNotAvailableMessage />
          )}
        </div>
      </div>
      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
          className="button settings__button"
        >
          { t('Update') }
        </button>
      )}
    </>
  );
};

AdminSettingsMailgun.propTypes = {
  mailgunSettings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsMailgun;
