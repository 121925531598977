import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useMe } from '../myHooks'
import {
  GET_USER_DIRECTOR_LIST,
  GET_COMPANY_LIVENESS_CHECK_SERVICE_INFO,
} from '../queriesAndMutations'

const KybDirectorStatus = ({
  title,
  isAdmin,
  directorList,
}) => {
  const { t } = useTranslation();
  const { data: { getUserDirectorList } = {} } = useQuery(GET_USER_DIRECTOR_LIST)
  const { data: { me } = {} } = useMe({ fetchPolicy: 'network-only' })
  const [
    getCompanyLivenessCheckServiceInfo,
    { data: { getCompanyLivenessCheckServiceInfo: livenessCheckRequest } = {} }
  ] = useLazyQuery(GET_COMPANY_LIVENESS_CHECK_SERVICE_INFO)

  useEffect(() => {
    if (getUserDirectorList && getUserDirectorList.length > 0) {
      getCompanyLivenessCheckServiceInfo({
        variables: {
          directorId: getUserDirectorList[0].id
        }
      })
    }
  }, [getUserDirectorList, getCompanyLivenessCheckServiceInfo])

  if (
    (!me || !getUserDirectorList || getUserDirectorList.length === 0) &&
    (!directorList || directorList.length === 0)
  ) {
    return null
  }

  return (
    <div className={`hit kyc-hit nospace__top ${!title && 'hit__clear-top'}`}>
      {title && (
        <div className="hit__head">
          <div className="title-primary hit__title">{title}</div>
        </div>
      )}

      <div className="hit__wrapper">
        <div className="summary">
          <div className="transactions__wrapper products__table-kyclist__wrapper">
            <div className="transactions__table kyc__table_transactions kyc__table_kyclist">
              <div className="transactions__row">
                <div className="transactions__col">
                  {t('Name')}
                </div>

                <div className="transactions__col">
                  {t('Director profile')}
                </div>

                <div className="transactions__col">
                  {t('Liveness check')}
                </div>

                {!isAdmin && (
                  <div className="transactions__col">
                    {t('Signature')}
                  </div>
                )}
              </div>

              {(directorList || getUserDirectorList).map((director, index) => {
                const isCompletedLivenessCheck =
                  (director.onfidoApplicant && director.onfidoApplicant.onfidoChecks.length > 0) ||
                  (director.complycubeClient && director.complycubeClient.complycubeChecks.length > 0) ||
                  (director.idnowIdent && director.idnowIdent.status === 'COMPLETED') ||
                  (director.livenessCheckApplicant && director.livenessCheckApplicant.status === 'SUBMITTED')
                const isLivenessCheckNotReady =
                  !director.onfidoApplicant &&
                  !director.idnowIdent &&
                  !director.livenessCheckApplicant &&
                  !director.complycubeClient
                const isWaitingForLivenessCheck =
                  (livenessCheckRequest && livenessCheckRequest.serviceName === 'onfido' && director.onfidoApplicant && director.onfidoApplicant.onfidoChecks.length === 0) ||
                  (livenessCheckRequest && livenessCheckRequest.serviceName === 'complycube' && director.complycubeClient && director.complycubeClient.complycubeChecks.length === 0) ||
                  (livenessCheckRequest && livenessCheckRequest.serviceName === 'idnow' && director.idnowIdent && director.idnowIdent.status === 'NOT_STARTED') ||
                  (livenessCheckRequest && livenessCheckRequest.serviceName === 'internal_liveness_check' && director.livenessCheckApplicant && director.livenessCheckApplicant.status === 'NOT_SUBMITTED')

                return (
                  <div className="transactions__row" key={director.id}>
                    <div className="transactions__col">
                      {`${director.firstName} ${director.lastName}`}
                      <br />
                      {director.email}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Director profile')}
                      </div>

                      {director.status === 'NOT_SUBMITTED' && me && director.email === me.email && (
                        <NavLink className="button button-small" to={`/director-profile/${director.id}`}>
                          {t('Add director profile')}
                        </NavLink>
                      )}
                      {director.status === 'REOPEN' && me && director.email === me.email && (
                        <NavLink className="button button-small" to={`/director-profile/${director.id}`}>
                          {t('Update director profile')}
                        </NavLink>
                      )}
                      {(director.status === 'NOT_SUBMITTED' || director.status === 'REOPEN') && me && director.email !== me.email && (
                        <div className="status status-pending-dark">
                          {t('Incomplete')}
                        </div>
                      )}
                      {['PENDING', 'VIDEO_IDENT', 'PASSED'].includes(director.status) && (
                        <div className="status status-green-dark">
                          {t('Success')}
                        </div>
                      )}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Liveness check')}
                      </div>
                      {isCompletedLivenessCheck && (
                        <div className="status status-green-dark">
                          {t('Success')}
                        </div>
                      )}
                      {isWaitingForLivenessCheck && me && director.email === me.email && (
                        <NavLink className="button button-stroke button-xs button-nowrap" to={`/director-profile/${director.id}/video-ident`}>
                          {t('Start Liveness check now')}
                        </NavLink>
                      )}
                      {(isLivenessCheckNotReady || (isWaitingForLivenessCheck && director.email !== me.email)) && (
                        <div className="status status-pending-dark">
                          {t('Incomplete')}
                        </div>
                      )}
                    </div>

                    {!isAdmin && (
                      <div className="transactions__col">
                        <div className="transactions__label">
                          {t('Signature')}
                        </div>
                        {me && me.customerCompany && (
                          <>
                            {me.customerCompany.directorFormType && director.willSignForm && (
                              director.allDocumentESignature.map(documentESignature =>
                                <>
                                  {(!documentESignature || documentESignature.status) !== 'fulfilled' && (
                                    <div className="status status-pending-dark">
                                      {t('Incomplete')}
                                    </div>
                                  )}
                                  {documentESignature && documentESignature.status === 'fulfilled' && (
                                    <div className="status status-green-dark">
                                      {t('Success')}
                                    </div>
                                  )}
                                  <br />
                                </>
                              )
                            )}
                            {(!me.customerCompany.directorFormType || !director.willSignForm) && (
                              <div className="status status-idle">{t('Not required')}</div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}

            </div>
          </div>
        </div>
      </div>
      <div className="space space__24">{' '}</div>
    </div>
  )
}

export default KybDirectorStatus
