import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  CircularProgress,
} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { GET_LOG_EVENT } from '../queriesAndMutations'
// import '../styles/overwrite/Chip.scss'
import { useTranslation } from 'react-i18next'
import Icon from '../components/Icon'
import classNames from 'classnames'
import TablePaginationActions from '../components/TablePagination'

const StatusChip = ({ label }) => {
  switch (label) {
  case 'APPROVED':
    return (
      <div className="status-green products__status">
        {label}
      </div>
    )
  case 'ENABLED':
    return (
      <div className="status-green products__status">
        {label}
      </div>
    )
  case 'PENDING':
  case 'REOPEN':
  case 'IN_PROGRESS':
  case 'KYC_LVL_CHANGE':
  case 'REOPEN_WITH_VIDEOIDENT':
    return (
      <div className="status-pending products__status">
        {label}
      </div>
    )
  case 'DELETED':
  case 'REJECTED':
    return (
      <div className="status-pending products__status">
        {label}
      </div>
    )
  default:
    return (
      <div className="status-idle products__status">
        {label}
      </div>
    )
  }
}

const AdminLogEvent = () => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const changeRowsPerPage = ({ currentTarget: { value } }) => {
    setPage(0)
    setRowsPerPage(+value)
  }
  const changePage = (event, page) => { setPage(page) }
  const [filterFields, setFilterFields] = useState({
    kyc_individual_id: [],
    kyc_company_id: [],
    transaction_id: [],
    user_id: [],
    log_type: [],
  })
  const setFilter = (field) => ({target: { value } }) => {
    switch (field) {
      case 'kyc_individual_id':
      case 'kyc_company_id':
      case 'transaction_id':
      case 'user_id': {
        const ids = value.split(',').map(id => parseInt(id)).filter(id => !!id);
        setFilterFields({
          ...filterFields,
          [field]: ids
        })
        break;
      }
      default:
        break;
    }

    setPage(0)
  }

  const logTypeList = [
    'TRANSACTION_STATUS_CHANGE',
    'CUSTOMER_INDIVIDUAL_STATUS_CHANGE',
    'CUSTOMER_INDIVIDUAL_DELETE',
    'CUSTOMER_COMPANY_STATUS_CHANGE',
    'CUSTOMER_COMPANY_DELETE',
    'USER_2FA_CHANGE',
    'CHANGE_SETTINGS'
  ]
  const toggleLogType = (type) => ({ target: { checked }}) => {
    if (checked) {
      setFilterFields({
        ...filterFields,
        log_type: [...filterFields.log_type, type]
      })
    } else {
      setFilterFields({
        ...filterFields,
        log_type: filterFields.log_type.filter((item) => item !== type)
      })
    }

    setPage(0)
  }

  const { data, loading } = useQuery(GET_LOG_EVENT, {
    variables: {
      input: {
        ...filterFields,
        page,
        pageSize: rowsPerPage,
      }
    }
  })
  const logData = (data && data.getLogEvent) || { total: 0, data: [] }

  const renderActionObject = (logEvent) => {
    if (logEvent.kyc_individual_id) {
      return `Individual #${logEvent.kyc_individual_id}`
    }

    if (logEvent.kyc_company_id) {
      return `Company #${logEvent.kyc_company_id}`
    }

    if (logEvent.director_id) {
      return `Director #${logEvent.director_id}`
    }

    if (logEvent.transaction_id) {
      return `Transaction #${logEvent.transaction_id}`
    }

    if (logEvent.log_type === 'USER_2FA_CHANGE') {
      return `User #${logEvent.user_id}`
    }

    const commentData = logEvent.comment ? logEvent.comment.match(/\((Individual|Company) (#\d+)\)/) : null

    return commentData ? `${commentData[1]} ${commentData[2]}` : '-'
  }

  const renderComment = (comment) => {
    return comment ? comment.split('\n').reduce((all, sentence) => [...all, sentence, <br />], []) : '';
  }

  const renderSettingChanges = (changes) => JSON.parse(changes).map((change) => (
    <div key={change.path}>
      <strong className="products__category">{change.path}</strong>
      :
      {' '}
      {change.old}
      <ArrowRightAltIcon style={{verticalAlign: 'bottom'}}/>
      {change.new}
    </div>
  ))

  return (
    <>
      <div className="page__title h3">{ t('Log Event')}</div>

      <div className="create__card card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('Category & attributes') }</div>
        </div>

        <div className="field create__group">
          <div className="field">
            <div className="field__label">
              { t('Actor IDs') }
            </div>

            <div className="field__wrap">
              <input
                type="text"
                className="field__input"
                placeholder={ t('e.g: 2,5,10')}
                onChange={setFilter('user_id')}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">
              { t('Individual IDs') }
            </div>

            <div className="field__wrap">
              <input
                type="text"
                className="field__input"
                placeholder={ t('e.g: 2,5,10')}
                onChange={setFilter('kyc_individual_id')}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">
              { t('Company IDs') }
            </div>

            <div className="field__wrap">
              <input
                type="text"
                className="field__input"
                placeholder={ t('e.g: 2,5,10')}
                onChange={setFilter('kyc_company_id')}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">
              { t('Transaction IDs') }
            </div>

            <div className="field__wrap">
              <input
                type="text"
                className="field__input"
                placeholder={ t('Transaction IDs, e.g: 2,5,10')}
                onChange={setFilter('transaction_id')}
              />
            </div>
          </div>
        </div>

        <div className="create__label">
          { t('Action') }
        </div>

        <div className="create__list create__list_logevent">
          {logTypeList.map((type) => (
            <label className="checkbox" key={type}>
              <input
                type="checkbox"
                className="checkbox__input"
                checked={filterFields.log_type.includes(type)}
                onChange={toggleLogType(type)}
                value={type}
              />
              <div className="checkbox__inner">
                <div className="checkbox__tick">{' '}</div>
                <div className="checkbox__text">{type}</div>
              </div>
            </label>
          ))}
        </div>
      </div>

      <div className="transactions card">
        <div className="products__head">
          <div className="title-primary card__title">{ t('Log Event Lists') }</div>
        </div>

        <div className="products__wrapper">
          <div className="products__table products__table-fix_label_rwd products__table-fix_firstcolumn products__table-logevent">

            <div className="products__row">
              <div className="products__col">
                { t('ID') }
              </div>

              <div className="products__col">
                { t('Actor') }
              </div>

              <div className="products__col">
                { t('Object') }
              </div>

              <div className="products__col">
                { t('Status') }
              </div>

              <div className="products__col">
                { t('Created At')}
              </div>

              <div className="products__col">
                { t('Comment') }
              </div>
            </div>

            { loading && (
              <div className="products__row">
                <div className="transactions__col transactions__col-full">
                  <CircularProgress />
                </div>
              </div>
            )}

            { logData && logData.data.map((logEvent) => (
              <div
                className="products__row"
                key={logEvent.id}
              >
                <div className="products__col">
                  <div className="products__label">{t('ID')}</div>
                  <div className="products__box">
                    <div className="products__category">
                      #
                      {logEvent.id}
                    </div>
                  </div>
                </div>

                <div className="products__col">
                  <div className="products__label">{t('Actor')}</div>
                  <div className="products__box">
                    <div className="products__item products__item-normal flex__column flex__wrap flex__justify_start flex__align_start">
                      <div className="products__category">
                        {logEvent.user_id ? `User #${logEvent.user_id}` : 'Auto'}
                      </div>

                      <div className="d__block">
                        {logEvent.user_id && (
                          <>
                            {logEvent.user.email}
                            <br />
                            {logEvent.from_ip && `IP: ${logEvent.from_ip}`}
                          </>
                        )}
                        {!logEvent.user_id && 'System'}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="products__col">
                  <div className="products__label">{t('Object')}</div>
                  <div className="products__box">
                    {renderActionObject(logEvent)}
                  </div>
                </div>

                <div className="products__col">
                  <div className="products__label">{t('Status')}</div>
                  <div className="products__box">
                    <div className={logEvent.log_type === 'CHANGE_SETTINGS' ? '' : 'products__item products__item-normal products__item-chip'}>
                      {logEvent.log_type === 'CHANGE_SETTINGS'
                        ? renderSettingChanges(logEvent.comment)
                        : (
                          <>
                            <StatusChip label={logEvent.status_type_before} />
                            <Icon
                              className={classNames('arrow-right')}
                              name="arrow-right"
                              size="24"
                            />{" "}
                            <StatusChip label={logEvent.status_type_after} />
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="products__col">
                  <div className="products__label">{t('Created At')}</div>
                  <div className="products__box">
                    <div className="products__category">
                      {logEvent.created_at}
                    </div>
                  </div>
                </div>

                <div className="products__col">
                  <div className="products__label">
                    {t('Comment')}
                  </div>
                  <div className="products__box">
                    <div className="products__item products__item-normal">
                      {logEvent.log_type === 'CHANGE_SETTINGS'
                        ? `${logEvent.user.email} updated settings.`
                        : renderComment(logEvent.comment)
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        { logData && logData.data && (
          <TablePaginationActions
            count={logData.total}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={changePage}
            onChangeRows={changeRowsPerPage}
            rowsPerPageOptions={[1, 5, 10, 25]}
          />
        )}
      </div>

    </>
  )
}

export default AdminLogEvent
