import React, { useEffect } from 'react'
import {
  withRouter,
} from 'react-router-dom'

import { useCookies } from 'react-cookie'
import { compose } from 'recompose'
import { useMutation } from '@apollo/client'
import queryString from 'query-string'

import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { REGISTRATION_CONFIRM } from '../../../queriesAndMutations'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const RegistrationConfirm = ({ history }) => {
  const classes = useStyles()
  const [, setCookie] = useCookies(["jwt"]);
  const { t } = useTranslation();
  const [registrationConfirm, { data, loading, error }] = useMutation(REGISTRATION_CONFIRM)

  const query = queryString.parse(history.location.search)
  const registrationConfirmToken = query && query.token

  useEffect(() => {
    registrationConfirm({ variables: { input: { token: registrationConfirmToken } } }).then(({ data }) => {
      setCookie("jwt", data.registrationConfirm.accessToken, { path: "/" });
      localStorage.setItem("jwt", data.registrationConfirm.accessToken);
      localStorage.setItem("volveJwt", data.registrationConfirm.volveAccessToken);
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 1000)
    }).catch(() => {})
  }, [registrationConfirm, registrationConfirmToken, setCookie])

  if (data) {
    return (
      <div className="page-ath-text">
        <div className="alert alert-success">{t('Your email is successfull verified.')}{t('Redirecting...')}</div>
        <div className="gaps-0-5x" />

      </div>
    )
  }

  if (error) {
    return (
      <div className="page-ath-text">
        <div className="alert alert-warning">{ t('Email verify error') }</div>
        <div className="gaps-0-5x" />
      </div>
    )
  }

  return (
    <div className="page-ath-text">
      { loading && (
      <>
        <div className="alert alert-info">{ t('Email verification in progress')}</div>
        <div className="gaps-0-5x" />
      </>
      )}
      <div className={classes.circularProgressWrapper}><CircularProgress /></div>
    </div>
  )
}

export default compose(
  withRouter
)(RegistrationConfirm)
