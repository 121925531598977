import React, { useEffect } from 'react'
import {
  Redirect, withRouter,
} from 'react-router-dom'
import { compose } from 'recompose'
import { useMutation } from '@apollo/client'
import queryString from 'query-string'
// import '../styles/legacy/style.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { AUTH_TOKEN } from '../queriesAndMutations'
import LineInformation from './InformationLine'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const AuthToken = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation();
  const [authToken, { data, loading, error }] = useMutation(AUTH_TOKEN)

  const query = queryString.parse(history.location.search)
  const queryAuthToken = (query || {}).auth_token

  useEffect(() => {
    authToken({ variables: { auth_token: queryAuthToken } }).catch(() => {})
  }, [authToken, queryAuthToken])
  if (data) {
    localStorage.removeItem('jwt')
    localStorage.setItem('jwt', data.authToken.accessToken)
    return <Redirect to={`/${query.redirect_url || '/'}`} />
  }

  if (error) {
    return (
      <LineInformation
        informationColor="box__information-red"
        icon="close"
        text={t('Login Error')}
      />
    )
  }

  return (
    <>
      { loading && (
        <>
          <LineInformation
            informationColor="box__information-green"
            icon="check"
            text={t('Login in progress')}
          />
        </>
      )}
      <div className={classes.circularProgressWrapper}><CircularProgress /></div>
    </>
  )
}

export default compose(
  withRouter
)(AuthToken)
