import React, { useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import {
  CircularProgress,
  Grid,
} from '@material-ui/core'
import {
  SEARCH_KOMPANY_MAPPING,
  SAVE_KOMPANY_MAPPING,
} from '../../queriesAndMutations'

const KompanySearchMapping = ({
  company,
  onMapped,
}) => {
  const [searchResult, setSearchResult] = useState([])
  const [selectedResultIndex, setSelectedResultIndex] = useState(null)
  const [searchKompanyMapping, searchKompanyMappingStatus] = useMutation(SEARCH_KOMPANY_MAPPING)
  const [saveKompanyMapping, saveKompanyMappingStatus] = useMutation(SAVE_KOMPANY_MAPPING)

  const searchCompany = (searchBy) => () => {
    setSelectedResultIndex(null)
    searchKompanyMapping({
      variables: {
        companyId: company.id,
        type: searchBy
      }
    }).then(({ data: { searchKompanyMapping: result } }) => {
      setSearchResult(result || [])
    }).catch(() => {})
  }

  const saveMapping = () => {
    if (selectedResultIndex === null || !searchResult[selectedResultIndex]) {
      return
    }

    const selectedResult = searchResult[selectedResultIndex];

    saveKompanyMapping({
      variables: {
        companyId: company.id,
        kompanyId: selectedResult.id,
        data: selectedResult,
      }
    }).then(({ data: { saveKompanyMapping }}) =>
      onMapped && onMapped()
    )
  }

  const saveAsNoMatched = () => {
    saveKompanyMapping({
      variables: {
        companyId: company.id,
        kompanyId: null,
        data: null,
      }
    }).then(({ data: { saveKompanyMapping }}) =>
      onMapped && onMapped()
    )
  }

  return (
    <>
      <h3>Provided data:</h3>
      <ul>
        <li>Company name: {company.companyName}</li>
        <li>Registration number: {company.companyRegisterNumber}</li>
        <li>Country: {company.companyCountry}</li>
      </ul>
      <div>
        <button className="button button-small" onClick={searchCompany('number')} disabled={searchKompanyMappingStatus.loading}>
          Search by registration number
        </button>
        {' '}
        <button className="button button-small" onClick={searchCompany('name')} disabled={searchKompanyMappingStatus.loading}>
          Search by name
        </button>
      </div>
      <br />
      {searchKompanyMappingStatus.loading && <CircularProgress />}
      {!searchKompanyMappingStatus.loading && searchKompanyMappingStatus.data && searchResult.length === 0 && (
        <p>No result found</p>
      )}
      {!searchKompanyMappingStatus.loading && searchResult.length > 0 && (
        <>
          <Grid container spacing={3}>
            {searchResult.map((foundData, index) => (
              <Grid item sm={6} md={4} key={foundData.id}>
                <div className="card light-border d__flex flex__column flex__justify_between" style={{height: '100%'}}>
                  <div>
                    <h3>{foundData.name}</h3>
                    <p><label>ID</label>: {foundData.id}</p>
                    <p><label>Registration number</label>: {foundData.registrationNumber}</p>
                    <p><label>Last updated</label>: {moment(foundData.lastUpdate * 1000).format('DD-MM-YYYY')}</p>
                  </div>
                  <button
                    className={classNames('button button-small w-full', selectedResultIndex === index ? 'button-stroke ' : '')}
                    onClick={() => { setSelectedResultIndex(index) }}
                    disabled={selectedResultIndex === index}
                  >
                    {selectedResultIndex === index ? 'Selected' : 'Select as correct mapping'}
                  </button>
                </div>
              </Grid>
            ))}
          </Grid>

          {saveKompanyMappingStatus.loading && <CircularProgress />}
          {!saveKompanyMappingStatus.loading && (
            <>
              <br />
              <button
                className="button"
                onClick={saveMapping}
                disabled={selectedResultIndex === null}
              >
                Save
              </button>
              {' '}
              <button
                className="button button-stroke"
                onClick={saveAsNoMatched}
              >
                Save as no matched result
              </button>
            </>
          )}
        </>
      )}
    </>
  )
}

export default KompanySearchMapping
