import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const SETTINGS = gql`
  query settings {
    settings {
      token_type
      token_name
      token_symbol
      base_currency
      minimum_investment_amount
      maximum_investment_amount
      video_ident_value
      interval_upload_csv_pythagoras
      prospectus_date
      prospectus_url
      show_source_signup
      send_gto_api_when_changing_status
      accepted_currencies
      accepted_mandatory_kyc
      accepted_field_application_individual_fiat
      accepted_field_application_individual_crypto
      kyc_accepted_wallets
      kyc_validate_wallets
      kyc_text
      prospectus_or_issuing
      issuing_guidelines
      token_sale_agreement_date
      issuing_guidelines_date
      token_sale_agreement_url
      memorandum_date
      memorandum_url
      token_safe_date
      token_safe_url
      not_video_ident_countries
      high_risk_countries
      medium_risk_countries
      enhanced_risk_countries
      days_for_notifications
      kyc_levels
      kyc_video_ident_two_steps
      kyc_use_base_currency
      identification
      google_analytics_id
      google_tag_manager_id
      pixel_id
      company
      bank
      crypto
      mailgun
      logo_path
      example_photo_proof_path
      logo_for_dark_bg_path
      is_white_label
      is_white_skin
      evolve_host
      sub_domain
      brief_logo_path
      dataroom_logo_path
      source_of_funds_example_path
      source_of_address_for_tokens_example_path
      source_of_address_for_sending_tokens_example_path
      rights_of_withdrawal_path
      login_youtube_video
      thumbnail_path
      first_link_login
      first_text_login
      second_link_login
      second_text_login
      add_ether_wallet_later_option
      internal_sales_api
      internal_sales_api_key
      hotjar_id
      intercom_app_id
      intercom_region
      enable_cryptodotcom
      crypto_api_pk
      crypto_api_sk
      kyc_occupation
      kyc_yearly_salary
      kyc_employer_company_name
      kyc_education_level
      kyc_referal_code
      purchase_agreement_link
      enable_allow_list_countries
      allow_list_countries_choosen
      enable_allow_list_nationality
      allow_list_nationality_choosen
      enable_subscription_agreement_link
      subscription_agreement_link
      enable_kyc_discount_level
      kyc_discount_phase,
      enable_stripe
      stripe_public_key
      stripe_secret_key
      enable_voltio
      voltio_url
      voltio_client_id
      voltio_client_secret
      voltio_password
      voltio_username
      voltio_account
      signnow_setting_type
      signnow_api_key
      template_id_required_signature
      signnow_account_email
      signnow_account_password
      signnow_template_name
      signnow_company_form_c_1_signature_template_id
      signnow_company_form_c_2_signature_template_id
      signnow_company_form_c_3_signature_template_id
      signnow_company_form_c_4_signature_template_id
      signnow_company_form_t_1_signature_template_id
      signnow_company_form_t_2_signature_template_id
      signnow_company_form_t_3_signature_template_id
      signnow_company_form_t_4_signature_template_id
      signnow_individual_form_swap_token_template_id
      signnow_company_form_swap_token_template_id
      signnow_individual_form_token_sale_agreement_id
      signnow_company_form_token_sale_agreement_id
      signnow_token_safe_id
      enable_signnow_individual_form
      enable_acronis
      acronis_client_id
      acronis_client_secret
      acronis_account_login
      acronis_account_password
      acronis_root_folder
      enable_sync_pythagoras
      enable_transaction_limit
      transaction_limit_quota
      capital_increase_nominal_value
      enable_cashlink
      cashlink_api_url
      cashlink_client_id
      cashlink_client_secret
      cashlink_mode
      cashlink_network
      cashlink_calculation_factor
      cashlink_isin
      cashlink_paper_contract_hash
      cashlink_paper_contract_url
      cashlink_signature_mode
      cashlink_backup_wallet
      cashlink_supply_limit
      cashlink_initial_supply
      cashlink_contract_info
      require_exact_investment_amount_on_none
      disable_erc_20_check
      kyc_require_referral
      wallet_service
      hide_wallet_format_note
      disable_2fa_security
      allow_port_kyc_and_create_transaction_from_master_tenant
      kyc_phone_number
      kyc_net_investable_assets
      kyc_what_will_you_use
      kyc_politically_exposed_persons
      kyc_acknowledgement_of_receipt
      kyc_client_statement
      kyc_confirmations
      kyc_prefer_currency
      kyc_intended_investment
      kyc_support_source_of_funds
      kyc_support_source_of_wealth
      allow_compliance_update_kyc
      rename_share_to_token
      kyc_enable_kyb
      allow_sync_kyc_data_investhub
      kyc_show_receiving_wallet
      kyc_show_website
      kyc_show_position
      allow_duplicated_id_kyc
      onboarding_type
      hide_token_balance
      show_investhub_dao_login_btn
      show_tenant_on_investhub
      sendwyre_api_key
      sendwyre_api_secret
      sendwyre_account_id
      sendwyre_payment_type
      enable_auto_approve_kyc
      enable_evolve
      auto_approve_kyc_max_level
      enable_spot_check
      spot_check_threshold
      enable_product_tour
      liquidation_contract_address
      liquidation_token_decimal
      liquidation_holder_addresses
      liquidation_compensation_rate
      liquidation_specific_compensation_rate
      enable_tangany
      tangany_setting_type
      tangany_client_id
      tangany_client_secret
      tangany_vault_url
      tangany_subscription
      enable_impira
      impira_setting_type
      impira_org
      impira_token
      impira_utility_bill
      twilio_setting_type
      twilio_account_sid
      twilio_auth_token
      twilio_service_sid
      force_user_till_first_transaction
      force_generate_wallet
      enable_swap_token
      enable_personal_discount
      old_token_symbol
      kyc_require_receiving_wallet
      kyc_require_industry
      kyb_require_activity_description
      kyb_require_main_income
      kyb_require_other_income
      kyb_require_gross_revenue
      kyb_require_organization_chart
      kyb_require_association_article
      kyb_require_last_balance_sheet
      kyb_show_good_standing_certificate
      kyb_require_shareholder_list
      kyb_require_ubo
      kyb_require_director_form
      kyb_skip_confirm_beneficial_ownership
      kyb_require_utility_bill
      kyb_show_business_countries
      kyb_show_business_following_industries
      kyc_skip_legal_disclaimer
      use_registration_confirm_for_proof_of_residence
      enable_proof_of_residence_checking
      allocate_transaction_require_capital_increase
      kyc_require_legal_protection_insurance
      kyc_require_policy_number
      disable_registration
      enable_limit_total_token
      limit_total_token
      enable_smart_contract
      smart_contract_type
      smart_contract_address
      smart_contract_network
      smart_contract_whitelist_address
      smart_contract_dai_address
      smart_contract_usdc_address
      smart_contract_usdt_address
      smart_contract_eure_address
      smart_contract_busd_address
      smart_contract_owner_mnemonic
      public_sale_sof_sow
      enforce_token_minimum_at_kyc
      site_locales
      default_locale
      show_verify_wallet
      deepl_setting_type
      deepl_auth_key
      enable_admin_whitelisted_ips
      admin_whitelisted_ips
      enable_monerium
      monerium_client_id
      monerium_client_secret
      enable_fiat_republic
      fiat_republic_client_id
      fiat_republic_client_secret
      discount_maximum_raise
      discount_end_date
    }
  }
`;

export const PUBLIC_SETTINGS = gql`
  query publicSettings {
    publicSettings {
      token_type
      token_name
      token_symbol
      base_currency
      show_source_signup
      minimum_investment_amount
      maximum_investment_amount
      video_ident_value
      prospectus_date
      prospectus_url
      accepted_currencies
      accepted_mandatory_kyc
      accepted_field_application_individual_fiat
      accepted_field_application_individual_crypto
      kyc_text
      prospectus_or_issuing
      issuing_guidelines
      token_sale_agreement_date
      issuing_guidelines_date
      token_sale_agreement_url
      memorandum_date
      memorandum_url
      token_safe_date
      token_safe_url
      not_video_ident_countries
      high_risk_countries
      medium_risk_countries
      enhanced_risk_countries
      days_for_notifications
      kyc_levels
      kyc_video_ident_two_steps
      kyc_use_base_currency
      enable_auto_approve_kyc
      enable_evolve
      enable_product_tour
      identification {
        active_ident_service
        comply_with_bafin
        watchlist_enhanced_service
        ident_services {
          onfido {
            api_url
            force_cross_device
          }
          electronic_id {
            api_url
            video_ident_extract_data
            video_ident_attended_type
          }
          idnow {
            customer_id
            region
          }
        }
      }
      kyc_accepted_wallets
      kyc_validate_wallets
      google_analytics_id
      google_tag_manager_id
      pixel_id
      company
      bank
      crypto
      logo_path
      example_photo_proof_path
      logo_for_dark_bg_path
      brief_logo_path
      dataroom_logo_path
      source_of_funds_example_path
      source_of_address_for_tokens_example_path
      source_of_address_for_sending_tokens_example_path
      rights_of_withdrawal_path
      login_youtube_video
      first_link_login
      first_text_login
      second_link_login
      second_text_login
      add_ether_wallet_later_option
      internal_sales_api
      internal_sales_api_key
      hotjar_id
      intercom_app_id
      intercom_region
      enable_cryptodotcom
      crypto_api_pk
      kyc_occupation
      kyc_yearly_salary
      kyc_employer_company_name
      kyc_education_level
      kyc_referal_code
      enable_kyc_discount_level
      kyc_discount_phase,
      purchase_agreement_link
      enable_allow_list_countries
      allow_list_countries_choosen
      enable_allow_list_nationality
      allow_list_nationality_choosen
      enable_subscription_agreement_link
      subscription_agreement_link
      enable_stripe
      stripe_public_key
      enable_voltio
      voltio_url
      voltio_account
      enable_transaction_limit
      transaction_limit_quota
      capital_increase_nominal_value
      wallet_service
      enable_cashlink
      require_exact_investment_amount_on_none
      kyc_require_referral
      hide_wallet_format_note
      disable_2fa_security
      allow_port_kyc_and_create_transaction_from_master_tenant
      system_status
      kyc_phone_number
      kyc_net_investable_assets
      kyc_what_will_you_use
      kyc_politically_exposed_persons
      kyc_acknowledgement_of_receipt
      kyc_client_statement
      kyc_confirmations
      kyc_prefer_currency
      kyc_intended_investment
      kyc_support_source_of_funds
      kyc_support_source_of_wealth
      allow_compliance_update_kyc
      rename_share_to_token
      kyc_enable_kyb
      allow_sync_kyc_data_investhub
      kyc_show_receiving_wallet
      kyc_show_website
      kyc_show_position
      allow_duplicated_id_kyc
      onboarding_type
      hide_token_balance
      show_investhub_dao_login_btn
      spot_check_threshold
      liquidation_compensation_rate
      liquidation_contract_address
      liquidation_token_decimal
      enable_tangany
      enable_impira
      force_user_till_first_transaction
      force_generate_wallet
      enable_personal_discount
      enable_swap_token
      old_token_symbol
      enable_signnow_individual_form
      kyc_require_receiving_wallet
      kyc_require_industry
      kyb_require_activity_description
      kyb_require_main_income
      kyb_require_other_income
      kyb_require_gross_revenue
      kyb_require_organization_chart
      kyb_require_association_article
      kyb_require_last_balance_sheet
      kyb_show_good_standing_certificate
      kyb_require_shareholder_list
      kyb_require_ubo
      kyb_require_director_form
      kyb_skip_confirm_beneficial_ownership
      kyb_require_utility_bill
      kyb_show_business_countries
      kyb_show_business_following_industries
      kyc_skip_legal_disclaimer
      use_registration_confirm_for_proof_of_residence
      enable_proof_of_residence_checking
      allocate_transaction_require_capital_increase
      kyc_require_legal_protection_insurance
      kyc_require_policy_number
      disable_registration
      enable_limit_total_token
      limit_total_token
      enable_smart_contract
      smart_contract_type
      smart_contract_address
      smart_contract_network
      smart_contract_whitelist_address
      smart_contract_dai_address
      smart_contract_usdc_address
      smart_contract_usdt_address
      smart_contract_eure_address
      smart_contract_busd_address
      public_sale_sof_sow
      enforce_token_minimum_at_kyc
      sendwyre_payment_type
      sendwyre_account_id
      site_locales
      default_locale
      show_verify_wallet
      master_tenant_company
      enable_monerium
      enable_fiat_republic
      discount_maximum_raise
      discount_end_date
    }
  }
`;

export const SYSTEM_SETTINGS = gql`
  query systemSettings {
    systemSettings {
      disable_2fa_security
    }
  }
`
