import React from 'react'
import { useTranslation } from 'react-i18next'

const DashboardSubApp = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page__title h3">{t('Cockpit')}</div>
    </>
  )
}

export default DashboardSubApp
