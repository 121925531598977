import React, { useState, useEffect } from 'react'
import {
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE,
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT,
} from '../../constants/customer'
import { extractMsgFromErrByPropertyName } from '../../utils'
import { useTranslation } from 'react-i18next'
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem'
import ImageUpload from '../ImageUpload'
import { ethToFiat, tokenToEth } from '../../utils/rate'
import { useExchangeRates } from '../../myHooks'
import { TOKEN_TYPES } from '../../constants/settings'

const CompanyStep3 = ({
  values,
  setValues,
  toPrevStep,
  toNextStep,
  errors,
  publicSettings,
}) => {
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const [currentLevel, setCurrentLevel] = useState(0);
  const baseCurrency = publicSettings.kyc_use_base_currency ? publicSettings.base_currency : 'CHF'

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  useEffect(() => {
    if (exchangeRates && publicSettings.kyc_levels) {
      const tokenInBaseCurrency = exchangeRates && ethToFiat(
        baseCurrency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      );

      let lv = Object.values(publicSettings.kyc_levels).filter((item) => {
        return !item.enable || item.enable.enable_lvl;
      }).reduce((all, item) => all + (tokenInBaseCurrency >= item.min_invest_amount ? 1 : 0), 0);

      if (publicSettings.token_type === TOKEN_TYPES.NONE) {
        lv = getIntendedInvestmentOptions().indexOf(values.intended_investment) + 1
      }

      setCurrentLevel(lv);
    }
    // eslint-disable-next-line
  }, [exchangeRates]);

  const maxLv = publicSettings.kyc_levels[5].enable && publicSettings.kyc_levels[5].enable.enable_lvl ? 5 :
    publicSettings.kyc_levels[4].enable && publicSettings.kyc_levels[4].enable.enable_lvl ? 4 : 3;
  const { t } = useTranslation()
  const onDropFile = (name) => (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({ ...values, [name]: newImage })
  }
  return (
    <div className="settings__wrapper">
      <div className="settings__list">
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Proof of residence')}
          </div>

          <div className="settings__fieldset">
            <div className="field">
              <div className="field__label">
                {t("Proof of residence")}
                <span className="text__red"> *</span>
              </div>

              <div className="field__wrap field__wrap-select">
                <TextField
                  required
                  select
                  variant="outlined"
                  value={values.proofOfResidenceType}
                  onChange={({ target: { value }}) => setValues({ ...values, proofOfResidenceType: value})}
                  helperText={(
                    <span style={{marginTop: '5px', lineHeight: 1.5, color: 'inherit'}}>
                      {t(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT[values.proofOfResidenceType]) || ''}

                      <span className="space space__32">{' '}</span>
                    </span>
                  )}
                >
                  { Object.entries(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE)
                    .filter(x => (publicSettings.use_registration_confirm_for_proof_of_residence && currentLevel === maxLv ? x[0] === "REGISTRATION_CONFIRMATION" : x[0] !== "REGISTRATION_CONFIRMATION"))
                    .map(type => (
                      <MenuItem value={type[0]} key={type[0]}>
                        {t(type[1])}
                      </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <div className="field">
              <div className="field__label text__red">
                {t('Documents of the Corporation must not be older than 3 months.')}
              </div>

              {!values.proofOfResidenceUploadFile && (
                <div className="field__wrap field__wrap-imageupload">
                  <ImageUpload
                    isRequired
                    buttonText={t('Click or drop images')}
                    buttonClassName="button"
                    onChange={onDropFile('proofOfResidenceUploadFile')}
                    maxFileSize={10485760}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={[]}
                    accept='image/*, application/pdf'
                  >
                    <div className="file__wrap_text text__center">
                      <p>
                        { t('Drag and drop the document') }
                      </p>
                    </div>
                  </ImageUpload>
                </div>
              )}
              {values.proofOfResidenceUploadFile && (
                <div className="settings__top">
                  <div className="settings__label">
                    {values.proofOfResidenceUploadFile.img.name}
                  </div>

                  <button
                    onClick={() => { setValues({ ...values, proofOfResidenceUploadFile: null }) }}
                    className="button-stroke button-small settings__button"
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
              {!!extractMsgFromErrByPropertyName(errors, 'articlesOfAssociationFile').length && (
                <div className="field__wrap status-red field__fill">
                  { extractMsgFromErrByPropertyName(errors, 'articlesOfAssociationFile') }
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={toPrevStep}>
            {t('Previous')}
          </button>

          <button className="button" onClick={toNextStep}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CompanyStep3
