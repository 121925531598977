import axios from "axios";
import { downloadFile } from "../utils/file";

const queryString = require("query-string");

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // needs for getting error response in blob request
    if (
      error.response &&
      error.request.responseType === "blob" &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf("json") !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  }
);

const token = localStorage.getItem("volveJwt");

const defaultConfig = {
  headers: {
    'x-auth-token': token
  }
}

if (!token) {
  delete defaultConfig.headers['x-auth-token']
}

// if (token) {
//   axios.defaults.headers.common['x-auth-token'] = token
// } else {
//   delete axios.defaults.headers.common['x-auth-token']
// }

// axios.defaults.withCredentials = false;

class VolveApi {
  _basicPath = process.env.REACT_APP_VOLVE_HOST;

  loginUser = async (data) => {
    const url = `${this._basicPath}/api/auth`;
    return axios.post(url, data, defaultConfig);
  };

  forgotPassword = async (email) => {
    const authData = { email };
    const url = `${this._basicPath}/api/auth/forgotpassword`;
    return axios.post(url, authData, defaultConfig);
  };

  resetPassword = async (password, resetToken) => {
    const authData = { password, resetToken };
    const url = `${this._basicPath}/api/auth/resetpassword`;
    return axios.post(url, authData, defaultConfig);
  };

  confirmEmail = async (hash) => {
    const url = `${this._basicPath}/api/users/email-confirmation/${hash}`;
    return axios.get(url, defaultConfig);
  };

  getCurrentUser = async () => {
    const url = `${this._basicPath}/api/users/me`;
    return axios.get(url, defaultConfig);
  };

  registerUser = async (values) => {
    const url = `${this._basicPath}/api/users`;

    return axios.post(url, values, defaultConfig);
  };

  updateCurrentUser = async (values) => {
    const url = `${this._basicPath}/api/users/me`;
    return axios.post(url, values, defaultConfig);
  };

  updateUser = async (userId, values) => {
    const url = `${this._basicPath}/api/users/${userId}`;
    return axios.post(url, values, defaultConfig);
  };

  exportUsers = async () => {
    const url = `${this._basicPath}/api/users/export`;
    downloadFile(url, defaultConfig);
  };

  deleteUser = async (userId) => {
    const url = `${this._basicPath}/api/users/${userId}`;
    return axios.delete(url, defaultConfig);
  };

  getExpenseHistory = async (expenseId, data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/expenses/${expenseId}/history?${query}`;
    return axios.get(url, defaultConfig);
  };

  getExpenses = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/expenses?${query}`;
    // eslint-disable-next-line
    return await axios.get(url, defaultConfig);
  };

  searchExpense = async (values) => {
    const query = queryString.stringify(values);
    const url = `${this._basicPath}/api/expenses/search?${query}`;
    return axios.get(url, defaultConfig);
  };

  getExpensesExport = async (data) => {
    const query = queryString.stringify(data, { arrayFormat: "comma" }); // use comma because in query string express converts long array into object
    const url = `${this._basicPath}/api/expenses/export?${query}`;
    downloadFile(url);
  };

  getExpensesExportCount = async (data) => {
    const query = queryString.stringify(data, { arrayFormat: "index" });
    const url = `${this._basicPath}/api/expenses/export/count?${query}`;
    return axios.get(url, defaultConfig);
  };

  expenseAction = async (expenseId, action) => {
    const url = `${this._basicPath}/api/expenses/${expenseId}/action`;
    return axios.post(url, { action }, defaultConfig);
  };

  rejectExpense = async (id, values) => {
    const url = `${this._basicPath}/api/expenses/${id}/reject`;
    return axios.post(url, values, defaultConfig);
  };

  getExchangeRate = async (fromCurrency, toCurrency, amount = 1, date) => {
    const url = `${this._basicPath}/api/expenses/exchange`;
    return axios.post(url, { fromCurrency, toCurrency, amount, date }, defaultConfig);
  };

  getExpense = async (cardId) => {
    const url = `${this._basicPath}/api/expenses/${cardId}`;
    return axios.get(url, defaultConfig);
  };

  getExpenseProjects = async (id) => {
    const url = `${this._basicPath}/api/expenses/${id}/projects`;
    return axios.get(url, defaultConfig);
  };

  getExpenseCategories = async (id) => {
    const url = `${this._basicPath}/api/expenses/${id}/categories`;
    return axios.get(url, defaultConfig);
  };

  // getGroupedExpensesByUser = async (status) => {
  //     const query = queryString.stringify({status:status});
  //     const url = `${this._basicPath}/api/expenses/users?${query}`;
  //     return axios.get(url, defaultConfig);
  // }
  getDashboardAmountsByDay = async (filterData) => {
    const query = queryString.stringify(filterData);
    const url = `${this._basicPath}/api/expenses/dashboard-amount-by-day?${query}`;
    return axios.get(url, defaultConfig);
  };

  getDashboardLatest = async (filterData) => {
    const query = queryString.stringify(filterData);
    const url = `${this._basicPath}/api/expenses/dashboard-latest?${query}`;
    return axios.get(url, defaultConfig);
  };

  getDashboardWaitingForApproval = async (filterData) => {
    const query = queryString.stringify(filterData);
    const url = `${this._basicPath}/api/expenses/dashboard-waiting-for-approval?${query}`;
    return axios.get(url, defaultConfig);
  };

  getDashboardAmountsByCategory = async (filterData) => {
    const query = queryString.stringify(filterData);
    const url = `${this._basicPath}/api/expenses/dashboard-amount-by-category?${query}`;
    return axios.get(url, defaultConfig);
  };

  updateExpense = async (expenseId, data) => {
    const url = `${this._basicPath}/api/expenses/${expenseId}`;
    return axios.post(url, data, defaultConfig);
  };

  addBatchExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses/batch-add`;
    return axios.post(url, data, defaultConfig);
  };

  updateBatchExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses/batch-edit`;
    return axios.post(url, data, defaultConfig);
  };

  submitBatchExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses/batch-submit`;
    return axios.post(url, data, defaultConfig);
  };

  deleteBatchExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses/batch-delete`;
    return axios.post(url, data, defaultConfig);
  };

  rejectBatchExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses/batch-reject`;
    return axios.post(url, data, defaultConfig);
  };

  actionBatchExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses/batch-action`;
    return axios.post(url, data, defaultConfig);
  };

  deleteExpense = async (expenseId) => {
    const url = `${this._basicPath}/api/expenses/${expenseId}`;
    return axios.delete(url, defaultConfig);
  };

  createExpense = async (data) => {
    const url = `${this._basicPath}/api/expenses`;
    return axios.post(url, data, defaultConfig);
  };

  disableCard = async (cardId, data) => {
    const url = `${this._basicPath}/api/cards/${cardId}/disable`;
    return axios.post(url, data, defaultConfig);
  };

  futureBlockCard = async (cardId, data) => {
    const url = `${this._basicPath}/api/cards/${cardId}/future-block`;
    return axios.post(url, data, defaultConfig);
  };

  uploadCardImage = async (cardId, data) => {
    const url = `${this._basicPath}/api/cards/${cardId}/image`;
    return axios.post(url, data, defaultConfig);
  };

  getCards = async (data) => {
    const query = queryString.stringify(data, { skipEmptyString: false });
    const url = `${this._basicPath}/api/cards?${query}`;
    return axios.get(url, defaultConfig);
  };

  getCardsList = async (role) => {
    let query = queryString.stringify(role ? { fromTab: role } : null);
    const url = `${this._basicPath}/api/cards/all/?${query}`;
    return axios.get(url, defaultConfig);
  };

  getCardsForExpense = async (id) => {
    const url = `${this._basicPath}/api/expenses/${id}/cards`;
    return axios.get(url, defaultConfig);
  };

  getTransaction = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/transactions?${query}`;
    return axios.get(url, defaultConfig);
  };

  getCard = async (cardId) => {
    const url = `${this._basicPath}/api/cards/${cardId}`;
    return axios.get(url, defaultConfig);
  };

  getCardShortInfo = async (cardId) => {
    const url = `${this._basicPath}/api/cards/${cardId}/short-info`;
    return axios.get(url, defaultConfig);
  };

  deleteCard = async (cardId) => {
    const url = `${this._basicPath}/api/cards/${cardId}`;
    return axios.delete(url, defaultConfig);
  };

  blockUnblockPermsGroupCard = async (cardId, values) => {
    const url = `${this._basicPath}/api/cards/${cardId}/block-unblock-perms-group`;
    return axios.post(url, values, defaultConfig);
  };

  blockUnblockCard = async (cardId, action) => {
    const url = `${this._basicPath}/api/cards/${cardId}/block`;
    return axios.post(url, { action }, defaultConfig);
  };

  blockCardPermanently = async (cardId, values) => {
    const url = `${this._basicPath}/api/cards/${cardId}/block-permanently`;
    return axios.post(url, values, defaultConfig);
  };

  cardSetMonthLimit = async (cardId, values) => {
    const url = `${this._basicPath}/api/cards/${cardId}/month-limit`;
    return axios.post(url, values, defaultConfig);
  };

  activateCard = async (cardId, data) => {
    const url = `${this._basicPath}/api/cards/${cardId}/activate`;
    return axios.post(url, data, defaultConfig);
  };

  getCardPinCode = async (cardId, values) => {
    const url = `${this._basicPath}/api/cards/${cardId}/pin`;
    return axios.post(url, values, defaultConfig);
  };

  receiveCardPan = async (cardId) => {
    const url = `${this._basicPath}/api/cards/${cardId}/pan`;
    return axios.post(url, defaultConfig);
  };

  getCardHiddenInfo = async (cardId) => {
    const url = `${this._basicPath}/api/cards/${cardId}/hiddenInfo`;
    return axios.get(url, defaultConfig);
  };

  getCardTransactions = async (cardId, data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/cards/${cardId}/transactions?${query}`;
    return axios.get(url, defaultConfig);
  };

  createCard = async (data) => {
    const url = `${this._basicPath}/api/cards`;
    return axios.post(url, data, defaultConfig);
  };

  getUsers = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/users?${query}`;
    return axios.get(url, defaultConfig);
  };

  importUsers = async (data) => {
    const url = `${this._basicPath}/api/users/import`;
    return axios.post(url, data, defaultConfig);
  };

  getUsersAll = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/users/all?${query}`;
    return axios.get(url, defaultConfig);
  };

  assignReporters = async (id, data) => {
    const url = `${this._basicPath}/api/users/${id}/assign-reporters`;
    return axios.post(url, data, defaultConfig);
  };

  getUsersUser = async (userId) => {
    const url = `${this._basicPath}/api/users/${userId}`;
    return axios.get(url, defaultConfig);
  };

  actionBatchActivate = async (data) => {
    const url = `${this._basicPath}/api/users/batch-activate`;
    return axios.post(url, data, defaultConfig);
  };

  getGroupedRoles = async () => {
    const url = `${this._basicPath}/api/users/roles`;
    return axios.get(url, defaultConfig);
  };

  createInvitedUser = async (values) => {
    const url = `${this._basicPath}/api/invitation/add`;
    return axios.post(url, values, defaultConfig);
  };

  resendInvitedUser = async (userId) => {
    const url = `${this._basicPath}/api/invitation/resend`;
    return axios.post(url, { userId }, defaultConfig);
  };

  getInvitedUser = async (hash) => {
    const url = `${this._basicPath}/api/invitation/${hash}`;
    return axios.get(url, defaultConfig);
  };

  updateInvitedUser = async (values) => {
    const url = `${this._basicPath}/api/invitation`;
    return axios.post(url, values, defaultConfig);
  };

  createAccount = async (values) => {
    const url = `${this._basicPath}/api/accounts`;
    return axios.post(url, values, defaultConfig);
  };

  getAccounts = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/accounts?${query}`;
    return axios.get(url, defaultConfig);
  };

  getAccountStatement = async (id, dataQuery) => {
    const query = queryString.stringify(dataQuery);
    const url = `${this._basicPath}/api/account-transactions/${id}/get-statement?${query}`;
    downloadFile(url, defaultConfig);
  };

  downloadKybDocument = async () => {
    const url = `${this._basicPath}/api/company/kyb-document`;
    downloadFile(url, defaultConfig);
  };

  kybForwardForSignature = async (values) => {
    const url = `${this._basicPath}/api/company/kyb-forward-for-signature`;
    return axios.post(url, values, defaultConfig);
  };

  getAccountsAll = async () => {
    const url = `${this._basicPath}/api/accounts/all`;
    return axios.get(url, defaultConfig);
  };

  getAccount = async (id) => {
    const url = `${this._basicPath}/api/accounts/${id}`;
    return axios.get(url, defaultConfig);
  };

  getAccountCards = async (accountId, data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/accounts/${accountId}/cards?${query}`;
    return axios.get(url, defaultConfig);
  };

  getAccountTransactions = async (accountId, data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/account-transactions/${accountId}?${query}`;
    return axios.get(url, defaultConfig);
  };

  getServiceData = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/service-data?${query}`;
    return axios.get(url, defaultConfig);
  };

  createProject = async (values) => {
    const url = `${this._basicPath}/api/projects`;
    return axios.post(url, values, defaultConfig);
  };

  getProjects = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/projects?${query}`;
    return axios.get(url, defaultConfig);
  };

  getMyProjects = async () => {
    const url = `${this._basicPath}/api/projects/my`;
    return axios.get(url, defaultConfig);
  };

  getMyCategories = async () => {
    const url = `${this._basicPath}/api/categories/my`;
    return axios.get(url, defaultConfig);
  };

  getProjectsAll = async () => {
    const url = `${this._basicPath}/api/projects/all`;
    return axios.get(url, defaultConfig);
  };

  getProject = async (id) => {
    const url = `${this._basicPath}/api/projects/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateProject = async (id, values) => {
    const url = `${this._basicPath}/api/projects/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  deleteProject = async (id) => {
    const url = `${this._basicPath}/api/projects/${id}`;
    return axios.delete(url, defaultConfig);
  };

  importProject = async (data) => {
    const url = `${this._basicPath}/api/projects/import`;
    return axios.post(url, data, defaultConfig);
  };

  createGroup = async (values) => {
    const url = `${this._basicPath}/api/groups`;
    return axios.post(url, values, defaultConfig);
  };

  getGroups = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/groups?${query}`;
    return axios.get(url, defaultConfig);
  };

  getGroupsAll = async () => {
    const url = `${this._basicPath}/api/groups/all`;
    return axios.get(url, defaultConfig);
  };

  importGroups = async (data) => {
    const url = `${this._basicPath}/api/groups/import`;
    return axios.post(url, data, defaultConfig);
  };

  getGroup = async (id) => {
    const url = `${this._basicPath}/api/groups/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateGroup = async (id, values) => {
    const url = `${this._basicPath}/api/groups/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  deleteGroup = async (id) => {
    const url = `${this._basicPath}/api/groups/${id}`;
    return axios.delete(url, defaultConfig);
  };

  createDepartment = async (values) => {
    const url = `${this._basicPath}/api/departments`;
    return axios.post(url, values, defaultConfig);
  };

  getDepartments = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/departments?${query}`;
    return axios.get(url, defaultConfig);
  };

  geDepartmentsAll = async () => {
    const url = `${this._basicPath}/api/departments/all`;
    return axios.get(url, defaultConfig);
  };

  getDepartment = async (id) => {
    const url = `${this._basicPath}/api/departments/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateDepartment = async (id, values) => {
    const url = `${this._basicPath}/api/departments/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  deleteDepartment = async (id) => {
    const url = `${this._basicPath}/api/departments/${id}`;
    return axios.delete(url, defaultConfig);
  };

  createBranch = async (values) => {
    const url = `${this._basicPath}/api/branches`;
    return axios.post(url, values, defaultConfig);
  };

  getBranches = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/branches?${query}`;
    return axios.get(url, defaultConfig);
  };

  getBranchesAll = async () => {
    const url = `${this._basicPath}/api/branches/all`;
    return axios.get(url, defaultConfig);
  };

  getBranch = async (id) => {
    const url = `${this._basicPath}/api/branches/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateBranch = async (id, values) => {
    const url = `${this._basicPath}/api/branches/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  importBranches = async (data) => {
    const url = `${this._basicPath}/api/branches/import`;
    return axios.post(url, data, defaultConfig);
  };

  createCategory = async (values) => {
    const url = `${this._basicPath}/api/categories`;
    return axios.post(url, values, defaultConfig);
  };

  getCategories = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/categories?${query}`;
    return axios.get(url, defaultConfig);
  };

  getCategoriesAll = async () => {
    const url = `${this._basicPath}/api/categories/all`;
    return axios.get(url, defaultConfig);
  };

  importCategories = async (data) => {
    const url = `${this._basicPath}/api/categories/import`;
    return axios.post(url, data, defaultConfig);
  };

  getCategory = async (id) => {
    const url = `${this._basicPath}/api/categories/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateCategory = async (id, values) => {
    const url = `${this._basicPath}/api/categories/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  updateBatchCategories = async (data) => {
    const url = `${this._basicPath}/api/categories/batch-edit`;
    return axios.post(url, data, defaultConfig);
  };

  disableBatchCategories = async (data) => {
    const url = `${this._basicPath}/api/categories/batch-disable`;
    return axios.post(url, data, defaultConfig);
  };

  submitKycForm = async (userId, data) => {
    const url = `${this._basicPath}/api/users/${userId}/kyc`;
    return axios.post(url, data, defaultConfig);
  };

  getCompany = async (withOwner) => {
    const url = `${this._basicPath}/api/company?${
      withOwner ? "with-owner=true" : ""
    }`;
    return axios.get(url, defaultConfig);
  };

  getCompanyFbas = async () => {
    const url = `${this._basicPath}/api/company/funding-bank-accounts`;
    return axios.get(url, defaultConfig);
  };

  updateCompanyFba = async (id, data) => {
    const url = `${this._basicPath}/api/company/funding-bank-accounts/${id}`;
    return axios.post(url, data, defaultConfig);
  };

  createCompanyFba = async (data) => {
    const url = `${this._basicPath}/api/company/funding-bank-accounts/`;
    return axios.post(url, data, defaultConfig);
  };

  getCompanyFba = async (id) => {
    const url = `${this._basicPath}/api/company/funding-bank-accounts/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateCompany = async (data) => {
    const url = `${this._basicPath}/api/company`;
    return axios.post(url, data, defaultConfig);
  };

  updateCompanyAdvancedSettings = async (data) => {
    const url = `${this._basicPath}/api/company/advanced-settings`;
    return axios.post(url, data, defaultConfig);
  };

  updateCompanyPermissions = async (data) => {
    const url = `${this._basicPath}/api/company/permissions`;
    return axios.post(url, data, defaultConfig);
  };

  submitKybForm = async (data, stepName) => {
    let query;
    if (stepName) {
      query = queryString.stringify({ stepName });
    }
    const url = `${this._basicPath}/api/company/kyb?${query}`;
    return axios.post(url, data, defaultConfig);
  };

  getNotifications = async () => {
    const url = `${this._basicPath}/api/users/me/notifications`;
    return axios.get(url, defaultConfig);
  };

  deleteNotification = async (id) => {
    const url = `${this._basicPath}/api/users/me/notifications/${id}`;
    return axios.delete(url, defaultConfig);
  };

  deleteNotificationAll = async () => {
    const url = `${this._basicPath}/api/users/me/notifications`;
    return axios.delete(url, defaultConfig);
  };

  getTaxRate = async (id) => {
    const url = `${this._basicPath}/api/tax-rates/${id}`;
    return axios.get(url, defaultConfig);
  };

  getTaxRates = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/tax-rates?${query}`;
    return axios.get(url, defaultConfig);
  };

  createTaxRate = async (values) => {
    const url = `${this._basicPath}/api/tax-rates`;
    return axios.post(url, values, defaultConfig);
  };

  updateTaxRate = async (id, values) => {
    const url = `${this._basicPath}/api/tax-rates/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  deleteTaxRate = async (id) => {
    const url = `${this._basicPath}/api/tax-rates/${id}`;
    return axios.delete(url, defaultConfig);
  };

  importTaxRate = async (data) => {
    const url = `${this._basicPath}/api/tax-rates/import`;
    return axios.post(url, data, defaultConfig);
  };

  getReports = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/reports?${query}`;
    return axios.get(url, defaultConfig);
  };

  getReport = async (id) => {
    const url = `${this._basicPath}/api/reports/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateReport = async (id, values) => {
    const url = `${this._basicPath}/api/reports/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  deleteReport = async (id) => {
    const url = `${this._basicPath}/api/reports/${id}`;
    return axios.delete(url, defaultConfig);
  };

  createExpenseQueue = async (data) => {
    const url = `${this._basicPath}/api/expenses-queue`;
    return axios.post(url, data, defaultConfig);
  };

  getExpensesQueue = async () => {
    const url = `${this._basicPath}/api/expenses-queue`;
    return axios.get(url, defaultConfig);
  };

  deleteExpenseQueue = async (id) => {
    const url = `${this._basicPath}/api/expenses-queue/${id}`;
    return axios.delete(url, defaultConfig);
  };

  createRejectReason = async (data) => {
    const url = `${this._basicPath}/api/reject-reasons`;
    return axios.post(url, data, defaultConfig);
  };

  getRejectReasons = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/reject-reasons?${query}`;
    return axios.get(url, defaultConfig);
  };

  getRejectReasonsAll = async () => {
    const url = `${this._basicPath}/api/reject-reasons/all`;
    return axios.get(url, defaultConfig);
  };

  getRejectReason = async (id) => {
    const url = `${this._basicPath}/api/reject-reasons/${id}`;
    return axios.get(url, defaultConfig);
  };

  updateRejectReason = async (id, values) => {
    const url = `${this._basicPath}/api/reject-reasons/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  deleteRejectReason = async (id) => {
    const url = `${this._basicPath}/api/reject-reasons/${id}`;
    return axios.delete(url, defaultConfig);
  };

  createExpenseReportTemplate = async (values) => {
    const url = `${this._basicPath}/api/expense-report-templates`;
    return axios.post(url, values, defaultConfig);
  };

  getExpenseReportTemplates = async (data) => {
    const query = queryString.stringify(data);
    const url = `${this._basicPath}/api/expense-report-templates?${query}`;
    return axios.get(url, defaultConfig);
  };

  updateExpenseReportTemplates = async (id, values) => {
    const url = `${this._basicPath}/api/expense-report-templates/${id}`;
    return axios.post(url, values, defaultConfig);
  };

  getExpenseReportTemplate = async (id) => {
    const url = `${this._basicPath}/api/expense-report-templates/${id}`;
    return axios.get(url, defaultConfig);
  };

  getExpenseReportTemplateAll = async (id) => {
    const url = `${this._basicPath}/api/expense-report-templates/all`;
    return axios.get(url, defaultConfig);
  };

  deleteExpenseReportTemplate = async (id) => {
    const url = `${this._basicPath}/api/expense-report-templates/${id}`;
    return axios.delete(url, defaultConfig);
  };
}

export default VolveApi;
