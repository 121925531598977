import React, { useEffect, useState, useCallback } from 'react'
import { MenuItem, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { useMe } from '../../myHooks'
import { WALLET_ADDRESS_MUTATION } from '../../queriesAndMutations'
import InputTheme from '../InputTheme'
// import { toaster } from '../../utils'
import { isFiat } from '../../utils/rate'
import { usePublicSettings } from '../../myHooks/useSettings'
import {
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
} from '../../constants/settings'
import { walletLabelTextMapping, getSendingWalletTypeOptions } from '../../constants/walletLabelTextMapping'
import { useTranslation } from 'react-i18next'
import DropdownMaterial from '../DropdownMaterial'
import CardInformation from '../InformationCard'
import LineInformation from '../InformationLine'
import WalletRequest from '../WalletRequest'

const WalletAddress = () => {
  const { t } = useTranslation();
  const {
    data: { me: userData },
    refetch,
  } = useMe();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [updateWalletAddress, { loading, error }] = useMutation(
    WALLET_ADDRESS_MUTATION
  );

  const [showSubmitInfo, setShowSubmitInfo] = useState(false)

  const [walletData, setWalletData] = useState({
    currency: '',
    walletAddress: '',
    walletSendingAddress: '',
    walletType: '',
    sending_wallet: '',
    generatedWallet: '',
    isAddEtherWalletLater: false
  });

  const setField = (field) => ({ target: { value }}) => {
    setWalletData({
      ...walletData,
      [field]: value
    })
  }

  let isWalletBitRanked;
  let isAllowedChangeWallet;
  let isSendingWalletBitRanked;
  let isAllowedChangeSendingWallet;

  try {
    if (userData) {
      if (userData.customer) {
        isWalletBitRanked = userData.customer.ethWalletBitRanked && !userData.customer.ethWalletBitRanked.error;
        isSendingWalletBitRanked = userData.customer.ethSendingWalletBitRanked && !userData.customer.ethSendingWalletBitRanked.error;
        isAllowedChangeWallet = userData.customer.allowChangeReceivingWallet;
        isAllowedChangeSendingWallet = userData.customer.allowChangeSendingWallet;
      } else if (userData.customerCompany) {
        isWalletBitRanked = userData.customerCompany.ethWalletBitRanked && !userData.customerCompany.ethWalletBitRanked.error;
        isSendingWalletBitRanked = userData.customerCompany.ethSendingWalletBitRanked && !userData.customerCompany.ethSendingWalletBitRanked.error;

        isAllowedChangeWallet = userData.customerCompany.allowChangeReceivingWallet;
        isAllowedChangeSendingWallet = userData.customerCompany.allowChangeSendingWallet;
      } else {
        isWalletBitRanked = false;
        isSendingWalletBitRanked = false;
        isAllowedChangeWallet = true;
        isAllowedChangeSendingWallet = true;
      }
    } else {
      isWalletBitRanked = false;
      isSendingWalletBitRanked = false;
      isAllowedChangeWallet = true;
      isAllowedChangeSendingWallet = true;
    }
  } catch (error) {
    isWalletBitRanked = false;
    isSendingWalletBitRanked = false;
    isAllowedChangeWallet = true;
    isAllowedChangeSendingWallet = true;
  }

  const kycAcceptedWallets = useCallback(() => (
    publicSettings.kyc_accepted_wallets
      ? Object.entries(
        publicSettings.kyc_accepted_wallets
      ).filter((ele) => ele[1])
        .map((ele) => ele[0])
      : []
  ), [publicSettings])

  useEffect(() => {
    setWalletData({
      walletAddress:
        userData.wallet_address || userData.eth_receiving_wallet || "",
      walletSendingAddress: userData.wallet_sending_address || "",
      walletType: userData.wallet_type
        ? userData.wallet_type || kycAcceptedWallets()[0]
        : "eth",
      sending_wallet: userData.sending_wallet,
      currency: userData.currency
    });
  }, [kycAcceptedWallets, userData]);

  // const isDataUpToDate = () =>
  //   (userData.eth_receiving_wallet || userData.wallet_address) &&
  //   (userData.eth_receiving_wallet || userData.wallet_address) ===
  //     walletData.walletAddress;

  const submit = async (e) => {
    e.preventDefault();
    try {
      await updateWalletAddress({
        variables: {
          input: {
            wallet_address: walletData.walletAddress,
            wallet_sending_address: walletData.walletSendingAddress,
            wallet_type: walletData.walletType,
            sending_wallet: walletData.sending_wallet,
            currency: walletData.currency
          },
        },
      });

      // toaster.success("Wallet address was successfully saved");
      setShowSubmitInfo(true)
      await refetch();
    } catch (e) {}
  };

  const isFiatKyc = () => {
    return walletData && walletData.currency && isFiat(walletData.currency);
  }

  const setGeneratedWallet = (generatedWallet) => {
    setWalletData({
      ...walletData,
      generatedWallet,
      walletAddress: generatedWallet,
      wallet: !walletData.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : walletData.wallet,
    })
  }

  return (
    <>
      {(publicSettings && publicSettings.token_type !== "NONE"
        ? (
          <div className="refund__content refund__content-link">
            <p>
              { t('In order to receive your') }
              <strong>
                {" "}
                {publicSettings && publicSettings.token_symbol} { t('Tokens') }
              </strong>
              , { t('please select your wallet address and you have to put the address below input box.') }
              {' '}
              <strong>
                { t('You will receive') } {publicSettings && publicSettings.token_symbol}{" "}{ t('tokens to this address after the Token Sale end.') }
              </strong>
            </p>
          </div>
        )
        : null
      )}

      <div className="field w-full">
        <div className="space space__16">{' '}</div>
        <div className="field__label">{t('Currency')}</div>

        <RadioGroup
          row
          value={walletData.currency}
          onChange={setField('currency')}
        >
          {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
            .filter((name) => publicSettings.accepted_currencies[name])
            .map((name) => (
              <FormControlLabel
                key={name}
                label={name.toUpperCase()}
                value={name}
                control={<Radio color="primary" />}
              />
            ))}
        </RadioGroup>
      </div>

      <div className="space space__32">{' '}</div>
      <form onSubmit={submit} className="settings__fieldset w-full">
        {publicSettings && (
          <DropdownMaterial
            label={t('Select a Wallet Address type')}
            propertyName="walletType"
            state={walletData}
            setState={setWalletData}
            error={error}
            loading={loading}
            select
          >
            {kycAcceptedWallets()
              .map((wallet) => (
                <MenuItem key={wallet} value={(wallet || "")} className="kyc__list-dropdown">
                  {walletLabelTextMapping[`${wallet || ""}`]}
                </MenuItem>
              ))}
          </DropdownMaterial>
        )}

        {publicSettings && (publicSettings.kyc_show_receiving_wallet || publicSettings.kyc_require_receiving_wallet) && (
          <>
            {!publicSettings.force_generate_wallet && (
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="walletAddress"
                label={t('My Receiving Address or ID')}
                state={walletData}
                setState={setWalletData}
                error={error}
                loading={loading}
                disabled={isWalletBitRanked && !isAllowedChangeWallet}
              />
            )}

            {(!isWalletBitRanked || isAllowedChangeWallet) && (
              <WalletRequest
                type={!walletData.walletType && kycAcceptedWallets().includes('eth') ? 'eth' : walletData.walletType}
                generatedWallet={walletData.generatedWallet}
                onComplete={setGeneratedWallet}
              />
            )}
            <br />
          </>
        )}

        {!!walletData.currency && !isFiatKyc() && (
          <>
            <DropdownMaterial
              label={ t('Select sending wallet') }
              state={walletData}
              setState={setWalletData}
              propertyName="sending_wallet"
              isRequired={true}
            >
              {getSendingWalletTypeOptions(walletData.currency).map((option) => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </DropdownMaterial>

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"

              propertyName="walletSendingAddress"
              label={t('My Sending Address or ID')}
              state={walletData}
              setState={setWalletData}
              error={error}
              loading={loading}
              disabled={(isSendingWalletBitRanked && !isAllowedChangeSendingWallet)}
            />
          </>

        )}

        <CardInformation
          color="card__information-primary"
          title={t('Information')}
        >
          <>
            {!isAllowedChangeWallet &&
              (isFiatKyc() || (!isAllowedChangeSendingWallet)) && (
                <p>
                  { t('If you want to change the wallet address please get in touch with us') }
                  {" "}
                  {publicSettings && publicSettings.company && publicSettings.company.email}
                  .
                </p>
              )
            }

            <p> { t('Please ensure that you have your private key and/or mnemonic phrase available and safely backed-up, as you will not be able to access your tokens without this and could permanently lose your assets.') } </p>
          </>
        </CardInformation>
        <div className="space space__32">{' '}</div>

        <div className="field">
          <button
            type="submit"
            className="button button-stroke"
            disabled={
              isWalletBitRanked && !isAllowedChangeWallet &&
              (isFiatKyc() || (isSendingWalletBitRanked && !isAllowedChangeSendingWallet))
            }
          >
            { t('Update my address') }
          </button>
          {showSubmitInfo && (
            <>
              <div className="space space__32">{' '}</div>
              <LineInformation
                informationColor="box__information-green"
                icon="check-all"
                text={ t('Wallet address is up-to-date') }
              />
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default WalletAddress;
