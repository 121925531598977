import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../NotSupportViewer'

import AppSidebarAndHeader from './Sidebar/Sidebar'

const PageDashboardSubApp = ({
  children,
  // from HOCs
  location,
}) => {
  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className={`page page-subapp ${location.pathname.indexOf("/admin") === 0 ? "page-admin" : ""}`}>
      <AppSidebarAndHeader />

      <div className="page__inner">
        <div className="page__container">
          {children}
        </div>
      </div>
    </div>
  )
}

PageDashboardSubApp.propTypes = {
  // from HOCs
  location: PropTypes.object
}

export default compose(withRouter)(PageDashboardSubApp)
