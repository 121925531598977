import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import reduce from 'lodash/reduce'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import InputTheme from '../../../components/InputTheme'
import { isFeatureEnabled, toaster, isProduction } from "../../../utils";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  GET_AVAILABLE_FEATURES,
} from "../../../queriesAndMutations";
import LockedFeatureIcon from '../../../components/LockedFeatureIcon';
import {
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
} from '@material-ui/core'
import { CREATE_EVOLVE_DOMAIN, VERIFY_EVOLVE_DOMAIN } from "../../../queriesAndMutations/settingsMutation"

const SETS = {
  enable_evolve: {
    default: true,
  },
  enable_monerium: {
    default: false,
  },
  monerium_client_id: {
    default: "",
  },
  monerium_client_secret: {
    default: "",
  },
  enable_fiat_republic: {
    default: false,
  },
  fiat_republic_client_id: {
    default: "",
  },
  fiat_republic_client_secret: {
    default: "",
  },
  evolve_host: {
    default: ""
  },
  sub_domain: {
    default: isProduction ? '-evolve.investhub.io' : '-dev-evolve.investhub.io'
  },
  is_white_label: {
    default: 'true'
  },
  is_white_skin: {
    default: "true"
  }
};

const AdminSettingsIco = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation();
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const [verifyDomain, verifyDomainData] = useMutation(VERIFY_EVOLVE_DOMAIN);
  const [createDomain, createDomainData] = useMutation(CREATE_EVOLVE_DOMAIN);
  const [useCustomHost, setUseCustomtHost] = useState(false)
  const [showButton, setShowButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        if (
          key === "prospectus_date" ||
          key === "issuing_guidelines" ||
          key === "token_sale_agreement_date" ||
          key === "issuing_guidelines_date" ||
          key === "memorandum_date"
        ) {
          // eslint-disable-next-line no-param-reassign
          memo[key] =
            (settings[key] && moment(settings[key]).format("YYYY-MM-DD")) ||
            moment(SETS[key].default).format("YYYY-MM-DD");
          return memo;
        }
        if (key === "liquidation_token_decimal") {
          // eslint-disable-next-line no-param-reassign
          memo[key] = settings[key] === undefined ? SETS[key].default : `${settings[key]}`;
          return memo;
        }
        if (key === "evolve_host" || key === "sub_domain" || key === "is_white_label" || key === "is_white_skin") {
          // eslint-disable-next-line no-param-reassign
          memo[key] = !settings[key] ? SETS[key].default : `${settings[key]}`;
          return memo;
        }

        // eslint-disable-next-line no-param-reassign
        memo[key] = settings[key] === undefined ? SETS[key].default : settings[key];
        return memo;
      },
      {}
    ),
  });

  const onChangeCheckbox = (name, state, setState) => (event) => {
    const currentState = { ...state, [name]: event.target.checked }
    if (name === 'enable_monerium' && !event.target.checked) {
      currentState['monerium_client_id'] = '';
      currentState['monerium_client_secret'] = '';
    }

    setState(currentState);
  };

  const onUpdateBtnClick = () => {
    const newValues = {
      ...values,
    };

    onUpdate(newValues);
  };

  const onVerifyEvolveDomain = async () => {
    try {
      setIsLoading(true);
      const domain = useCustomHost ? values.evolve_host : values.evolve_host + values.sub_domain
      if (!useCustomHost && !values.evolve_host.match(/^[a-z0-9\-_]+$/i)) {
        toaster.error(`Domain ${domain} don't allow to contain special characters`)
      }
      const { data: isValid } = await verifyDomain({
        variables: {
          input: {
            evolve_host: domain
          },
        },
      });
      if (isValid.verifyEvolveDomain) {
        toaster.success(`Domain ${domain} is available`)
      } else {
        toaster.error(`Domain ${domain} is not available`)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  const onCreateEvolveDomain = async () => {
    try {
      setIsLoading(true);
      const domain = useCustomHost ? values.evolve_host : values.evolve_host + values.sub_domain
      const { data: isValid } = await createDomain({
        variables: {
          input: {
            evolve_host: domain
          },
        },
      });
      if (isValid.createEvolveDomain) {
        toaster.success(`Creating domain ${domain} successfully`)
      } else {
        toaster.error(`Creating domain ${domain} failed`)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  const renderMoneriumSetting = () => {
    return (
      <div className="settings__item">
        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Evolve')}
              {' '}
              {!isFeatureEnabled('EVOLVE', availableFeatures) && (
                <LockedFeatureIcon />
              )}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_evolve}
                onChange={({ target: { value } }) => {
                  console.log(value);
                  console.log(true);
                  setValues({ ...values, enable_evolve: value === 'true' })
                }}
              >
                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('EVOLVE', availableFeatures)}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('EVOLVE', availableFeatures)}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="title-primary settings__title">
          {t('Monerium Settings')}
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              {t('Enable Monerium')}
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_monerium}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_monerium",
                  values,
                  setValues
                )}
                value={values.enable_monerium}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="monerium_client_id"
              label="Monerium Client ID"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed || !values.enable_monerium}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="monerium_client_secret"
              label="Monerium Client Secret"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed || !values.enable_monerium}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFiatRepublicSetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Fiat Republic Settings')}
        </div>

        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">
              {t('Enable Fiat Republic')}
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_fiat_republic}
                disabled={!editingAllowed}
                onChange={onChangeCheckbox(
                  "enable_fiat_republic",
                  values,
                  setValues
                )}
                value={values.enable_fiat_republic}
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="fiat_republic_client_id"
              label="Fiat Republic Client ID"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="fiat_republic_client_secret"
              label="Fiat Republic Client Secret"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderEvolveDomainSetting = () => {
    return isLoading ? <div className="settings__item">
      <CircularProgress />
    </div> : values?.is_white_label === 'true' && (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Evolve Domain Settings')}
        </div>
        <div className="create__info">
          {t('Do you want to have your own subdomain?')}
        </div>

        <label className="switch">
          <input
            type="checkbox"
            className="switch__input"
            checked={useCustomHost}
            disabled={!editingAllowed}
            onChange={(e) => setUseCustomtHost(e.target.checked)}
            value={values.enable_fiat_republic}
          />

          <div className="switch__inner">
            <div className="switch__box">{' '}</div>
          </div>
        </label>
        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="evolve_host"

              label="Evolve Domain"
              state={values}
              setState={setValues}
              disabled={!showButton}
              loading={loading}
            />
            {!useCustomHost ?
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="sub_domain"
                label="Sub Domain"
                state={values}
                disabled={!showButton}
                loading={loading}
                setState={setValues}
              /> : <></>}
          </div>
          {showButton && <>
            <button
              className="button settings__button"
              onClick={onVerifyEvolveDomain}
            >Verify your Evolve domain</button>
            <button
              className="button settings__button"
              onClick={onCreateEvolveDomain}
            >Create Evolve domain</button>
          </>}
          <Box>
            {t('Please setup a CNAME DNS record point to the address app.investhub.io (without proxy enabled. We recommend to use CloudFlare).')}
            <p>{t('If you wish to set up your subdomain, please make sure to follow the video.')}</p>
          </Box>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.youtube.com/embed/6eUCbY5oIuU"
              title={t('YouTube video player')}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderEvolveWhiteLabelSetting = () => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Evolve Set Up Settings')}
        </div>
        <div className="create__info">
          {t('Is white label?')}
        </div>
        <RadioGroup
          row
          value={(values || {}).is_white_label}
          onChange={({ target: { value } }) => {
            setValues({ ...values, is_white_label: value })
          }}
        >
          <FormControlLabel
            label={t('Yes')}
            value="true"
            control={<Radio />}
          />
          <FormControlLabel
            label={t('No')}
            value="false"
            control={<Radio />}
          />
        </RadioGroup>
      </div>
    );
  };

  const renderEvolveSkinSetting = () => {
    return values?.is_white_label === 'true' && (
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Evolve Skin Settings')}
        </div>
        <div className="create__info">
          {t('What skin you want to choose?')}
        </div>
        <RadioGroup
          row
          value={(values || {}).is_white_skin}
          onChange={({ target: { value } }) => {
            setValues({ ...values, is_white_skin: value })
          }}
        >
          <FormControlLabel
            label={t('White Skin')}
            value="true"
            control={<Radio />}
          />
          <FormControlLabel
            label={t('Dark Skin')}
            value="false"
            control={<Radio />}
          />
        </RadioGroup>
      </div>
    );
  };

  useEffect(() => {
    if (settings?.evolve_host?.length) {
      setShowButton(false)
      if (settings?.sub_domain === '' || !settings?.sub_domain) {
        setUseCustomtHost(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.evolve_host, settings?.sub_domain])

  useEffect(() => {
    if (useCustomHost) {
      values['sub_domain'] = '';
    } else {
      values['sub_domain'] = isProduction ? '-evolve.investhub.io' : '-dev-evolve.investhub.io';
    }
  }, [useCustomHost, values])

  return (
    <>
      {renderMoneriumSetting()}
      {renderFiatRepublicSetting()}
      {renderEvolveWhiteLabelSetting()}
      {renderEvolveDomainSetting()}
      {renderEvolveSkinSetting()}
      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
          className="button settings__button"
        >
          {t('Update')}
        </button>
      )}
    </>
  );
};

AdminSettingsIco.propTypes = {
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsIco;
