import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import classNames from 'classnames'
import {
  CircularProgress,
  ClickAwayListener,
  Dialog,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreIcon from '@material-ui/icons/Restore'
import PersonIcon from '@material-ui/icons/Person'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Icon from '../../../components/Icon'
import AdminExportUsers from '../../../components/AdminCsvDialogs/AdminExportUsers'
import ModalInnerDialog from '../../../components/ModalInnerMaterialDialog'
import DropdownMaterial from '../../../components/DropdownMaterial'
import InputThemeField from '../../../components/InputThemeField';

import { useTranslation } from 'react-i18next'
import {
  ADMIN_USER_LIST,
  CHANGE_USER_RIGHTS,
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_VIDEO_IDENTIFICATION,
  GET_AVAILABLE_FEATURES,
  GET_REFERRAL_CODE_LIST,
  GRANT_CONTROLLER_ROLE,
} from '../../../queriesAndMutations'
import {
  USER_RIGHT_TYPES,
  USER_STATUS_TYPES,
  USER_KYC_STATUS_TYPES,
  USER_VIDEO_IDENT_STATUS,
} from '../../../constants/user'
import {
  hasUserEnoughRights,
  isFeatureEnabled,
  toaster,
  getQueryVars,
  getTextAvatarUrl,
} from '../../../utils'
import { useMe } from '../../../myHooks'
import { usePublicSettings } from '../../../myHooks/useSettings'
import TablePaginationActions from '../../../components/TablePagination'

const useStyles = makeStyles(() => ({
  table: {
    margin: "0",
  },
  tableHeader: {
    "& .MuiTableCell-head": {
      color: "#007bff",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  tableRow: {
    minHeight: "75px",
    "& .MuiTableCell-body": {
      padding: "10px 3px",
    },
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  verifiedStatus: {
    "&>div": {
      minHeight: "75px",
      display: "flex",
      flexWrap: "wrap",
      "&>div": {
        display: "flex",
        alignItems: "center",
        margin: "6px",
        "&>svg": {
          marginRight: "3px",
        },
      },
    },
  },
  activeStatusBadge: {
    padding: "6px 12px",
    border: "1px solid #009f65",
    borderRadius: "8%",
  },
  disabledStatusBadge: {
    padding: "6px 12px",
    border: "1px solid #ea0b0b",
    borderRadius: "8%",
  },
  nameTableCell: {
    display: "flex",
    alignItems: "center",
  },
  actionBtnPaper: {
    zIndex: 10,
    position: "absolute",
    "&>div": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      "&>svg": {
        marginRight: "5px",
      },
      padding: "8px 10px",
    },
  },
  actionMenuOption: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&>svg": {
      marginLeft: "5px",
    },
  },
  avatar: {
    backgroundColor: " #007bff",
    borderRadius: "50%",
    margin: "8px",
    width: "36px",
    height: "36px",
  },
  successIcon: {
    color: "#009f65",
  },
  pendingIcon: {
    color: "#ffc100",
  },
  errorIcon: {
    color: "#f00",
  },
  idleIcon: {
    color: "#000",
    opacity: 0.6,
  },
}));

const UserListActions = ({
  me,
  user,
  loading,
  onChangeUserStatus,
  onChangeUserRights,
  onChangeUserVideoIdent,
  showActivationLinkDialog,
  availableFeatures,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedUserId, setActionBtnOpen] = React.useState(null);
  const [isReferalPartnerCodeDialogOpen, setIsReferalPartnerCodeDialogOpen] = React.useState(null);
  const [selectedReferalPartnerCode, setSelectedReferalPartnerCode] = React.useState({ referralCode: user.referral_partner_code ? user.referral_partner_code.split(',') : [] });
  const { data: { getReferralCodeList: referralCodeList } = {}, loading: loadingReferralCodeList } = useQuery(GET_REFERRAL_CODE_LIST);
  const [grantControllerRole, grantControllerRoleStatus] = useMutation(GRANT_CONTROLLER_ROLE)
  const [newReferralCode, setNewReferralCode] = useState('');
  const [inactiveComment, setInactiveComment] = useState('');
  const [isInactiveDialogOpen, setIsInactiveDialogOpen] = React.useState(false);

  const onActionBtnClick = (clickedUserId) => () => {
    if (selectedUserId === clickedUserId) {
      setActionBtnOpen(null);
    } else {
      setActionBtnOpen(clickedUserId);
    }
  };

  const onActionBtnClickAway = () => {
    setActionBtnOpen(false);
  };

  const onGrantControllerRole = () => {
    console.log("user", user)

    grantControllerRole({
      variables: {
        input: {
          controller_address: user.wallet_address
        }
      },
    }).then((response) => {
      if (response.data.grantControllerRole.success) {
        toaster.success(t('Grant Controller Role successfully'))
      } else {
        toaster.error(response.data.grantControllerRole.message || t('Grant Controller Role failed'))
      }
    }).catch((error) => {
      console.log(error)
      toaster.error(t('Grant Controller Role failed'))
    })
  }

  const renderChangeRightsBtn = () => {
    if (!user) {
      return false;
    }

    return (
      <>
        {
          user.rights === USER_RIGHT_TYPES.ADMIN ? (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMMON)}>
              <PersonIcon />
              { t('Revoke admin rights') }
            </button>
          ) : (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.ADMIN)}>
              <PermIdentityIcon className="icon" />
              { t('Make admin') }
            </button>
          )
        }
        {
          user.rights === USER_RIGHT_TYPES.COMPLIANCE_OFFICER ? (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMMON)}>
              <PersonIcon className="icon" />
              { t('Revoke compliance officer rights') }
            </button>
          ) : (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMPLIANCE_OFFICER)}>
              <PermIdentityIcon className="icon" />
              { t('Make compliance officer') }
            </button>
          )
        }
        {
          user.rights === USER_RIGHT_TYPES.REFERRAL_PARTNER ? (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMMON)}>
              <PersonIcon className="icon" />
              { t('Revoke referral partner rights') }
            </button>
          ) : (
            <>
              <button className="actions__option text__left nowrap" onClick={() => setIsReferalPartnerCodeDialogOpen(true)}>
                <PermIdentityIcon className="icon" />
                { t('Make referral partner') }
              </button>

              <Dialog
                open={isReferalPartnerCodeDialogOpen}
                onClose={() => {
                  setIsReferalPartnerCodeDialogOpen(false);
                }}
              >
                <ModalInnerDialog
                  onClickClose={() => { setIsReferalPartnerCodeDialogOpen(false); }}
                  modalTitle={t('Select referral code')}
                  modalTitleClassColor="title-primary"
                >
                  <div>
                    {loadingReferralCodeList && (
                      <CircularProgress />
                    )}

                    {!loadingReferralCodeList && (
                      <>
                        <DropdownMaterial
                          propertyName="referralCode"
                          label="Select a referral code"
                          state={selectedReferalPartnerCode}
                          setState={setSelectedReferalPartnerCode}
                          SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: (selected) => {
                              if (selected && selected.length > 0) {
                                return selected.join(', ')
                              }
                              return (
                                <span className="text__gray">
                                  {t('-- Select referral codes --')}
                                </span>
                              )
                            }
                          }}
                          >
                          {referralCodeList.map(referralCode => (
                            <MenuItem key={referralCode} value={referralCode}>
                              <Checkbox
                                checked={(selectedReferalPartnerCode.referralCode || []).indexOf(referralCode) > -1}
                                color="primary"
                              />
                              <ListItemText primary={referralCode} />
                            </MenuItem>
                          ))}
                        </DropdownMaterial>

                        <br />
                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          label="Add new referral code"
                          value={newReferralCode}
                          onChange={({ target: { value } }) => {
                            setNewReferralCode(value)
                          }}
                          endInput={(
                            <button className="button" onClick={() => {
                              if (!selectedReferalPartnerCode.referralCode.includes(newReferralCode)) {
                                setSelectedReferalPartnerCode({ referralCode: [...selectedReferalPartnerCode.referralCode, newReferralCode]})
                              }
                              setNewReferralCode('')
                            }}>
                              {t('Add')}
                            </button>
                          )}
                        />
                      </>
                    )}

                    <br />

                    <button className="button" onClick={() => {
                      onChangeUserRights(user.id, USER_RIGHT_TYPES.REFERRAL_PARTNER, {referralCode: (selectedReferalPartnerCode.referralCode || []).join(',')})()
                      setIsReferalPartnerCodeDialogOpen(false)
                    }}>
                      Set Referral Partner
                    </button>
                  </div>
                </ModalInnerDialog>
              </Dialog>
            </>
          )
        }
        {
          user.rights === USER_RIGHT_TYPES.ACCOUNTING ? (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMMON)}>
              <PersonIcon className="icon" />
              { t('Revoke Accounting rights') }
            </button>
          ) : (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.ACCOUNTING)}>
              <PermIdentityIcon className="icon" />
              { t('Make Accounting rights') }
            </button>
          )
        }
        {
          grantControllerRoleStatus.loading ? (
            <CircularProgress />
          ) : (
            <button className="actions__option text__left nowrap" onClick={onGrantControllerRole}>
              <PermIdentityIcon className="icon" />
              { t('Grant contract controller role') }
              </button>
          )
        }
        {isFeatureEnabled('CUSTODIAN_ROLE', availableFeatures) && (
          <>
            {
              user.rights === USER_RIGHT_TYPES.CUSTODIAN ? (
                <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMMON)}>
                  <PersonIcon className="icon" />
                  { t('Revoke custodian') }
                </button>
              ) : (
                <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.CUSTODIAN)}>
                  <PermIdentityIcon className="icon" />
                  { t('Make custodian') }
                </button>
              )
            }
          </>
        )}
        {
          user.rights === USER_RIGHT_TYPES.AUDITOR ? (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.COMMON)}>
              <PersonIcon className="icon" />
              { t('Revoke auditor') }
            </button>
          ) : (
            <button className="actions__option text__left nowrap" onClick={onChangeUserRights(user.id, USER_RIGHT_TYPES.AUDITOR)}>
              <PermIdentityIcon className="icon" />
              { t('Make auditor') }
            </button>
          )
        }
      </>
    );
  };

  const renderChangeStatusBtn = () => {
    if (!user) {
      return false;
    }

    switch (user.status) {
      case USER_STATUS_TYPES.ACTIVE:
        return (
          <>
            <button onClick={() => setIsInactiveDialogOpen(true)} className="actions__option text__left nowrap">
              <DeleteIcon className="icon" /> { t('Inactive user') }
            </button>

            <button onClick={onChangeUserStatus(user.id, USER_STATUS_TYPES.DELETED)} className="actions__option text__left nowrap">
              <DeleteIcon className="icon" /> { t('Delete user') }
            </button>
          </>
        );
      case USER_STATUS_TYPES.DELETED:
        return (
          <>
            <button onClick={onChangeUserStatus(user.id, USER_STATUS_TYPES.INACTIVE)} className="actions__option text__left nowrap">
              <DeleteIcon className="icon" /> { t('Inactive user') }
            </button>

            <button onClick={onChangeUserStatus(user.id, USER_STATUS_TYPES.ACTIVE)} className="actions__option text__left nowrap">
              <RestoreIcon className="icon" /> { t('Restore user') }
            </button>
          </>
        );
      case USER_STATUS_TYPES.INACTIVE:
        return false;
      default:
    }

    return <CircularProgress />;
  };

  const renderChangeVideoIdentBtn = () => {
    if (
      !user ||
      user.video_ident_status === USER_VIDEO_IDENT_STATUS.NOT_NEEDED
    ) {
      return false;
    }

    if (user.video_ident_status === USER_VIDEO_IDENT_STATUS.PENDING) {
      return (
        <>
          <button
            onClick={onChangeUserVideoIdent(
              user.id,
              USER_VIDEO_IDENT_STATUS.PASSED
            )}
            className="actions__option text__left nowrap"
          >
            <DoneIcon className="icon" /> {t('Liveness check approve')}
          </button>
          <button
            onClick={onChangeUserVideoIdent(
              user.id,
              USER_VIDEO_IDENT_STATUS.DENIED
            )}
            className="actions__option text__left nowrap"
          >
            <ClearIcon className="icon" /> { t('Liveness check deny') }
          </button>
        </>
      );
    }

    if (user.video_ident_status === USER_VIDEO_IDENT_STATUS.DENIED) {
      return (
        <button
          onClick={onChangeUserVideoIdent(
            user.id,
            USER_VIDEO_IDENT_STATUS.PASSED
          )}
          className="actions__option text__left nowrap"
        >
          <DoneIcon className="icon" /> {t('Liveness check approve')}
        </button>
      );
    }

    return (
      user.video_ident_status === USER_VIDEO_IDENT_STATUS.PASSED && (
        <button
          className="actions__option text__left nowrap"
          onClick={onChangeUserVideoIdent(
            user.id,
            USER_VIDEO_IDENT_STATUS.DENIED
          )}
        >
          <ClearIcon className="icon" /> { t('Liveness check deny') }
        </button>
      )
    );
  };

  const renderCopyActivationLinkBtn = () => {
    if (user.token) {
      return (
        <button
          onClick={() => {
            setActionBtnOpen(false)
            showActivationLinkDialog(`${window.location.origin}/register/confirm?token=${user.token}`)
          }}
          className="actions__option text__left nowrap"
        >
          <VpnKeyIcon className="icon" />
          { t('Copy activation link') }
        </button>
      )
    }

    return <></>
  }

  return (
    <ClickAwayListener onClickAway={onActionBtnClickAway}>
      <div className={`actions actions__kyc ${!!selectedUserId || selectedUserId === 0 ? "active" : ""}`}>
        {loading ? (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <button onClick={onActionBtnClick(user.id)} className="actions__button">
            <Icon
              className={classNames('more-horizontal')}
              name='more-horizontal'
              size="24"
            />{" "}
          </button>
        )}
        <Dialog
          open={isInactiveDialogOpen}
          onClose={() => {
            setIsInactiveDialogOpen(false);
          }}
        >
          <ModalInnerDialog
            onClickClose={() => { setIsInactiveDialogOpen(false); }}
            modalTitle={t('Inactive User')}
            modalTitleClassColor="title-primary"
          >
            <div>
              <InputThemeField
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                label="Add comment"
                value={inactiveComment}
                onChange={({ target: { value } }) => {
                  setInactiveComment(value)
                }}
              />

              <button className="button" onClick={() => {
                onChangeUserStatus(user.id, USER_STATUS_TYPES.INACTIVE, inactiveComment)()
                setIsInactiveDialogOpen(false)
              }}>
                Submit
              </button>
            </div>
          </ModalInnerDialog>
        </Dialog>
        <div className="actions__body actions__body_l">
          {user.video_ident_id && renderChangeVideoIdentBtn()}
          {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.GENERAL_ADMIN) &&
            renderChangeRightsBtn()}
          {renderCopyActivationLinkBtn()}
          {renderChangeStatusBtn()}
        </div>
      </div>
    </ClickAwayListener>
  );
};

UserListActions.propTypes = {
  user: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onChangeUserStatus: PropTypes.func.isRequired,
  onChangeUserRights: PropTypes.func.isRequired,
  onChangeUserVideoIdent: PropTypes.func.isRequired,
  showActivationLinkDialog: PropTypes.func.isRequired,
  availableFeatures: PropTypes.array.isRequired,
};

const AdminUserList = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const { t } = useTranslation();
  const classes = useStyles();
  const queryParam = getQueryVars()
  const [reqOptions, setReqOptions] = useState({
    email: queryParam.search || "",
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [activationLink, setActivationLink] = React.useState('');
  const [isShowActivationLinkDialog, setIsShowActivationLinkDialog] = React.useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const {
    data: { me },
  } = useMe();

  const { data, error, refetch } = useQuery(ADMIN_USER_LIST, {
    variables: {
      input: {
        ...reqOptions,
        page,
        pageSize,
      },
    },
  });
  const [changeUserStatus, changeUserStatusData] =
    useMutation(CHANGE_USER_STATUS);
  const [changeUserRights, changeUserRightsData] =
    useMutation(CHANGE_USER_RIGHTS);
  const [changeUserVideoIdent, changeUserVideoIdentData] = useMutation(
    CHANGE_USER_STATUS_VIDEO_IDENTIFICATION
  );

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  const onChangeReqOptions = (name) => (event) => {
    switch (name) {
      case "email":
        if (event.target.value.length >= 50) {
          return false;
        }
        break;
      default:
    }
    setPage(0);
    return setReqOptions({ ...reqOptions, [name]: event.target.value });
  };

  const onChangeUserStatus = (userId, status, comment) => () => {
    changeUserStatus({ variables: { input: { id: +userId, status, comment } } }).then(
      () => refetch()
    ).catch(() => {});
  };

  const onChangeUserRights = (userId, rights, extraData) => () => {
    changeUserRights({ variables: { input: { id: +userId, rights, extraData } } }).then(
      () => refetch()
    ).catch((e) => { console.log(e) });
  };

  const onChangeUserVideoIdent = (userId, videoIdentificationStatus) => () => {
    changeUserVideoIdent({
      variables: { id: +userId, videoIdentificationStatus },
    }).then(() => refetch()).catch(() => {});
  };

  const renderVerifiedStatus = (user) => (
    <>
      <>
        {user.is_active ? (
          <div className="status-green-dark products__status">
            { t('Email') }
          </div>
        ) : (
          <div className="status-red-dark products__status">
            { t('Email') }
          </div>
        )}
      </>

      {(user.rights === USER_RIGHT_TYPES.ADMIN ||
        user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN) && (
        <div className="status-green-dark products__status">
          { t('Admin') }
        </div>
      )}
      {user.rights === USER_RIGHT_TYPES.COMPLIANCE_OFFICER && (
        <div className="status-green-dark products__status">
          { t('Compliance Officer') }
        </div>
      )}
      {user.rights === USER_RIGHT_TYPES.REFERRAL_PARTNER && (
        <div className="status-green-dark products__status">
          { t('Referral Partner') }:{user.referral_partner_code}
        </div>
      )}
      {user.rights === USER_RIGHT_TYPES.CUSTODIAN && (
        <div className="status-green-dark products__status">
          { t('Custodian') }
        </div>
      )}
      {user.rights === USER_RIGHT_TYPES.AUDITOR && (
        <div className="status-green-dark products__status">
          { t('Auditor') }
        </div>
      )}
      {user.rights === USER_RIGHT_TYPES.ACCOUNTING && (
        <div className="status-green-dark products__status">
          { t('Accounting') }
        </div>
      )}
      <>
        {user.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
          <div className="status-pending-dark products__status">
            { t('KYC') }
          </div>
        )}
        {user.kyc_status === USER_KYC_STATUS_TYPES.PENDING && (
          <div className="status-pending-dark products__status">
            { t('KYC') }
          </div>
        )}
        {user.kyc_status === USER_KYC_STATUS_TYPES.PASSED && (
          <div className="status-green-dark products__status">
            { t('KYC') }
          </div>
        )}
        {user.kyc_status === USER_KYC_STATUS_TYPES.REJECTED && (
          <div className="status-red-dark products__status">
            { t('KYC') }
          </div>
        )}
      </>
      {user.video_ident_status !== USER_VIDEO_IDENT_STATUS.NOT_NEEDED && (
        <>
          {user.video_ident_status === USER_VIDEO_IDENT_STATUS.PASSED && (
            <div className="status-green-dark products__status">
              {t('Liveness check')}
            </div>
          )}
          {user.video_ident_status === USER_VIDEO_IDENT_STATUS.DENIED && (
            <div className="status-red-dark products__status">
              {t('Liveness check')}
            </div>
          )}
          {user.video_ident_status === USER_VIDEO_IDENT_STATUS.NOT_STARTED && (
            <div className="status-pending-dark products__status">
              {t('Liveness check')}
            </div>
          )}
          {user.video_ident_status === USER_VIDEO_IDENT_STATUS.PENDING && (
            <div className="status-pending-dark products__status">
              {t('Liveness check')}
            </div>
          )}
        </>
      )}
      <>
        {user.is_2fa_enabled && (
          <div className="status-green-dark products__status">
            { t('2FA') }
          </div>
        )}
        {!user.is_2fa_enabled && (
          <div className="status-red-dark products__status">
            { t('2FA') }
          </div>
        )}
      </>
    </>
  );

  const renderUserStatus = (user) => {
    switch (user.status) {
      case USER_STATUS_TYPES.ACTIVE:
        return <div className="status-green-dark products__status">{user.status}</div>;
      case USER_STATUS_TYPES.BLOCKED:
      case USER_STATUS_TYPES.DELETED:
        return (
          <div className="status-red-dark products__status">{user.status}</div>
        );
      case USER_STATUS_TYPES.INACTIVE:
        return (
          <div className="status-gray-dark products__status">{user.status}</div>
        );
      default:
        return false;
    }
  };

  const showActivationLinkDialog = (url) => {
    setActivationLink(url)
    setIsShowActivationLinkDialog(true)
  }

  const hideActivationLinkDialog = () => {
    setActivationLink('')
    setIsShowActivationLinkDialog(false)
  }

  const copyActivationLink = () => {
    const input = document.getElementById('activation_link')
    input.select()
    input.setSelectionRange(0, activationLink.length)
    navigator.clipboard.writeText(input.value).then(() => {
      toaster.success('Copied to clipboard')
    })
  }

  if (error) {
    return <div>Some error</div>;
  }

  if (data && data.userList) {
    const emptyRows = pageSize - Math.min(pageSize, data.userList.meta.total - page * pageSize);

    return (
      <div className="transactions card">
        <div className="products__head">
          <div className="title-primary products__title">{ t('Users')}</div>

          <form className="form">
            <input
              type="search"
              className="form__input"
              placeholder={t('Type in search')}
              value={reqOptions.email}
              onChange={onChangeReqOptions("email")}
            />
            <button className="form__button">
              <Icon
                className={classNames('searchh')}
                name='searchh'
                size="24"
              />{" "}
            </button>
          </form>

          {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.ADMIN) && (
            <div className="products__nav products__nav_show">
              <button
                className="button-small card-button"
                onClick={() => setDialogOpen(true)}
              >
                { t('Download as csv') }
              </button>
            </div>
          )}
        </div>

        <div className="transactions__wrapper">
          <div className="transactions__table products__table-userlist">
            <div className="transactions__row">
              <div className="transactions__col">
                { t('Name') }
              </div>

              <div className="transactions__col">
                { t('Email') }
              </div>

              <div className="transactions__col">
                { t('Tokens') }
              </div>

              <div className="transactions__col">
                { t('Status') }
              </div>

              {publicSettings.show_source_signup && (
                <div className="transactions__col">
                  { t('Source') }
                </div>
              )}

              <div className="transactions__col">
                { t('Account Status') }
              </div>

              <div className="transactions__col">
                { t('Last login') }
              </div>

              <div className="transactions__col">
                {' '}
              </div>
            </div>

            {data.userList.objects.map((user) => (
              <div className="transactions__row" key={user.id}>
                <div className="transactions__col">
                  <div className="transactions__label">
                    {t('Name')}
                  </div>

                  <div className="customer__item customer__item_normal customer__item-transaction">
                    <div className="customer__avatar status-green d__flex flex__justify_center flex__align_center">
                      <img
                        src={getTextAvatarUrl(`${user.first_name} ${user.last_name}`)}
                        alt={`${user.first_name} ${user.last_name} avatar`} />
                    </div>

                    <div className="customer__description">
                      <div className="header__subtitle">
                        {`${user.first_name} ${user.last_name}`}
                      </div>
                    </div>

                  </div>
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Email')}</div>

                  {user.email}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Tokens')}</div>
                  {user.sum_invest_token}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Status')}</div>
                  {renderVerifiedStatus(user)}
                </div>

                {publicSettings.show_source_signup && (
                  <div className="transactions__col vert__middle">
                    <div className="transactions__label">{t('Source')}</div>
                    {user.customer && user.customer.referal_code
                      ? user.customer.referal_code
                      : user.is_gtoswiss
                      ? "GTOwiss.com"
                      : user.is_internal_sales
                      ? "InternalSales"
                      : (user.referral_code || "Organic")}
                  </div>
                )}

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Account Status')}</div>
                  {renderUserStatus(user)}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Last login')}</div>
                  {user.lastLogin ? (new Date(+user.lastLogin).toDateString()) : 'Never'}
                </div>

                <div className="transactions__col transactions__col-action vert__middle">
                  <UserListActions
                    me={me}
                    user={user}
                    onChangeUserRights={onChangeUserRights}
                    onChangeUserStatus={onChangeUserStatus}
                    onChangeUserVideoIdent={onChangeUserVideoIdent}
                    loading={
                      changeUserStatusData.loading ||
                      changeUserRightsData.loading ||
                      changeUserVideoIdentData.loading
                    }
                    showActivationLinkDialog={showActivationLinkDialog}
                    availableFeatures={availableFeatures}
                  />
                </div>
              </div>
            ))}

            {emptyRows > 0 && (
              <div className="transactions__row">
                <div className="transactions__col transactions__col-full">
                  {' '}
                </div>
              </div>
            )}
          </div>
        </div>

        <TablePaginationActions
          count={data.userList.meta.total}
          page={page}
          rowsPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRows={onChangePageSize}
          rowsPerPageOptions={[1, 5, 10, 25]}
        />
        <Dialog
          open={isShowActivationLinkDialog}
          onClose={hideActivationLinkDialog}
        >
          <div className="popup js-popup animation visible">
            <div onClick={hideActivationLinkDialog} className="popup__overlay js-popup-overlay">{' '}</div>
            <div className="popup__wrap js-popup-wrap">
              <div className="description">
                <div className="title-primary description__title">{t('Activation link')}</div>

                <div className="description__row flex__row flex__wrap">
                  <div className="field w-full">
                    <div className="field__wrap field__wrap-icon-right">
                      <input
                        type="text"
                        value={activationLink}
                        disabled
                        className="field__input"
                        id="activation_link"
                      />

                      <button
                        className="field__icon"
                        onClick={copyActivationLink}
                      >
                        <Icon
                          className={classNames('copy')}
                          name='copy'
                          size='24'
                        />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="popup__close js-popup-close"
                onClick={hideActivationLinkDialog}
              >
                <Icon
                  className={classNames('close', 'icon-24')}
                  name="close"
                  size="24"
                />
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={isDialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        >
          <ModalInnerDialog
            onClickClose={() => {
              setDialogOpen(false);
            }}
            modalTitle={t('Download CSV')}
            modalTitleClassColor="title-primary"
          >
            <div className="description__row flex__row flex__wrap">
              <AdminExportUsers
                onClose={() => {
                  setDialogOpen(false);
                }}
              />
            </div>
          </ModalInnerDialog>
        </Dialog>
      </div>
    );
  }

  return (
    <div className={classes.circularProgressWrapper}>
      <CircularProgress />
    </div>
  );
};

AdminUserList.propTypes = {};

export default AdminUserList;
