import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Checkbox,
} from '@material-ui/core'

import {
  GET_PERSONAL_DISCOUNT_LIST,
  DELETE_PERSONAL_DISCOUNT,
} from '../../queriesAndMutations'
import TablePaginationActions from '../../components/TablePagination'
import Icon from '../../components/Icon'

const AdminPersonalDiscountList = () => {
  const { t } = useTranslation()
  const [multiSelect, setMultiSelect] = useState([])
  const [reqOptions, setReqOptions] = useState({
    search: '',
    page: 1,
    pageSize: 10,
  })
  const { data: { getPersonalDiscountList: data } = {}, loading, refetch } = useQuery(GET_PERSONAL_DISCOUNT_LIST, {
    variables: {
      input: {
        search: reqOptions.search,
        page: reqOptions.page,
        pageSize: reqOptions.pageSize,
      }
    }
  })
  const [deletePersonalDiscount, deletePersonalDiscountStatus] = useMutation(DELETE_PERSONAL_DISCOUNT)

  const submitSingleDelete = (userId) => () => {
    deletePersonalDiscount({
      variables: {
        ids: [userId]
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  const submitMultipleDelete = () => {
    deletePersonalDiscount({
      variables: {
        ids: multiSelect
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  const toggleMultiSelect = (id) => () => {
    if (multiSelect.includes(id)) {
      setMultiSelect(multiSelect.filter(i => i !== id))
    } else {
      setMultiSelect([...multiSelect, id])
    }
  }

  const onChangeReqOptions = ({target}) => {
    return setReqOptions({ ...reqOptions, search: target.value, page: 1 });
  };

  if (!data) {
    return null;
  }

  return (
    <div>
      <form className="form">
        <input
          type="search"
          className="form__input"
          placeholder={t('Type in search')}
          value={reqOptions.search}
          onChange={onChangeReqOptions}
        />
        <button className="form__button">
          <Icon
            className='search'
            name='search'
            size="24"
          />{" "}
        </button>
      </form>

      <br />

      {deletePersonalDiscountStatus.loading && (
        <CircularProgress />
      )}

      {!deletePersonalDiscountStatus.loading && (
        <button className="button button-danger" onClick={submitMultipleDelete} disabled={multiSelect.length <= 0}>Delete bulk</button>
      )}

      <div className="transactions__wrapper products__table-kyclist__wrapper">
        <div className="transactions__table kyc__table_transactions kyc__table_kyclist">
          <div className="transactions__row">
            <div className="transactions__col" />

            <div className="transactions__col">
              {t('Email')}
            </div>

            <div className="transactions__col">
              {t('Price')}
            </div>

            <div className="transactions__col" />
          </div>

          {data.results.map(personalDiscount => (
            <div key={personalDiscount.id} className="transactions__row">
              <div className="transactions__col vert__middle">
                <Checkbox
                  color="primary"
                  value={personalDiscount.id}
                  checked={multiSelect.includes(+personalDiscount.id)}
                  onChange={toggleMultiSelect(+personalDiscount.id)}
                />
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Email')}
                </div>
                {personalDiscount.email}
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Price')}
                </div>
                {`${personalDiscount.price} ${personalDiscount.currency.toUpperCase()} / Token`}
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('New token amount')}
                </div>
                {personalDiscount.newTokenAmount}
              </div>
              <div className="transactions__col vert__middle">
                {deletePersonalDiscountStatus.loading && (
                  <CircularProgress />
                )}
                {!deletePersonalDiscountStatus.loading && (
                  <button className="button button-danger" onClick={submitSingleDelete(+personalDiscount.id)}>Delete</button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <TablePaginationActions
        count={data.meta.total}
        page={reqOptions.page - 1}
        rowsPerPage={reqOptions.pageSize}
        onChangePage={(event, newPage) => { setReqOptions({ ...reqOptions, page: newPage + 1 }) }}
        onChangeRows={({ target: { value }}) => { setReqOptions({ ...reqOptions, pageSize: +value}) }}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  )
}

export default AdminPersonalDiscountList
