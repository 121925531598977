import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import classNames from 'classnames'
import { CircularProgress } from '@material-ui/core'
import {
  getQueryVars,
  toaster,
} from '../../utils'
import { TRANSACTION } from '../../queriesAndMutations'
import styles from './styles.module.scss'

const PaymentSuccess = () => {
  const { tnx: transactionId } = getQueryVars() || {};
  const [getTransaction, getTransactionStatus] = useLazyQuery(TRANSACTION)

  useEffect(() => {
    getTransaction({
      variables: {
        id: transactionId
      }
    })
  }, [getTransaction, transactionId])

  if (!transactionId) {
    toaster.error('Invalid URL')

    return <Redirect to="/" />
  }

  if (getTransactionStatus.loading || (!getTransactionStatus.data && !getTransactionStatus.error)) {
    return <CircularProgress />
  }

  if (getTransactionStatus.error) {
    return <Redirect to="/" />
  }

  const transaction = getTransactionStatus.data.transaction

  setTimeout(() => {
    window.location.href = window.location.origin
  }, 5000)

  return (
    <div className={classNames('page-success', styles.module_page_success)}>
      <div className={classNames('page-success__wrapper', styles.module_page_wrapper)}>
        <div className={classNames('page-success__status', styles.module_page_status)}>
          {/* Credit to https://codepen.io/Hellwinkel/pen/KKaNNKb */}
          <div className={classNames('check-container', styles.module_check_container)}>
            <div className={classNames('check-background', styles.module_check_background)}>
              <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className={classNames('check-shadow', styles.module_check_shadow)}>{' '}</div>
          </div>
        </div>

        <div className={classNames('page-success__title title-success', styles.module_page_title)}>
          <p>You paid {(+transaction.currency_amount).toFixed(2)} {transaction.currency.toUpperCase()} via Volt.io. Your tokens will be allocated as soon as the payment is successfully reconciled.</p>
          <p>Redirect to dashboard in 5 seconds...</p>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
