import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { KYC_HISTORY_CHANGE_STATUS_ADMIN } from '../queriesAndMutations'
import { CUSTOMER_STATUS_TYPES } from '../constants/customer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => {
  const statusBadge = {
    padding: '6px 12px',
    border: '1px solid #000',
    borderRadius: '8%',
    textTransform: 'lowercase',
  }

  return {
    idleStatusBadge: statusBadge,
    pendingStatusBadge: {
      ...statusBadge,
      borderColor: '#ffc100',
    },
    activeStatusBadge: {
      ...statusBadge,
      borderColor: '#009f65',
    },
    rejectedStatusBadge: {
      ...statusBadge,
      borderColor: '#ea0b0b',
    },
    circularProgressWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  }
})

const AdminHistoryChangeStatusKyc = ({
  userKyc, onChangeStatus, customerType,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: { historyChangeStatusKycAdmin = {} } = {}, loading: loadingKycHistoryChangeStatusKycAdmin } = useQuery(KYC_HISTORY_CHANGE_STATUS_ADMIN, {
    variables: {
      input: {
        customerId: userKyc.id,
        customerType,
      },
    },
  })

  const renderKycStatus = (status) => {
    const statusStyles = {
      [CUSTOMER_STATUS_TYPES.IDLE]: "status-idle",
      [CUSTOMER_STATUS_TYPES.WHITELISTED]: "status-purple-dark",
      [CUSTOMER_STATUS_TYPES.APPROVED]: "status-green-dark",
      [CUSTOMER_STATUS_TYPES.PENDING]: "status-pending-dark",
      [CUSTOMER_STATUS_TYPES.REOPEN]: "status-pending-dark",
      [CUSTOMER_STATUS_TYPES.REJECTED]: "status-red-dark",
      REOPEN_WITH_VIDEOIDENT: "status-pending-dark",
      PASSED: "status-green-dark",
      [CUSTOMER_STATUS_TYPES.CONTRACTED]: 'status-green-dark',
      [CUSTOMER_STATUS_TYPES.KYC_LVL_CHANGE]: 'status-pending-dark',
      [CUSTOMER_STATUS_TYPES.INACTIVE]: 'status-gray-dark',
    }
    const text = {
      REOPEN_WITH_VIDEOIDENT: 'REOPEN_WITH_LIVENESS_CHECK',
    }

    return <span className={statusStyles[status]}>{text[status] || status}</span>
  }

  const renderKycHistory = (object) => (
    <div className="transactions__row" key={object.id}>
      <div className="transactions__col products__col-first-clear">
        <div className="transactions__label">{t('Date')}</div>
        {new Date(object.createdAt).toDateString()}
        <br/>
        {moment(object.createdAt).format('h:mm:ss a')}
      </div>

      <div className="transactions__col vert__middle">
        <div className="transactions__label">{t('Status')}</div>
        {object.director_id ? <p><span className="status-idle">{`Director ${object.customerCompanyDirector.email}`}</span></p> : null}
        {renderKycStatus(object.status_type_after)}
      </div>

      <div className="transactions__col vert__middle">
        <div className="transactions__label">{t('By User')}</div>
        {object.user ? `${object.user.first_name} ${object.user.last_name}` : 'System'}
      </div>

      <div className="transactions__col">
        <div className="transactions__label">{t('Comment')}</div>
        {object.comment}
      </div>
    </div>
  )

  if (loadingKycHistoryChangeStatusKycAdmin) {
    return <div className={classes.circularProgressWrapper}><CircularProgress /></div>
  }
  if (!historyChangeStatusKycAdmin) {
    return <p>Cannot fetch data.</p>
  }
  return (
    <>
      <div className="transactions__wrapper">
        <div className="transactions__table kyc__table_transactions kyc__table_transactions_clear-col-3">
          <div className="transactions__row">
            <div className="transactions__col products__col-first-clear">
              { t('Date')}
            </div>
            <div className="transactions__col">{ t('Status') }</div>
            <div className="transactions__col">{ t('By user') }</div>
            <div className="transactions__col">{ t('Comment') }</div>
          </div>
          { Object.keys(historyChangeStatusKycAdmin.objects).length > 0
          && historyChangeStatusKycAdmin.objects.map(renderKycHistory)}
        </div>
      </div>
    </>
  )
}

AdminHistoryChangeStatusKyc.propTypes = {
  userKyc: PropTypes.object.isRequired,
  customerType: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default AdminHistoryChangeStatusKyc
