import React, {useState} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import ReactTooltip from 'react-tooltip'
import Popover from '@material-ui/core/Popover'
import { useTranslation } from 'react-i18next'
import { usePublicSettings, useMe } from '../../../myHooks'
import Icon from '../../Icon'

const SETS = {
  cockpit: [
    {
      title: "Cockpit",
      url: "/cockpit",
      icon_name: "diamond",
      id_tooltip: "dashboard-general"
    },
    {
      title: "Issue",
      hasChildren: true,
      icon_name: "link",
      id_tooltip: 'issue-cockpit',
      children: [
        {
          title: "Dashboard",
          url: "/issue/dashboard"
        },
        {
          title: "KYC Token",
          url: "/issue/kyc-token"
        },
        {
          title: "Resisien",
          url: "/issue/resisien"
        }
      ]
    },
    {
      title: "Evolve",
      hasChildren: true,
      icon_name: "link",
      id_tooltip: "evolve-cockpit",
      children: [
        {
          title: "Dashboard",
          url: "/evolve/dashboard"
        },
        {
          title: "Expenses",
          url: "/evolve/expenses"
        },
        {
          title: "Payment",
          url: "/evolve/payment"
        },
        {
          title: "Balances",
          url: "/evolve/balances"
        },
        {
          title: "Approvals",
          url: "/evolve/approvals"
        },
        {
          title: "Cards",
          url: "/evolve/cards"
        },
        {
          title: "Users",
          url: "/evolve/users"
        },
        {
          title: "Reports",
          url: "/evolve/reports"
        },
        {
          title: "Accounting",
          url: "/evolve/accounting"
        },
        {
          title: "Settings",
          url: "/evolve/settings"
        }
      ]
    },
    {
      title: "Custody",
      icon_name: "link",
      id_tooltip: "custody-cockpit"
    }
  ]
}

const MainNavigation = ({
  onNavigationClick,
  onSidebarActive,
  onSidebarActiveForce,
  onSidebarVisibleActiveForce,
  // from HOCs
  location,
}) => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me: userData } = {} } = useMe()

  const [subMenuOpen, setSubMenuOpen] = useState(-1)
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x)

  const [anchorPopoverEl, setAnchorPopoverEl] = useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorPopoverEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorPopoverEl(null)
  }
  const openPopover = Boolean(anchorPopoverEl)

  const renderCockpitMenu = () => (
    <>
      {SETS.cockpit.map((item) => {
        let itemUrl = item.url;

        if (item.hasChildren) {
          return (
            <div
              key={item.title}
              onClick={() => {toggleMenu(item.title); onSidebarActive();}}
              className={classNames('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
            >
              <button
                className="sidebar__head"
                data-tip={item.title}
                data-for={item.id_tooltip}
              >
                {item.icon_name && (
                  <Icon className={classNames(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                )}
                {t(item.title)}
                <Icon
                  className={classNames('arrow-down', 'icon-24')}
                  name="arrow-down"
                  size="24"
                />
              </button>
              <ReactTooltip id={item.id_tooltip} className="sidebar__item_tooltip" />

              <div
                className={classNames('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
              >
                {item.children.map((childItem) => {
                  const isActive = location.pathname.indexOf(childItem.url) === 0;
                  return (
                    <NavLink
                      key={childItem.title}
                      to={childItem.url}
                      className="sidebar__link disable"
                      activeClassName="active is-active"
                      onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
                    >
                      {t(childItem.title)}
                    </NavLink>
                  )
                })}
              </div>
            </div>
          )
        }

        return (
          <React.Fragment key={item.title}>
            <NavLink
              to={{pathname: itemUrl, state: { prevPath: location.pathname }}}
              className="sidebar__item"
              activeClassName="active is-active"
            >
              {item.icon_name && (
                <Icon className={classNames(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
              )}
              {t(item.title)}
            </NavLink>
          </React.Fragment>
        );
      })}
    </>
  )

  return (
    <div className="sidebar__menu sidebar__menu-kyc">
      {renderCockpitMenu()}
    </div>
  )
}

MainNavigation.propTypes = {
  // from HOCs
  location: PropTypes.object
}

export default compose(withRouter)(MainNavigation)
