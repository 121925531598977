import React, { useEffect, useState } from 'react'
import CapitalIncreaseTransactions from '../../components/CapitalIncreaseTransactions'
import { TRANSACTIONS_ADMIN, CAPITAL_INCREASES } from '../../queriesAndMutations'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddCapitalIncreaseForm from "./AddCapitalIncreaseForm"
import { hasUserEnoughRights } from '../../utils/me'
import { useMe } from '../../myHooks'
import { USER_RIGHT_TYPES } from '../../constants/user'

const AdminCapitalIncrease = () => {
  const [filteredStatus, setFilteredStatus] = useState(null);
  const { t } = useTranslation();
  const [capitalIncreases, setCapitalIncreases] = useState([])
  const { data: { me } = {} } = useMe();

  const {
    data: {
      capitalIncreases: capitalIncreasesData,
    } = {},
    loading,
  } = useQuery(CAPITAL_INCREASES, {
    variables: {},
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (capitalIncreasesData) {
      setCapitalIncreases(capitalIncreasesData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, capitalIncreasesData)

  return (
    <>
      <div className="page__title h3">{ t('Capital Increases')}</div>

      {me && hasUserEnoughRights(
          me.rights,
          USER_RIGHT_TYPES.COMPLIANCE_OFFICER
        ) && (
        <div className="card">
          <MaterialAccordion className="accordion__kyc">
            <MaterialAccordionSummary
              className="accordion__kyc_head"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="attributesbh-content"
              id="attributes-header"
            >
              <div className="title-primary card__title">{ t('Add manual') }</div>
            </MaterialAccordionSummary>
            <MaterialAccordionDetails className="accordion__kyc_body">
              <div className="w-full">
                <AddCapitalIncreaseForm onComplete={() => { window.location.reload() }} />
              </div>
            </MaterialAccordionDetails>
          </MaterialAccordion>
        </div>
      )}

      {capitalIncreases.map(capitalIncrease => (
        <div className="transactions customer card" key={capitalIncrease.id}>
          <CapitalIncreaseTransactions
            isAdmin
            query={TRANSACTIONS_ADMIN}
            capitalIncrease={capitalIncrease}
            filteredStatus={(newStatuses) =>
              setFilteredStatus(newStatuses)
            }
          />
        </div>
      ))}
    </>
  );
}

export default AdminCapitalIncrease
