import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useQuery, useMutation } from '@apollo/client'
import { CircularProgress, Dialog } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { GET_OWN_KYC_LOG_EVENT, SYNC_USER } from '../queriesAndMutations'
import { useMe } from '../myHooks'
import { ReactComponent as StatusSuccessSVG } from '../media/icons/status-success.svg';
import { ReactComponent as StatusIncompleteSVG } from '../media/icons/status-incomplete.svg';
import { ReactComponent as StatusFailedSVG } from '../media/icons/status-failed.svg';
import { toaster } from '../utils'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'

const KycStatusLog = () => {
  const { t } = useTranslation()
  const { data: { getOwnKycLogEvent: data } = {}, loading, refetch } = useQuery(GET_OWN_KYC_LOG_EVENT)
  const { data: { me } = {}, refetch: reloadUser } = useMe()
  const [refetchCountDown, setRefetchCountDown] = useState(20)
  const [refetchIntervalId, setRefetchIntervalId] = useState(null)
  const [isRefetching, setIsRefetching] = useState(null)
  const [showRedirectModal, setShowRedirectModal] = useState(false)
  const [cancelRedirect, setCancelRedirect] = useState(false)
  const [syncUser, syncUserStatus] = useMutation(SYNC_USER);

  const currentHost = window.location.host;
  const isMasterTenant = currentHost === 'kyc.investhub.io' || currentHost === 'kyc-dev.investhub.io' || currentHost === 'localhost:3000'

  const onRedirectToInvesthub = () => {
    const callback = () => {
      if (cancelRedirect) {
        return
      }

      syncUser({ variables: { syncMasterTenant: false } }).then((response) => {
        const data = response.data.syncUser;

        if (data.success) {
          const redirectUrl = `${process.env.REACT_APP_DAO_HOST}/sso/auth?token=${data.token}&host=${window.location.host}&redirect_path=personalised_opportunities`;
          window.location.href = redirectUrl;
        } else {
          toaster.error('Sync User failed');
        }
      }).catch((error) => {
        console.log(error);
        toaster.error('Sync User failed');
      })
    }
    setTimeout(callback, 3000)
  }

  useEffect(() => {
    const intervalId = setInterval(function () {
      if (refetchCountDown > 0) {
        setRefetchCountDown(refetchCountDown - 1)
      }
    }, 1000)

    if (me && me.customer && me.customer.status === 'PENDING') {
      setRefetchIntervalId(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [me, refetchCountDown])

  useEffect(() => {
    let timeoutId
    let refetchTime = 0

    const resetRefreshTimeout = () => {
      if (me && me.customer && me.customer.status === 'PENDING') {
        if (refetchTime < 2) {
          timeoutId = setTimeout(() => {
            refetchTime += 1
            setRefetchCountDown(20)
            setIsRefetching(true)
            Promise.all([
              refetch(),
              reloadUser().then((response) => {
                if (response.data.me.customer.status === 'APPROVED') {
                  if (isMasterTenant) {
                    setShowRedirectModal(true)
                    onRedirectToInvesthub()
                  } else {
                    window.location.href = window.location.origin
                  }
                }
              }),
            ]).then(() => {
              setIsRefetching(false)
            })
            resetRefreshTimeout()
          }, 20000)
        } else {
          clearInterval(refetchIntervalId)
          if (isMasterTenant) {
            window.location.href = window.location.origin
          }
        }
      }
    }

    resetRefreshTimeout()

    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, refetch, reloadUser])

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    )
  }

  if (!data) {
    return <p>{t('No application has been submit')}</p>
  }

  return (
    <div className="entry__text entry__text-onboarding">
      <div className="sidebar__body">
        {data.map((log) => {
          const date = moment(+log.created_at).tz('Europe/Vaduz').format('DD-MM-YYYY HH:mm z')

          if (log.status_type_before === 'NONE') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusSuccessSVG />
                <span className="sidebar__link-text">
                  { t('KYC submitted') } ({date})
                </span>
              </div>
            )
          }

          if (log.status_type_after === 'APPROVED') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusSuccessSVG />
                <span className="sidebar__link-text">
                  {t('KYC approved')}
                  {log.comment ? `: ${t(log.comment)} ` : ' '}
                  ({date})
                </span>
              </div>
            )
          }
          if (log.status_type_after === 'KYC_LVL_CHANGE') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {log.comment}
                  {' '}
                  ({date})
                </span>
              </div>
            )
          }

          if (log.status_type_before === 'REOPEN' && log.status_type_after === 'PENDING') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {t('KYC resubmitted')} ({date})
                </span>
              </div>
            )
          }

          if (log.status_type_after === 'REOPEN') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {t('KYC reopened')} ({date})
                </span>
              </div>
            )
          }

          if (log.id.match(/^STATIC_REJECT/)) {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusFailedSVG />
                <span className="sidebar__link-text">
                  {t(log.comment)} ({date})
                </span>
              </div>
            )
          }

          if (log.id.match(/^STATIC_PENDING/)) {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {t(log.comment)}
                </span>
              </div>
            )
          }

          if (log.id.match(/^STATIC_SUCCESS/)) {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusSuccessSVG />
                <span className="sidebar__link-text">
                  {t(log.comment)} ({date})
                </span>
              </div>
            )
          }

          if (log.status_type_after === 'PENDING') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  KYC {log.status_type_after.toLowerCase()}{log.comment ? `: ${t(log.comment)}` : ''} ({date})
                </span>
              </div>
            )
          }

          return (
            <div className="sidebar__link sidebar__link-normal color" key={log.id}>
              <StatusSuccessSVG />
              <span className="sidebar__link-text">
                KYC {log.status_type_after.toLowerCase()} ({date})
              </span>
            </div>
          )
        })}
      </div>

      <div className="space space__24">{' '}</div>

      {isRefetching && (
        <CircularProgress />
      )}

      {me && me.customer && me.customer.status === 'PENDING' && (
        <p className="text__gray text-center">
          {t('Refresh in')} {refetchCountDown} {refetchCountDown > 1 ? t('seconds') : t('second') }
        </p>
      )}

      <Dialog
        open={showRedirectModal}
        onBackdropClick={() => setShowRedirectModal(false)}
        onClose={() => setShowRedirectModal(false)}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-small"
          modalTitle={t('Redirecting to Investhub')}
          onClickClose={() => setShowRedirectModal(false)}
          modalTitleClassColor="title-primary"
        >
          <div className="settings__wrapper">
            <div className="settings__list">
              <div className="settings__item">
                <div className="create__info">
                  <p>{t('Your KYC is approved. You are redirecting to Investhub')}</p>
                  <br />
                  <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
                    <button type="button" className="button button-stroke" onClick={() => setCancelRedirect(true)}>{t('Cancel')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </div>
  )
}

export default KycStatusLog
