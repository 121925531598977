import React, { useState } from 'react';
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Chip,
  CircularProgress,
  FormControlLabel,
  FormControl,
  RadioGroup,
  ListItemText,
  MenuItem,
  Checkbox,
  Radio,
} from '@material-ui/core';
import { GET_REFERRAL_CODE_LIST } from '../../queriesAndMutations'
import InputThemeField from '../InputThemeField';
import DropdownMaterial from '../DropdownMaterial';
import SwitchCheckbox from '../SwitchCheckbox';

const AdminSettingsIdentification = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation();
  const { data: { getReferralCodeList: referralCodeList} = {} } = useQuery(GET_REFERRAL_CODE_LIST);
  const [newReferralCode, setNewReferralCode] = useState('');
  const nameFields = {
    api_url: "API url",
    api_token: "API token",
  };
  const onfidoNameFields = {
    api_url: "API url",
    api_token: "API token",
    api_webhook_token: "API webhook token",
  };
  const idnowNameFields = {
    customer_id: "Customer ID",
    api_key: "API key",
  };

  const complycubeNameFields = {
    api_key: "API key",
    webhook_secret: "API webhook secret",
  };

  const choosingVideoIdentLabels = {
    onfido: "Onfido",
    electronic_id: "Electronic ID",
    internal_liveness_check: "Internal Liveness Check",
    idnow: "IDnow",
    complycube: "ComplyCube",
  };

  const [identification, setIdentification] = useState(settings.identification);

  const onUpdateBtnClick = () => {
    onUpdate({ identification: identification });
  };

  const allowed = ["api_url", "api_token"];

  const renderElectronicIdent = (electronicIdSetting) => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Electronic ID') }</div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            {Object.keys(electronicIdSetting)
              .filter((key) => allowed.includes(key))
              .map((item) => (
                <React.Fragment key={item}>
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={nameFields[item]}
                    value={electronicIdSetting[item]}
                    disabled={!editingAllowed}
                    loading={loading}
                    onChange={(event) => {
                      const setting = {
                        ...identification["ident_services"]["electronic_id"],
                        [item]: event.target.value,
                      };

                      const identServices = {
                        ...identification["ident_services"],
                        electronic_id: setting,
                      };

                      setIdentification({
                        ...identification,
                        ident_services: identServices,
                      });
                    }}
                  />
                </React.Fragment>
            ))}
          </div>

          <div className="create__line">
            <div className="create__info">
              { t('Liveness check extract & compare data') }
            </div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={electronicIdSetting["video_ident_extract_data"]}
                disabled={!editingAllowed}
                onChange={(event) => {
                  const setting = {
                    ...identification["ident_services"]["electronic_id"],
                    video_ident_extract_data: event.target.checked,
                  };

                  const identServices = {
                    ...identification["ident_services"],
                    electronic_id: setting,
                  };

                  setIdentification({
                    ...identification,
                    ident_services: identServices,
                  });
                }}
                value={electronicIdSetting["video_ident_extract_data"]}
              />
              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="field field-radio__material">
            <div className="field__label">{ t('Liveness Check Type') }</div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={electronicIdSetting["video_ident_attended_type"]}
                onChange={({ target: { value } }) => {
                  const setting = {
                    ...identification["ident_services"]["electronic_id"],
                    video_ident_attended_type: value,
                  };

                  const identServices = {
                    ...identification["ident_services"],
                    electronic_id: setting,
                  };

                  setIdentification({
                    ...identification,
                    ident_services: identServices,
                  });
                }}
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Attended')}
                  value="attended"
                  control={<Radio />}
                />
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Unattended')}
                  value="unattended"
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMoreIdentOption = (onFidoSetting) => {
    return (
      <div className="settings__item">
        <div className="field field-radio__material">
          <div className="field__label">
            { t('Liveness check method') }
          </div>

          <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
            <RadioGroup
              className="flex__row flex__wrap"
              row
              value={onFidoSetting['force_cross_device'] || false}
              onChange={({ target: { value } }) => {
                setIdentification({
                  ...identification,
                  ident_services: {
                    ...identification.ident_services,
                    onfido: {
                      ...identification.ident_services.onfido,
                      force_cross_device: value === 'true',
                    }
                  },
                });
              }}
            >
              <FormControlLabel
                disabled={!editingAllowed}
                label={t('Allow upload document on PC')}
                value={false}
                control={<Radio />}
              />
              <FormControlLabel
                disabled={!editingAllowed}
                label={t('Force check on mobile')}
                value={true}
                control={<Radio />}
              />
            </RadioGroup>
          </div>
        </div>

        <br />

        <div className="field field-radio__material">
          <div className="field__label">{ t('Watchlist extended check on new transaction') }</div>

          <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
            <RadioGroup
              row
              value={onFidoSetting['watchlist_extended_check'] || false}
              onChange={({ target: { value } }) => {
                setIdentification({
                  ...identification,
                  ident_services: {
                    ...identification.ident_services,
                    onfido: {
                      ...identification.ident_services.onfido,
                      watchlist_extended_check: value === 'true',
                    }
                  },
                });
              }}
            >
              <FormControlLabel
                disabled={!editingAllowed}
                label={t('Disable')}
                value={false}
                control={<Radio />}
              />
              <FormControlLabel
                disabled={!editingAllowed}
                label={t('Enable')}
                value={true}
                control={<Radio />}
              />
            </RadioGroup>
          </div>
        </div>
      </div>
    )
  }

  const renderOnfidoIdent = (onFidoSetting) => {
    return (
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Onfido') }</div>

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">{ t('Setting type') }</div>

            <div className="field__wrap">
              <FormControl>
                <RadioGroup
                  row
                  value={onFidoSetting.setting_option || 'own_setting'}
                  onChange={({ target: { value } }) =>
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        onfido: {
                          ...identification.ident_services.onfido,
                          setting_option: value,
                        },
                      },
                    })
                  }
                >
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Predefined setting')}
                    value="predefined"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Use my own setting')}
                    value="own_setting"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="settings__row flex__row flex__wrap">
            {Object.keys(onfidoNameFields).map((item) => (
              <React.Fragment key={item}>
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"

                  label={onfidoNameFields[item]}
                  value={onFidoSetting[item]}
                  disabled={!editingAllowed}
                  loading={loading}
                  onChange={({ target: { value } }) => {
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        onfido: {
                          ...identification.ident_services.onfido,
                          [item]: value,
                        },
                      },
                    });
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderIdnowIdent = (idnowSetting) => {
    return (
      <div className="settings__item">
        <div className="title-purple settings__title">{ t('IDnow') }</div>

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">{ t('Setting type') }</div>

            <div className="field__wrap">
              <FormControl>
                <RadioGroup
                  row
                  value={idnowSetting.setting_option || 'predefined'}
                  onChange={({ target: { value } }) =>
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        idnow: {
                          ...idnowSetting,
                          setting_option: value,
                        },
                      },
                    })
                  }
                >
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Predefined setting')}
                    value="predefined"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Use my own setting')}
                    value="own_setting"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              { t('Region') }
            </div>

            <div className="field__wrap">
              <FormControl>
                <RadioGroup
                  row
                  value={idnowSetting.region || 'other'}
                  onChange={({ target: { value } }) =>
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        idnow: {
                          ...idnowSetting,
                          region: value,
                        },
                      },
                    })
                  }
                >
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label="Switzerland"
                    value="switzerland"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Others')}
                    value="other"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="settings__row flex__row flex__wrap">
            {Object.keys(idnowNameFields).map((item) => (
              <React.Fragment key={item}>
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"

                  label={idnowNameFields[item]}
                  value={idnowSetting[item]}
                  disabled={!editingAllowed}
                  loading={loading}
                  onChange={({ target: { value } }) => {
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        idnow: {
                          ...idnowSetting,
                          [item]: value,
                        },
                      },
                    });
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderComplycubeIdent = (complycubeSetting) => {
    return (
      <div className="settings__item">
        <div className="title-purple settings__title">{ t('ComplyCube') }</div>

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">{ t('Setting type') }</div>

            <div className="field__wrap">
              <FormControl>
                <RadioGroup
                  row
                  value={complycubeSetting.setting_option || 'predefined'}
                  onChange={({ target: { value } }) =>
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        complycube: {
                          ...complycubeSetting,
                          setting_option: value,
                        },
                      },
                    })
                  }
                >
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Predefined setting')}
                    value="predefined"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    disabled={!editingAllowed}
                    label={t('Use my own setting')}
                    value="own_setting"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className="settings__row flex__row flex__wrap">
            {Object.keys(complycubeNameFields).map((item) => (
              <React.Fragment key={item}>
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"

                  label={complycubeNameFields[item]}
                  value={complycubeSetting[item]}
                  disabled={!editingAllowed}
                  loading={loading}
                  onChange={({ target: { value } }) => {
                    setIdentification({
                      ...identification,
                      ident_services: {
                        ...identification.ident_services,
                        complycube: {
                          ...complycubeSetting,
                          [item]: value,
                        },
                      },
                    });
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSelectIdentServices = (identServiceList) => {
    return (
      <div className="settings__item">
        <div className="title-green settings__title">{ t('Choosing Liveness Check Service') }</div>

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__wrap">
              <RadioGroup
                value={identification["active_ident_service"]}
                onChange={({ target: { value } }) => {
                  setIdentification({
                    ...identification,
                    active_ident_service: value,
                  });
                }}
              >
                {identServiceList.map((identService) => (
                  <FormControlLabel
                    key={identService}
                    label={choosingVideoIdentLabels[identService]}
                    value={identService}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          <br />

          {identification.active_ident_service !== 'idnow' && (
            <>
              <div className="settings__fieldset">
                <SwitchCheckbox
                  value="comply_with_bafin"
                  defaultChecked={identification.comply_with_bafin || false}
                  switchText="Comply with Bafin"
                  onChange={({ target: { checked }}) => {
                    setIdentification({
                      ...identification,
                      comply_with_bafin: checked,
                    });
                  }}
                  isGrayText
                />
                <p>
                  {t('With this setting enabled, KYC whose using wallet from Tangany services or KYC whose referral code is determined will be assigned to make the liveness check using IDnow service instead of selected service.')}
                </p>
              </div>

              <br />

              <div className="settings__fieldset">
                <div className="settings__row flex__row flex__wrap">
                  {referralCodeList && referralCodeList.length > 0 && (
                    <DropdownMaterial
                      propertyName="referral_code_comply_with_bafin"
                      label="Selected existed referral code"
                      state={{referral_code_comply_with_bafin: identification.referral_code_comply_with_bafin || []}}
                      setState={({ referral_code_comply_with_bafin: value }) => {
                        setIdentification({
                          ...identification,
                          referral_code_comply_with_bafin: value ? (typeof value === 'string' ? value.split(',') : value) : [],
                        })
                      }}
                      SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        renderValue: (selected) => {
                          if (selected && selected.length > 0) {
                            return selected.join(', ')
                          }
                          return (
                            <span className="text__gray">
                              {t('-- Select referral codes --')}
                            </span>
                          )
                        }
                      }}
                    >
                      {referralCodeList.map(referralCode => (
                        <MenuItem key={referralCode} value={referralCode}>
                          <Checkbox
                            checked={(identification.referral_code_comply_with_bafin || []).indexOf(referralCode) > -1}
                            color="primary"
                          />
                          <ListItemText primary={referralCode} />
                        </MenuItem>
                      ))}
                    </DropdownMaterial>
                  )}
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label="Add new referral code"
                    value={newReferralCode}
                    onChange={({ target: { value } }) => {
                      setNewReferralCode(value)
                    }}
                    endInput={(
                      <button className="button" onClick={() => {
                        setIdentification({
                          ...identification,
                          referral_code_comply_with_bafin: [
                            ...(identification.referral_code_comply_with_bafin || []),
                            newReferralCode,
                          ],
                        })
                        setNewReferralCode('')
                      }}>
                        {t('Add')}
                      </button>
                    )}
                  />
                </div>
                {
                  identification.referral_code_comply_with_bafin &&
                  identification.referral_code_comply_with_bafin.length > 0 &&
                  identification.referral_code_comply_with_bafin.map(code => (
                    <React.Fragment key={code}>
                      <Chip
                        label={code}
                        onDelete={() => {
                          setIdentification({
                            ...identification,
                            referral_code_comply_with_bafin: (identification.referral_code_comply_with_bafin || []).filter(c => c !== code),
                          })
                        }}
                      />
                      {' '}
                    </React.Fragment>
                  ))
                }
              </div>
            </>
          )}
        </div>

        <br />
        <div className="title-green settings__title">{ t('Choosing Watchlist Enhanced Check Service') }</div>

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__wrap">
              <RadioGroup
                value={identification["watchlist_enhanced_service"] || 'onfido'}
                onChange={({ target: { value } }) => {
                  setIdentification({
                    ...identification,
                    watchlist_enhanced_service: value,
                  });
                }}
              >
                {['onfido', 'complycube'].map((identService) => (
                  <FormControlLabel
                    key={identService}
                    label={choosingVideoIdentLabels[identService]}
                    value={identService}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          <br />
        </div>
      </div>
    );
  };

  return (
    <>
      {Object.keys(identification).length > 0 && (
        <>
          {renderSelectIdentServices(
            ['electronic_id', 'onfido', 'idnow', 'complycube', 'internal_liveness_check']
          )}
          {renderMoreIdentOption(identification["ident_services"]["onfido"])}
          {renderElectronicIdent(
            identification["ident_services"]["electronic_id"]
          )}
          {renderOnfidoIdent(identification["ident_services"]["onfido"])}
          {renderIdnowIdent(identification["ident_services"]["idnow"] || {})}
          {renderComplycubeIdent(identification["ident_services"]["complycube"] || {})}
        </>
      )}
      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <>
          <button
            className="button settings__button"
            disabled={!editingAllowed}
            onClick={onUpdateBtnClick}
          >
            { t('Update') }
          </button>
        </>
      )}
    </>
  );
};

AdminSettingsIdentification.propTypes = {
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsIdentification;
