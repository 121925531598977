import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from "../../../components/Icon"
import { RESTORE } from '../../../queriesAndMutations'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const PasswordRestorForm = () => {
  const classes = useStyles()
  const { t } = useTranslation();
  const [restore, { data, loading }] = useMutation(RESTORE)
  const [values, setValues] = useState({
    email: '',
  })

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onRestoreBtnClick = () => {
    setValues({ ...values, email: '' })
    restore({ variables: { input: values } }).catch(() => {})
  }

  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName, label = '', index,
  } = {}) => {
    return (
      <>
        <div className="field field_icon" key={index}>
          <div className="field__wrap">
            <input
              placeholder={label}
              type="email"
              className="field__input"
              value={values[propertyName] || ''}
              onChange={handleChange(propertyName)}
              disabled={loading}
            />
            <div className="field__icon">
              <Icon className="icon mail" name="mail" size="24" />{" "}
            </div>
          </div>
        </div>
      </>
    )
  }

  if (data) {
    return (
      <div className="entry__fieldset">
        <div className="entry__note">
          { t('Password reset link sent. Please check your mail!')}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="entry__fieldset">
        {
          loading
            ? <div className={classes.circularProgressWrapper}><CircularProgress /></div>
            : (
              <>
                {
                  renderInputField({
                    propertyName: 'email',
                    label: t('Your email'),
                  })
                }

                <div className="field input-submit">
                  <button
                    onClick={onRestoreBtnClick}
                    type="button"
                    className="button entry__button"
                  >
                    { t('Send Reset Link') }
                  </button>
                </div>
              </>
            )
        }
      </div>

      <div className="entry__info">
        <Link to="/login">{ t('Return to login') }</Link>
      </div>
    </>
  )
}

PasswordRestorForm.propTypes = {
  // from HOCs
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

export default PasswordRestorForm
