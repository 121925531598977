import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  CircularProgress,
  MenuItem,
} from '@material-ui/core'

import {
  ADD_PERSONAL_DISCOUNT,
} from '../../queriesAndMutations'
import { toaster } from '../../utils'
import {
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
} from '../../constants/settings'
import InputTheme from '../../components/InputTheme'
import DropdownMaterial from '../../components/DropdownMaterial'

const AddPersonalDiscountForm = ({
  onComplete,
}) => {
  const [values, setValues] = useState({
    email: '',
    price: '',
    currency: '',
  })
  const [addPersonalDiscount, addPersonalDiscountStatus] = useMutation(ADD_PERSONAL_DISCOUNT);

  const submitAddPersonalDiscount = () => {
    addPersonalDiscount({
      variables: {
        input: {
          email: values.email,
          price: +values.price,
          currency: values.currency,
        }
      }
    }).then(({ data: { addPersonalDiscount: success }}) => {
      if (success) {
        setValues({
          email: '',
          price: '',
          currency: '',
        })
        toaster.success('Personal discount saved')
        onComplete()
      }
    }).catch(() => {})
  }

  return (

    <div className="settings__fieldset">
      <div className="settings__row flex__row flex__wrap">
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label="Email"
          propertyName="email"
          state={values}
          setState={setValues}
          required
        />
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label="Price"
          propertyName="price"
          state={values}
          setState={setValues}
          required
          type="number"
        />
        <DropdownMaterial
          label="Currency"
          propertyName="currency"
          state={values}
          setState={setValues}
          select
        >
          {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES].map(currency => (
            <MenuItem key={currency} value={currency}>{currency.toUpperCase()}</MenuItem>
          ))}
        </DropdownMaterial>
      </div>

      <div>
        {!addPersonalDiscountStatus.loading && (
          <button className="button" onClick={submitAddPersonalDiscount}>Add</button>
        )}
        {addPersonalDiscountStatus.loading && (
          <CircularProgress />
        )}
      </div>
    </div>
  )
}

export default AddPersonalDiscountForm
