import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { usePublicSettings, useMe } from '../../../myHooks'

import HeaderSearchBar from '../../SearchBar'
import HeaderSubApp from '../../SubApp/SubApp'
import HeaderAddtionalBar from '../../AdditionalBar'
import HeaderMessageBar from '../../MessageBar'
import HeaderNotificationBar from '../../NotificationBar'
import HeaderUserBar from '../../UserBar'

import SidebarLogo from '../../Sidebar/RenderSidebarLogo'
import SidebarNavigationPrimary from '../MainNavigation/MainNavigation'
import Icon from '../../Icon'

const Sidebar = ({
  // from HOCs
  location
}) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me = {} } = {} } = useMe();
  const { t } = useTranslation();

  // Header Sidebar Visible Handle
  const [sidebarVisibleValues, setSidebarVisibleValues] = useState({
    isSidebarVisibleActive: false
  });
  const onSidebarVisibleActive = () => {
    setSidebarVisibleValues({...sidebarVisibleValues, isSidebarVisibleActive: !sidebarVisibleValues.isSidebarVisibleActive });
  }
  const onSidebarVisibleActiveForce = () => {
    setSidebarVisibleValues({ isSidebarVisibleActive: false });
  }

  // Sidebar Visible
  const [sidebarValues, setSidebarValues] = useState({
    isSidebarActive: false
  });

  const onSidebarActive = () => {
    setSidebarValues({...sidebarValues, isSidebarActive: !sidebarValues.isSidebarActive });
  }

  const onSidebarActiveForce = () => {
    setSidebarValues({ isSidebarActive: false });
  }

  return (
    <>
      {/* Header - Start */}
      <div className={`header header-subapp ${location.pathname.indexOf("/admin") === 0 ? "header-admin" : ""}`}>
        <button className="header__burger" onClick={onSidebarVisibleActive}>{' '}</button>

        {(location.pathname.indexOf("/admin") === 0 && (<HeaderSearchBar />))}

        <div className="header__control">
          <HeaderSubApp />
          <HeaderAddtionalBar />
          <HeaderMessageBar />
          <HeaderNotificationBar />
          {me && (
            <HeaderUserBar />
          )}
        </div>
      </div>
      {/* Header - End */}

      {/* Sidebar - Start */}
      <div className={`sidebar ${sidebarValues.isSidebarActive ? "active" : ""} ${sidebarVisibleValues.isSidebarVisibleActive ? "visible" : ""} ${location.pathname.indexOf("/admin") === 0 ? "sidebar-admin" : ""}`}>
        <button className="sidebar__close" onClick={onSidebarVisibleActive}>
          <Icon
            className={classNames('close', 'icon-24')}
            name="close"
            size="24"
          />
        </button>

        <SidebarLogo />

        <SidebarNavigationPrimary
          onNavigationClick={onSidebarVisibleActive}
          onSidebarActive={onSidebarActive}
          onSidebarActiveForce={onSidebarActiveForce}
          onSidebarVisibleActiveForce={onSidebarVisibleActiveForce}
        />
      </div>
      {/* Sidebar - End */}
    </>
  )
}

Sidebar.propTypes = {
  // from HOCs
  location: PropTypes.object
}

export default compose(withRouter)(Sidebar)
