import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import {
  CircularProgress,
} from '@material-ui/core'
import {
  GET_IDNOW_IDENT
} from '../queriesAndMutations'
import { getExternalFileUrl } from '../utils'
import Icon from './Icon'

const AdminIdnowViewer = ({
  idnowIdent,
}) => {
  const { t } = useTranslation()
  const { data: { getIdnowIdent: data } = {}, loading } = useQuery(GET_IDNOW_IDENT, {
    variables: {
      id: idnowIdent.id,
    },
  })

  if (loading) {
    return (
      <CircularProgress />
    )
  }

  if (!data) {
    return (
      <p>{t('Data not found')}</p>
    )
  }

  return (
    <>
      <div className="card">
        <div className="card__head">
          <div className="title-clear card__title">
            {t('Check')}{' '}{moment(+data.created_at).tz('Europe/Vaduz').format('YYYY-MM-DD')} ({data.identCode})
          </div>
        </div>

        <div className="earning__table">
          <div className="earning__row">{' '}</div>
          <div className="earning__row">
            <div className="earning__col">
              {t('Status')}
            </div>
            <div className="earning__col">
              <span className="status-green-dark">{data.status}</span>
            </div>
          </div>

          <div className="earning__row">
            <div className="earning__col">
              {t('Result')}
            </div>

            <div className="earning__col">
              {data.result === 'SUCCESS' && (
                <span className="status-green-dark">{data.result}</span>
              )}
              {data.result === 'SUCCESS_DATA_CHANGED' && (
                <span className="status-pending-dark">{data.result}</span>
              )}
              {data.result !== 'SUCCESS' && data.result !== 'SUCCESS_DATA_CHANGED' && (
                <span className="status-red">{data.result}</span>
              )}
            </div>
          </div>

          {data.result !== 'SUCCESS' && (
            <div className="earning__row">
              <div className="earning__col">
                {t('Reason')}
              </div>
              <div className="earning__col">
                <span className="status-red">{data.reason}</span>
              </div>
            </div>
          )}
        </div>

        <div className="space space__32">{' '}</div>
        <div className="users__head">
          <div className="users__info">
            <strong>
              {t('Documents')}
            </strong>
          </div>
        </div>

        <div className="drawer_report__list">
          {data.idnowDocument.map(doc => (
            <div key={`${doc.storageName}`} className="summary summary__document">
              <div className="summary__preview">
                <div className="summary__preview_aspect_ratio">
                  <img alt="" src={getExternalFileUrl(doc.storageName)} />
                </div>

                <div className="summary__control">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                  <a
                    className="summary__button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getExternalFileUrl(doc.storageName)}`}
                  >
                    <Icon
                      name='link'
                      size={24}
                    />{" "}
                  </a>
                </div>
              </div>

              <div className="summary__line">
                <div className="summary__title packages__content nospace__bottom">
                  {doc.storageName}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="users__head">
          <div className="users__info">
            <strong>
              {t('Pictures')}
            </strong>
          </div>
        </div>
        <div className="drawer_report__list">
          {data.idnowPicture.map(picture => (
            <div key={`${picture.storageName}`} className="summary summary__document">
              <div className="summary__preview">
                <div className="summary__preview_aspect_ratio">
                  <img alt="" src={getExternalFileUrl(picture.storageName)} />
                </div>

                <div className="summary__control">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                  <a
                    className="summary__button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getExternalFileUrl(picture.storageName)}`}
                  >
                    <Icon
                      name='link'
                      size="24"
                    />{" "}
                  </a>
                </div>
              </div>

              <div className="summary__line">
                <div className="summary__title packages__content nospace__bottom">
                  {picture.storageName}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="users__head">
          <div className="users__info">
            <strong>
              {t('Report')}
            </strong>
          </div>
        </div>
        <div className="drawer_report__list">
          {data.idnowPdf.map(pdf => (
            <div key={`${pdf.storageName}`} className="summary summary__document">
              <div className="summary__preview">
                <div className="summary__preview_aspect_ratio">
                  <embed
                    src={getExternalFileUrl(pdf.storageName)}
                    width="500"
                    height="375"
                    type="application/pdf"
                  />
                </div>

                <div className="summary__control">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                  <a
                    className="summary__button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getExternalFileUrl(pdf.storageName)}`}
                  >
                    <Icon
                      name='link'
                      size="24"
                    />{" "}
                  </a>
                </div>
              </div>

              <div className="summary__line">
                <div className="summary__title packages__content nospace__bottom">
                  {pdf.storageName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

AdminIdnowViewer.propTypes = {
  // using whole object to force component refresh on manual fetch KYC
  idnowIdent: PropTypes.object.isRequired,
}

export default AdminIdnowViewer
