import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import { usePublicSettings } from '../../myHooks/useSettings'
import { compose } from 'recompose'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import { isFeatureEnabled } from '../../utils'
import { USER_RIGHT_TYPES } from '../../constants/user'
import { TOKEN_TYPES } from '../../constants/settings'
import { useMe } from '../../myHooks'
import { useQuery } from '@apollo/client'
import {
  GET_AVAILABLE_FEATURES,
} from '../../queriesAndMutations'
import Popover from '@material-ui/core/Popover'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon'

const SETS = {
  main: [
    {
      title: "Dashboard",
      url: "/",
      icon_name: "home",
      id_tooltip: "dashboard-general"
    },
    {
      title: 'Buy Tokens',
      url: "/contribute",
      icon_name: "shopping-bag",
      id_tooltip: "contribute-general"
    },
    {
      title: 'Transactions',
      url: "/transactions",
      icon_name: "ticket",
      id_tooltip: "transactions-general"
    },
    {
      title: 'Profile',
      url: "/profile",
      icon_name: "profile-circle",
      id_tooltip: "profile-general"
    },
    {
      title: 'Data Center',
      url: "/data-center",
      icon_name: "pie-chart",
      id_tooltip: "datacenter-general"
    },
    {
      title: 'Admin',
      url: "/admin",
      icon_name: "multiselect",
      id_tooltip: "admin-general",
      rights: [
        USER_RIGHT_TYPES.ADMIN,
        USER_RIGHT_TYPES.GENERAL_ADMIN,
        USER_RIGHT_TYPES.COMPLIANCE_OFFICER,
        USER_RIGHT_TYPES.ACCOUNTING,
        USER_RIGHT_TYPES.AUDITOR,
      ],
    },
    {
      title: 'Admin Panel',
      url: "/admin/kyc-list/individual",
      icon_name: "multiselect",
      rights: [
        USER_RIGHT_TYPES.REFERRAL_PARTNER,
      ],
    },
    {
      title: 'Token Registry',
      url: "/admin/token-registry",
      icon_name: "repeat",
      rights: [
        USER_RIGHT_TYPES.CUSTODIAN,
      ],
      id_tooltip: "token-registry-general"
    },
  ],
  admin: [
    {
      title: "Dashboard",
      url: "/admin",
      icon_name: "home",
      id_tooltip: "dashboard-admin"
    },
    {
      title: 'User list',
      url: "/admin/users",
      icon_name: "copy",
      id_tooltip: "userlist-admin"
    },
    {
      title: 'Compliance',
      icon_name: "multiselect",
      hasChildren: true,
      id_tooltip: "compliance-admin",
      children: [
        {
          title: 'KYC list individual',
          url: "/admin/kyc-list/individual",
        },
        {
          title: 'KYC list company',
          url: "/admin/kyc-list/company",
        },
        {
          title: 'Swap Token',
          url: "/admin/swap-token",
        },
        {
          title: 'Personal Discount',
          url: "/admin/personal-discount",
        },
      ]
    },
    {
      title: 'Settings',
      url: "/admin/settings",
      icon_name: "setting",
      id_tooltip: "settings-admin"
    },
    {
      title: 'Transactions',
      url: "/admin/transactions",
      icon_name: "ticket",
      id_tooltip: "transactions-admin"
    },
    {
      title: 'Capital Increase',
      url: "/admin/capital-increase",
      icon_name: "ticket",
      id_tooltip: "capital-increase-admin"
    },
    {
      title: 'Exchange rates',
      url: "/admin/exchange-rates",
      icon_name: "payment",
      id_tooltip: "exchange-rates-admin"
    },
    {
      title: "Web3 Iban",
      url: "/admin/web3-iban",
      icon_name: "video-stroke",
      id_tooltip: "web3-iban-admin"
    },
    {
      title: 'Token Registry',
      url: "/admin/token-registry",
      icon_name: "repeat",
      id_tooltip: "token-registr-admin"
    },
    {
      title: 'Log Event',
      url: "/admin/log-event",
      icon_name: "edit",
      id_tooltip: "log-event-admin"
    },
    {
      title: 'Monitoring',
      url: "/admin/monitoring",
      icon_name: "check-all"
    },
    {
      title: 'Spot Check',
      url: "/admin/spotcheck",
      icon_name: "searchh"
    },
    {
      title: 'Messages',
      url: "/admin/messages",
      icon_name: "message"
    },
  ],

  compliance_officer: [
    {
      title: 'User list',
      url: "/admin/users",
      icon_name: "leaderboard",
      id_tooltip: "userlist-admin"
    },
    {
      title: 'KYC List individual',
      url: "/admin/kyc-list/individual",
      icon_name: "multiselect",
      id_tooltip: "kyclist-individual-admin"
    },
    {
      title: 'KYC List company',
      url: "/admin/kyc-list/company",
      icon_name: "activity",
      id_tooltip: "kyclist-company-admin"
    },
    {
      title: 'Transactions',
      url: "/admin/transactions",
      icon_name: "ticket",
      id_tooltip: "transactions-admin"
    },
    {
      title: 'Capital Increase',
      url: "/admin/capital-increase",
      icon_name: "ticket",
      id_tooltip: "capital-increase-admin"
    },
    {
      title: 'Monitoring',
      url: "/admin/monitoring",
      icon_name: "check-all",
    },
    {
      title: 'Spot Check',
      url: "/admin/spotcheck",
      icon_name: "searchh",
    },
    {
      title: 'Messages',
      url: "/admin/messages",
      icon_name: "message"
    },
  ],

  referral_partner: [
    {
      title: 'KYC List individual',
      url: "/admin/kyc-list/individual",
      icon_name: "multiselect"
    },
  ],

  custodian: [
    {
      title: 'Token Registry',
      url: "/admin/token-registry",
      id_tooltip: "token-registry-admin"
    },
  ],

  accounting: [
    {
      title: "Token Registry",
      url: "/admin/token-registry",
      id_tooltip: "token-registry-admin",
      icon_name: "repeat",
    },
    {
      title: "Transactions",
      url: "/admin/transactions",
      icon_name: "ticket",
      id_tooltip: "transactions-admin"
    },
    {
      title: 'Capital Increase',
      url: "/admin/capital-increase",
      icon_name: "ticket",
      id_tooltip: "capital-increase-admin"
    },
  ],

  auditor: [
    {
      title: "Dashboard",
      url: "/admin",
      icon_name: "home",
      id_tooltip: "dashboard-admin"
    },
    {
      title: 'Compliance',
      icon_name: "multiselect",
      hasChildren: true,
      id_tooltip: "compliance-admin",
      children: [
        {
          title: 'KYC list individual',
          url: "/admin/kyc-list/individual",
        },
        {
          title: 'KYC list company',
          url: "/admin/kyc-list/company",
        },
      ]
    },
    {
      title: 'Transactions',
      url: "/admin/transactions",
      icon_name: "ticket",
      id_tooltip: "transactions-admin"
    },
    {
      title: 'Capital Increase',
      url: "/admin/capital-increase",
      icon_name: "ticket",
      id_tooltip: "capital-increase-admin"
    },
    {
      title: 'Token Registry',
      url: "/admin/token-registry",
      icon_name: "repeat",
      id_tooltip: "token-registr-admin"
    },
    {
      title: 'Monitoring',
      url: "/admin/monitoring",
      icon_name: "check-all"
    },
    {
      title: 'Spot Check',
      url: "/admin/spotcheck",
      icon_name: "searchh"
    },
  ],

  main_with_none_token_type: ['Profile', 'Dashboard'],
};

const RenderMainNavigation = ({
  onNavigationClick,
  onSidebarActive,
  onSidebarActiveForce,
  onSidebarVisibleActiveForce,
  // from HOCs
  location,
}) => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me: userData } = {} } = useMe();
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const isTokenRegistryEnabled = isFeatureEnabled('TOKEN_REGISTRY', availableFeatures);
  const isLogEventEnabled = isFeatureEnabled('EVENT_LOG', availableFeatures);

  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  const [anchorPopoverEl, setAnchorPopoverEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorPopoverEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorPopoverEl(null)
  }

  const openPopover = Boolean(anchorPopoverEl)

  const renderMainMenu = () => {
    let mainMenu;
    if (
      publicSettings &&
      publicSettings.token_type === "NONE" &&
      userData.rights === "COMMON"
    ) {
      mainMenu = SETS.main.filter((item) =>
        SETS.main_with_none_token_type.includes(item.title)
      );
    } else {
      mainMenu = SETS.main;
    }
    return (
      <>
        {mainMenu.map((item) => {
          const isActive =
            item.url === "/"
              ? location.pathname === "/"
              : location.pathname.indexOf(item.url) === 0;

          if (
            !item.rights ||
            (item.rights &&
              (item.rights === userData.rights ||
                item.rights.includes(userData.rights)))
          ) {
            if (item.title === 'Token Registry' && !isTokenRegistryEnabled) {
              return (
                <NavLink key={item.title} to="#" className={cn('disabled')}>
                  {item.icon && (
                    <Icon className={item.icon} name={item.icon} size="24" />
                  )}
                  {" "}
                  {t(item.title)}
                </NavLink>
              )
            }

            if (
              publicSettings &&
              publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
              item.title === "Buy Tokens"
            ) {
              return null
            }

            let itemUrl = item.url;

            if (userData.rights === USER_RIGHT_TYPES.ACCOUNTING && itemUrl === '/admin') {
              itemUrl = '/admin/transactions'
            }

            return (
              <React.Fragment key={item.title}>
                <NavLink
                  className={cn('sidebar__item', isActive ? "active is-active" : "")}
                  to={{pathname: itemUrl, state: { prevPath: location.pathname }}}
                  onClick={onNavigationClick}
                  data-tip={item.title}
                  data-for={item.id_tooltip}
                >
                  {publicSettings &&
                  publicSettings.token_type === TOKEN_TYPES.UTILITY &&
                  item.title === "Buy Tokens"
                    ?
                      <>
                        <Icon className={cn('basket', 'icon-24')} name="basket" size="24" />
                        {t("Purchase")}
                      </>
                    :
                      <>
                        <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                        {t(item.title)}
                      </>
                  }
                </NavLink>

                <ReactTooltip id={item.id_tooltip} className="sidebar__item_tooltip" />

                <Popover
                  id={itemUrl}
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={openPopover}
                  anchorEl={anchorPopoverEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  Popover {item.title}
                </Popover>
              </React.Fragment>
            );
          }

          return false;
        })}
      </>
    );
  };

  const renderAdminMenu = () => (
    <>
      {location.pathname.indexOf("/admin") === 0 &&
        SETS.admin.map((item) => {
          const isActive =
            item.hasChildren
              ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
              : item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;

          if (!isFeatureEnabled('KYB_ONBOARDING', availableFeatures) && item.url !== "/admin/settings") {
            return null
          }

          if (item.hasChildren) {
            return (
              <div
                key={item.title}
                onClick={() => {toggleMenu(item.title); onSidebarActive();}}
                className={cn('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
              >
                <button
                  className="sidebar__head"
                  data-tip={item.title}
                  data-for={item.id_tooltip}
                >
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                  <Icon
                    className={cn('arrow-down', 'icon-24')}
                    name="arrow-down"
                    size="24"
                  />
                </button>

                <ReactTooltip id={item.id_tooltip} className="sidebar__item_tooltip" />

                <div
                  className={cn('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
                >
                  {item.children.map((childItem) => {
                    const isActive = location.pathname.indexOf(childItem.url) === 0;
                    if (
                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                      (childItem.title === 'Log Event' && !isLogEventEnabled)
                    ) {
                      return (
                        <NavLink
                          key={childItem.title}
                          className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                          to="#"
                          onClick={() => {onNavigationClick(); onSidebarActive();}}
                        >
                          {t(childItem.title)}
                        </NavLink>
                      )
                    }

                    return (
                      <NavLink
                        key={childItem.title}
                        className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                        to={childItem.url}
                        onClick={() => {onNavigationClick()}}
                      >
                        {t(childItem.title)}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          }

          if (!publicSettings.enable_monerium && item.url === "/admin/web3-iban") {
            return null
          }

          return (
            <React.Fragment key={item.title}>
              <NavLink
                className={cn('sidebar__item', isActive ? "active is-active" : "")}
                to={item.url}
                onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
                data-tip={item.title}
                data-for={item.id_tooltip}
              >
                <>
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                </>
              </NavLink>
              <ReactTooltip id={item.id_tooltip} className="sidebar__item_tooltip" />
            </React.Fragment>
          );
        })}
    </>
  );

  const renderComplianceOfficerMenu = () => (
    <>
      {location.pathname.indexOf("/admin") === 0 &&
        SETS.compliance_officer.map((item) => {
          const isActive =
            item.hasChildren
              ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
              : item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;

          if (item.hasChildren) {
            return (
              <div
                key={item.title}
                onClick={() => {toggleMenu(item.title); onSidebarActive();}}
                className={cn('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
              >
                <button
                  className="sidebar__head"
                >
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                  <Icon
                    className={cn('arrow-down', 'icon-24')}
                    name="arrow-down"
                    size="24"
                  />
                </button>

                <div
                  className={cn('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
                >
                  {item.children.map((childItem) => {
                    const isActive = location.pathname.indexOf(childItem.url) === 0;
                    if (
                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                      (childItem.title === 'Log Event' && !isLogEventEnabled)
                    ) {
                      return (
                        <NavLink
                          key={childItem.title}
                          className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                          to="#"
                          onClick={() => {onNavigationClick(); onSidebarActive();}}
                        >
                          {t(childItem.title)}
                        </NavLink>
                      )
                    }

                    return (
                      <NavLink
                        key={childItem.title}
                        className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                        to={childItem.url}
                        onClick={() => {onNavigationClick()}}
                      >
                        {t(childItem.title)}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          }

          return (
            <NavLink
              key={item.title}
              className={cn('sidebar__item', isActive ? "active is-active" : "")}
              to={item.url}
              onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
            >
              <>
                {item.icon_name && (
                  <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                )}
                {t(item.title)}
              </>
            </NavLink>
          );
        })}
    </>
  );

  const renderReferralPartnerMenu = () => (
    <>
      {location.pathname.indexOf("/admin") === 0 &&
        SETS.referral_partner.map((item) => {
          const isActive =
            item.hasChildren
              ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
              : item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;

          if (item.hasChildren) {
            return (
              <div
                key={item.title}
                onClick={() => {toggleMenu(item.title); onSidebarActive();}}
                className={cn('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
              >
                <button
                  className="sidebar__head"
                >
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                  <Icon
                    className={cn('arrow-down', 'icon-24')}
                    name="arrow-down"
                    size="24"
                  />
                </button>

                <div
                  className={cn('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
                >
                  {item.children.map((childItem) => {
                    const isActive = location.pathname.indexOf(childItem.url) === 0;
                    if (
                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                      (childItem.title === 'Log Event' && !isLogEventEnabled)
                    ) {
                      return (
                        <NavLink
                          key={childItem.title}
                          className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                          to="#"
                          onClick={() => {onNavigationClick(); onSidebarActive();}}
                        >
                          {t(childItem.title)}
                        </NavLink>
                      )
                    }

                    return (
                      <NavLink
                        key={childItem.title}
                        className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                        to={childItem.url}
                        onClick={() => {onNavigationClick()}}
                      >
                        {t(childItem.title)}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          }

          return (
            <NavLink
              key={item.title}
              className={cn('sidebar__item', isActive ? "active is-active" : "")}
              to={item.url}
              onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
            >
              <>
                {item.icon_name && (
                  <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                )}
                {t(item.title)}
              </>
            </NavLink>
          );
        })}
    </>
  );

  const renderCustodianMenu = () => (
    <>
      {location.pathname.indexOf("/admin") === 0 &&
        SETS.custodian.map((item) => {
          const isActive =
            item.hasChildren
              ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
              : item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;

          if (item.hasChildren) {
            return (
              <div
                key={item.title}
                onClick={() => {toggleMenu(item.title); onSidebarActive();}}
                className={cn('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
              >
                <button
                  className="sidebar__head"
                >
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                  <Icon
                    className={cn('arrow-down', 'icon-24')}
                    name="arrow-down"
                    size="24"
                  />
                </button>

                <div
                  className={cn('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
                >
                  {item.children.map((childItem) => {
                    const isActive = location.pathname.indexOf(childItem.url) === 0;
                    if (
                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                      (childItem.title === 'Log Event' && !isLogEventEnabled)
                    ) {
                      return (
                        <NavLink
                          key={childItem.title}
                          className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                          to="#"
                          onClick={() => {onNavigationClick(); onSidebarActive();}}
                        >
                          {t(childItem.title)}
                        </NavLink>
                      )
                    }

                    return (
                      <NavLink
                        key={childItem.title}
                        className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                        to={childItem.url}
                        onClick={() => {onNavigationClick()}}
                      >
                        {t(childItem.title)}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          }

          return (
            <NavLink
              key={item.title}
              className={cn('sidebar__item', isActive ? "active is-active" : "")}
              to={item.url}
              onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
            >
              <>
                {item.icon_name && (
                  <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                )}
                {t(item.title)}
              </>
            </NavLink>
          );
        })}
    </>
  );

  const renderAccountingMenu = () => (
    <>
      {location.pathname.indexOf("/admin") === 0 &&
        SETS.accounting.map((item) => {
          const isActive =
            item.hasChildren
              ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
              : item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;

          if (item.hasChildren) {
            return (
              <div
                key={item.title}
                onClick={() => {toggleMenu(item.title); onSidebarActive();}}
                className={cn('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
              >
                <button
                  className="sidebar__head"
                >
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                  <Icon
                    className={cn('arrow-down', 'icon-24')}
                    name="arrow-down"
                    size="24"
                  />
                </button>

                <div
                  className={cn('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
                >
                  {item.children.map((childItem) => {
                    const isActive = location.pathname.indexOf(childItem.url) === 0;
                    if (
                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled)
                    ) {
                      return (
                        <NavLink
                          key={childItem.title}
                          className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                          to="#"
                          onClick={() => {onNavigationClick(); onSidebarActive();}}
                        >
                          {t(childItem.title)}
                        </NavLink>
                      )
                    }

                    return (
                      <NavLink
                        key={childItem.title}
                        className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                        to={childItem.url}
                        onClick={() => {onNavigationClick()}}
                      >
                        {t(childItem.title)}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          }

          return (
            <NavLink
              key={item.title}
              className={cn('sidebar__item', isActive ? "active is-active" : "")}
              to={item.url}
              onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
            >
              <>
                {item.icon_name && (
                  <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                )}
                {t(item.title)}
              </>
            </NavLink>
          );
        })}
    </>
  );

  const renderAuditorMenu = () => (
    <>
      {location.pathname.indexOf("/admin") === 0 &&
        SETS.auditor.map((item) => {
          const isActive =
            item.hasChildren
              ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
              : item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;

          if (item.hasChildren) {
            return (
              <div
                key={item.title}
                onClick={() => {toggleMenu(item.title); onSidebarActive();}}
                className={cn('sidebar__item sidebar__item_dropdown', subMenuOpen === item.title ? "active is-active wide" : "")}
              >
                <button
                  className="sidebar__head"
                >
                  {item.icon_name && (
                    <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                  )}
                  {t(item.title)}
                  <Icon
                    className={cn('arrow-down', 'icon-24')}
                    name="arrow-down"
                    size="24"
                  />
                </button>

                <div
                  className={cn('sidebar__body', subMenuOpen === item.title ? "active is-active" : "")}
                >
                  {item.children.map((childItem) => {
                    const isActive = location.pathname.indexOf(childItem.url) === 0;
                    if (
                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled)
                    ) {
                      return (
                        <NavLink
                          key={childItem.title}
                          className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                          to="#"
                          onClick={() => {onNavigationClick(); onSidebarActive();}}
                        >
                          {t(childItem.title)}
                        </NavLink>
                      )
                    }

                    return (
                      <NavLink
                        key={childItem.title}
                        className={cn('sidebar__link disable', isActive ? "active is-active" : "")}
                        to={childItem.url}
                        onClick={() => {onNavigationClick()}}
                      >
                        {t(childItem.title)}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          }

          return (
            <NavLink
              key={item.title}
              className={cn('sidebar__item', isActive ? "active is-active" : "")}
              to={item.url}
              onClick={() => {onNavigationClick(); onSidebarActiveForce(); onSidebarVisibleActiveForce();}}
            >
              <>
                {item.icon_name && (
                  <Icon className={cn(item.icon_name, 'icon-24')} name={item.icon_name} size="24" />
                )}
                {t(item.title)}
              </>
            </NavLink>
          );
        })}
    </>
  );

  const renderSidebarNavigation = () =>
    userData && (
      <div className="sidebar__menu sidebar__menu-kyc">
        {location.pathname.indexOf("/admin") !== 0 && renderMainMenu()}

        {userData.rights === USER_RIGHT_TYPES.COMPLIANCE_OFFICER && renderComplianceOfficerMenu()}
        {userData.rights === USER_RIGHT_TYPES.REFERRAL_PARTNER && renderReferralPartnerMenu()}
        {userData.rights === USER_RIGHT_TYPES.CUSTODIAN && renderCustodianMenu()}
        {userData.rights === USER_RIGHT_TYPES.ACCOUNTING && renderAccountingMenu()}
        {userData.rights === USER_RIGHT_TYPES.AUDITOR && renderAuditorMenu()}

        {[USER_RIGHT_TYPES.ADMIN, USER_RIGHT_TYPES.GENERAL_ADMIN].includes(
          userData.rights
        ) && renderAdminMenu()}
      </div>
  );

  return (
    <>
      { renderSidebarNavigation() }
    </>
  );
}

RenderMainNavigation.propTypes = {
  // from HOCs
  location: PropTypes.object,
}

export default compose(withRouter)(RenderMainNavigation);
