import React, { useState } from 'react'
import { MenuItem } from '@material-ui/core'
import ImageUpload from './ImageUpload'
import { useTranslation } from 'react-i18next'
import { isFiat } from '../utils'
import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'
import {
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE,
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT,
} from '../constants/customer'

const KycFormDocumentProofOfResidence = ({
  publicSettings,
  onDropDocumentProofOfResidence,
  documentProofOfResidence,
  removeDropDocumentProofOfResidence,
  currency,
  currentLevel,
  values,
  setValues
}) => {
  const maxLv = publicSettings.kyc_levels[5].enable && publicSettings.kyc_levels[5].enable.enable_lvl ? 5 :
    publicSettings.kyc_levels[4].enable && publicSettings.kyc_levels[4].enable.enable_lvl ? 4 : 3;

  const { t } = useTranslation();
  if (!isFiat(currency) && !publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto.proofOfResidenceUpload) {
    return <></>;
  }

  if (isFiat(currency) && !publicSettings.kyc_levels[1].acceptedFieldIndividualFiat.proofOfResidenceUpload) {
    return <></>;
  }

  if (publicSettings && publicSettings.enable_proof_of_residence_checking) {
    return <></>;
  }

  return (
    <>
      <div className="editor">
        <div className="field easy-onboarding__proofofresidence">
          <div className="field__label">
            {t('Proof of residence')}
            <span className="text__red"> *</span>
          </div>

          <div className={classNames('field__wrap field__wrap-select')}>
            <TextField
              select
              margin="none"
              variant="outlined"
              fullWidth
              value={values.proof_of_residence_type}
              onChange={({ target: { value }}) => { setValues({...values, proof_of_residence_type: value}) }}
              helperText={t(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT[values.proof_of_residence_type]) || ''}
            >
              { Object.entries(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE)
                .filter(x => (publicSettings.use_registration_confirm_for_proof_of_residence && currentLevel === maxLv ? x[0] === "REGISTRATION_CONFIRMATION" : x[0] !== "REGISTRATION_CONFIRMATION"))
                .map(type => (
                  <MenuItem value={type[0]} key={type[0]}>
                    {t(type[1])}
                  </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <br/>
        <div className="field easy-onboarding__documentmustnotolderthan3months">
          <ImageUpload
            labelText={
              <span className="text__red">
                {t('Documents must not older than 3 months.')}
              </span>
             }
            isRequired
            buttonText={t('Choose Document')}
            buttonClassName="button"
            onChange={onDropDocumentProofOfResidence}
            maxFileSize={10000000}
            withPreview
            singleImagePick
            singleFile
            defaultImages={[]}
          >
            <div className="file__wrap_text text__center">
              { t('Drag and drop the document') }
              <br />
              { t('Suport: JPG, JPEG, GIF, PNG, PDF.') }
              <br />
              { t('Max: 10MB') }
            </div>
          </ImageUpload>
        </div>

        {documentProofOfResidence && (
          <>
            <div className="space space__32">{' '}</div>
            <div className="settings__top">
              <div className="settings__label">
                {(documentProofOfResidence && documentProofOfResidence.img && documentProofOfResidence.img.name) || t('Cannot load file')}
              </div>

              <button
                className="button-stroke button-small settings__button"
                onClick={(e) => {
                  removeDropDocumentProofOfResidence();
                }}
              >
                { t('Remove') }
              </button>
            </div>
          </>
        )}
        <div className="space space__32">{' '}</div>
      </div>
    </>
  );
};

export default KycFormDocumentProofOfResidence;
