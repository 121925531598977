import { useQuery } from "@apollo/client";
import { ME } from "../queriesAndMutations";
import {
  VIDEO_ID_ATTENDED_AUTHORIZATION,
  VIDEO_ID_UNATTENDED_AUTHORIZATION,
  VIDEO_ID_RECORDED_EXTRACTED_DATA,
} from "../queriesAndMutations/authQuery";

// eslint-disable-next-line import/prefer-default-export
export const useMe = (options = {}) => useQuery(ME, options);

export const useVideoAttended = () => useQuery(VIDEO_ID_ATTENDED_AUTHORIZATION);

export const useVideoUnAttended = () =>
  useQuery(VIDEO_ID_UNATTENDED_AUTHORIZATION);

export const useVideoRecoredExtractedData = (video_ident_id) =>
  useQuery(VIDEO_ID_RECORDED_EXTRACTED_DATA, {
    variables: { video_ident_id: video_ident_id },
  });
