import React, { useState, useEffect } from "react"
import axios from 'axios'
import { endpoint } from '../../config'
import { getTextAvatarUrl, toaster } from '../../utils'
import { useCookies } from "react-cookie";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { useTranslation } from 'react-i18next'
import InputThemeField from '../InputThemeField'
import Icon from '../Icon'
import classNames from "classnames";
import { PEP_OPTIONS } from '../CompanyDirectors/CardForm'

const CompanyStep2 = ({
  values,
  setValues,
  toPrevStep,
  toNextStep,
}) => {
  const { t } = useTranslation()
  const [{ jwt }] = useCookies();
  const defaultNewDirector = {
    firstName: '',
    lastName: '',
    email: '',
    signatureRight: 'SINGLE',
    personType: '',
    willSignForm: false,
  }
  const [newDirector, setNewDirector] = useState({...defaultNewDirector})
  const [directorError, setDirectorError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    userStatus: [],
    personType: '',
    willSignForm: false,
  })
  const [showAddForm, setShowAddForm] = useState(false);
  const [userStatus, setUserStatus] = useState({
    email: '',
    userStatus: []
  });

  const validateNewDirector = () => {
    const error = {
      firstName: (!newDirector.firstName) ? 'First name is required' : '',
      lastName: (!newDirector.lastName) ? 'Last name is required' : '',
      email: (!newDirector.email)
        ? 'Email is required'
        : (newDirector.email.indexOf('@') < 0 || newDirector.email.indexOf('@') === newDirector.email.length + 1)
          ? 'Invalid email'
          : '',

    }
    setDirectorError(error);
    return Object.values(error).filter(e => e).length === 0;
  }

  const updateUserStatus = email => {
    axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwt || localStorage.getItem('jwt'),
      },
      data : JSON.stringify({
        query: `query getUserStatus($email: String!) {
          getUserStatus(email: $email)
        }`,
        variables: { email: email }
      })
    }).then(response => {
      setUserStatus({
        email: email,
        userStatus: response.data.data.getUserStatus
      })
    })
  }

  const addNewDirector = () => {
    if (validateNewDirector()) {
      const email = newDirector.email;

      setValues({
        ...values,
        directors: [
          ...values.directors,
          { ...newDirector, status: 'pending' }
        ]
      })

      updateUserStatus(email);

      setNewDirector(defaultNewDirector)
      setShowAddForm(false);
    }
  }

  useEffect(() => {
    setValues({
      ...values,
      directors: values.directors.map(director => (
        director.email === userStatus.email
          ? { ...director, userStatus: userStatus.userStatus }
          : { ...director }
        )
      ),
    })
    // eslint-disable-next-line
  }, [userStatus]);

  const removeDirector = (index) => {
    if (values.directors.length) {
      const directors = values.directors;
      const removedDirector = directors.splice(index, 1)[0];

      setValues({
        ...values,
        directors: directors,
      })

      if (values.directorAsUbo && removedDirector.email === values.directorAsUbo.email) {
        setValues({
          ...values,
          directorAsUbo: null,
        })
      }

      toaster.success(t('Remove authorized signatory successfully'));
    }
  }

  const renderUserStatus = (statuses) => {
    const statusMap = {
      NOT_FOUND: {text: 'USER NOT REGISTERED', className: 'status-red'},
      EMAIL_NOT_VERIFIED: {text: 'EMAIL NOT VERIFIED', className: 'status-red'},
      EMAIL_VERIFIED: {text: 'EMAIL VERIFIED', className: 'status-green-dark'},
      DIRECTOR_PROFILE_NOT_SUBMITTED: {text: 'AUTHORIZED SIGNATORY PROFILE NOT SUBMITTED', className: 'status-idle'},
      DIRECTOR_PROFILE_PENDING: {text: 'DIRECTOR PROFILE SUBMITTED', className: 'status-green-dark'},
      DIRECTOR_PROFILE_REOPEN: {text: 'DIRECTOR PROFILE REOPEN', className: 'status-pending-dark'},
      DIRECTOR_PROFILE_REJECTED: {text: 'DIRECTOR PROFILE REJECTED', className: 'status-red-dark'},
      DIRECTOR_PROFILE_PASSED: {text: 'DIRECTOR PROFILE PASSED', className: 'status-green-dark'},
      DIRECTOR_PROFILE_APPROVED: {text: 'DIRECTOR PROFILE APPROVED', className: 'status-green-dark'},
      VIDEO_IDENT_NOT_NEEDED: {text: 'LIVENESS CHECK NOT STARTED', className: 'status-idle'},
      VIDEO_IDENT_PENDING: {text: 'LIVENESS CHECK PENDING', className: 'status-pending-dark'},
      VIDEO_IDENT_PASSED: {text: 'LIVENESS CHECK PASSED', className: 'status-green-dark'},
      VIDEO_IDENT_DENIED: {text: 'LIVENESS CHECK DENIED', className: 'status-red-dark'},
      VIDEO_IDENT_NOT_STARTED: {text: 'LIVENESS CHECK NOT STARTED', className: 'status-idle'},
    }
    return statuses.map((status, index) => (
      <span
        key={index}
        className={`${((statusMap[status] && statusMap[status].className) || 'status-primary-dark')} transactions__status`}
        style={{marginRight: '4px', marginBottom: '4px'}}
      >
        {t((statusMap[status] && statusMap[status].text) || t(status))}
      </span>
    ))
  }

  const changeAddedDirector = (index, field, value) => {
    setValues({
      ...values,
      directors: values.directors.map((director, dIndex) => {
        return index === dIndex
          ? { ...director, [field]: value }
          : director;
      }),
    });
  }

  return (
    <div className="settings__wrapper">
      <div className="settings__list">
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Add authorized signatories')}
          </div>

          <div className="settings__fieldset">
            <div className="settings__row flex__row flex__wrap">
              <div className="field">
                <button className="button-stroke w-full text__center" onClick={() => setShowAddForm(true)}>
                  <Icon
                    className={classNames('check')}
                    name='check'
                    size="24"
                  />{" "}
                  {t('Add new authorized signatory')}
                </button>
              </div>
            </div>

            <div className="field">
              <div className="transactions__row">{' '}</div>
              {values.directors.map((director, index) => (
                <>
                  <div key={index} className={'transactions__row row__force row__force_rwd ' + (director.status === 'verified' ? 'verified' : 'pending')}>
                    <div className="transactions__col">
                      <div className="transactions__details">
                        <div className="transactions__product">
                          <img
                            className="radius__full"
                            src={getTextAvatarUrl(`${director.firstName[0].toUpperCase()}`)}
                            alt={`${director.firstName} ${director.lastName}`}
                          />
                        </div>
                      </div>

                      <span className="space space__8 tablet-show">{' '}</span>

                      {director.status === 'verified' ? (
                        <div className="status-green-dark transactions__status">
                          {t('Verified')}
                        </div>
                      ) : (
                        <div className="status-pending-dark transactions__status">
                          {t('pending')}
                        </div>
                      )}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Name')}
                      </div>
                      {director.firstName} {director.lastName}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Email')}
                      </div>
                      {director.email}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Status')}
                        {' '}
                        { director.status === 'verified' ? 'Verified' : 'Not finished'}
                      </div>

                      { director.userStatus && (
                        <div className="content_status">
                          { renderUserStatus(director.userStatus) }
                        </div>
                      )}
                    </div>

                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Signature Right')}
                      </div>
                      <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                        <div className="field__wrap">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-label="acknowledgement-of-receipt"
                              name="acknowledgement-of-receipt"
                              value={director.signatureRight}
                              onChange={({target: {value}}) => changeAddedDirector(index, 'signatureRight', value) }
                            >
                              <FormControlLabel value="SINGLE" control={<Radio color="primary" />} label={t('Single')} />
                              <FormControlLabel value="COLLECTIVE" control={<Radio color="primary" />} label={t('Collective')} />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className={classNames('transactions__col')}>
                      <div className="transactions__label">{t('Will sign the form')}</div>
                      <div className="field field__checkbox-kyc nospace__bottom">
                        <label className="checkbox">
                          <input
                            className="checkbox__input"
                            type="checkbox"
                            onChange={({ target: { checked }}) => changeAddedDirector(index, 'willSignForm', checked)}
                            checked={director.willSignForm}
                          />
                          <span className="checkbx__inner">
                            <span className="cehckbox__tick">&nbsp;</span>
                            <span className="checkbox__text">&nbsp;</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="space space__32">{' '}</div>
                  <div className="field">
                    <button className="button-stroke w-full text__center" onClick={() => removeDirector(index)} disabled={!values.directors.length}>
                      <Icon
                        className={classNames('close')}
                        name='close'
                        size="24"
                      />{" "}
                      {t('Remove authorized signatory')}
                    </button>
                  </div>

                  <div className="field">
                    <div className="field__label">
                      {t('Politically Exposed Persons')}
                    </div>

                    <div className={
                      classNames(
                        'field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray',
                      )
                    }>
                      <RadioGroup
                        value={director.personType}
                        onChange={({ target: { value }}) => changeAddedDirector(index, 'personType', value)}
                      >
                        { PEP_OPTIONS.map((name) => (
                            <FormControlLabel
                              key={name}
                              label={t(name)}
                              value={name}
                              control={<Radio color="primary" />}
                            />
                          ))}
                      </RadioGroup>
                    </div>
                  </div>

                  { director.personType && director.personType !== 'notPoliticalPerson' &&
                    <>
                      <div className="space space__32">{' '}</div>
                      <div className="settings__row flex__row flex__wrap have__fields">
                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          required
                          label={t('Exact description of the function')}
                          value={director.duties}
                          onChange={({ target: { value }}) => changeAddedDirector(index, 'duties', value)}
                        />

                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          required
                          label={t('Relationship to PEP')}
                          value={director.relationshipToPEP}
                          onChange={({ target: { value }}) => changeAddedDirector(index, 'relationshipToPEP', value)}
                        />
                      </div>
                    </>
                  }
                </>
              ))}

              {showAddForm && (
                <>
                  <div className="space space__32">{' '}</div>
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Your name')}
                    value={newDirector.firstName}
                    error={!!directorError.firstName}
                    helperText={directorError.firstName}
                    onChange={({ target: { value }}) => setNewDirector({ ...newDirector, firstName: value.trim()})}
                  />

                  <div className="space space__24">{' '}</div>
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Your surname')}
                    value={newDirector.lastName}
                    error={!!directorError.lastName}
                    helperText={directorError.lastName}
                    onChange={({ target: { value }}) => setNewDirector({ ...newDirector, lastName: value.trim()})}
                  />

                  <div className="space space__24">{' '}</div>
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Email address')}
                    type="email"
                    value={newDirector.email}
                    error={!!directorError.email}
                    helperText={directorError.email}

                    onChange={({ target: { value }}) => setNewDirector({ ...newDirector, email: value.trim().toLowerCase()})}
                  />

                  <div className="space space__24">{' '}</div>
                  <div className="field">
                    <div className="field__label">
                      {t('Signature Right')}

                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-select">
                      <TextField
                        required
                        select
                        value={newDirector.signatureRight}
                        onChange={({ target: { value }}) => setNewDirector({ ...newDirector, signatureRight: value})}
                      >
                        <MenuItem value='SINGLE'>
                          {t('Single')}
                        </MenuItem>
                        <MenuItem value='COLLECTIVE'>
                          {t('Collective')}
                        </MenuItem>
                      </TextField>
                    </div>
                  </div>

                  <div className="space space__24">{' '}</div>
                  <div className="field">
                    <button className="button" onClick={addNewDirector}>
                      {t('Add Director')}
                    </button>
                  </div>
                </>
              )}
            </div>

          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={toPrevStep}>
            {t('Previous')}
          </button>

          <button className="button" onClick={toNextStep}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CompanyStep2
