import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useCookies } from "react-cookie";
import axios from 'axios'
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  MenuItem,
} from '@material-ui/core'
import { endpoint } from '../../../config'
import { getTextAvatarUrl, toaster } from '../../../utils'
import InputThemeField from '../../InputThemeField'
import Icon from '../../Icon'
import styles from './styles.module.scss'

export const PEP_OPTIONS = [
  'notPoliticalPerson',
  'Are you a PEP or have you held one of the following important public office positions',
  'Are you an immediate family member',
  'Are you known to be a joint beneficial owner of legal entities'
]

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
}) => {
  const { t } = useTranslation()
  const [{ jwt }] = useCookies();
  const defaultNewDirector = {
    firstName: '',
    lastName: '',
    email: '',
    signatureRight: 'SINGLE',
    personType: '',
    duties: '',
    relationshipToPEP: '',
    willSignForm: false,
  }
  const [newDirector, setNewDirector] = useState({...defaultNewDirector})
  const [directorError, setDirectorError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    userStatus: [],
    personType: '',
    duties: '',
    relationshipToPEP: '',
    willSignForm: false,
  })
  const [showAddForm, setShowAddForm] = useState(false);
  const [userStatus, setUserStatus] = useState({
    email: '',
    userStatus: []
  });

  const validateNewDirector = () => {
    const error = {
      firstName: (!newDirector.firstName) ? 'First name is required' : '',
      lastName: (!newDirector.lastName) ? 'Last name is required' : '',
      email: (!newDirector.email)
        ? 'Email is required'
        : (newDirector.email.indexOf('@') < 0 || newDirector.email.indexOf('@') === newDirector.email.length + 1)
          ? 'Invalid email'
          : '',

    }
    setDirectorError(error);
    return Object.values(error).filter(e => e).length === 0;
  }

  const setNewDirectorField = (field) => ({ target: { value }}) => {
    if (field === 'email') {
      setNewDirector({
        ...newDirector,
        [field]: value.trim().toLowerCase()
      })
    } else if (field === 'willSignForm') {
      setNewDirector({
        ...newDirector,
        [field]: !newDirector.willSignForm
      })
    } else {
      setNewDirector({
        ...newDirector,
        [field]: value.trim()
      })
    }
  }

  const updateUserStatus = email => {
    axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwt || localStorage.getItem('jwt'),
      },
      data : JSON.stringify({
        query: `query getUserStatus($email: String!) {
          getUserStatus(email: $email)
        }`,
        variables: { email: email }
      })
    }).then(response => {
      setUserStatus({
        email: email,
        userStatus: response.data.data.getUserStatus
      })
    })
  }

  const addNewDirector = () => {
    if (validateNewDirector()) {
      const email = newDirector.email;

      setValues({
        ...values,
        directors: [
          ...values.directors,
          { ...newDirector, status: 'pending' }
        ]
      })

      updateUserStatus(email);

      setNewDirector(defaultNewDirector)
      setShowAddForm(false);
    }
  }

  const changeAddedDirector = (index, field, value) => {
    setValues({
      ...values,
      directors: values.directors.map((director, dIndex) => {
        return index === dIndex
          ? { ...director, [field]: value }
          : director;
      }),
    });
  }

  const renderUserStatus = (statuses) => {
    if (!statuses || statuses.length === 0) {
      return null
    }

    const statusMap = {
      NOT_FOUND: {text: t('USER NOT REGISTERED'), className: 'status-red'},
      EMAIL_NOT_VERIFIED: {text: t('EMAIL NOT VERIFIED'), className: 'status-red'},
      EMAIL_VERIFIED: {text: t('EMAIL VERIFIED'), className: 'status-green-dark'},
      KYC_NOT_SUBMITTED: {text: t('KYC NOT SUBMITTED'), className: 'status-pending-dark'},
      KYC_PENDING: {text: t('KYC PENDING'), className: 'status-pending-dark'},
      KYC_REOPEN: {text: t('KYC REOPEN'), className: 'status-pending-dark'},
      KYC_REJECTED: {text: t('KYC REJECTED'), className: 'status-red-dark'},
      KYC_PASSED: {text: t('KYC PASSED'), className: 'status-green-dark'},
      KYC_APPROVED: {text: t('KYC APPROVED'), className: 'status-green-dark'},
      VIDEO_IDENT_NOT_NEEDED: {text: t('LIVENESS CHECK NOT STARTED'), className: 'status-idle'},
      VIDEO_IDENT_PENDING: {text: t('LIVENESS CHECK PENDING'), className: 'status-pending-dark'},
      VIDEO_IDENT_PASSED: {text: t('LIVENESS CHECK PASSED'), className: 'status-green-dark'},
      VIDEO_IDENT_DENIED: {text: t('LIVENESS CHECK DENIED'), className: 'status-red-dark'},
      VIDEO_IDENT_NOT_STARTED: {text: t('LIVENESS CHECK NOT STARTED'), className: 'status-pending-dark'},
    }
    return statuses.map((status, index) => (
      <span
        key={index}
        className={classNames((statusMap[status] && statusMap[status].className) || 'status-primary-dark', styles.director__contentstatus)}
        style={{marginRight: '4px', marginBottom: '4px'}}
      >
        {t((statusMap[status] && statusMap[status].text) || status)}
      </span>
    ))
  }

  useEffect(() => {
    setValues({
      ...values,
      directors: values.directors.map(director => (
        director.email === userStatus.email
          ? { ...director, userStatus: userStatus.userStatus }
          : { ...director }
        )
      ),
    })
    // eslint-disable-next-line
  }, [userStatus]);

  return (
    <div className="card easy-onboarding__company-addauthorized-signatories">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">
            {t('Add authorized signatories')}
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__wrap">
              <button
                className="button-stroke w-full text__center easy-onboarding__company-addnewauthorizedsignatory"
                type="button"
                onClick={() => setShowAddForm(true)}
              >
                <Icon
                  className={classNames('check')}
                  name='check'
                  size="24"
                />{" "}
                {t('Add new authorized signatory')}
              </button>

              <div className="space space__16">{' '}</div>
            </div>
          </div>

          {values.directors.map((director, index) => (
            <>
              <div className="field easy-onboarding__company-directordata" key={director.email}>
                <div className="transactions__row">{' '}</div>
                <div className="transactions__row row__force row__force_rwd pending">
                  <div className={classNames('transactions__col', styles.col__vert)}>
                    <div className="transactions__label mb__0-important">{t('Name')}</div>
                    <div className="space space__8 tablet-show">{' '}</div>
                    {`${director.firstName} ${director.lastName}`}
                  </div>
                  <div className={classNames('transactions__col', styles.col__vert)}>
                    <div className="transactions__label mb__0-important">{t('Email')}</div>
                    <div className="space space__8 tablet-show">{' '}</div>
                    {director.email}
                  </div>

                  <div className={classNames('transactions__col', styles.director__header, styles.col__vert)}>
                    <div className="transactions__details">
                      <div className="transactions__product">
                        <img
                          src={getTextAvatarUrl(`${director.firstName.toUpperCase()} ${director.lastName.toUpperCase()}`)}
                          alt={`${director.firstName.toUpperCase()}`}
                          className="radius__full"
                        />
                      </div>
                    </div>

                    <div className="space space__8 tablet-show">{' '}</div>

                    <div className="status-green-dark transactions__status">{t('Status')} </div>
                  </div>

                  <div className={classNames('transactions__col', styles.director__status)}>
                    <div className="transactions__label">{t('Status not finished')}</div>
                    <div className={classNames('content-status', styles.director__contentstatus_wrapper)}>
                      {renderUserStatus(director.userStatus)}
                    </div>
                  </div>

                  <div className={classNames('transactions__col', styles.col__vert)}>
                    <div className="transactions__label mb__0-important">{t('Signature Right')}</div>
                    <div className="space space__8 tablet-show">{' '}</div>

                    <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1 mb__0-important">
                      <div className="field__wrap">
                        <RadioGroup
                          row
                          defaultValue="signature-single"
                          value={director.signatureRight}
                          onChange={({ target: { value }}) => changeAddedDirector(index, 'signatureRight', value)}
                        >
                          <FormControlLabel
                            value="SINGLE"
                            label={t('Single')}
                            control={<Radio color="primary" />}
                          />
                          <FormControlLabel
                            value="COLLECTIVE"
                            label={t('Collective')}
                            control={<Radio color="primary" />}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>

                  <div className={classNames('transactions__col', styles.col__vert)}>
                    <div className="transactions__label mb__0-important">{t('Will sign the form')}</div>
                    <div className="space space__8 tablet-show">{' '}</div>

                    <div className="field field__checkbox-kyc nospace__bottom">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          onChange={({ target: { checked }}) => changeAddedDirector(index, 'willSignForm', checked)}
                          checked={director.willSignForm}
                          className="checkbox__input"
                        />
                        <span className="checkbox__inner">
                          <span className="checkbox__tick">&nbsp;</span>
                          <span className="checkbox__text">&nbsp;</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <button
                  className="button-stroke w-full text__center easy-onboarding__company-removeauthorizedsignatory"
                  type="button"
                  onClick={() => {
                    const directors = values.directors;
                    const removedDirector = directors.splice(index, 1)[0];

                    setValues({
                      ...values,
                      directors: directors,
                    })

                    if (values.directorAsUbo && removedDirector.email === values.directorAsUbo.email) {
                      setValues({
                        ...values,
                        directorAsUbo: null,
                      })
                    }

                    toaster.success(t('Remove authorized signatory successfully'));
                  }}
                >
                  <Icon
                    className={classNames('close fill__red')}
                    name='close'
                    size="24"
                  />{" "}
                  {t('Remove authorized signatory')}
                </button>
              </div>

              <div className="field easy-onboarding__company-politicallyexposedpersons">
                <div className="field__label">
                  {t('Politically Exposed Persons')}
                </div>

                <div className={
                  classNames(
                    'field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray',
                    styles.field__wrap
                  )
                }>
                  <RadioGroup
                    value={director.personType}
                    onChange={({ target: { value }}) => changeAddedDirector(index, 'personType', value)}
                  >
                    { PEP_OPTIONS.map((name) => (
                        <FormControlLabel
                          key={name}
                          label={t(name)}
                          value={name}
                          control={<Radio color="primary" />}
                        />
                      ))}
                  </RadioGroup>
                </div>
              </div>

              { director.personType && director.personType !== 'notPoliticalPerson' &&
                <>
                  <div className="settings__row flex__row flex__wrap have__fields">
                    <InputThemeField
                      classWrapper="field easy-onboarding__company-exactdescriptionofthefunction"
                      classLabel="field__label"
                      classInput="field__input"
                      required
                      label={t('Exact description of the function')}
                      value={director.duties}
                      onChange={({ target: { value }}) => changeAddedDirector(index, 'duties', value)}
                    />

                    <InputThemeField
                      classWrapper="field easy-onboarding__company-relationshiptopep"
                      classLabel="field__label"
                      classInput="field__input"
                      required
                      label={t('Relationship to PEP')}
                      value={director.relationshipToPEP}
                      onChange={({ target: { value }}) => changeAddedDirector(index, 'relationshipToPEP', value)}
                    />
                  </div>
                </>
              }

            </>
          ))}

          {showAddForm && (
            <>
              <InputThemeField
                classWrapper="field easy-onboarding__company-firstname"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('First name')}
                value={newDirector.firstName}
                error={[directorError.firstName]}
                onChange={setNewDirectorField('firstName')}
              />

              <InputThemeField
                classWrapper="field easy-onboarding__company-surname"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('Surname')}
                value={newDirector.lastName}
                error={[directorError.lastName]}
                onChange={setNewDirectorField('lastName')}
              />

              <InputThemeField
                classWrapper="field easy-onboarding__company-emailaddress"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('Email address')}
                value={newDirector.email}
                error={[directorError.email]}
                onChange={setNewDirectorField('email')}
              />

              <div className="field easy-onboarding__company-signatureright">
                <div className="field__label">
                  {t('Signature Right')}
                  <span className="text__red"> *</span>
                </div>

                <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                  <TextField
                    select
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={newDirector.signatureRight}
                    onChange={setNewDirectorField('signatureRight')}
                  >
                    <MenuItem value="SINGLE">{t('Single')}</MenuItem>
                    <MenuItem value="COLLECTIVE">{t('Collective')}</MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="field easy-onboarding__company-adddirector">
                <button className="button" onClick={addNewDirector}>
                  {t('Add Director')}
                </button>
              </div>
            </>
          )}
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Next')}</button>
        </div>

      </div>
    </div>
  )
}

export default CardForm
