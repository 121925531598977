import React, { useEffect } from 'react'
import { TOKEN_TYPES } from '../../constants/settings'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SwitchCheckboxTheme from '../SwitchCheckbox'
import CardInformation from '../InformationCard'

const CompanyStep5DisclaimerEquityBond = ({
  publicSettings,
  checkboxValues,
  setCheckboxValues,
  prospectusOrIssuing,
  prospectusOrIssuingDate,
  setEnableSubmit
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const enable = [
      'IActOnMyOwnAccount',
      'clientStatement',
      'confirmationTerms1',
      'declarationOfConsent1',
      'declarationOfConsent2',
      'declarationOfConsent3',
      'declarationOfConsent4',
      'declarationOfConsent5',
      'declarationOfConsent6',
      'declarationOfConsent7',
    ].reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked})
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Acknowledgement of Receipts')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              onChange={changeCheckbox('IActOnMyOwnAccount')}
              defaultChecked={checkboxValues.IActOnMyOwnAccount}
              value="IActOnMyOwnAccount"
              isRemoveLine
              isGrayText
              isLeftSwitch
              switchText={
              <>
                {t('I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others.')}
                {' '}
                {t('Or The Beneficial Owner Is, If Different From The Subscriber')}
                :
              </>
            }
            />
          </div>
        </div>

        <div className="field">
          <div className="space space__32">{' '}</div>
          <CardInformation
            color="card__information-primary"
            title={t('Note')}
          >
            <>
              {t('A Beneficial Owner In Accordance With Art. 2 Para. 1 Let. E) DDA Is A Natural Person On Whose Behalf Or In Whose Interest A Transaction Or Activity Is Carried Out Or A Business Relationship Is Ultimately Established.')}
              {' '}
              {t('In The Case Of Legal Entities, It Is Also The Natural Person In Whose Property Or Under Its Control The Legal Entity Ultimately Stands.')}
              <br/>
              {t('According to Art. 3 of the Liechtenstein Due Diligence Ordinance (DDO) in particular')}:
              <br/>
              {t('in the case of corporations, including corporately structured establishments or trust companies, and non-personality corporations')}:
              {' '}
              {t('those individuals who, in the end, directly or indirectly hold or control share or voting rights of 25% or more in such entities, or otherwise control these entities exercise')};
              <br/>
              {t('in the case of foundations, trusts and foundation-like structured establishments or trust enterprises')}:
              {' '}
              {t('those natural persons who are effective, non-trustee founders, founders or trustors, regardless of whether they exercise control over them after the legal entity has been established')};
              {' '}
              {t('those natural or legal persons who are members of the foundation council or of the board of trustees')};
              {' '}
              {t('any natural persons who are protectors or persons with similar or equivalent functions')};
              {' '}
              {t('those natural persons who are beneficiaries')};
              {' '}
              {t('if no beneficiaries are yet to be designated, the group of persons in whose interest the legal entity is primarily established or operated')};
              {' '}
              {t('in addition, those individuals who ultimately control the entity through direct or indirect ownership or otherwise.')}
            </>
          </CardInformation>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Client Statement')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="clientStatement"
              defaultChecked={checkboxValues.clientStatement}
              onChange={changeCheckbox('clientStatement')}
              switchText={`
                The Legal Entity confirms that it is the beneficial owner of
                all assets held with ${
                  publicSettings &&
                  publicSettings.company &&
                  publicSettings.company.name
                }.
              `}
            />
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Declaration of Consent')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="declarationOfConsent1"
              defaultChecked={checkboxValues.declarationOfConsent1}
              onChange={changeCheckbox('declarationOfConsent1')}
              switchText={`
                I Hereby Confirm The Receipt Of The ${prospectusOrIssuing} Of
                The Issuer Dated ${prospectusOrIssuingDate} Regarding The
                ${publicSettings.token_type === TOKEN_TYPES.BOND
                    ? "Bond"
                    : "Shares"
                },
                Which Among Other Things Contain The Information According To Art.
                5 Liechtenstein Act On Distance Marketing Of Consumer Financial
                Services.
              `}
            />

            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="declarationOfConsent2"
              defaultChecked={checkboxValues.declarationOfConsent2}
              onChange={changeCheckbox('declarationOfConsent2')}
              switchText={
                <>
                  {"I Hereby Confirm To Have Been Informed "}
                  <NavLink to="/right-of-withdrawal" target="_blank" rel="noopener noreferrer">
                    {t('About The Right Of Withdrawal.')}
                  </NavLink>
                </>
              }
            />

            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="declarationOfConsent3"
              defaultChecked={checkboxValues.declarationOfConsent3}
              onChange={changeCheckbox('declarationOfConsent3')}
              switchText={(
                <>
                {t('I Hereby Declare And Confirm That, At The Same Time As The Subscription Is Accepted, I Am Requested To Transfer The Subscription Amount To The Following Special Account Of The Issuer Within The Duration Of The Offer.')}
                {t('Please Refer To Further Instructions In Your My Account (This Account Will Be Created Once The KYC Form Has Been Successfully Submitted)')}
                </>
              )}
            />
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Confirmations')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              value="confirmationTerms1"
              defaultChecked={checkboxValues.confirmationTerms1}
              onChange={changeCheckbox('confirmationTerms1')}
              switchText={
              <div>
                <span>
                  {t('I Hereby Confirm That, Before Subscribing, I Have Received And Read The PROSPECTUS_OR_ISSUING Of The Issuer Dated PROSPECTUS_OR_ISSUING_DATE Regarding The Shares, In Particular The Risk Information, Promptly And In Full And I Agree With The Content Of The Prospectus, And In Particular That', {
                    PROSPECTUS_OR_ISSUING: prospectusOrIssuing,
                    PROSPECTUS_OR_ISSUING_DATE: prospectusOrIssuingDate,
                  })}
                </span>
                <br />
                <small>{t('I Accept The Subscription Applications')}</small>
                <br />
                <small>
                  {t('I Have Duly Noted The Sales Restrictions Stipulated In The PROSPECTUS_OR_ISSUING And Hereby Confirm That Those Restrictions Are Observed, Especially I Certify That I As A  Single Natural Person Or Legal Entity', {
                    PROSPECTUS_OR_ISSUING: prospectusOrIssuing
                  })}
                </small>
                <br />
                <ul className="circle-bullet">
                  { (publicSettings.enable_blacklist_citizen && publicSettings.blacklist_citizen_choosen) && (
                    <li>
                      {t('Am Not A Citizen Of The CITIZEN', {CITIZEN: publicSettings.blacklist_citizen_choosen})}
                    </li>
                  )}
                  <li>
                    <small>
                      {t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia')}
                    </small>
                  </li>
                  <li>
                    <small>
                      {t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories')}
                    </small>
                  </li>
                  <li>
                    <small>
                      {t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia')}
                    </small>
                  </li>
                  <li>
                    <small>
                      {t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia')}
                    </small>
                  </li>
                </ul>
                <br />
                <small>
                  {t('I Have Duly Noted The Risks And Their Potential Implications Described In The PROSPECTUS_OR_ISSUING And Hereby Accept Them', {
                    PROSPECTUS_OR_ISSUING: prospectusOrIssuing,
                  })}
                </small>
              </div>
            }
            />
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Declaration of Consent')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="declarationOfConsent4"
              defaultChecked={checkboxValues.declarationOfConsent4}
              onChange={changeCheckbox('declarationOfConsent4')}
              switchText={
                <>
                  {t('I Hereby Consent To My Personal Data Being Processed By The Issuer And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular The Issuer’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used.')}
                  {' '}
                  {t('The Data Collected May Also Be Used For The Purposes Of Advertising By Email And Post, Regardless Of A Contractual Relationship.')}
                  {' '}
                  {t('Please')}
                  <NavLink to="/declaration-of-consent" target="_blank" rel="noopener noreferrer">
                    {t('See The Declaration Of Consent')}
                  </NavLink>
                </>
              }
            />
          </div>
        </div>

        <div className="field">
          <div className="space space__32">{' '}</div>
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              value="declarationOfConsent5"
              defaultChecked={checkboxValues.declarationOfConsent5}
              onChange={changeCheckbox('declarationOfConsent5')}
              switchText={
                <>
                  {`
                    The Legal Entity Hereby Declare That We Have Been Provided
                    With The
                  `}
                  <NavLink to="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</NavLink>
                  {`
                    Of The Issuer And That We Have Been Informed About How Our
                    Data Will Be Processed And Our Data Protection Rights.
                  `}
                </>
              }
            />
          </div>
        </div>

        <div className="field">
          <div className="space space__32">{' '}</div>
          <CardInformation
            color="card__information-red"
            title={t('Warnings')}
          >
            <>
              {`
                The Acquisition Of The
              `}
              {
                publicSettings.token_type === TOKEN_TYPES.BOND
                  ? "Bond"
                  : "Shares"
              }
              {`
                Involves Considerable Risks And May Result In The Complete
                Loss Of The Invested Assets. The Issuer
                Indicates That It Does Not Assess Whether (1) The
              `}
              {
                publicSettings.token_type === TOKEN_TYPES.BOND
                  ? "Bond"
                  : "Shares"
              }
              {`
                Comply With The Investment Objectives Of The subscriber,
                (2) The Resulting Investment Risk Is Financially Viable For
                The subscriber Of Its Investment Objectives And (3) The
                subscriber With His / Her / Its Knowledge And Experiences
                Can Understand The Resulting Investment Risks.
              `}
            </>
          </CardInformation>
          <div className="space space__32">{' '}</div>
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              value="declarationOfConsent6"
              defaultChecked={checkboxValues.declarationOfConsent6}
              onChange={changeCheckbox('declarationOfConsent6')}
              switchText={`
                I Hereby Confirm That The Declarations Made To The Best Of My
                Knowledge And Belief Are Correct And Complete. Any Changes To The
                Aforementioned Circumstances Will Be Communicated To The Issuer
                Without Delay In Written Form And Must Be Forwarded An Updated
                Self-Assessment Within 30 Days Upon Request.
              `}
            />
          </div>
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              value="declarationOfConsent7"
              defaultChecked={checkboxValues.declarationOfConsent7}
              onChange={changeCheckbox('declarationOfConsent7')}
              switchText={`
              I Have Read The Aforementioned “Warnings” And Especially The
              “Risk Information” In The ${prospectusOrIssuing}. I Am Aware Of
              The Risk Of The Acquisition Of The
              ${publicSettings.token_type === TOKEN_TYPES.BOND
                  ? "Bond"
                  : "Shares"
              }.
            `}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyStep5DisclaimerEquityBond
