import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Link, withRouter } from 'react-router-dom'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import { useQuery } from '@apollo/client'

import { usePublicSettings, useMe } from '../../myHooks'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'
import RenderSidebarLogo from './RenderSidebarLogo'
import RenderMainNavigation from './RenderMainNavigation'

import HeaderSearchBar from '../SearchBar'
import HeaderAdditionalBar from '../AdditionalBar'
import HeaderMessageBar from '../MessageBar'
import HeaderNotificationBar from '../NotificationBar'
import HeaderUserBar from '../UserBar'
import HeaderSubApp from '../SubApp/SubApp'
import ThemeToggle from '../ThemeToggle'
import Help from './Help'
import { DOCUMENT_TYPES } from '../../constants/customer'
import {
  CHECK_FIELDS_IS_FILLED,
} from '../../queriesAndMutations'
import { isProduction } from '../../utils/app'

const NEED_UDPATE_ATTRIBUTES = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'birth_date',
  'bankName',
  'iban',
  'bankAddress',
];

const AppSidebar = ({
  // from HOCs
  location,
}) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me = {} } = {} } = useMe();
  const { t } = useTranslation();
  const [signnow, setSignnow] = React.useState(false);
  const [signnowLinkInvite, setSignnowLinkInvite] = React.useState(null);
  const eDocument = me.customer && me.customer.documentESignature && me.customer.documentESignature.length
    ? me.customer.documentESignature[me.customer.documentESignature.length - 1]
    : {}

  let fields = ["Signature_1"];

  // if (eDocument.document_type === DOCUMENT_TYPES.INDIVIDUAL_FORM) {
  //   fields = ["Signature_1"]
  // } else if (
  //   [
  //     DOCUMENT_TYPES.TOKEN_SAFE,
  //     DOCUMENT_TYPES.KYB_SINGLE_FORM,
  //     DOCUMENT_TYPES.KYB_COLLECTIVE_FORM,
  //     DOCUMENT_TYPES.LIQUIDATION_FORM
  //   ].includes(eDocument.document_type)) {
  //   fields = ["Signature_1", "Signature_2"]
  // } else {
  //   fields = ["Signature_1", "Signature_2", "Signature_3"]
  // }

  const { data: { checkFieldsIsFilled = {} } = {} } = useQuery(
    CHECK_FIELDS_IS_FILLED,
    {
      variables: {
        input: {
          fields,
          filename: eDocument.remote_id,
        },
      },
    },
    {
      enabled: eDocument.remote_id
    }
  );

  React.useEffect(() => {
    if (checkFieldsIsFilled && checkFieldsIsFilled.result) {
      // eslint-disable-next-line
      if (checkFieldsIsFilled.result.Signature_1 && !checkFieldsIsFilled.result.Signature_1.fulfilled) {
        setSignnow(true)
        const fieldInvite = checkFieldsIsFilled.result.Signature_1.fieldInvite

        if (fieldInvite) {
          const host = isProduction ? 'https://app.signnow.com' : 'https://app-eval.signnow.com'
          const link = `${host}/webapp/document/${checkFieldsIsFilled.result.documentId}?fieldinvite_id=${fieldInvite.id}&mobileweb=app_only&route=fieldinvite&sign=1&source=email`
          setSignnowLinkInvite(link)
        }
      }
    }

    // eslint-disable-next-line
  }, [checkFieldsIsFilled]);

  const todoKyc =
    me && me.kyc_status === "NOT_SUBMITTED";
  let todoUpdateProfile = false;
  let todoEnable2FA = false;

  NEED_UDPATE_ATTRIBUTES.forEach((key) => {
    if (me && (me[`${key}`] === "" || me[`${key}`] === null)) {
      todoUpdateProfile = true;
    }
  });

  if (publicSettings && !publicSettings.disable_2fa_security && me && !me.is_2fa_enabled) {
    todoEnable2FA = true;
  }

  const todoWalletAddress = me && !(me.eth_receiving_wallet || me.wallet_address) && publicSettings && (publicSettings.kyc_show_receiving_wallet || publicSettings.kyc_require_receiving_wallet);

  const totalTodo = todoKyc + (todoUpdateProfile || todoEnable2FA) + todoWalletAddress + signnow

  const [helpValues, setHelpValues] = useState({
    isHelpSidebarActive: false
  });

  const onHelpSidebarActive = () => {
    setHelpValues({...helpValues, isHelpSidebarActive: !helpValues.isHelpSidebarActive });
  }

  const [sidebarValues, setSidebarValues] = useState({
    isSidebarActive: false
  });

  const onSidebarActive = () => {
    setSidebarValues({...sidebarValues, isSidebarActive: !sidebarValues.isSidebarActive });
  }

  const onSidebarActiveForce = () => {
    setSidebarValues({ isSidebarActive: false });
  }

  const [sidebarVisibleValues, setSidebarVisibleValues] = useState({
    isSidebarVisibleActive: false
  });

  const onSidebarVisibleActive = () => {
    setSidebarVisibleValues({...sidebarVisibleValues, isSidebarVisibleActive: !sidebarVisibleValues.isSidebarVisibleActive });
  }

  const onSidebarVisibleActiveForce = () => {
    setSidebarVisibleValues({ isSidebarVisibleActive: false });
  }

  const renderSidebarFooterNavigation = () => {
    return (
      <>
        <Link
          className="sidebar__help"
          to="/data-center"
          onClick={onSidebarVisibleActive}
          data-tip={t('Data Center')}
          data-for="help-data-center"
        >
          <Icon
            className={cn('pie-chart', 'icon-24')}
            name="pie-chart"
            size="24"
          />
          { t('Data Center') }
        </Link>
        <ReactTooltip id="help-data-center" className="sidebar__item_tooltip" />

        <Link
          className="sidebar__help"
          to="/privacy-policy"
          onClick={onSidebarVisibleActive}
          data-tip={t('Privacy Policy')}
          data-for="help-privacy-policy"
        >
          <Icon
            className={cn('promotion', 'icon-24')}
            name="promotion"
            size="24"
          />
          { t('Privacy Policy') }
        </Link>
        <ReactTooltip id="help-privacy-policy" className="sidebar__item_tooltip" />

        {publicSettings.token_type !== "UTILITY" ? (
          <>
            <Link
              className="sidebar__help"
              to="/purchase-agreement"
              onClick={onSidebarVisibleActive}
              data-tip={t('Purchase Agreement')}
              data-for="help-purchase-agreement"
            >
              <Icon
                className={cn('ticket', 'icon-24')}
                name="ticket"
                size="24"
              />
              { t('Purchase Agreement') }
            </Link>
            <ReactTooltip id="help-purchase-agreement" className="sidebar__item_tooltip" />
          </>
        ) : ""}

        <Link
          className="sidebar__help"
          to="/right-of-withdrawal"
          onClick={onSidebarVisibleActive}
          data-tip={t('Right of Withdrawal')}
          data-for="help-right-of-withdrawal"
        >
          <Icon
            className={cn('link', 'icon-24')}
            name="link"
            size="24"
          />
          { t('Right of Withdrawal') }
        </Link>
        <ReactTooltip id="help-right-of-withdrawal" className="sidebar__item_tooltip" />

        <div className="sidebar__help sidebar__help_copyright">
          <div className="icon sidebar__help_copyright_icon">
            &copy;
          </div>
          &copy; {new Date().getFullYear()} {(publicSettings.company || {}).name}.
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`header ${location.pathname.indexOf("/admin") === 0 ? "header-admin" : ""}`}>
        <button className="header__burger" onClick={onSidebarVisibleActive}>{' '}</button>

        {(location.pathname.indexOf("/admin") === 0 && (<HeaderSearchBar />))}

        <div className="header__control">
          <HeaderSubApp />
          <HeaderAdditionalBar />
          <HeaderMessageBar />
          <HeaderNotificationBar />
          {me && (
            <HeaderUserBar />
          )}

        </div>
      </div>

      <div className={`sidebar ${sidebarValues.isSidebarActive ? "active" : ""} ${sidebarVisibleValues.isSidebarVisibleActive ? "visible" : ""} ${location.pathname.indexOf("/admin") === 0 ? "sidebar-admin" : ""}`}>
        <button className="sidebar__close" onClick={onSidebarVisibleActive}>
          <Icon
            className={cn('close', 'icon-24')}
            name="close"
            size="24"
          />
        </button>

        <RenderSidebarLogo />

        <RenderMainNavigation
          onNavigationClick={onSidebarVisibleActive}
          onSidebarActive={onSidebarActive}
          onSidebarActiveForce={onSidebarActiveForce}
          onSidebarVisibleActiveForce={onSidebarVisibleActiveForce}
        />

        <button className="sidebar__toggle" onClick={onSidebarActive}>
          <Icon
            className={cn('arrow-right', 'icon-24')}
            name="arrow-right"
            size="24"
          />
          <Icon
            className={cn('close', 'icon-24')}
            name="close"
            size="24"
          />
        </button>

        <div className="sidebar__foot">
          {me && (
            <button
              className="sidebar__help"
              onClick={onHelpSidebarActive}
              data-tip={t('Need to do')}
              data-for="help-need-to-do"
            >
              <Icon
                className={cn('icon-help', 'icon-24')}
                name="icon-help"
                size="24"
              />
              {t('Need to do')}

              <div className="sidebar__counter">
                {`${totalTodo}`}
              </div>
            </button>
          )}

          <ReactTooltip
            id="help-need-to-do"
            className="sidebar__item_tooltip"
          />
          { renderSidebarFooterNavigation() }

          <ThemeToggle />
        </div>
      </div>
      <div
        className={`overlay ${sidebarValues.isSidebarActive ? "active" : ""}`}
        onClick={onSidebarActive}
      >
        {' '}
      </div>

      <div className={`help ${helpValues.isHelpSidebarActive ? "active" : ""}`}>
        <div className="help__head">
          <Icon
            className={cn('icon-help', 'icon-24')}
            name="icon-help"
            size="24"
          />
          {t('Need to do')}

          <button
            className="help__close"
            onClick={onHelpSidebarActive}
          >
            <Icon
              className={cn('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
        <Help
          todoKyc={todoKyc}
          todoUpdateProfile={todoUpdateProfile}
          todoEnable2FA={todoEnable2FA}
          todoWalletAddress={todoWalletAddress}
          onHelpSidebarActive={onHelpSidebarActive}
          signnow={signnow}
          signnowLinkInvite={signnowLinkInvite}
        />
      </div>
      <div className={`overlay ${helpValues.isHelpSidebarActive ? "active" : ""}`} onClick={onHelpSidebarActive}>{' '}</div>
    </>
  )
};

AppSidebar.propTypes = {
  // from HOCs
  location: PropTypes.object,
};

export default compose(withRouter)(AppSidebar);
