import React from "react";
import classNames from "classnames";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { NavLink } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useQuery } from "@apollo/client";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { makeStyles } from "@material-ui/core";
import { TRANSACTIONS_USER } from "../queriesAndMutations";
import TablePaginationActions from "./TablePagination";
import { formatDate } from "../utils/table";
import { TRANSACTIONS_STATUS_TYPES } from "../constants/transaction";
import { useTranslation } from 'react-i18next';

import { TRANSACTION } from "../constants/riskAssessmentLookup";

const useStyles = makeStyles(() => {
  const defaultStatusIcon = {
    margin: "0 8px 0 0",
    opacity: ".8",
  };

  return {
    table: {
      margin: "0",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px",
      },
    },
    tableBody: {
      "& .MuiTableRow-root": {
        minHeight: "75px",
      },
      "& .MuiTableCell-body": {
        padding: "10px",
      },
    },
    circularProgressWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    txNum: {
      display: "flex",
      alignItems: "center",
    },
    cellText: {
      opacity: ".8",
      fontWeight: "bold",
      fontSize: "14px",
    },
    cellSubText: {
      opacity: ".6",
      fontSize: "12px",
    },
    successIcon: {
      ...defaultStatusIcon,
      color: "#009f65",
    },
    pendingIcon: {
      ...defaultStatusIcon,
      color: "#ffc100",
    },
    errorIcon: {
      ...defaultStatusIcon,
      color: "#f00",
    },
    idleIcon: {
      ...defaultStatusIcon,
      color: "#000",
    },
  };
});

const AdminTransactionsUser = ({ user, exchangeRates, publicSettings, customClasses, userKyc }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);

  const convertRate = (
    token,
    toConvert = "eth_to_chf",
    discountPercent = 0
  ) => {
    const tokenToETH = token * ((exchangeRates || {}).token_to_eth || 1);

    return (
      tokenToETH *
      ((exchangeRates || {})[toConvert] || 1) *
      ((100 - discountPercent) / 100)
    );
  };

  const { data: { transactionsUser: transactionsUserList } = {} } = useQuery(
    TRANSACTIONS_USER,
    {
      variables: {
        input: {
          page,
          pageSize,
          userId: user.id,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case TRANSACTIONS_STATUS_TYPES.APPROVED:
        return (
          <span className={classes.successIcon}>
            <CheckCircleOutlineRoundedIcon fontSize="large" />
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.CONTRACTED:
        return (
          <span className={classes.successIcon}>
            <CheckCircleOutlineRoundedIcon fontSize="large" />
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.IN_PROGRESS:
        return (
          <span className={classes.successIcon}>
            <QueryBuilderRoundedIcon fontSize="large" />
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.PENDING:
        return (
          <span className={classes.pendingIcon}>
            <ErrorOutlineRoundedIcon fontSize="large" />
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.REJECTED:
        return (
          <span className={classes.errorIcon}>
            <HighlightOffRoundedIcon fontSize="large" />
          </span>
        );
      default:
        return (
          <span className={classes.idleIcon}>
            <HelpOutlineRoundedIcon fontSize="large" />
          </span>
        );
    }
  };

  const renderTxNum = (transaction) => (
    <div className={classes.txNum}>
      {renderStatus(transaction.status)}
      <div>
        <span className={classes.cellText}>
          TNX
          {transaction.id}
        </span>{" "}
        <br />
        <span className={classes.cellSubText}>
          {formatDate(transaction.createdAt)}
        </span>
      </div>
    </div>
  );

  const getConfirmActor = (transaction, status) => {
    const confirmLog = transaction.logEvent.find(logEvent => logEvent.status_type_after === status)

    if (!confirmLog) {
      return 'Undefined'
    }

    if (confirmLog.user) {
      return confirmLog.user.email
    }

    return `User #${confirmLog.user_id}`
  }

  if (transactionsUserList) {
    const emptyRows =
      pageSize -
      Math.min(pageSize, transactionsUserList.meta.total - page * pageSize);

    const onfidoCompletedChecks = (
      userKyc.onfidoApplicant
      && userKyc.onfidoApplicant.onfidoChecks
      && userKyc.onfidoApplicant.onfidoChecks.sort((a, b) => a.id - b.id).filter(check => check.status === 'complete')
      ) || []

    let watchlistChecks = onfidoCompletedChecks.filter(check => check.onfidoReports.find(report => report.name === 'watchlist_enhanced'))

    let isOnfidoWatchlist = false
    if (watchlistChecks.length) {
      isOnfidoWatchlist = true
    }

    if (!watchlistChecks.length) {
      const complycubeCompletedChecks = (
        userKyc.complycubeClient
        && userKyc.complycubeClient.complycubeChecks
        && userKyc.complycubeClient.complycubeChecks.sort((a, b) => a.id - b.id).filter(check => check.status === 'complete')
        ) || []

      watchlistChecks = complycubeCompletedChecks.filter(check => check.check_type === 'extensive_screening_check')
    }

    return (
      <>
        <div className={classNames('products__wrapper overflow-x__auto', customClasses)}>
          <div className="products__table">
            <div className="products__row">
              <div className="products__col products__col-first-clear">
                { t('Tranx No') }
              </div>

              <div className="products__col">
                { t('Approved Tokens') }
              </div>

              <div className="products__col">
                { t('Tokens') }
              </div>

              <div className="products__col">
                { t('Amount') }
              </div>

              <div className="products__col">
                { t('Discount') }
              </div>

              {(publicSettings || {}).show_source_signup && (
                <div className="products__col">
                  { t('Source') }
                </div>
              )}
              <div className="products__col">
                { t('Compliance') }
              </div>
              <div className="products__col">
                {' '}
              </div>
            </div>

            {transactionsUserList.objects.map((transaction) => {
              let watchlistResult;
              if (watchlistChecks && watchlistChecks.length) {
                const transactionDate = new Date(transaction.created_at);

                const watchlist = watchlistChecks.find(watchlistCheck => {
                  const completedCheckDate = new Date(watchlistCheck.created_at);
                  return Math.abs(completedCheckDate - transactionDate) < 60*1000;
                })

                if (watchlist) {
                  watchlistResult = isOnfidoWatchlist ? (watchlist.onfidoReports.find(report => report.name === 'watchlist_enhanced') || {}).result : (watchlist.result || {}).outcome;
                }
              }

              return (
                <div className="products__row" key={transaction.id}>
                  <div className="products__col products__col-first-clear">
                    <div className="products__label">
                      { t('Tranx No') }
                    </div>
                    {renderTxNum(transaction)}
                  </div>

                  {watchlistResult && (
                    <div className="products__col">
                      <div className="products__label">
                        { t('Watchlist check') }
                      </div>
                      <div className="products__item products__item-normal">
                        <div className="products__details">
                          <div className={watchlistResult === 'clear' ? 'status-green-dark' : 'status-pending-dark'}>
                            {watchlistResult}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="products__col">
                    <div className="products__label">
                      { t('Approved Tokens') }
                    </div>
                    <div className="products__item products__item-normal">
                      <div className="products__details">
                        <div className="products__subtitle">
                          {transaction.status === 'APPROVED' ? +transaction.approved_token || +transaction.token_amount : '' }
                          <br />
                          {transaction.status === 'APPROVED' && publicSettings && publicSettings.token_symbol}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">
                      { t('Tokens') }
                    </div>
                    <div className="products__item products__item-normal">
                      <div className="products__details">
                        <div className="products__subtitle">
                          {+transaction.token_amount}
                          <br />
                          {publicSettings && publicSettings.token_symbol}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">
                      { t('Amount') }
                    </div>
                    <div className="products__item products__item-normal">
                      <div className="products__details">
                        <div className="products__subtitle">
                          {+transaction.currency_amount}
                          <br />
                          {transaction.currency}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">
                      { t('Discount') }
                    </div>
                    <div className="products__item products__item-normal">
                      <div className="products__details">
                        <div className="products__subtitle">
                          {transaction.discount_percent ? `${transaction.discount_percent}%` : ''}
                        </div>
                      </div>
                    </div>
                  </div>

                  {(publicSettings || {}).show_source_signup && (
                    <div className="products__col">
                      <div className="products__label">
                        { t('Source') }
                      </div>
                      <div className="products__item products__item-normal">
                        <div className="products__details">
                          <div className="products__subtitle">
                            {user.is_gtoswiss
                              ? "GTOwiss.com"
                              : user.is_internal_sales
                              ? "InternalSales"
                              : "Organic"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="products__col">
                    <div className="products__label">
                      { t('Compliance') }
                    </div>
                    <div className="products__item products__item-normal">
                      <div className="products__details">
                        <div className="products__subtitle">
                          <span className={classes.cellSubText}>
                            { t('Business profile identical') }: {" "}
                          </span>
                          {transaction.was_business_profile_the_same !== null && (
                            <span className={classes.cellText}>
                              {transaction.was_business_profile_the_same
                                .toString()
                                .toUpperCase()}
                            </span>
                          )}
                        </div>
                        {!transaction.was_business_profile_the_same && (
                          <div>
                            <span className={classes.cellSubText}>Comment:</span>{" "}
                            {transaction.approved_note}
                          </div>
                        )}
                        {transaction.status === TRANSACTIONS_STATUS_TYPES.APPROVED && (
                          <div>
                            <span className={classes.cellSubText}>Approved:</span>{" "}
                            <span className={classes.cellText}>
                              {getConfirmActor(transaction, TRANSACTIONS_STATUS_TYPES.APPROVED)}
                            </span>
                          </div>
                        )}
                        {transaction.status === TRANSACTIONS_STATUS_TYPES.REJECTED && (
                          <div>
                            <span className={classes.cellSubText}>Rejected:</span>{" "}
                            <span className={classes.cellText}>
                              {getConfirmActor(transaction, TRANSACTIONS_STATUS_TYPES.REJECTED)}
                            </span>
                          </div>
                        )}
                        <div>
                          <span className={classes.cellSubText}>
                            { t('Transaction come from') }:
                          </span>{" "}
                          <span>
                            {
                              (
                                TRANSACTION.find((item) => {
                                  return item.id === transaction.risk_evaluate_id;
                                }) || {}
                              ).description
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <NavLink
                      rel="noopener noreferrer"
                      target="_blank"
                      to={`/admin/transactions/${transaction.id}`}
                      className="button-primary button-fullwidth"
                    >
                      <span>{ t('Go to transaction') }</span>
                    </NavLink>
                  </div>
                </div>
              )
            })}
            {emptyRows > 0 && (
              <div className="transactions__row">
                <div className="transactions__col transactions__col-full">
                  {' '}
                </div>
              </div>
            )}
          </div>
        </div>
        <TablePaginationActions
          count={transactionsUserList.meta.total}
          page={page}
          rowsPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRows={onChangePageSize}
          rowsPerPageOptions={[1, 5, 10, 25]}
        />
      </>
    );
  }

  return (
    <div className={classes.circularProgressWrapper}>
      <CircularProgress />
    </div>
  );
};

export default AdminTransactionsUser;
