import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core'
import {
  CUSTOMER_COMPANY_DIRECTOR,
  GET_DIRECTOR_LIVENESS_CHECK_SERVICE_INFO,
} from '../queriesAndMutations'
import { usePublicSettings } from '../myHooks'
import KycDirectorVideoIdentOnfido from '../components/KycDirectorVideoIdent/KycDirectorVideoIdentOnfido'
import KycDirectorVideoIdentInternalLivenessCheck from '../components/KycDirectorVideoIdent/KycDirectorVideoIdentInternalLivenessCheck'
import KycCustomerVideoIdentIdnow from '../components/KycCustomerVideoIdentIdnow'
import KycCustomerVideoIdentComplycube from '../components/KycCustomerVideoIdentComplycube'

import { useTranslation } from 'react-i18next'
// import '../styles/onfidoIdent.scss'

const KycDirectorVideoIdent = ({ match }) => {
  const { t } = useTranslation();
  const {
    data: { getDirectorLivenessCheckServiceInfo: livenessCheckRequest } = {},
    loading: loadingLivenessCheck,
  } = useQuery(GET_DIRECTOR_LIVENESS_CHECK_SERVICE_INFO, {
    variables: {
      directorId: +(match.params.directorId),
    },
    fetchPolicy: 'network-only',
  })
  const {
    data: { customerCompanyDirector } = {},
    loading: loadingDirector,
    refetch: refetchDirector,
  } = useQuery(CUSTOMER_COMPANY_DIRECTOR, {
    variables: {
      id: +(match.params.directorId),
    },
    fetchPolicy: 'network-only',
  })
  const { data: { publicSettings = {} } = {}, loading: loadingPublicSetting } = usePublicSettings()

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
  })

  const [companyName, setCompanyName] = useState('')
  const [isRefetched, setIsRefetched] = useState(false)

  const redirectToDashboard = () => {
    window.location.href = window.location.origin
  }

  useEffect(() => {
    if (customerCompanyDirector) {
      setValues(Object.keys(values).reduce((all, key) => ({
        ...all,
        [key]: customerCompanyDirector[key],
      }), {}))

      setCompanyName(customerCompanyDirector.customerCompany.companyName);
    }
  // eslint-disable-next-line
  }, [customerCompanyDirector])

  if (loadingPublicSetting || loadingDirector || loadingLivenessCheck) {
    return <CircularProgress />
  }

  // IF liveness check request has just been created from getIndividualLivenessCheckServiceInfo
  // THEN refetch user to get the latest with new liveness check request included
  // setIsRefetched to true to assure only refetch one time
  if (livenessCheckRequest.isNew && !isRefetched) {
    setIsRefetched(true)
    refetchDirector()
  }

  return (
    <>
      <div className="h3 nospace__bottom">
        Hi {values.firstName} {values.lastName}
      </div>
      <div className="shop__info">
        {t('You were added as a Director of COMPANY_NAME. Please verify yourself so COMPANY_NAME can complete the onboarding', {COMPANY_NAME: companyName})}
      </div>
      <div className="page__title">{' '}</div>

      <div className="card">
        <div className="kyc__stepper kyc__stepper_32">
          <Stepper activeStep={1}>
            <Step key={0}>
                <StepLabel>{t('Personal Details')}</StepLabel>
              </Step>

              <Step key={1}>
                <StepLabel>{t('Liveness Check')}</StepLabel>
              </Step>
          </Stepper>
        </div>
      </div>

      <div className="card">
        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              {livenessCheckRequest.serviceName === 'onfido' && (
                <KycDirectorVideoIdentOnfido
                  director={customerCompanyDirector}
                  onComplete={redirectToDashboard}
                  forceCrossDevice={
                    publicSettings.identification.ident_services.onfido &&
                    publicSettings.identification.ident_services.onfido.force_cross_device
                  }
                />
              )}

              {livenessCheckRequest.serviceName === 'complycube' && (
                <KycCustomerVideoIdentComplycube
                  object={customerCompanyDirector}
                  onComplete={redirectToDashboard}
                  objectType='director'
                  forceCrossDevice={
                    publicSettings.identification.ident_services.onfido &&
                    publicSettings.identification.ident_services.onfido.force_cross_device
                  }
                />
              )}

              {livenessCheckRequest.serviceName === 'internal_liveness_check' && (
                <KycDirectorVideoIdentInternalLivenessCheck director={customerCompanyDirector} />
              )}
              {livenessCheckRequest.serviceName === 'idnow' && (
                <KycCustomerVideoIdentIdnow
                  customer={customerCompanyDirector}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KycDirectorVideoIdent
