import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import cn from 'classnames'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment-timezone'

import { SEARCH_GENERAL } from '../../queriesAndMutations'
import { USER_RIGHT_TYPES } from '../../constants/user'
import { hasUserEnoughRights, createQueryString, getTextAvatarUrl } from '../../utils'
import { useMe } from '../../myHooks'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'
import Result from './Result'
import Suggestion from './Suggestion'

const SearchBar = ({
  // eslint-disable-next-line react/prop-types
  isFullWidth
} = {}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState({
    isSearchInputVisible: false,
  });
  const { data: { me } = {} } = useMe()
  const [keyword, setKeyword] = useState('')
  const [searchGeneral, searchGeneralStatus] = useLazyQuery(SEARCH_GENERAL)

  const onSearchInputClick = () => {
    setVisible({...visible, isSearchInputVisible: !visible.isSearchInputVisible });
  }
  const onSearchInputChange = ({ target }) => {
    const value = target.value;

    setVisible({...visible, isSearchInputVisible: true });
    setTimeout(() => {
      if (value === target.value) {
        setKeyword(value)
      }
    }, 700)
  }

  const [searchPopoverValues, setSearchPopoverValues] = useState({
    isSearchPopoverActive: false
  });

  const onSearchPopoverClick = () => {
    setSearchPopoverValues({...searchPopoverValues, isSearchPopoverActive: !searchPopoverValues.isSearchPopoverActive});
  }

  const getSearchGeneralResult = (key) => (
    searchGeneralStatus.data &&
    searchGeneralStatus.data.searchGeneral[key] &&
    searchGeneralStatus.data.searchGeneral[key].data
  ) || []

  const noResultFound = () => {
    if (
      searchGeneralStatus.data &&
      searchGeneralStatus.data.searchGeneral
    ) {
      const total = Object.values(searchGeneralStatus.data.searchGeneral)
        .reduce((all, group) => (all + (group.data ? group.data.length : 0)), 0)

      return total === 0
    }

    return false
  }

  useEffect(() => {
    if (keyword) {
      searchGeneral({
        variables: {
          search: keyword,
        }
      })
    }
  }, [keyword, searchGeneral])

  // Check if required data loaded.
  if (!me) {
    return null
  }

  // Render
  return (
    <>
      <div className={`search ${visible.isSearchInputVisible ? "active" : ""} ${isFullWidth ? "search__fullwidth" : ""} ${searchPopoverValues.isSearchPopoverActive ? "visible" : ""}`}>
        <div className="search__head">
          <button className="search__start">
            <Icon
              className={cn('searchh', 'icon-24')}
              name="searchh"
              size="24"
            />
          </button>

          <button className="search__direction">
            <Icon
              className={cn('arrow-left', 'icon-24')}
              name="arrow-left"
              size="24"
            />
          </button>

          <input
            className="search__input"
            type="text"
            placeholder={t('Search or type a command')}
            onChange={onSearchInputChange}
          />
          <button className="search__result">⌘ F</button>

          <button
            className="search__close"
            onClick={onSearchInputClick}
          >
            <Icon
              className={cn('close-circle', 'icon-24')}
              name="close-circle"
              size="24"
            />
          </button>
        </div>
        <div className="search__body">
          <div className="search__content">
            {searchGeneralStatus.loading && (<CircularProgress />)}

            {noResultFound() && (
              <p>{t('No match result found')}</p>
            )}

            {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER) && (
              <>
                {getSearchGeneralResult('users').length > 0 && (
                  <div className="search__box">
                    <div className="search__category">{t('Users')}</div>
                    <div className="search__list">
                      {getSearchGeneralResult('users').map((user) => (
                        <Result
                          key={user.id}
                          item={{
                            title: `${user.first_name} ${user.middle_name} ${user.last_name}`,
                            content: user.email,
                            image: getTextAvatarUrl(`${user.first_name} ${user.last_name}`, 'EFEFEF', '1A1D1F'),
                            url: `/admin/users?id=${user.id}`
                          }}
                        />
                      ))}
                    </div>
                    <div className="search__see-all">
                      <NavLink to={`/admin/users?${createQueryString({ search: keyword })}`}>
                        {t('See all results')}
                      </NavLink>
                    </div>
                  </div>
                )}
              </>
            )}

            {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.REFERRAL_PARTNER) && (
              <>
                {getSearchGeneralResult('individuals').length > 0 && (
                  <div className="search__box">
                    <div className="search__category">{t('Individual Investors')}</div>
                    <div className="search__list">
                      {getSearchGeneralResult('individuals').map((individual) => (
                        <Result
                          key={individual.id}
                          item={{
                            title: `${individual.first_name} ${individual.middle_name} ${individual.last_name}`,
                            content: individual.email,
                            image: getTextAvatarUrl(`${individual.first_name} ${individual.last_name}`),
                            url: `/admin/kyc-list/individual?id=${individual.id}`
                          }}
                        />
                      ))}
                    </div>
                    <div className="search__see-all">
                      <NavLink to={`/admin/kyc-list/individual?${createQueryString({ search: keyword })}`}>
                        {t('See all results')}
                      </NavLink>
                    </div>
                  </div>
                )}
              </>
            )}

            {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER) && (
              <>
                {getSearchGeneralResult('companies').length > 0 && (
                  <div className="search__box">
                    <div className="search__category">{t('Companies')}</div>
                    <div className="search__list">
                      {getSearchGeneralResult('companies').map((company) => (
                        <Result
                          key={company.id}
                          item={{
                            title: company.companyName,
                            content: company.email,
                            image: getTextAvatarUrl(company.companyName, 'CABDFF', '1A1D1F'),
                            url: `/admin/kyc-list/company?id=${company.id}`
                          }}
                        />
                      ))}
                    </div>
                    <div className="search__see-all">
                      <NavLink to={`/admin/kyc-list/company?${createQueryString({ search: keyword })}`}>
                        {t('See all results')}
                      </NavLink>
                    </div>
                  </div>
                )}
              </>
            )}

            {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.REFERRAL_PARTNER) && (
              <>
                {getSearchGeneralResult('token_swaps').length > 0 && (
                  <div className="search__box">
                    <div className="search__category">{t('Token Swaps')}</div>
                    <div className="search__list">
                      {getSearchGeneralResult('token_swaps').map((token_swap) => (
                        <Result
                          key={token_swap.id}
                          item={{
                            title: token_swap.email,
                            content: token_swap.email,
                            image: getTextAvatarUrl(token_swap.email),
                            url: `/admin/swap-token?${createQueryString({ search: keyword })}`
                          }}
                        />
                      ))}
                    </div>
                    <div className="search__see-all">
                      <NavLink to={`/admin/swap-token?${createQueryString({ search: keyword })}`}>
                        {t('See all results')}
                      </NavLink>
                    </div>
                  </div>
                )}
              </>
            )}

            {getSearchGeneralResult('transactions').length > 0 && (
              <div className="search__box">
                <div className="search__category">{t('Transactions')}</div>
                <div className="search__list">
                  {getSearchGeneralResult('transactions').map((transaction) => (
                    <Suggestion
                      key={transaction.id}
                      item={{
                        title: `TNX${transaction.id}`,
                        content: moment(transaction.created_at).tz('Europe/Vaduz').format('DD-MM-YYYY HH:mm z'),
                        icon: 'diamond',
                        url: hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER)
                          ? `/admin/transactions/${transaction.id}`
                          : `/transactions/${transaction.id}`,
                      }}
                    />
                  ))}
                </div>
                <div className="search__see-all">
                  <NavLink
                    to={hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER)
                      ? `/admin/transactions?${createQueryString({ search: keyword })}`
                      : `/transactions?${createQueryString({ search: keyword })}`
                    }
                  >
                    {t('See all results')}
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <button
        className={`header__search ${searchPopoverValues.isSearchPopoverActive ? "active" : ""}`}
        onClick={onSearchPopoverClick}>
        <Icon
          className={cn('searchh', 'icon-24')}
          name="searchh"
          size="24"
        />
      </button>
    </>
  );
}

export default SearchBar;
