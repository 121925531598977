import React, { useState, useEffect, useCallback } from 'react'
import { remove } from 'lodash'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import Icon from '../Icon'

import {
  extractMsgFromErrByPropertyName,
  getExternalFileUrl,
  isCrypto,
  numberWithCommas,
  toLocalYmdString,
  toaster,
  getCountryList,
} from '../../utils'
import { useExchangeRates } from '../../myHooks'
import { ethToFiat, tokenToEth, tokenToUsdc, fiatToToken } from '../../utils/rate'
import { walletLabelTextMapping, getSendingWalletTypeOptions } from '../../constants/walletLabelTextMapping'
import {
  TOKEN_TYPES,
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
 } from '../../constants/settings';
import { useTranslation } from 'react-i18next'
import {
  COUNTRY_LIST,
  NET_INVESTABLE_ASSETS_OPTIONS,
  COMPANY_LEGAL_FORMS,
  HIGH_AND_PROHIBIT_RISK_COUNTRIES,
  BUSINESS_FOLLOWING_INDUSTRIES,
} from '../../constants/customer';
import SIC_CODES from '../../constants/sic_code'
import TextField from '@material-ui/core/TextField'
import InputThemeField from "../InputThemeField"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DateFnsUtils from '@date-io/date-fns/build/index'
import Radio from '@material-ui/core/Radio'
import MenuItem from '@material-ui/core/MenuItem'
import ImageUpload from '../ImageUpload'
import KycFormSupportedSourceOfFund from '../KycFormSupportedSourceOfFund'
import InputAdornment from '@material-ui/core/InputAdornment';
import { GET_MY_TOKEN_SWAP } from '../../queriesAndMutations'
import WalletRequest from '../WalletRequest'
import classNames from 'classnames'
import InputThemeLite from "../InputThemeLite"
import metamaskService from '../../services/metamask'
import styles from '../CompanyDetails/CardForm/styles.module.scss'

const helperTextForWalletCompliant = (wallet) => {
  switch (wallet) {
    case "eth":
      return "Address should be ERC20-compliant.";
    case "ixo_id":
      return "Address should be IXO-ID-compliant.";
    case "cosmos":
      return "Address should be Cosmos-compliant.";
    default:
      return '';
  }
}

const CompanyStep1 = ({
  publicSettings,
  intendedInvestmentOptions,
  values,
  setValues,
  toNextStep,
  errors,
  oldData
}) => {
  const { t } = useTranslation()
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const baseCurrency = (publicSettings && publicSettings.base_currency) || "chf";
  const [exactIntendedInvestmentAmountValue, setExactIntendedInvestmentAmountValue] = useState(values.exactIntendedInvestmentAmount)
  const [amountValue, setAmountValue] = useState(values.amount)
  const { data: { getMyTokenSwap: tokenSwap } = {} } = useQuery(GET_MY_TOKEN_SWAP)
  const isCryptoadvisory = window.location.host === 'kyc.cryptoadvisory.li' || window.location.host === 'dev-kyc.cryptoadvisory.li';

  const [checkedBusinessCountries, setCheckedBusinessCountries] = useState([])
  const [disableSubmitVerify, setDisableSubmitVerify] = useState(false)

  useEffect(() => {
    if (values.business_countries) {
      const checkedCountries = (values.business_countries || '').split(',').filter(Boolean).map(x => String(x).trim())
      setCheckedBusinessCountries(checkedCountries)
    }

    // eslint-disable-next-line
  }, [values.business_countries])

  const handleCheckAllowlistCountries = (event) => {
    let updateList = [...checkedBusinessCountries]
    if (event.target.checked){
      updateList = [...checkedBusinessCountries, event.target.value]
    } else {
      updateList.splice(checkedBusinessCountries.indexOf(event.target.value), 1)
    }

    setValues({
      ...values,
      business_countries: updateList.join(', ')
    });
  }

  const updateField = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
    if (name === 'exactIntendedInvestmentAmount') {
      setExactIntendedInvestmentAmountValue(+event.target.value);
    }
    if (name === 'amount') {
      setAmountValue(+event.target.value);
    }
  }

  const acceptedCurrencies = (publicSettings && publicSettings.accepted_currencies) || {};

  const handleDateChange = (field) => (date) => {
    setValues({ ...values, [field]: toLocalYmdString(date) })
  }

  const [supportedSourceOfFundFiles, setSupportedSourceOfFundFiles] = useState(
    values.supportedSourceOfFundUploadFiles
  )

  const onDropFile = (name) => (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({ ...values, [name]: newImage })
  }

  const onDropSupportedSourceOfFundFiles = (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    }
    setSupportedSourceOfFundFiles([...supportedSourceOfFundFiles, newImage])
    setValues({...values, supportedSourceOfFundUploadFiles: [...supportedSourceOfFundFiles, newImage]})
  }

  const removeDropSupportedSourceOfFundFiles = (index) => {
    const newSupportedSourceOfFundFiles = [...supportedSourceOfFundFiles]
    const removedEle = remove(newSupportedSourceOfFundFiles, (file, indx) => {
      return indx === index
    })

    if (removedEle) {
      setSupportedSourceOfFundFiles(newSupportedSourceOfFundFiles)
      setValues({...values, supportedSourceOfFundUploadFiles: newSupportedSourceOfFundFiles})
    }
  }

  useEffect(() => {
    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      const autoSelect = intendedInvestmentOptions.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= exactIntendedInvestmentAmountValue
          : range[0] <= exactIntendedInvestmentAmountValue
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line
  }, [exactIntendedInvestmentAmountValue])

  useEffect(() => {
    if (publicSettings.token_type !== TOKEN_TYPES.NONE && exchangeRates) {
      const tokenToCurrency = ethToFiat(baseCurrency, tokenToEth(amountValue, exchangeRates), exchangeRates)
      const autoSelect = intendedInvestmentOptions.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= tokenToCurrency
          : range[0] <= tokenToCurrency
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line
  }, [amountValue, exchangeRates])

  useEffect(() => {
    if (publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && publicSettings.enable_swap_token && tokenSwap) {
      setValues({
        ...values,
        amount: `${tokenSwap.newTokenAmount}`,
      })

      setAmountValue(tokenSwap.newTokenAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, tokenSwap])

  const countryNameOptions = getCountryList(publicSettings, isCryptoadvisory);

  const tokenToBaseCurrency = exchangeRates && ethToFiat(
    baseCurrency,
    tokenToEth(values.amount, exchangeRates),
    exchangeRates
  )

  const isRenderSourceOfFunds = () => {
    if (publicSettings.token_type === 'NONE') {
      const intendedInvestmentRange =
        values && values.intended_investment && values.intended_investment.match(/\d+/g).map(Number);
      if (intendedInvestmentRange) {
        const level = Object.entries(publicSettings.kyc_levels).find(
          (item) =>
            item[1].min_invest_amount === intendedInvestmentRange[0] &&
            (
              (
                item[1].enable &&
                item[1].enable.enable_open_end
              ) ||
              item[1].max_invest_amount === intendedInvestmentRange[1]
            )
        );
        return level[0] >= 2;
      } else {
        return false;
      }
    } else {
      return tokenToBaseCurrency >= +publicSettings.kyc_levels[3].min_invest_amount;
    }
  };

  const renderEqualTo = (amount) => {
    switch (values.currency) {
      case "eth":
        return (
          <h4>
            { t('Equals') } {numberWithCommas(tokenToEth(amount, exchangeRates))}{" "}
            ETH
          </h4>
        );
      case "usdc":
        return (
          <h4>
            { t('Equals') } {numberWithCommas(tokenToUsdc(amount, exchangeRates))}{" "}
            USDC
          </h4>
        );
      default:
        return (
          <h4>
            { t('Equals') }{" "}
            {exchangeRates && numberWithCommas(
              ethToFiat(
                values.currency,
                tokenToEth(amount, exchangeRates),
                exchangeRates
              )
            )}{" "}
            {(values.currency || "").toUpperCase()}
          </h4>
        );
    }
  };

  const formatIntendedInvestment = (numberStr) => {
    return numberStr.replace(/\d+/g, (str) => numberWithCommas(str, 0));
  }

  const kycAcceptedWallets = useCallback(() => (
    publicSettings.kyc_accepted_wallets
      ? Object.entries(
        publicSettings.kyc_accepted_wallets
      ).filter((ele) => ele[1])
        .map((ele) => ele[0])
      : []
  ), [publicSettings])
  const setGeneratedWallet = (generatedWallet) => {
    setValues({
      ...values,
      generatedWallet,
      tokenAddress: generatedWallet,
      wallet: !values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet,
    })
  }

  console.log("values", values);

  const confirmWalletAndSend = async () => {
    setDisableSubmitVerify(true);
    const message = 'Please sign this message to confirm the ownership of provided wallet '

    try {
      await metamaskService.requestAccounts()

      const signature = await metamaskService.signMessage(
        metamaskService.getCurrentAddress(),
        message
      )
      const signerAddress = await metamaskService.getSigner(signature, message)

      if (signerAddress === values.tokenAddress.toLowerCase()) {
        setValues({...values, verified_wallet: signerAddress})
        toaster.success('The wallet have been verified.')
      } else {
        toaster.error('The wallet you provided does not match with the current wallet on Metamask.')
      }

      setDisableSubmitVerify(false);
    } catch (error) {
      switch (error.code) {
        case 4001:
          toaster.error('You need to select at least the account with the provided address to connect')
          break
        default:
          toaster.error(error.message)
          break
      }
      setDisableSubmitVerify(false);
    }
  }

  return (
    <div className="settings__wrapper">
      <div className="settings__list">
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Your personal information required for identification')}
          </div>

          <div className="settings__fieldset">
            <div className="settings__row flex__row flex__wrap">
              <InputThemeField
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('Business email address')}
                value={values.email}

                onChange={updateField('email')}

                error={!!extractMsgFromErrByPropertyName(errors, 'email')}
                helperText={extractMsgFromErrByPropertyName(errors, 'email').join('. ')}
              />

              <InputThemeField
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('Company name')}
                value={values.companyName}

                onChange={updateField('companyName')}

                error={!!extractMsgFromErrByPropertyName(errors, 'companyName')}
                helperText={extractMsgFromErrByPropertyName(errors, 'companyName').join('. ')}
              />
            </div>

            {publicSettings.kyb_require_director_form && (
              <div className="field field-radio__material">
                <div className="field__label">
                  {t('Identification of the beneficial owner according to Liechtenstein legislation')}
                </div>
                <div className="field__wrap field__wrap-radio field__vert_label field__wrap-radio_text_gray">
                  <RadioGroup
                    row
                    aria-label="acknowledgement-of-receipt"
                    name="acknowledgement-of-receipt"
                    value={values.directorFormType}
                    onChange={updateField('directorFormType')}
                  >
                    <FormControlLabel value="FORM_T" control={<Radio color="primary" />} label={(
                      <>
                        <span className="field-radio__material-title">
                          { t('Foundations, trusteeships and establishments (with a structure similar to a foundation)') }
                          {' '}
                          {t('Art. 3 (1) lit. b DDO')}
                        </span>
                        <ul>
                          <li>{t('Founder or settlor')}</li>
                          <li>{t('Member of the foundation board or board of directors of the trustee')}</li>
                          <li>{t('Protectors')}</li>
                          <li>{t('Beneficiaries')}</li>
                          <li>{t('Natural persons, who ultimately control the legal entity')}</li>
                        </ul>
                      </>)}
                    />
                    <FormControlLabel value="FORM_C" control={<Radio color="primary" />} label={(
                      <>
                        <span className="field-radio__material-title">
                          {t('Corporations and establishments (with a structure similar to a corporation)')}
                          {' '}
                          {t('Art. 3 (1) lit. a DDO')}
                        </span>

                        <ul>
                          <li>{t('25 % or more of the company')}</li>
                          <li>{t('Exercise control')}</li>
                          <li>{t('Fall Back Clause Member of the executive body')}</li>
                        </ul>
                      </>
                      )}
                    />
                  </RadioGroup>
                </div>
              </div>
            )}

            <div className="settings__row flex__row flex__wrap">
              <InputThemeField
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('Company Register Number')}
                value={values.companyRegisterNumber}

                onChange={updateField('companyRegisterNumber')}

                error={!!extractMsgFromErrByPropertyName(errors, 'companyRegisterNumber')}
                helperText={extractMsgFromErrByPropertyName(errors, 'companyRegisterNumber').join('. ')}
              />

              <div className="field">
                <div className="field__label">
                  {t('Legal form')}
                  <span className="text__red"> *</span>
                </div>

                <div className="field__wrap field__wrap-select">
                  <TextField
                    select
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={values.legalForm}
                    onChange={updateField('legalForm')}
                  >
                    {COMPANY_LEGAL_FORMS.map(option => (
                      <MenuItem key={option} value={option}>{t(option)}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              { values.legalForm === 'Other' && (
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  value={values.legalFormOther}
                  label={t('Description of Legal form')}
                  onChange={updateField('legalFormOther')}
                  error={!!extractMsgFromErrByPropertyName(errors, 'legalFormOther')}
                  helperText={extractMsgFromErrByPropertyName(errors, 'legalFormOther').join('. ')}
                />
              )}
            </div>

            <div className="settings__row flex__row flex__wrap have__fields have__space">
              <div className="field">
                <div className="field__label">
                  {t('Date of incorporation')}
                </div>

                <div className="field__wrap field__wrap-date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      views={["year", "month", "date"]}
                      openTo="year"
                      disableToolbar
                      disableFuture
                      variant="outlined"
                      format="dd.MM.yyyy"
                      required
                      margin="normal"
                      value={values.dateOfIncorporation}
                      KeyboardButtonProps={{
                        'aria-label': 'change Date of incorporation',
                      }}
                      onChange={handleDateChange('dateOfIncorporation')}

                      error={!!extractMsgFromErrByPropertyName(errors, 'dateOfIncorporation')}
                      helperText={extractMsgFromErrByPropertyName(errors, 'dateOfIncorporation').join('. ')}
                      invalidDateMessage={t('Invalid Date Format')}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="field">
                <div className="field__label">
                  {t('Date of entry in the Commercial Register')}
                </div>

                <div className="field__wrap field__wrap-date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      views={["year", "month", "date"]}
                      openTo="year"
                      disableToolbar
                      disableFuture
                      variant="outlined"
                      format="dd.MM.yyyy"
                      margin="normal"
                      value={values.dateOfEntryInCommercialRegister}
                      required
                      onChange={handleDateChange('dateOfEntryInCommercialRegister')}
                      KeyboardButtonProps={{
                        'aria-label': 'change Date of entry in the Commercial Register',
                      }}

                      error={!!extractMsgFromErrByPropertyName(errors, 'dateOfEntryInCommercialRegister')}
                      helperText={extractMsgFromErrByPropertyName(errors, 'dateOfEntryInCommercialRegister').join('. ')}
                      invalidDateMessage={t('Invalid Date Format')}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <InputThemeField
                required
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                label={t('Place of Commercial Register')}
                value={values.placeOfCommercialRegister}

                onChange={updateField('placeOfCommercialRegister')}

                error={!!extractMsgFromErrByPropertyName(errors, 'placeOfCommercialRegister')}
                helperText={extractMsgFromErrByPropertyName(errors, 'placeOfCommercialRegister').join('. ')}
              />

              <InputThemeField
                required
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                label={t('Company Share Capital')}
                value={values.companyShareCapital}
                onChange={updateField('companyShareCapital')}

                error={!!extractMsgFromErrByPropertyName(errors, 'companyShareCapital')}
                helperText={extractMsgFromErrByPropertyName(errors, 'companyShareCapital').join('. ')}
              />

              <InputThemeField
                required
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                label={t('Company Place')}
                value={values.companyPlace}

                onChange={updateField('companyPlace')}

                error={!!extractMsgFromErrByPropertyName(errors, 'companyPlace')}
                helperText={extractMsgFromErrByPropertyName(errors, 'companyPlace').join('. ')}
              />

              <InputThemeField
                required
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                label={t('Company address')}
                value={values.companyAddress}

                onChange={updateField('companyAddress')}

                error={!!extractMsgFromErrByPropertyName(errors, 'companyAddress')}
                helperText={extractMsgFromErrByPropertyName(errors, 'companyAddress').join('. ')}
              />

              <div className="field">
                <div className="field__label">
                  {t('Company Country')}
                  <span className="text__red"> *</span>
                </div>

                <div className="field__wrap field__wrap-select">
                  <TextField
                    select
                    required
                    value={values.companyCountry}
                    onChange={updateField('companyCountry')}

                    error={!!extractMsgFromErrByPropertyName(errors, 'companyCountry')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'companyCountry').join('. ')}
                  >
                    {countryNameOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <InputThemeField
                required
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                label={t('Company Postalcode')}
                value={values.companyPostalCode}

                onChange={updateField('companyPostalCode')}

                error={!!extractMsgFromErrByPropertyName(errors, 'companyPostalCode')}
                helperText={extractMsgFromErrByPropertyName(errors, 'companyPostalCode').join('. ')}
              />
            </div>

            {publicSettings.kyb_require_activity_description && (
              <div className="field">
                <div className="field__label">
                  {t('Description register extract of business activity')}
                  <span className="text__red"> *</span>
                </div>

                <div className="field__wrap field__wrap-textarea">
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="input-bordered"
                    required
                    multiline
                    rows={6}
                    value={values.descriptionRegisterExtractBusinessActivity}
                    onChange={updateField('descriptionRegisterExtractBusinessActivity')}

                    error={!!extractMsgFromErrByPropertyName(errors, 'descriptionRegisterExtractBusinessActivity')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'descriptionRegisterExtractBusinessActivity').join('. ')}
                  />
                </div>
              </div>
            )}

            {publicSettings.kyb_require_main_income && (
              <div className="field">
                <div className="field__label">
                  {t('What is the main source of income of the company?')}
                  <span className="text__red"> *</span>
                </div>

                <div className="field__wrap field__wrap-textarea">
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      className="input-bordered"
                      multiline
                      rows={6}
                      value={values.sourceOfIncome}
                      onChange={updateField('sourceOfIncome')}
                      error={!!extractMsgFromErrByPropertyName(errors, 'sourceOfIncome')}
                      helperText={extractMsgFromErrByPropertyName(errors, 'sourceOfIncome').join('. ')}
                    />
                </div>
              </div>
            )}

            {publicSettings.kyb_require_other_income && (
              <>
                <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                  <div className="field__label">
                    {t('Does the company have any source of incoming arising from any activity that is not related to the company’s main activity?')}
                    <span className="text__red"> *</span>
                  </div>

                  <div className="field__wrap">
                    <RadioGroup
                      value={values.hasNotRelatedSourceIncome}
                      aria-label="company-source-income"
                      name="hasNotRelatedSourceIncome"
                      onChange={({target: { value }}) => {setValues({...values, hasNotRelatedSourceIncome: value === 'true'})}}
                    >
                      <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                  </div>
                </div>

                { values.hasNotRelatedSourceIncome && (
                  <div className="field">
                    <div className="field__wrap field__wrap-textarea">
                      <TextField
                        required={values.hasNotRelatedSourceIncome}
                        fullWidth
                        variant="outlined"
                        className="input-bordered"
                        multiline
                        rows={6}
                        value={values.notRelatedSourceIncomeDescription}
                        onChange={updateField('notRelatedSourceIncomeDescription')}

                        error={!!extractMsgFromErrByPropertyName(errors, 'notRelatedSourceIncomeDescription')}
                        helperText={extractMsgFromErrByPropertyName(errors, 'notRelatedSourceIncomeDescription').join('. ')}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {publicSettings.kyb_require_gross_revenue && (
              <div className="field">
                <div className="field__label">
                  {t('Average gross revenue of the last 12 months?')}
                  <span className="text__red"> *</span>
                </div>

                <div className="field__wrap field__wrap-select">
                  <TextField
                    required
                    select
                    value={values.grossRevenue}
                    variant="outlined"

                    onChange={updateField('grossRevenue')}

                    error={!!extractMsgFromErrByPropertyName(errors, 'grossRevenue')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'grossRevenue').join('. ')}
                  >
                    {NET_INVESTABLE_ASSETS_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option.replaceAll('CHF', baseCurrency.toUpperCase())}>
                        {t(option.replaceAll('CHF', baseCurrency.toUpperCase()))}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            )}

            {publicSettings.kyc_what_will_you_use && (
              <InputThemeField
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                label={t('Information regarding company’s investment purpose')}
                required
                value={values.investmentPurpose}

                onChange={updateField('investmentPurpose')}

                error={!!extractMsgFromErrByPropertyName(errors, 'investmentPurpose')}
                helperText={extractMsgFromErrByPropertyName(errors, 'investmentPurpose').join('. ')}
              />
            )}

            {publicSettings.kyb_show_business_countries && (
              <>
                <div className="field">
                  <div className="field__label">{t('Are you doing business in any of these Countries?')}</div>
                </div>

                <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                  <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
                    {HIGH_AND_PROHIBIT_RISK_COUNTRIES.map((country) => (
                      <label className="checkbox" key={country}>
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          onChange={handleCheckAllowlistCountries}
                          checked={checkedBusinessCountries && checkedBusinessCountries.includes(country)}
                          value={country}
                        />
                        <div className="checkbox__inner">
                          <div className="checkbox__tick">{' '}</div>
                          <div className="checkbox__text">{t(country)}</div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </>
            )}

            { publicSettings.kyb_show_business_following_industries && (
              <>
                <div className="field">
                  <div className="field__label">
                    {t('Is your Business operated in the following industries?')}
                  </div>

                  <div className={classNames('field__wrap field__wrap-select')}>
                    <TextField
                      select
                      margin="none"
                      variant="outlined"
                      fullWidth
                      value={values.business_following_industries}
                      onChange={updateField('business_following_industries')}
                    >
                      {BUSINESS_FOLLOWING_INDUSTRIES.map(industry => (
                        <MenuItem key={industry} value={industry}>{t(industry)}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </>
            )}

            <div className="field">
              <div className="field__label">
                {t('Please choose your correct SIC Code for your Business')}
              </div>

              <div className={classNames('field__wrap field__wrap-select')}>
                {/* <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={values.sic_code}
                  onChange={updateField('sic_code')}
                >
                  {SIC_CODES.map(code => (
                    <MenuItem key={code.SICCode} value={code.SICCode}>{`${code.SICCode} - ${code.SICDescription}`}</MenuItem>
                  ))}
                </TextField> */}
                {values.sic_code && (
                  <Autocomplete
                    id="combo-box"
                    options={SIC_CODES}
                    getOptionLabel={(option) => `${option.SICCode} - ${option.SICDescription}`}
                    getOptionSelected={(option) => `${option.SICCode} - ${option.SICDescription}`}
                    classes={{
                      popper: styles.custom_popper,
                    }}
                    onChange={(_event, value) => {
                      if (value) {
                        setValues({
                          ...values,
                          sic_code: `${value.SICCode} - ${value.SICDescription}`
                        })
                      }
                    }}
                    value={values.sic_code ? console.log(SIC_CODES.find(code => `${code.SICCode} - ${code.SICDescription}` === values.sic_code)) || SIC_CODES.find(code => `${code.SICCode} - ${code.SICDescription}` === values.sic_code) : undefined}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="none"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                )}

                {!values.sic_code && (
                  <Autocomplete
                    id="combo-box"
                    options={SIC_CODES}
                    getOptionLabel={(option) => `${option.SICCode} - ${option.SICDescription}`}
                    getOptionSelected={(option) => `${option.SICCode} - ${option.SICDescription}`}
                    classes={{
                      popper: styles.custom_popper,
                    }}
                    onChange={(_event, value) => {
                      if (value) {
                        setValues({
                          ...values,
                          sic_code: `${value.SICCode} - ${value.SICDescription}`
                        })
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="none"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                )}
              </div>
            </div>

            {!isCryptoadvisory && (
              <div className="field">
                <div className="field__label">
                  { t('Commercial Register Extract') }
                  <span className="text__red"> *</span>
                </div>

                {!values.commercialRegisterExtract && (
                  <div className="field__wrap field__wrap-imageupload">
                    <ImageUpload
                      isRequired
                      buttonText={t('Click or drop images')}
                      buttonClassName="button"
                      onChange={onDropFile('commercialRegisterExtract')}
                      maxFileSize={10485760}
                      withPreview
                      singleImagePick
                      singleFile
                      defaultImages={[]}
                      accept='image/*, application/pdf'
                    >
                      <div className="file__wrap_text text__center">
                        <p>
                          { t('Drag and drop the document') }
                        </p>
                      </div>
                    </ImageUpload>
                  </div>
                )}

                {values.commercialRegisterExtract && (
                  <div className="settings__top">
                    <div className="settings__label">
                      {values.commercialRegisterExtract.img.name}
                    </div>
                    <button
                      className="button-stroke button-small settings__button"
                      onClick={() => { setValues({...values, commercialRegisterExtract: null})}}
                    >
                      {t('Remove')}
                    </button>
                  </div>
                )}

                {!!extractMsgFromErrByPropertyName(errors, 'commercialRegisterExtract').length && (
                  <div className="field__wrap status-red field__fill">
                    { extractMsgFromErrByPropertyName(errors, 'commercialRegisterExtract') }
                  </div>
                )}
              </div>
            )}

            {
              (
                publicSettings.kyb_require_organization_chart ||
                publicSettings.kyb_require_association_article ||
                publicSettings.kyb_require_last_balance_sheet ||
                publicSettings.kyb_show_good_standing_certificate ||
                publicSettings.kyb_require_shareholder_list
              ) && (
                <>
                  {/* <div className="field nospace__bottom">
                    <div className="field__label text__red">
                      {t('Documents of the Corporation must not be older than 12 months')}
                    </div>
                  </div> */}

                  <div className="settings__row flex__row flex__wrap have__fields have__space">
                    {publicSettings.kyb_require_organization_chart && (
                      <div className="field">
                        <div className="field__label">
                          {t('Corporation Organization Chart of the Company')}
                          {!oldData && (
                            <span className="text__red"> *</span>
                          )}
                        </div>

                        {!values.corporationOrganizationChartFile && (
                          <div className="field__wrap field__wrap-imageupload">
                            <ImageUpload
                              isRequired
                              buttonText={t('Click or drop images')}
                              buttonClassName="button"
                              onChange={onDropFile('corporationOrganizationChartFile')}
                              maxFileSize={10485760}
                              withPreview
                              singleImagePick
                              singleFile
                              defaultImages={[]}
                              accept='image/*, application/pdf'
                            >
                              <div className="file__wrap_text text__center">
                                <p>
                                  { t('Drag and drop the document') }
                                </p>
                              </div>
                            </ImageUpload>
                          </div>
                        )}

                        {values.corporationOrganizationChartFile && (
                          <div className="settings__top">
                            <div className="settings__label">
                              {values.corporationOrganizationChartFile.img.name}
                            </div>
                            <button
                              className="button-stroke button-small settings__button"
                              onClick={() => { setValues({...values, corporationOrganizationChartFile: null})}}
                            >
                              {t('Remove')}
                            </button>
                          </div>
                        )}

                        {!!extractMsgFromErrByPropertyName(errors, 'articlesOfAssociationFile').length && (
                          <div className="field__wrap status-red field__fill">
                            { extractMsgFromErrByPropertyName(errors, 'articlesOfAssociationFile') }
                          </div>
                        )}
                      </div>
                    )}

                    {publicSettings.kyb_require_association_article && (
                      <div className="field">
                        <div className="field__label">
                          {t('Articles of Association')}
                          {!oldData && (
                            <span className="text__red"> *</span>
                          )}
                        </div>

                        {!values.articlesOfAssociationFile && (
                          <div className="field__wrap field__wrap-imageupload">
                            <ImageUpload
                              isRequired
                              buttonText={t('Click or drop images')}
                              buttonClassName="button"
                              onChange={onDropFile('articlesOfAssociationFile')}
                              maxFileSize={10485760}
                              withPreview
                              singleImagePick
                              singleFile
                              defaultImages={[]}
                              accept='image/*, application/pdf'
                            >
                              <div className="file__wrap_text text__center">
                                <p>
                                  { t('Drag and drop the document') }
                                </p>
                              </div>
                            </ImageUpload>
                          </div>
                        )}

                        {values.articlesOfAssociationFile && (
                          <div className="settings__top">
                            <div className="settings__label">
                              {values.articlesOfAssociationFile.img.name}
                            </div>
                            <button
                              className="button-stroke button-small settings__button"
                              onClick={() => { setValues({...values, articlesOfAssociationFile: null})}}
                            >
                              {t('Remove')}
                            </button>
                          </div>
                        )}

                        {!!extractMsgFromErrByPropertyName(errors, 'articlesOfAssociationFile').length && (
                          <div className="field__wrap status-red field__fill">
                            { extractMsgFromErrByPropertyName(errors, 'articlesOfAssociationFile') }
                          </div>
                        )}
                      </div>
                    )}

                    {publicSettings.kyb_require_last_balance_sheet && (
                      <div className="field">
                        <div className="field__label">
                          {t('Last balance sheet available')}
                          {!oldData && (
                            <span className="text__red"> *</span>
                          )}
                        </div>

                        {!values.lastBalanceSheetFile && (
                          <div className="field__wrap field__wrap-imageupload">
                            <ImageUpload
                              isRequired
                              buttonText={t('Click or drop images')}
                              buttonClassName="button"
                              onChange={onDropFile('lastBalanceSheetFile')}
                              maxFileSize={10485760}
                              withPreview
                              singleImagePick
                              singleFile
                              defaultImages={[]}
                              accept='image/*, application/pdf'
                            >
                              <div className="file__wrap_text text__center">
                                <p>
                                  { t('Drag and drop the document') }
                                </p>
                              </div>
                            </ImageUpload>
                          </div>
                        )}

                        {values.lastBalanceSheetFile && (
                          <div className="settings__top">
                            <div className="settings__label">
                              {values.lastBalanceSheetFile.img.name}
                            </div>

                            <button
                              className="button-stroke button-small settings__button"
                              onClick={() => { setValues({...values, lastBalanceSheetFile: null})}}
                            >
                              {t('Remove')}
                            </button>
                          </div>
                        )}
                        {!!extractMsgFromErrByPropertyName(errors, 'lastBalanceSheetFile').length && (
                          <div className="field__wrap status-red field__fill">
                            { extractMsgFromErrByPropertyName(errors, 'lastBalanceSheetFile') }
                          </div>
                        )}
                      </div>
                    )}

                    {publicSettings.kyb_show_good_standing_certificate && (
                      <div className="field">
                        <div className="field__label">
                          {t('Certificate of Good Standing')}
                        </div>

                        {!values.certificateOfGoodStandingFile && (
                          <div className="field__wrap field__wrap-imageupload">
                            <ImageUpload
                              buttonText={t('Click or drop images')}
                              buttonClassName="button"
                              onChange={onDropFile('certificateOfGoodStandingFile')}
                              maxFileSize={10485760}
                              withPreview
                              singleImagePick
                              singleFile
                              defaultImages={[]}
                              accept='image/*, application/pdf'
                            >
                              <div className="file__wrap_text text__center">
                                <p>
                                  { t('Drag and drop the document') }
                                </p>
                              </div>
                            </ImageUpload>
                          </div>
                        )}

                        {values.certificateOfGoodStandingFile && (
                          <div className="settings__top">
                            <div className="settings__label">
                              {values.certificateOfGoodStandingFile.img.name}
                            </div>
                            <button
                              className="button-stroke button-small settings__button"
                              onClick={() => { setValues({...values, certificateOfGoodStandingFile: null})}}
                            >
                              {t('Remove')}
                            </button>
                          </div>
                        )}
                        {!!extractMsgFromErrByPropertyName(errors, 'certificateOfGoodStandingFile').length && (
                          <div className="field__wrap status-red field__fill">
                            <i>
                              { extractMsgFromErrByPropertyName(errors, 'certificateOfGoodStandingFile') }
                            </i>
                          </div>
                        )}
                      </div>
                    )}

                    {publicSettings.kyb_require_shareholder_list && (
                      <div className="field">
                        <div className="field__label">
                          {t('Shareholder List')}
                          {!oldData && (
                            <span className="text__red"> *</span>
                          )}
                        </div>

                        {!values.shareholderListFile && (
                          <div className="field__wrap field__wrap-imageupload">
                            <ImageUpload
                              isRequired
                              buttonText={t('Click or drop images')}
                              buttonClassName="button"
                              onChange={onDropFile('shareholderListFile')}
                              maxFileSize={10485760}
                              withPreview
                              singleImagePick
                              singleFile
                              defaultImages={[]}
                              accept='image/*, application/pdf'
                            >
                              <div className="file__wrap_text text__center">
                                <p>
                                  { t('Drag and drop the document') }
                                </p>
                              </div>
                            </ImageUpload>
                          </div>
                        )}
                        {values.shareholderListFile && (
                          <div className="settings__top">
                            <div className="settings__label">
                              {values.shareholderListFile.img.name}
                            </div>
                            <button
                              onClick={() => { setValues({...values, shareholderListFile: null})}}
                              className="button-stroke button-small settings__button"
                            >
                              {t('Remove')}
                            </button>
                          </div>
                        )}

                        {!!extractMsgFromErrByPropertyName(errors, 'shareholderListFile').length && (
                          <div className="field__wrap status-red field__fill">
                            { extractMsgFromErrByPropertyName(errors, 'shareholderListFile') }
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )
            }

            {publicSettings.kyc_prefer_currency && (
              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="field__label">
                  { t('My preferred payment currency') }
                </div>

                <div className="field__wrap field__wrap-radio_text_gray">
                  <FormControl>
                    <RadioGroup
                      value={(values || {}).currency}
                      onChange={({ target: { value } }) =>
                        setValues({ ...values, currency: value })
                      }
                      row
                    >
                      {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
                        .filter((name) => acceptedCurrencies[name])
                        .map((name) => (
                          <FormControlLabel
                            key={name}
                            label={name.toUpperCase()}
                            value={name}
                            control={<Radio color="primary" />}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            )}

            {publicSettings.token_type === TOKEN_TYPES.NONE && publicSettings.require_exact_investment_amount_on_none && (
              <div className="settings__row flex__row flex__wrap">
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  type="number"
                  className="input-bordered"
                  label={t('Exact investment amount')}
                  value={values.exactIntendedInvestmentAmount}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <strong>{baseCurrency.toUpperCase()}</strong>
                    </InputAdornment>
                  }}

                  onChange={updateField('exactIntendedInvestmentAmount')}

                  error={!!extractMsgFromErrByPropertyName(errors, 'exactIntendedInvestmentAmount')}
                  helperText={extractMsgFromErrByPropertyName(errors, 'exactIntendedInvestmentAmount').join('. ')}
                />

                {exchangeRates && (
                  <div className="field d__flex flex__row flex__wrap flex__justify_start flex__align_end">
                    <div className="create__line remove__line">
                      <div className="create__info text__green">
                        {renderEqualTo(fiatToToken(baseCurrency, values.exactIntendedInvestmentAmount, exchangeRates))}
                        <div className="space space__8">{' '}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {publicSettings.token_type !== TOKEN_TYPES.NONE && (
              <div className="settings__row flex__row flex__wrap">
                {publicSettings.enable_swap_token && tokenSwap && (
                  <div className="field">
                    <div className="field__label">
                      {publicSettings && publicSettings.old_token_symbol
                        ? `${publicSettings.old_token_symbol} amount`
                        : 'Old token amount'
                      }
                    </div>
                    <div className="field__wrap">
                      <InputThemeLite
                        value={tokenSwap.oldTokenAmount}
                        disabled
                        fullWidth
                      />
                    </div>
                  </div>
                )}

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  type="number"
                  className="input-bordered"
                  label={publicSettings.enable_swap_token && tokenSwap
                    ? `${publicSettings.token_symbol} amount`
                    : t('Tokens')}
                  value={values.amount}
                  onChange={updateField('amount')}
                  disabled={publicSettings.enable_swap_token && tokenSwap}
                  error={!!extractMsgFromErrByPropertyName(errors, 'amount')}
                  helperText={extractMsgFromErrByPropertyName(errors, 'amount').join('. ')}
                />

                <div className="field d__flex flex__row flex__wrap flex__justify_start flex__align_end">
                  <div className="create__line remove__line">
                    <div className="create__info text__green">
                      {renderEqualTo(values.amount)}
                      <div className="space space__8">{' '}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {publicSettings.kyc_intended_investment && (
              <>
                <div className="field">
                  <div className="field__label">
                    {t('Intended investment')}
                    <span className="text__red"> *</span>
                  </div>

                  <div className="field__wrap field__wrap-select">
                    <TextField
                      select
                      required
                      className="input-bordered"
                      value={values.intended_investment}
                      variant="outlined"

                      onChange={updateField('intended_investment')}

                      error={!!extractMsgFromErrByPropertyName(errors, 'intended_investment')}
                      helperText={extractMsgFromErrByPropertyName(errors, 'intended_investment').join('. ')}
                    >
                      {intendedInvestmentOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {formatIntendedInvestment(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                { isRenderSourceOfFunds() && (
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={
                      <div className="card__head">
                        <div className="card__title card__title-has_icon">
                          { t('Source Of Funds') }
                          <span className="text__red"> *</span>
                          {" "}
                          <PopupState popupId="declaration-relevant" variant="popover" >
                            {(popupState) => (
                              <>
                                <span {...bindTrigger(popupState)} className="actions__popover-trigger">
                                  <Icon
                                    className={classNames('info')}
                                    name='info'
                                    size="16"
                                  />
                                </span>

                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  className="actions__popover"
                                >
                                  <div className="actions__body actions__body_l actions__body-text">
                                    <div className="tips__action">
                                      <p>
                                        {t('Where does the funds used for this transaction come from?')}
                                      </p>
                                    </div>
                                  </div>
                                </Popover>
                              </>
                            )}
                          </PopupState>
                        </div>
                      </div>
                    }
                    value={values.sourceOfFunds}
                    variant="outlined"

                    onChange={updateField('sourceOfFunds')}

                    error={!!extractMsgFromErrByPropertyName(errors, 'sourceOfFunds')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'sourceOfFunds').join('. ')}
                  />
                )}
              </>
            )}

            <KycFormSupportedSourceOfFund
              tokenToBaseCurrency={tokenToBaseCurrency}
              publicSettings={publicSettings}
              onDropSupportedSourceOfFundFiles={onDropSupportedSourceOfFundFiles}
              supportedSourceOfFundFiles={supportedSourceOfFundFiles}
              removeDropSupportedSourceOfFundFiles={removeDropSupportedSourceOfFundFiles}
              intendedInvestment={values.intended_investment}
            />

            {!!extractMsgFromErrByPropertyName(errors, 'supportedSourceOfFundUploadFiles').length && (
              <div className="refund__item refund__item-middle_content">
                <div className="refund__icon">
                  <Icon
                    className={classNames('icon-help')}
                    name='icon-help'
                    size="24"
                  />{" "}
                </div>
                <div className="refund__content refund__content-link">
                  { extractMsgFromErrByPropertyName(errors, 'supportedSourceOfFundUploadFiles') }
                </div>
              </div>
            )}

            <div className="settings__row flex__row flex__wrap">
              {publicSettings.kyc_show_receiving_wallet && (
                <div className="field">
                  <div className="field__label">
                    {t('Wallet')}
                    <span className="text__red"> *</span>
                  </div>

                  <div className="field__wrap field__wrap-select">
                    <TextField
                      select
                      required
                      className="input-bordered"
                      value={values.wallet}
                      variant="outlined"

                      onChange={updateField('wallet')}

                      error={!!extractMsgFromErrByPropertyName(errors, 'wallet')}
                      helperText={extractMsgFromErrByPropertyName(errors, 'wallet').join('. ')}
                    >
                      {Object.keys(walletLabelTextMapping).filter(wallet => publicSettings.kyc_accepted_wallets[wallet]).map((wallet) => (
                        <MenuItem key={wallet} value={wallet}>
                          {walletLabelTextMapping[wallet]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              )}

              {publicSettings.kyc_show_receiving_wallet && (
                <>
                  {!publicSettings.force_generate_wallet && (
                    <InputThemeField
                      classWrapper="field"
                      classLabel="field__label"
                      classInput="field__input"
                      required={publicSettings.enable_cashlink}
                      label={
                        <>
                          {t('Receiving Wallet Address')}
                          {(publicSettings || {}).source_of_address_for_tokens_example_path && (
                            <a
                              href={getExternalFileUrl(
                                publicSettings.source_of_address_for_tokens_example_path
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ marginLeft: '10px', fontWeight: 'bold' }}
                            >
                              {t('Show Example')}
                            </a>
                          )}
                        </>
                      }
                      value={values.tokenAddress}

                      onChange={updateField('tokenAddress')}

                      error={!!extractMsgFromErrByPropertyName(errors, 'tokenAddress')}
                      helperText={(
                        <>
                          {(publicSettings && !publicSettings.hide_wallet_format_note && t(helperTextForWalletCompliant(values.wallet))) || undefined}
                          <span className="text_danger">{extractMsgFromErrByPropertyName(errors, 'tokenAddress').join('. ')}</span>
                        </>
                      )}
                    />
                  )}

                  {publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
                    <WalletRequest
                      type={!values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet}
                      generatedWallet={values.generatedWallet}
                      onComplete={setGeneratedWallet}
                    />
                  )}
                  <br />

                  {publicSettings && publicSettings.show_verify_wallet ?
                    (values.tokenAddress && values.verified_wallet === values.tokenAddress.toLowerCase()) ?
                      <span className="text-green">{t('This address have been verified.')}</span> :
                      <button
                        type="button"
                        className="button"
                        onClick={confirmWalletAndSend}
                        disabled={disableSubmitVerify}
                      >
                        { t('Verify this wallet') }
                      </button>
                    : ''
                  }
                </>
              )}
            </div>

            {isCrypto(values.currency) && (
              <>
                <div className="create__line">
                  <div className="create__info">
                    {t('I will add my wallet later')}
                  </div>

                  <label className="switch">
                    <input
                      type="checkbox"
                      className="switch__input"
                      value="isAddEtherWalletLater"
                      checked={values.isAddEtherWalletLater}
                      onChange={({target: { checked }}) => setValues({...values, isAddEtherWalletLater: checked})}
                    />
                    <div className="switch__inner">
                      <div className="switch__box">{' '}</div>
                    </div>
                  </label>
                </div>

                <div className="field">
                  <div className="field__label">
                    {t('Sending Wallet')}
                    {!values.isAddEtherWalletLater && (<span className="text__red"> *</span>)}
                  </div>

                  <div className="field__wrap field__wrap-select">
                    <TextField
                      select
                      required={!values.isAddEtherWalletLater}
                      className="input-bordered"
                      value={values.sending_wallet}
                      variant="outlined"

                      onChange={updateField('sending_wallet')}

                      error={!!extractMsgFromErrByPropertyName(errors, 'sending_wallet')}
                      helperText={extractMsgFromErrByPropertyName(errors, 'sending_wallet').join('. ')}
                    >
                      {getSendingWalletTypeOptions(values.currency).map((option) => (
                        <MenuItem key={option[0]} value={option[0]}>
                          {option[1]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"

                  required={!values.isAddEtherWalletLater}
                  disabled={values.isAddEtherWalletLater}
                  label={
                    <>
                      { t('Sending Wallet Address') }
                      {(publicSettings || {}).source_of_address_for_sending_tokens_example_path && (
                        <a
                          href={getExternalFileUrl(
                            publicSettings.source_of_address_for_sending_tokens_example_path
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: '10px', fontWeight: 'bold' }}
                        >
                          {t('Show Example')}
                        </a>
                      )}
                    </>
                  }
                  value={values.tokenSendingAddress}
                  onChange={updateField('tokenSendingAddress')}
                  error={!!extractMsgFromErrByPropertyName(errors, 'tokenSendingAddress')}
                  helperText={(
                    <>
                      {(publicSettings && !publicSettings.hide_wallet_format_note && t(helperTextForWalletCompliant(values.wallet))) || undefined}
                      <span className="text__red">{extractMsgFromErrByPropertyName(errors, 'tokenSendingAddress').join('. ')}</span>
                    </>
                  )}
                />
              </>
            )}
            {/* Referal code */}
            <>
              {values.referral_code &&
                publicSettings &&
                publicSettings.kyc_referal_code && (
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={ t('Referral Code') }
                    value={values.referral_code}
                    onChange={() => {}}
                    disabled
                  />
                )}
            </>

          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button" onClick={toNextStep}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  )
}

CompanyStep1.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  toNextStep: PropTypes.func,
  oldData: PropTypes.object,
}

export default CompanyStep1
