import React, { useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { useMe } from '../../myHooks'
import {
  VERIFY_PHONE_NUMBER,
  SEND_VERIFY_PHONE_NUMBER_SMS,
} from '../../queriesAndMutations'
import LoginHead from './LoginHead'
import { toaster } from '../../utils'

const EasyOnBoardingRestorePasswordNumber = () => {
  const { t } = useTranslation();
  const input1 = useRef(null)
  const input2 = useRef(null)
  const input3 = useRef(null)
  const input4 = useRef(null)
  const [code, setCode] = useState(['', '', '', ''])
  const refs = [input1, input2, input3, input4]
  const { data: { me } = {} } = useMe()
  const changeCode = (index) => ({ target: { value } }) => {
    if (!value.match(/^[0-9]?$/)) {
      return
    }

    const newCode = [...code]

    newCode[index] = value
    setCode(newCode)

    if (value && index + 1 <= 3) {
      refs[index + 1].current.focus()
      refs[index + 1].current.setSelectionRange(0, 1)
    }
  }
  const query = new URLSearchParams(window.location.search)
  const company = query.get('company')

  const [verifyPhoneNumber, verifyPhoneNumberStatus] = useMutation(VERIFY_PHONE_NUMBER)
  const [sendVerifyPhoneNumberSms, sendVerifyPhoneNumberSmsStatus] = useMutation(SEND_VERIFY_PHONE_NUMBER_SMS)

  const sendVerifyPhoneNumberRequest = () => {
    verifyPhoneNumber({
      variables: {
        code: code.join('')
      }
    }).then((response) => {
      if (company === 'true') {
        window.location.href = `${window.location.origin}/easy-onboarding-company`
      } else {
        window.location.href = `${window.location.origin}/easy-onboarding`
      }
    }).catch(() => {})
  }

  const resendVerifyPhoneNumberSms = () => {
    sendVerifyPhoneNumberSms({variables: {}}).then((response) => {
      if (response?.data?.sendVerifyPhoneNumberSms) {
        toaster.success('Success');
      } else {
        toaster.error('Resend verification code failed. Please try again after 10 minutes');
      }
    }).catch(() => {})
  }

  if (me && me.phone_verified) {
    return <Redirect to="/easy-onboarding" />
  }

  if (me && !me.phone_verified && me.verify_sms_send_time < 1) {
    return <Redirect to="/easy-onboarding-2fa" />
  }

  return (
    <>
      <LoginHead />

      <div className="login__wrapper entry__wrapper-kyc">
        <div className="h2 entry__title">
          { t('Verify Phone Number') }
        </div>

        <div className="entry__top entry__top-clear">
          <div className="entry__text">{ t('Please enter OTP code') }</div>

          <div className="settings__row flex__row flex__wrap settings__row-otp have__space">

            <div className="field nospace__bottom">
              <div className="field__wrap">
                <input
                  className="field__input field__input-otp text__center"
                  name="number"
                  value={code[0]}
                  onChange={changeCode(0)}
                  ref={refs[0]}
                />
              </div>
            </div>

            <div className="field nospace__bottom">
              <div className="field__wrap">
                <input
                  className="field__input field__input-otp text__center"
                  name="number"
                  value={code[1]}
                  onChange={changeCode(1)}
                  ref={refs[1]}
                />
              </div>
            </div>

            <div className="field nospace__bottom">
              <div className="field__wrap">
                <input
                  className="field__input field__input-otp text__center"
                  name="number"
                  value={code[2]}
                  onChange={changeCode(2)}
                  ref={refs[2]}
                />
              </div>
            </div>

            <div className="field nospace__bottom">
              <div className="field__wrap">
                <input
                  className="field__input field__input-otp text__center"
                  name="number"
                  value={code[3]}
                  onChange={changeCode(3)}
                  ref={refs[3]}
                />
              </div>
            </div>
          </div>

          <div className="field">
            {(verifyPhoneNumberStatus.loading || sendVerifyPhoneNumberSmsStatus.loading) && (
              <CircularProgress />
            )}

            {!verifyPhoneNumberStatus.loading && (
              <button type="button" className="button entry__button w-full" onClick={sendVerifyPhoneNumberRequest}>
                { t('Enter OTP') }
              </button>
            )}
          </div>

          <div className="field">
            {!sendVerifyPhoneNumberSmsStatus.loading && (
              <button type="button" className="link" onClick={resendVerifyPhoneNumberSms}>
                { t('Resend verification code') }
              </button>
            )}
          </div>
        </div>
      </div>

    </>
  );
}

export default EasyOnBoardingRestorePasswordNumber;
