import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  CircularProgress,
} from '@material-ui/core'
import moment from 'moment'

import {
  ADD_CAPITAL_INCREASE,
} from '../../queriesAndMutations'
import { toaster } from '../../utils'
import InputTheme from '../../components/InputTheme'
import DateFnsUtils from '@date-io/date-fns/build/index'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  input_border_date: {
    borderRadius: "4px",
    border: "1px solid var(--color__border-box)",
    width: "100%",
    padding: "14px 15px",
    lineHeight: "20px",
    fontSize: ".9em",
    color: "rgba(73, 84, 99, 0.7)",
    transition: "all .4s",
    "&:focus": {
      boxShadow: "none",
      outline: "none",
      borderColor: "#b1becc",
    },
    "&:disabled": {
      background: "rgba(230, 239, 251, 0.2)",
    },

    "~": {
      error: {
        color: "#ff6868",
        marginBottom: "0",
        position: "relative",
        top: "7px",
      },
    },
  },
}))

const AddCapitalIncreaseForm = ({
  onComplete,
}) => {
  const classes = useStyles()
  const [values, setValues] = useState({
    date: new Date(),
    commercial_register_url: '',
    pdf_url: '',
  })
  const [addCapitalIncrease, addCapitalIncreaseStatus] = useMutation(ADD_CAPITAL_INCREASE);

  const submitAddCapitalIncrease = () => {
    addCapitalIncrease({
      variables: {
        input: {
          ...values,
          date: moment(values.date).format("YYYY-MM-DD"),
        }
      }
    }).then(({ data: { addCapitalIncrease: success }}) => {
      if (success) {
        setValues({
          date: new Date(),
          commercial_register_url: '',
          pdf_url: '',
        })
        toaster.success(' Capital Increase saved')
        onComplete()
      }
    }).catch(() => {})
  }

  return (

    <div className="settings__fieldset">
      <div className="settings__row flex__row flex__wrap">
        <div className="field">
          <div className="field__label">
            Date
          </div>

          <div className="field__wrap field__wrap-date">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.input_border_date}
                views={["year", "month", "date"]}
                openTo="year"
                variant="outlined"
                format="dd.MM.yyyy"
                margin="none"
                id="date-picker-dialog"
                error={false}
                value={values.date}
                onChange={(date) => setValues({...values, date})}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label="Commercial Register URL"
          propertyName="commercial_register_url"
          state={values}
          setState={setValues}
        />
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label="PDF URL"
          propertyName="pdf_url"
          state={values}
          setState={setValues}
        />
      </div>

      <div>
        {!addCapitalIncreaseStatus.loading && (
          <button className="button" onClick={submitAddCapitalIncrease}>Add</button>
        )}
        {addCapitalIncreaseStatus.loading && (
          <CircularProgress />
        )}
      </div>
    </div>
  )
}

export default AddCapitalIncreaseForm
