import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useMe, usePublicSettings } from '../../myHooks'
import {
  GET_MONERIUM_AUTH_TOKEN,
  CREATE_NEW_WALLET,
  WALLET_ADDRESS_MUTATION,
  GET_FIAT_REPUBLIC_LIST_ACCOUNT,
  ADD_FIAT_ACCOUNT,
} from '../../queriesAndMutations'
import axios from 'axios'
import { groupBy, filter } from 'lodash'
// import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  Button,
  CircularProgress
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { isProduction } from '../../utils'
import MoneriumAccount from '../../components/MoneriumAccount'
import ShortenText from '../../components/ShortenText'
import queryString from 'query-string'
import { NavLink, useHistory } from "react-router-dom";
import Icon from '../../components/Icon'
import Dialog from '@material-ui/core/Dialog'
import ModalInnerMaterialDialog from '../../components/ModalInnerMaterialDialog'
import InputTheme from '../../components/InputTheme'

const tanganyWalletSupports = ['btc', 'eth', 'usdt', 'usdc', 'polygon', 'xdc'];
const API_URL = isProduction ? 'https://api.monerium.app' : 'https://api.monerium.dev'

const FIAT_REP_API_URL = isProduction ? 'https://member.fiatrepublic.com/api/v1' : 'https://sandbox.fiatrepublic.com/api/v1'

const Balances = () => {
  const type = 'eth'
  const { t } = useTranslation()
  const { data: { me = {} } = {}, refetch } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [createNewWallet, createNewWalletStatus] = useMutation(CREATE_NEW_WALLET)
  const [updateWalletAddress, { loading: updateWalletAddressLoading, error }] = useMutation(
    WALLET_ADDRESS_MUTATION
  );
  const { data, loading: getAuthTokenLoading } = useQuery(GET_MONERIUM_AUTH_TOKEN)
  const { data: fiatRepublicListAccountData, loading: fiatRepublicListAccountLoading, refetch: fiatAccountRefetch } = useQuery(GET_FIAT_REPUBLIC_LIST_ACCOUNT)
  const [authToken, setAuthToken] = useState(null)
  const [defaultProfile, setDefaultProfile] = useState(null)
  const [fiatAccounts, setFiatAccounts] = useState([])
  const [balances, setBalances] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [loading, setLoading] = useState(null)
  const history = useHistory()
  const { expenseId, iban, amount } = queryString.parse(history.location.search)
  const [value, setValue] = useState({
    currency: '',
    label: '',
  })
  const [openModalAddAccount, setOpenModalAddAccount] = useState(false)
  const [addAccountLoading, setAddAccountLoading] = useState(false)
  const [addFiatAccount] = useMutation(ADD_FIAT_ACCOUNT);

  // const styles = useStyles()

  const getAuthContext = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/auth/context`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
      )

      setDefaultProfile(response.data.defaultProfile)

      return response.data.defaultProfile
    } catch (error) {
      console.log(error)

      return null
    }
  }

  const getProfileBalances = async (profile, token) => {
    try {
      const response = await axios.get(
        `${API_URL}/profiles/${profile}/balances`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
      )

      const balancesByAddress = groupBy(response.data, 'address')
      setBalances(balancesByAddress)
    } catch (error) {
      console.log(error)
    }
  }

  const getProfileAccounts = async (profile, token) => {
    try {
      const response = await axios.get(
        `${API_URL}/profiles/${profile}`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
      )

      setAccounts(response.data.accounts)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (fiatRepublicListAccountData && fiatRepublicListAccountData.getFiatRepublicListAccount && fiatRepublicListAccountData.getFiatRepublicListAccount.success) {
      setFiatAccounts(fiatRepublicListAccountData.getFiatRepublicListAccount.data)
    }

    // eslint-disable-next-line
  }, [fiatRepublicListAccountData])

  useEffect(() => {
    const getAppData = async (token) => {
      const profile = await getAuthContext(token)

      if (profile) {
        await getProfileBalances(profile, token)
        getProfileAccounts(profile, token)
      }
    }

    if (data && data.getMoneriumAuthToken && data.getMoneriumAuthToken.success) {
      setLoading(true)
      setAuthToken(data.getMoneriumAuthToken.data.access_token)

      if (!expenseId) {
        getAppData(data.getMoneriumAuthToken.data.access_token)
      } else {
        setLoading(false)
      }
    }

    // eslint-disable-next-line
  }, [data])

  const onSetCurrentAccount = (account, balance) => {
    console.log('account', { ...account, ...balance })
    setCurrentAccount({ ...account, ...balance })
  }

  useEffect(() => {
    const getFirstAccount = () => {
      let result
      Object.keys(balances).forEach((address) => {
        const balancesItem = balances[address]
        const accountsByAddress = filter(accounts, (account) => account.address === address)

        if (accountsByAddress && accountsByAddress.length) {
          accountsByAddress.forEach((account) => {
            if (result) {
              return
            }
            const balancesByAccount = balancesItem.find((b) => b.chain === account.chain && b.network === account.network)

            if (balancesByAccount) {
              const balance = balancesByAccount.balances.find((b) => b.currency === account.currency)

              if (balance) {
                result = [account, balance]
              }
            }
          })
        }
      })

      return result
    }

    if (expenseId && iban && amount && authToken) {
      setCurrentAccount({
        address: "0x45AC69479611EF0BC9B18eF2B3C454D946705C44",
        amount: "385503",
        chain: "polygon",
        currency: "eur",
        iban: "IS17 2800 9840 7365 0930 4506 12",
        id: "007e52c8-b35c-11ed-8d50-3aa1c633f614",
        network: "mumbai",
        standard: "iban",
        state: "approved",
      })
    }

    // eslint-disable-next-line
  }, [authToken, expenseId, iban, amount])

  if (currentAccount) {
    return <MoneriumAccount authToken={authToken} currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} />
  }

  const onUpdateWalletAddress = async (wallet) => {
    try {
      await updateWalletAddress({
        variables: {
          input: {
            wallet_address: wallet,
            wallet_type: 'eth',
            currency: 'eure'
          },
        },
      });

      // toaster.success("Wallet address was successfully saved");
      await refetch();
    } catch (e) {}
  }

  const submit = async (e) => {
    e.preventDefault();
    setAddAccountLoading(true)

    try {
      await addFiatAccount({ variables: {
        ...value,
        businessId: 'biz_x83lr41ojlg7dy5va2',
      } });

      setAddAccountLoading(false)
      fiatAccountRefetch()
      setOpenModalAddAccount(false)
      setValue({
        currency: '',
        label: '',
      })
    } catch (e) {
      console.log(e)
      setAddAccountLoading(false)
    }
  }

  const requestCreateNewWallet = () => {
    createNewWallet({
      variables: {
        type,
      },
    }).then(({ data: { createNewWallet: wallet } }) => {
      onUpdateWalletAddress(wallet)
    }).catch(() => {})
  }

  const renderAddFiatAccount = () => (
    <Dialog
      open={openModalAddAccount}
      onClose={() => setOpenModalAddAccount(false)}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={() => setOpenModalAddAccount(false)}
        modalTitleClassColor="title-primary"
      >
        <form onSubmit={submit} className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields have__space">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"

              propertyName="currency"
              label={t('Currency')}
              state={value}
              setState={setValue}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"

              propertyName="label"
              label={t('Label')}
              state={value}
              setState={setValue}
            />
          </div>

          <div className="field">
            {addAccountLoading && (
              <CircularProgress />
            )}
            {!addAccountLoading && (
              <button type="submit" className="button settings__button">
              { t('Save') }
              </button>
            )}
          </div>
        </form>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  return (
    <>
      {renderAddFiatAccount()}
      <div className="page__title h3">
        { t('Wallet Address')}
      </div>

      <div className="transactions customer card">
        <div className="transactions__wrapper overflow-x__auto">
          { me && me.wallet_address && (
            <div>
              {`${t('Your wallet')}: ${me.wallet_address}`}
            </div>
          )}

          {!me.wallet_address && t('Please generate the new wallet')}
          {' '}
          {(createNewWalletStatus.loading || updateWalletAddressLoading) && (
            <CircularProgress />
          )}
          {!me.wallet_address && !createNewWalletStatus.loading && (
            <span className="text__primary cursor-pointer" onClick={requestCreateNewWallet}>{t('Generate one')}</span>
          )}
        </div>
      </div>

      <br />
      <div className="page__title h3">
        { t('Fiat Accounts')}
        {' '}
        <Button
          className='button button-stroke'
          onClick={() => setOpenModalAddAccount(true)}
        >
          <Icon className="plus" name="plus" />
        </Button>
      </div>

      <div className="card">
        {fiatRepublicListAccountLoading && (
          <CircularProgress />
        )}

        <div className="earning__table-card__wrapper">
          { fiatAccounts && fiatAccounts.map(account => {
            return (
              <div className="earning__table earning__table-card" key={account.id}>
                <div className="earning__row">{' '}</div>
                <div className="earning__row">
                  <div className="earning__col">
                    {account.label}
                  </div>
                  <div className="earning__col">
                    {account.balance && account.balance.actual}
                  </div>
                </div>

                <div className="earning__row">
                  <div className="earning__col">
                    Currency
                  </div>
                  <div className="earning__col">
                    {account.currency}
                  </div>
                </div>

                { account.bankDetails && (
                  <div className="earning__row">
                    <div className="earning__col">
                      IBAN
                    </div>
                    <div className="earning__col">
                      <div className="earning__label">
                        IBAN
                      </div>
                      {account.bankDetails.iban}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      <br />
      <div className="page__title h3">
        { t('Web3 Accounts')}

        {(loading || getAuthTokenLoading) && (
          <CircularProgress />
        )}
      </div>

      {balances && (
        Object.keys(balances).map((address) => {
          const balancesItem = balances[address]
          const accountsByAddress = filter(accounts, (account) => account.address === address)

          return (
            <div className="card" key={address}>
              <div className="card__head">
                <div className="title-clear card__title mobile-hide">
                  {address}
                </div>
                <div className="title-clear card__title mobile-show">
                  <ShortenText text={address} v2 />
                </div>
              </div>

              <div className="earning__table-card__wrapper">
                {accountsByAddress && accountsByAddress.map((account) => {
                  const balancesByAccount = balancesItem.find((b) => b.chain === account.chain && b.network === account.network)

                  if (!balancesByAccount) {
                    return ''
                  }

                  const balance = balancesByAccount.balances.find((b) => b.currency === account.currency)

                  if (!balance) {
                    return ''
                  }

                  return (
                    <div className="earning__table earning__table-card" key={account.id} onClick={() => onSetCurrentAccount(account, balance)}>
                      <div className="earning__row">{' '}</div>
                      <div className="earning__row">
                        <div className="earning__col">
                          {balance.currency}
                        </div>
                        <div className="earning__col">
                          <div className="earning__label">
                            {balance.currency}
                          </div>
                          {balance.amount}
                        </div>
                      </div>

                      { account.iban && (
                        <div className="earning__row">
                          <div className="earning__col">
                            IBAN
                          </div>
                          <div className="earning__col">
                            <div className="earning__label">
                              IBAN
                            </div>
                            {account.iban}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })
      )}
    </>
  )
}

export default Balances
