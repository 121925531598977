import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { ADMIN_KYC_LIST_CSV } from '../../queriesAndMutations'
import InputTheme from '../InputTheme'
import { useTranslation } from 'react-i18next'

const AdminExportKyc = ({ type, onClose, isPythagoras, isBank }) => {
  const [value, setValue] = useState({
    stepNumber: 0,
    two_fa_token: '',
  })
  const { t } = useTranslation()
  const [queryUserList, { loading, error, data: { kycListCsv } = {} }] = useMutation(ADMIN_KYC_LIST_CSV)

  const changeStep = (step) => setValue({
    ...value,
    stepNumber: step,
  })

  const csvData = kycListCsv && URL.createObjectURL(new Blob([kycListCsv], {type: 'text/csv'}))

  const onSubmit = async (e) => {
    e.preventDefault()
    queryUserList({ variables: { two_fa_token: value.two_fa_token, is_pythagoras: isPythagoras, type, is_bank: isBank, } }).then(() => {
      changeStep(1)
    }).catch(() => {});
  }

  const renderFirstStep = () => (
    <>
      <div className="refund__content">
        <div className="space space__8">{' '}</div>
        { t('Please enter your 2fa token') }
        <span className="space space__16">{' '}</span>
      </div>

      <form onSubmit={onSubmit} className="settings__item">
        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="two_fa_token"
            label={t('Token')}
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />

          <div className="field">
            <button className="button" type="submit">{ t('Next') }</button>
          </div>
        </div>
      </form>
    </>
  )

  const renderSuccess = () => (
    <>
      <div className="settings__item">
        <div className="settings__fieldset">
          <div className="field">
            <a
              href={csvData}
              onClick={onClose}
              download={`${type.toLowerCase()}_kyc_list.csv`}
              className="button">
              { t('Click to Download') }
            </a>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <div className="kyc__stepper kyc__stepper_32">
        <Stepper activeStep={value.stepNumber}>
          <Step key={0}>
            <StepLabel>{ t('2FA token') }</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>{ t('Download') }</StepLabel>
          </Step>
        </Stepper>
      </div>

      {value.stepNumber === 0 && renderFirstStep()}
      {value.stepNumber === 1 && renderSuccess()}
    </>
  )
}

AdminExportKyc.propTypes = {
  type: propTypes.string.isRequired,
}

export default AdminExportKyc
