import React from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useMe } from '../myHooks'
import {
  GET_USER_UBO_LIST
} from '../queriesAndMutations'

const KybUboStatus = ({
  title,
  uboList,
}) => {
  const { t } = useTranslation();
  const { data: { getUserUboList } = {} } = useQuery(GET_USER_UBO_LIST)
  const { data: { me } = {} } = useMe()

  if (
    (!getUserUboList || getUserUboList.length === 0) &&
    (!uboList || uboList.length === 0)
  ) {
    return null
  }

  return (
    <div className={`hit kyc-hit nospace__top ${!title && 'hit__clear-top'}`}>
      {title && (
        <div className="hit__head">
          <div className="title-primary hit__title">{title}</div>
        </div>
      )}

      <div className="hit__wrapper">
        <div className="summary">
          <div className="transactions__wrapper products__table-kyclist__wrapper">
            <div className="transactions__table kyc__table_transactions kyc__table_kyclist">
              <div className="transactions__row">
                <div className="transactions__col">
                  {t('Name')}
                </div>

                <div className="transactions__col">
                  {t('UBO Profile')}
                </div>

                <div className="transactions__col">
                  {t('Watchlist')}
                </div>
              </div>

              {(uboList || getUserUboList).map((ubo, index) => (
                <div key={ubo.id} className="transactions__row">
                  <div className="transactions__col">
                    {`${ubo.firstName} ${ubo.lastName}`}
                  </div>

                  <div className="transactions__col">
                    <div className="transactions__label">
                      {t('UBO Profile')}
                    </div>
                    {ubo.status === 'NOT_SUBMITTED' && me && me.email === ubo.email && (
                      <NavLink className="button button-small" to={`/ubo-profile/${ubo.id}`}>
                        {t('Add UBO profile')}
                      </NavLink>
                    )}
                    {ubo.status === 'NOT_SUBMITTED' && me && me.email !== ubo.email && (
                      <div className="status status-pending-dark">
                        {t('Incomplete')}
                      </div>
                    )}
                    {ubo.status !== 'NOT_SUBMITTED' && (
                      <div className="status status-green-dark">
                        {t('Success')}
                      </div>
                    )}
                  </div>
                  <div className="transactions__col">
                    <div className="transactions__label">
                      {t('Watchlist')}
                    </div>
                    {
                      ubo.onfidoApplicant &&
                      ubo.onfidoApplicant.onfidoChecks.length > 0 &&
                      ubo.onfidoApplicant.onfidoChecks[0].onfidoReports.length > 0 &&
                      ubo.onfidoApplicant.onfidoChecks[0].onfidoReports[0].result === 'clear' && (
                        <div className="status status-green-dark">
                          {t('Success')}
                        </div>
                      )
                    }
                    {
                      ubo.onfidoApplicant &&
                      ubo.onfidoApplicant.onfidoChecks.length > 0 &&
                      ubo.onfidoApplicant.onfidoChecks[0].onfidoReports.length > 0 &&
                      ubo.onfidoApplicant.onfidoChecks[0].onfidoReports[0].result !== 'clear' &&
                      ubo.onfidoApplicant.onfidoChecks[0].onfidoReports[0].result !== null && (
                        <div className="status status-red-dark">
                          {t('Failed')}
                        </div>
                      )
                    }
                    {
                      (
                        !ubo.onfidoApplicant ||
                        ubo.onfidoApplicant.onfidoChecks.length === 0 ||
                        ubo.onfidoApplicant.onfidoChecks[0].onfidoReports.length === 0 ||
                        ubo.onfidoApplicant.onfidoChecks[0].onfidoReports[0].result === null
                      ) && (
                        <div className="status status-pending-dark">
                          {t('Incomplete')}
                        </div>
                      )
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="space space__24">{' '}</div>
    </div>
  )
}

export default KybUboStatus
