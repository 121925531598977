import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Switch } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useMe } from '../myHooks'
import { USER_RIGHT_TYPES } from "../constants/user";

import { hasUserEnoughRights, isFeatureEnabled } from '../utils'

import { useQuery } from '@apollo/react-hooks'
import { GET_AVAILABLE_FEATURES } from '../queriesAndMutations'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}))

const ProtectedZone = ({
  children,
  urlToRedirect,
  minRights,
  location,
}) => {
  const classes = useStyles()
  const { error, loading, data: { me: user } = {} } = useMe()
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);

  if (loading) {
    return <div className={classes.circularProgressWrapper}><CircularProgress /></div>
  }

  if (error || !user) {
    const errorCode = (
      error &&
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.code
    ) || ''

    if (location.pathname.match(/^\/application\/.+/) && errorCode === 'UNAUTHENTICATED') {
      return <Redirect to={urlToRedirect || '/register'} />
    }

    return <Redirect to={urlToRedirect || '/login'} />
  }

  if (user && [USER_RIGHT_TYPES.ADMIN, USER_RIGHT_TYPES.GENERAL_ADMIN].includes(user.rights) && !user.is_2fa_enabled) {
    return <Redirect to={urlToRedirect || '/second-layer-security'} />
  }

  if (hasUserEnoughRights(user.rights, minRights) === false) {
    return <Redirect to={urlToRedirect || '/login'} />
  }

  if (location.pathname === "/" && !isFeatureEnabled('KYB_ONBOARDING', availableFeatures)) {
    return <Redirect to={urlToRedirect || '/admin/settings'} />
  }

  return <Switch>{children}</Switch>
}

ProtectedZone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  // eslint-disable-next-line react/require-default-props
  urlToRedirect: PropTypes.string,
  minRights: PropTypes.string,
  location: PropTypes.object,
}

export default ProtectedZone
