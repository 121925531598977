import React from 'react'
import {
  useQuery,
  useMutation,
} from '@apollo/client'

import {
  CircularProgress,
} from '@material-ui/core'

import {
  GET_INTERNAL_LIVENESS_CHECK_APPLICANT_BY_ACCESS_TOKEN,
  SUBMIT_LIVENESS_CHECK_RESOURCES,
} from '../queriesAndMutations'

import Footer from '../components/Footer'
import LivenessCheck from '../components/LivenessCheck'

const InternalLivenessCheckForm = ({ match }) => {
  const accessToken = match.params.accessToken
  const {
    data: {
      getInternalLivenessCheckApplicantByAccessToken: {
        livenessCheckApplicant: applicant,
        challenge,
      }
    } = {
      getInternalLivenessCheckApplicantByAccessToken: {
        livenessCheckApplicant: null,
        challenge: null,
      }
    },
    loading,
  } = useQuery(GET_INTERNAL_LIVENESS_CHECK_APPLICANT_BY_ACCESS_TOKEN, {
    variables: {
      access_token: accessToken,
    }
  });
  const [submitLivenessCheckResources, submitLivenessCheckResourcesStatus] = useMutation(SUBMIT_LIVENESS_CHECK_RESOURCES)

  const onComplete = (data) => {
    submitLivenessCheckResources({
      variables: {
        input: {
          access_token: accessToken,
          ...data,
        }
      }
    })
  }

  return (
    <div className="page-user">

      <div className="page-dashboard__content page-dashboard__content-four">
        <div className="dashboard-application style-3">
          <h1 className="text-center">Liveness Check</h1>
          {loading && (
            <div className="text-center">
              <CircularProgress />
            </div>
          )}
          {!loading && !applicant && (
            <div className="text-center text-danger">
              <p>Invalid URL or token expired</p>
            </div>
          )}

          {applicant && (
            <LivenessCheck
              onComplete={onComplete}
              isSubmitting={submitLivenessCheckResourcesStatus.loading}
              challenge={challenge}
            />
          )}

        </div>
      </div>

      <Footer />
    </div>
  )
}

export default InternalLivenessCheckForm
