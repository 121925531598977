import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Icon from '../../../components/Icon'
import { hasUserEnoughRights, getFileUrl, getExternalFileUrl } from '../../../utils/index'
import { USER_RIGHT_TYPES } from '../../../constants/user'
import {
  PUBLIC_UPLOADS,
  REMOVE_PUBLIC_UPLOAD,
} from '../../../queriesAndMutations'
import { getOriginalFileExtension } from '../../../utils/file'
import { useMe } from '../../../myHooks'
import classNames from 'classnames'
import UploadingImage from './UploadingImage'
import UploadPreviewing from './UploadPreview'

const Uploads = () => {
  const { data: { publicUploads = [] } = {}, refetch } =
    useQuery(PUBLIC_UPLOADS);
  const { data: { me: user } = {} } = useMe();

  const [removePublicUpload] = useMutation(REMOVE_PUBLIC_UPLOAD);

  const onRemoveUpload = (uploadId) => async () => {
    try {
      await removePublicUpload({ variables: { upload_id: uploadId } });
      refetch();
    } catch (e) {}
  };

  const getUploadUrl = (upload) => getExternalFileUrl(upload.storage_key);

  const UploadPreview = ({ upload, ...rest }) => {
    if (upload.preview_storage_key) {
      return (
        <div className="summary__preview_aspect_ratio">
          <img
            alt=""
            height="375"
            src={getFileUrl(upload.preview_storage_key)}
            {...rest}
          />
          <div className="summary__control">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
            <a
              className="summary__button"
              href={getUploadUrl(upload)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                className={classNames('link', 'icon-24')}
                name="close"
                size="24"
              />
            </a>
            { hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN)
              && (
                <button
                  className="summary__button"
                  onClick={onRemoveUpload(upload.id)}
                >
                  <Icon
                    className={classNames('trash', 'icon-24')}
                    name="trash"
                    size="24"
                  />
                </button>
              )
            }
          </div>
        </div>
      );
    }
    if (getOriginalFileExtension(upload.storage_key) === "pdf") {
      return (
        <div className="summary__preview_aspect_ratio">
          <embed
            src={getFileUrl(upload.storage_key)}
            height="375"
            type="application/pdf"
            {...rest}
          />
          <div className="summary__control">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
            <a
              className="summary__button"
              href={getUploadUrl(upload)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                className={classNames('link', 'icon-24')}
                name="link"
                size="24"
              />
            </a>

            { hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN)
              && (
                <button
                  className="summary__button"
                  onClick={onRemoveUpload(upload.id)}
                >
                  <Icon
                    className={classNames('trash', 'icon-24')}
                    name="trash"
                    size="24"
                  />
                </button>
              )
            }
          </div>
        </div>
      );
    }
    return <div className="summary__preview_aspect_ratio"><img alt="" src={getFileUrl(upload.storage_key)} {...rest} /></div>;
  };

  return (
    <div className="shop__products">
      <div className="summary summary__document">
        <UploadingImage refetch={refetch}/>
      </div>

      {publicUploads.map((upload) => (
        <UploadPreviewing
          key={upload.storage_key}
          name={upload.original_name}
        >
          <UploadPreview upload={upload} />

          <div className="summary__control">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
            <a
              className="summary__button"
              href={getUploadUrl(upload)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                className={classNames('link', 'icon-24')}
                name="link"
                size="24"
              />
            </a>
            { hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN)
              && (
                <button
                  className="summary__button"
                  onClick={onRemoveUpload(upload.id)}
                >
                  <Icon
                    className={classNames('trash', 'icon-24')}
                    name="trash"
                    size="24"
                  />
                </button>
              )
            }
          </div>
        </UploadPreviewing>
      ))}
    </div>
  );
};

export default Uploads;
