import React, { useState } from 'react'
import { useQuery, useMutation } from "@apollo/client";
import { omit } from 'lodash'

import InputTheme from '../../../components/InputTheme'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import InfoIcon from '@material-ui/icons/Info'
import UpdateIcon from '@material-ui/icons/Update'
import DropdownMaterial from '../../../components/DropdownMaterial'
import { useTranslation } from 'react-i18next'

import {
  CASHLINK_DEPLOY_CONTRACT,
  CASHLINK_UPDATE_CONTRACT_STATUS,
  GET_AVAILABLE_FEATURES,
  DROP_CASHLINK_CONTRACT,
} from '../../../queriesAndMutations'
import { toaster, isFeatureEnabled } from '../../../utils'
import FeatureNotAvailableMessage from '../../../components/FeatureNotAvailableMessage';

const CASHLINK_NETWORKS = {
  ETHEREUM: 'ETHEREUM',
  MATIC_SIDE_CHAIN: 'POLYGON',
}

const CASHLINK_SIGNATURE_MODE = {
  MANAGED: 'MANAGED',
  CLIENT_SIDE: 'CLIENT_SIDE',
}

const CASHLINK_SUPPLY_LIMIT = {
  NO_LIMIT: 'NO_LIMIT',
  LIMIT: 'LIMIT',
}

const CASHLINK_MODE = {
  PRODUCTION: 'PRODUCTION',
  SANDBOX: 'SANDBOX',
}

const AdminSettingsCashlink = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
  refetch
}) => {
  const { t } = useTranslation();
  const [deployContract, deployContractData] = useMutation(CASHLINK_DEPLOY_CONTRACT);
  const [updateContractStatus, updateContractStatusData] = useMutation(CASHLINK_UPDATE_CONTRACT_STATUS);
  const [dropCashlinkContract, dropCashlinkContractData] = useMutation(DROP_CASHLINK_CONTRACT);
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);

  const [values, setValues] = useState({
    enable_cashlink: settings.enable_cashlink || false,
    cashlink_api_url: settings.cashlink_api_url || '',
    cashlink_client_id: settings.cashlink_client_id || '',
    cashlink_client_secret: settings.cashlink_client_secret || '',
    cashlink_mode: settings.cashlink_mode || CASHLINK_MODE.SANDBOX,

    cashlink_network: settings.cashlink_network || CASHLINK_NETWORKS.ETHEREUM,
    cashlink_calculation_factor: settings.cashlink_calculation_factor || 1,
    cashlink_isin: settings.cashlink_isin || '',
    cashlink_paper_contract_hash: settings.cashlink_paper_contract_hash || '',
    cashlink_paper_contract_url: settings.cashlink_paper_contract_url || '',

    cashlink_signature_mode: settings.cashlink_signature_mode || CASHLINK_SIGNATURE_MODE.MANAGED,
    cashlink_backup_wallet: settings.cashlink_backup_wallet || '',

    cashlink_supply_limit: settings.cashlink_supply_limit || CASHLINK_SUPPLY_LIMIT.NO_LIMIT,
    cashlink_initial_supply: settings.cashlink_initial_supply || 0,
  });

  const cashlinkContractInfo = settings.cashlink_contract_info || null

  const onUpdateBtnClick = () => {
    onUpdate({
      ...omit(values, [
        'cashlink_paper_contract_hash'
      ]),
      cashlink_calculation_factor: +(values.cashlink_calculation_factor),
      cashlink_initial_supply: +(values.cashlink_initial_supply),
    })
  }

  const deployContractHandle = () => {
    deployContract({
      variables: {}
    }).then(() => {
      refetch()
      toaster.success('Contract deployed successfuly')
    }).catch(e => console.log(e));
  }

  const dropCashlinkContractHandle = () => {
    dropCashlinkContract({
      variables: {}
    }).then(() => {
      refetch()
      toaster.success('Contract dropped successfuly')
    }).catch(e => console.log(e));
  }

  const updateContractInfo = () => {
    updateContractStatus({
      variables: {}
    }).then(response => {
      if (response.data.updateContractStatus.done) {
        refetch()
      }
    }).catch(e => console.log(e));
  }

  // const setDropdownFieldValue = (field) => (value) => {
  //   setValues({
  //     ...values,
  //     [field]: value,
  //   })
  // }

  if (!isFeatureEnabled('ACRONIS_EXPORT', availableFeatures)) {
    return (
      <div>
        <FeatureNotAvailableMessage />
      </div>
    )
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Cashlink') }</div>
        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">{ t('Enable Cashlink') }</div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_cashlink}
                disabled={!editingAllowed}
                onChange={({ target: { checked } }) => setValues({...values, enable_cashlink: checked})}
                value="enable_cashlink"
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields have__space">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="cashlink_api_url"
              label="API URL"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="cashlink_client_id"
              label="Client ID"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          </div>

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="cashlink_client_secret"
            label="Client Secret"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />

          <div className="field field-radio__material">
            <div className="field__label">{ t('Mode') }</div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).cashlink_mode}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, cashlink_mode: value })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label="Production"
                  value={CASHLINK_MODE.PRODUCTION}
                  control={<Radio color="primary"/>}
                />
                <FormControlLabel
                  disabled={!editingAllowed}
                  label="Sandbox"
                  value={CASHLINK_MODE.SANDBOX}
                  control={<Radio color="primary"/>}
                />
              </RadioGroup>
            </div>
          </div>

          <DropdownMaterial
            propertyName="cashlink_network"
            label={t('Network')}
            state={values}
            setState={setValues}
            options={Object.keys(CASHLINK_NETWORKS)}
            disabled={!editingAllowed}
          >
            {Object.keys(CASHLINK_NETWORKS).map((option) => (
              <MenuItem key={option} value={(option)}>
                {CASHLINK_NETWORKS[option]}
              </MenuItem>
            ))}
          </DropdownMaterial>

          <div className="settings__row flex__row flex__wrap have__fields have__space">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="cashlink_isin"
              label="ISIN"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />

            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="cashlink_calculation_factor"
              label="Calculation factor"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
              type="number"
            />
          </div>

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="cashlink_paper_contract_url"
            label="Paper Contract URL"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed}
            loading={loading}
          />
          {values.cashlink_paper_contract_hash && (
            <div>Hash: {settings.cashlink_paper_contract_hash}</div>
          )}
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Signature mechanisms') }
          {" "}
          <a
            href="https://docs.cashlink.de/cashlink-tokenization-api/concepts/contract#signature-mechanisms"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InfoIcon fontSize="small" color="disabled"/>
          </a>
        </div>

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">{ t('Signature Mode') }</div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).cashlink_signature_mode}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, cashlink_signature_mode: value })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label="Managed Mode"
                  value={CASHLINK_SIGNATURE_MODE.MANAGED}
                  control={<Radio color="primary"/>}
                />
                <FormControlLabel
                  disabled={!editingAllowed}
                  label="Client-side mode"
                  value={CASHLINK_SIGNATURE_MODE.CLIENT_SIDE}
                  control={<Radio color="primary"/>}
                />
              </RadioGroup>
            </div>
          </div>

          {values.cashlink_signature_mode === CASHLINK_SIGNATURE_MODE.MANAGED && (
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="cashlink_backup_wallet"
              label="Backup Wallet"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
            />
          )}
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Supply Setting') }</div>

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">
              { t('Supply Amount') }
            </div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).cashlink_supply_limit}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, cashlink_supply_limit: value })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label="No limit"
                  value={CASHLINK_SUPPLY_LIMIT.NO_LIMIT}
                  control={<Radio color="primary"/>}
                />
                <FormControlLabel
                  disabled={!editingAllowed}
                  label="Limit"
                  value={CASHLINK_SUPPLY_LIMIT.LIMIT}
                  control={<Radio color="primary"/>}
                />
              </RadioGroup>
            </div>
          </div>

          {values.cashlink_supply_limit === CASHLINK_SUPPLY_LIMIT.LIMIT && (
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="cashlink_initial_supply"
              label="Initial Supply"
              state={values}
              setState={setValues}
              error={error}
              disabled={!editingAllowed}
              loading={loading}
              type="number"
            />
          )}
        </div>
      </div>

      {settings.enable_cashlink && cashlinkContractInfo && cashlinkContractInfo.id && (
        <div className="settings__item">
          <div className="title-primary settings__title title__clear-bar">{ t('Contract Info') }:</div>

          <div className="settings__fieldset">
            <div className="field">
              <div className="field__label">
                { t('Contract deployment ID') }
                :
                {' '}
                <strong>{cashlinkContractInfo.id}</strong></div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Contract deployment status') }
                :
                {' '}
                <strong>{cashlinkContractInfo.status.toUpperCase()} </strong>
              </div>

              <div className="field__wrap">
                  {cashlinkContractInfo.status === 'processing' && (
                    <button
                      disabled={updateContractStatusData.loading}
                      onClick={updateContractInfo}
                      className="button button-stroke"
                    >
                      <UpdateIcon color="primary"/>
                    </button>
                  )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Contract deployment sub-status') }
                :
                {' '}
                <strong>{(cashlinkContractInfo.sub_status || '-').toUpperCase()}</strong>
              </div>
            </div>

            {cashlinkContractInfo.error && (
              <>
                <div className="field">
                  <div className="field__label">
                    { t('Error') }
                    {': '}
                    {cashlinkContractInfo.error.message}
                  </div>
                </div>
                {updateContractStatusData.loading && (
                  <CircularProgress />
                )}
              </>
            )}

            {cashlinkContractInfo.contract && (
              <div className="field">
                <div className="field__label">
                  { t('Contract ID') }
                  {': '}
                  <strong>{cashlinkContractInfo.contract.id}</strong>
                </div>
                <br />
                <div className="field__label">
                  { t('Asset Name') }
                  {': '}
                  <strong>{cashlinkContractInfo.contract.asset_name}</strong>
                </div>
                <br />
                <div className="field__label">
                  { t('Asset Code') }
                  {': '}
                  <strong>{cashlinkContractInfo.contract.asset_code}</strong>
                </div>
                <br />
                <div className="field__label">
                  { t('Address') }
                  {': '}
                  <strong>{cashlinkContractInfo.contract.address}</strong>
                </div>
                <br />
                <div className="field__label">
                  { t('Pools') }
                  {': '}
                  <br />
                  <ul className="circle-bullet">
                    {cashlinkContractInfo.contract.pools.map(pool => (
                      <li key={pool.id}>{pool.id}</li>
                    ))}
                  </ul>
                </div>
                <br />

                <button
                  className="button settings__button button-small button-danger"
                  onClick={dropCashlinkContractHandle}
                  disabled={dropCashlinkContractData.loading}
                >
                  Drop Cashlink Contract
                </button>
              </div>
            )}

          </div>
        </div>
      )}

      {settings.enable_cashlink && (!cashlinkContractInfo || !cashlinkContractInfo.id) && (
        <div className="settings__item">
          <div className="settings__fieldset">
            <div className="field">
              <button
                className="button settings__button button-small"
                onClick={deployContractHandle}
                disabled={deployContractData.loading}
              >
                Deploy Cashlink Contract
              </button>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          className="button settings__button button-small"
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
        >
          { t('Update') }
        </button>
      )}
    </>
  )
}

export default AdminSettingsCashlink
