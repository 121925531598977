import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useMutation } from "@apollo/client";
import pick from "lodash/pick";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "../../../components/InputTheme";
import { useTranslation } from 'react-i18next';
import { usePublicSettings } from '../../../myHooks';

import { LOGIN, IS_2FA_NEEDED } from "../../../queriesAndMutations";
import ResentActivationForm from "../../../components/ResentActivationForm";
import VolveApi from '../../../services/volveApi';

const volveApi = new VolveApi();

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  itemBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const LoginForm = ({ urlToRedirect }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [, setCookie] = useCookies(["jwt"]);
  const [login, { loading, error: loginError, data }] = useMutation(LOGIN);
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [checkIf2FANeeded, { error: twoFAError, data: { is2FANeeded } = {} }] =
    useMutation(IS_2FA_NEEDED);
  const error = twoFAError || loginError;
  const [values, setValues] = useState({
    email: localStorage.getItem("email") || "",
    password: "",
    rememberMe: true,
    token_2fa: "",
  });
  const [showResentActivateEmail, setShowResentActivateEmail] = useState(false)

  const onChangeCheckbox = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const signUpVolve = async () => {
    try {
      const response = await volveApi.loginUser({
        email: values.email,
        password: values.password,
      });
      const { token } = response.data;
      localStorage.setItem("volveJwt", token);
    } catch (error) {}
  }

  const onLoginBtnClick = async () => {
    localStorage.setItem("email", values.email);
    const loginData = {
      ...pick(values, "email", "password", "is_2fa_enabled", "token_2fa"),
    };

    if (typeof is2FANeeded === "undefined") {
      try {
        const {
          data: { is2FANeeded: needed2FA },
        } = await checkIf2FANeeded({ variables: { email: values.email } });
        if (needed2FA === false) {
          login({ variables: { input: loginData } }).catch((e) => {
            if (e.graphQLErrors && e.graphQLErrors[0].extensions && e.graphQLErrors[0].extensions.code === 'INACTIVE_ACCOUNT') {
              setShowResentActivateEmail(true)
            }
          });
          signUpVolve();
          console.log(await volveApi.getCurrentUser());
          setValues({ ...values, password: "" });
        }
      } catch (error) {}
    } else {
      login({ variables: { input: loginData } }).catch(e => {
        if (e.graphQLErrors && e.graphQLErrors[0].extensions && e.graphQLErrors[0].extensions.code === 'INACTIVE_ACCOUNT') {
          setShowResentActivateEmail(true)
        }
      });
      signUpVolve();
      console.log(await volveApi.getCurrentUser());
      setValues({ ...values, password: "" });
    }
  };

  if (data && data.login && data.login.accessToken) {
    setCookie("jwt", data.login.accessToken, { path: "/" });
    localStorage.setItem("jwt", data.login.accessToken);

    if (publicSettings && publicSettings.enable_evolve) {
      return <Redirect to={urlToRedirect || "/cockpit"} />;
    }

    return <Redirect to={urlToRedirect || "/"} />;
  }

  if (showResentActivateEmail) {
    return <ResentActivationForm onExit={() => setShowResentActivateEmail(false)} />;
  }

  return (
    <>
      <div className="entry__fieldset">

      <TextInput
        classWrapper="field"
        classInput="field__input"
        propertyName="email"
        name="email"
        type="email"
        placeholder={ t('Your email') }
        state={values}
        setState={setValues}
        error={error}
        loading={loading}
        isRequired
        icon="mail"
      />

      <TextInput
        classWrapper="field"
        classInput="field__input"
        propertyName="password"
        name="password"
        type="password"
        placeholder={ t('Password') }
        state={values}
        setState={setValues}
        error={error}
        loading={loading}
        isRrequired
        icon="lock"
      />

      {is2FANeeded && (
        <TextInput
          classWrapper="field"
          classInput="field__input"
          name="token_2fa"
          propertyName="token_2fa"
          label={t('Two-factor authentication token')}
          state={values}
          setState={setValues}
          error={error}
          loading={loading}
          type="email"
        />
      )}

      <div className="field field__checkbox-kyc">
        <div className="field__label">{' '}</div>
        <div className="field__wrap">
          <label className="checkbox">
            <input
              className="checkbox__input"
              type="checkbox"
              onChange={onChangeCheckbox("rememberMe")}
              value="rememberMe"
            />
            <span className="checkbox__inner">
              <span className="checkbox__tick">&nbsp;</span>
              <span className="checkbox__text">{t('Remember me Login Page')}</span>
            </span>
          </label>
        </div>
      </div>

      {loading ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className="field input-submit">
          <button
            onClick={onLoginBtnClick}
            type="button"
            className="button entry__button"
          >
            { t('Sign In Login Page') }
          </button>
        </div>
      )}
      </div>

      {publicSettings && !publicSettings.disable_registration && (
        <div className="entry__info">
          { t('Don’t have an account?') }
          {" "}
          <Link to="/register">{ t('Sign up here Login Page') }</Link>
        </div>
      )}
    </>
  );
};

LoginForm.propTypes = {
  urlToRedirect: PropTypes.string,

  // from HOCs
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default LoginForm;
