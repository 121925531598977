import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import {
  CircularProgress
} from '@material-ui/core'

import {
  GET_TOKEN_SWAP_IMPORT_LOG_LIST,
} from '../../queriesAndMutations'
import TablePaginationActions from '../../components/TablePagination'
import MessageViewer from '../../components/MessageViewer'

const AdminTokenSwapImportLogList = () => {
  const { t } = useTranslation()
  const [reqOptions, setReqOptions] = useState({
    page: 0,
    pageSize: 10,
  })
  const { data: { getTokenSwapImportLogList: data } = {}, loading } = useQuery(GET_TOKEN_SWAP_IMPORT_LOG_LIST, {
    variables: {
      input: {
        page: reqOptions.page,
        pageSize: reqOptions.pageSize,
      }
    }
  })

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <div className="transactions__wrapper products__table-kyclist__wrapper">
        <div className="transactions__table kyc__table_transactions kyc__table_kyclist">
          <div className="transactions__row">

            <div className="transactions__col">
              {t('User')}
            </div>

            <div className="transactions__col">
              {t('Source')}
            </div>

            <div className="transactions__col">
              {t('Status')}
            </div>

            <div className="transactions__col">
              {t('Details')}
            </div>

            <div className="transactions__col">
              {t('Timestamp')}
            </div>
          </div>

          {data.results.map(log => (
            <div key={log.id} className="transactions__row">
              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('User')}
                </div>
                {log.user.email}
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Source')}
                </div>
                {log.source}
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Status')}
                </div>
                <span className={log.status === 'FAILED' ? 'status-red-dark' : 'status-green-dark'}>
                  {log.status}
                </span>
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Details')}
                </div>
                <div
                  style={{
                    maxHeight: '500px',
                    overflowY: 'auto',
                  }}
                >
                  <MessageViewer message={log.detail} />
                </div>
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Timestamp')}
                </div>
                {moment(+log.created_at).tz('Europe/Vaduz').format('DD-MM-YYYY HH:mm z')}
              </div>
            </div>
          ))}
        </div>
      </div>
      <TablePaginationActions
        count={data.meta.total}
        page={reqOptions.page}
        rowsPerPage={reqOptions.pageSize}
        onChangePage={(event, value) => { setReqOptions({ ...reqOptions, page: value}) }}
        onChangeRows={({ target: { value } }) => { setReqOptions({ ...reqOptions, page: 0, pageSize: value}) }}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  )
}

export default AdminTokenSwapImportLogList
