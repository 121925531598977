import React, { useState, useEffect } from 'react'
import { CUSTOMER_DOCUMENT_NAME_TYPES } from '../../constants/customer'
import { toLocalYmdString, toaster } from '../../utils'
import InputThemeField from '../InputThemeField'
import CardInformation from '../InformationCard'
import ImageUpload from '../ImageUpload'
import { useTranslation } from 'react-i18next'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  MenuItem,
} from '@material-ui/core'

import DateFnsUtils from '@date-io/date-fns/build/index'
import Icon from '../Icon'
import classNames from 'classnames';
import { PEP_OPTIONS } from '../CompanyDirectors/CardForm'

const CompanyStep4 = ({
  values,
  setValues,
  toPrevStep,
  toNextStep,
}) => {
  const { t } = useTranslation();

  const updateUboField = (index, field, value) => {
    const newUboList = values.uboList.map((ubo, id) => (
      index === id
        ? {...ubo, [field]: value }
        : {...ubo}
      )
    )

    setValues({
      ...values,
      uboList: newUboList
    });
  }

  const updateUboDateField = (index, field) => (date) => {
    updateUboField(index, field, toLocalYmdString(date))
  }

  const updateDirectorUboField = (field, value) => {
    setValues({
      ...values,
      directorAsUbo: {...values.directorAsUbo, [field]: value }
    });
  }

  const updateDirectorUboDateField = (field) => (date) => {
    updateDirectorUboField(field, toLocalYmdString(date))
  }

  const [selectedDirector, setSelectedDirector] = useState(values.directorAsUbo ? values.directorAsUbo.email : '');

  const onDropFile = (index) => (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    const newUboList = values.uboList.map((ubo, id) => (
      index === id
        ? {...ubo, trueCertifiedCopyFile: newImage }
        : {...ubo}
      )
    )

    setValues({ ...values, uboList: newUboList })
  }

  const onDropFileOnDirector = (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({
      ...values,
      directorAsUbo: {...values.directorAsUbo, trueCertifiedCopyFile: newImage }
    })
  }

  const removeUboFile = (index) => () => {
    const newUboList = values.uboList.map((ubo, id) => (
      index === id
        ? {...ubo, trueCertifiedCopyFile: null }
        : {...ubo}
      )
    )

    setValues({ ...values, uboList: newUboList })
  }

  const removeDirectorUboFile = () => {
    setValues({
      ...values,
      directorAsUbo: {...values.directorAsUbo, trueCertifiedCopyFile: null }
    });
  }

  const defaultNewUbo = {
    firstName: '',
    lastName: '',
    email: '',
    trueCertifiedCopyType: 'PASSPORT',
    birthDate: '2000-01-01',
    personType: '',
  }

  const [newUbo, setNewUbo] = useState(defaultNewUbo)

  const [showAddForm, setShowAddForm] = useState(false)

  const [uboError, setUboError] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    personType: '',
  })

  const validateNewUbo = () => {
    const error = {
      firstName: (!newUbo.firstName) ? 'First name is required' : '',
      lastName: (!newUbo.lastName) ? 'Last name is required' : '',
      email: (!newUbo.email)
        ? 'Email is required'
        : (newUbo.email.indexOf('@') < 0 || newUbo.email.indexOf('@') === newUbo.email.length + 1)
          ? 'Invalid email'
          : '',

    }
    setUboError(error);
    return Object.values(error).filter(e => e).length === 0;
  }

  const addNewUbo = () => {
    if (validateNewUbo()) {
      setValues({
        ...values,
        uboList: [
          ...values.uboList,
          { ...newUbo, status: 'pending', ownership: 0 }
        ]
      })

      setNewUbo(defaultNewUbo)
      setShowAddForm(false);
    }
  }

  useEffect(() => {
    if (selectedDirector !== '') {
      const selected = values.directors.find((director) => director.email === selectedDirector);

      setValues({
        ...values,
        directorAsUbo: {
          firstName: selected.firstName,
          lastName: selected.lastName,
          email: selected.email,
          trueCertifiedCopyType: (values.directorAsUbo && values.directorAsUbo.trueCertifiedCopyType) || 'PASSPORT',
          trueCertifiedCopyFile: (values.directorAsUbo && values.directorAsUbo.trueCertifiedCopyFile) || null,
          status: 'verified',
          ownership: (values.directorAsUbo && values.directorAsUbo.ownership) || 100,
          birthDate: (values.directorAsUbo && values.directorAsUbo.birthDate) || '2000-01-01',
          personType: (values.directorAsUbo && values.directorAsUbo.personType) || '',
          duties: (values.directorAsUbo && values.directorAsUbo.duties) || '',
          relationshipToPEP: (values.directorAsUbo && values.directorAsUbo.relationshipToPEP) || '',
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedDirector])

  return (
    <div className="settings__wrapper">
      <div className="settings__list">
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('UBO')}
          </div>

          <div className="settings__fieldset">
            {!values.directorAsUbo && (
              <div className="field">
                <div className="field__label">{t('Select a director for UBO')}</div>

                <div className="field__wrap field__wrap-select">
                  <TextField
                    required
                    select
                    value={selectedDirector}
                    onChange={({target: {value}}) => setSelectedDirector(value)}
                  >
                    { values.directors.filter(director => {
                        return !values.uboList.map(ubo => ubo.email).includes(director.email) && director.email !== (values.directorAsUbo || {}).email
                      }).map((director, index) => (
                      <MenuItem value={director.email} key={index}>
                        {director.firstName} {director.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            )}

            { values.directorAsUbo && (
              <>
                <div className={'settings__row flex__row flex__wrap settings__row-fullwidth ' + (values.directorAsUbo.status === 'verified' ? 'verified' : 'pending')}>
                  <div className="field">
                    <div className="refund__item">
                      {values.directorAsUbo.status === 'verified' ? (
                        <div className="refund__icon verified">
                          <Icon
                            className={classNames('check')}
                            name='check'
                            size="24"
                          />
                        </div>
                      ) : (
                        <div className="refund__icon">
                          <Icon
                            className={classNames('clock')}
                            name='clock'
                            size="24"
                          />
                        </div>
                      )}

                      <div className="refund__content">
                        <strong>{values.directorAsUbo.firstName} {values.directorAsUbo.lastName}</strong>
                        <div className="d__block">
                          <strong>
                            {values.directorAsUbo.email}
                          </strong>
                        </div>
                        <div className="d__block">
                          <span className={ t(values.directorAsUbo.status === 'verified' ? 'status-green-dark' : 'status-red-dark')}>{ t(values.directorAsUbo.status === 'verified' ? 'Verified' : 'Not finished')}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="field__label">
                      {t('Ownership')}
                    </div>
                    <div className="field__wrap field__wrap-textarea">
                      <InputThemeField
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        value={`${values.directorAsUbo.ownership}`}
                        onChange={({target: {value}}) => updateDirectorUboField('ownership', value)}
                        InputProps={{
                          endAdornment: '%'
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="settings__row flex__row flex__wrap">
                  <div className="field">
                    <div className="field__label">
                      {t('Date of Birth')}
                    </div>

                    <div className="field__wrap field__wrap-date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          views={["year", "month", "date"]}
                          openTo="year"
                          disableToolbar
                          disableFuture
                          variant="outlined"
                          format="dd.MM.yyyy"
                          required
                          margin="normal"
                          value={values.directorAsUbo.birthDate}
                          KeyboardButtonProps={{
                            'aria-label': t('change Date of Birth'),
                          }}
                          onChange={updateDirectorUboDateField('birthDate')}
                          invalidDateMessage={t('Invalid Date Format')}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div className="field">
                    <div className="field__label">
                      {t('True certified copy')}
                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-select">
                      <TextField
                        required
                        select
                        variant="outlined"
                        value={values.directorAsUbo.trueCertifiedCopyType}
                        onChange={({target: {value}}) => updateDirectorUboField('trueCertifiedCopyType', value)}
                      >
                        { Object.entries(CUSTOMER_DOCUMENT_NAME_TYPES).map(type => (
                          <MenuItem value={type[0]} key={type[0]}>
                            {t(type[1])}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>

                <div className="field">
                  {!values.directorAsUbo.trueCertifiedCopyFile && (
                    <div className="field__wrap field__wrap-imageupload">
                      <ImageUpload
                        buttonText={t('Click or drop images')}
                        buttonClassName='button'
                        onChange={onDropFileOnDirector}
                        maxFileSize={10485760}
                        withPreview
                        singleImagePick
                        singleFile
                        defaultImages={[]}
                        accept='image/*, application/pdf'
                      >
                        <div className="file__wrap_text text__center">
                          <p>
                            { t('Drag and drop the document') }
                          </p>
                        </div>
                      </ImageUpload>
                    </div>
                  )}

                  {values.directorAsUbo.trueCertifiedCopyFile && (
                    <div className="settings__top">
                      <div className="settings__label">
                        {values.directorAsUbo.trueCertifiedCopyFile.img.name}
                      </div>

                      <button
                        className="button-stroke button-small settings__button"
                        onClick={removeDirectorUboFile}
                      >
                        {t('Remove')}
                      </button>
                    </div>
                  )}
                </div>

                <div className="field">
                  <button
                    className="button-stroke w-full text__center"
                    type="button"
                    onClick={() => {
                      setValues({
                        ...values,
                        directorAsUbo: null,
                      })
                      setSelectedDirector('')
                      toaster.success(t('Remove UBO successfully'));
                    }}
                  >
                    <Icon
                      className={classNames('close fill__red')}
                      name='close'
                      size="24"
                    />{" "}
                    {t('Remove UBO')}
                  </button>
                </div>

                <div className="field">
                  <div className="field__label">
                    {t('Politically Exposed Persons')}
                  </div>

                  <div className={
                    classNames(
                      'field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray',
                    )
                  }>
                    <RadioGroup
                      value={values.directorAsUbo.personType}
                      onChange={({target: {value}}) => updateDirectorUboField('personType', value)}
                    >
                      { PEP_OPTIONS.map((name) => (
                          <FormControlLabel
                            key={name}
                            label={t(name)}
                            value={name}
                            control={<Radio color="primary" />}
                          />
                        ))}
                    </RadioGroup>
                  </div>
                </div>

                { values.directorAsUbo.personType && values.directorAsUbo.personType !== 'notPoliticalPerson' &&
                  <>
                    <div className="settings__row flex__row flex__wrap have__fields">
                      <InputThemeField
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Exact description of the function')}
                        value={values.directorAsUbo.duties}
                        onChange={({target: {value}}) => updateDirectorUboField('duties', value)}
                      />

                      <InputThemeField
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Relationship to PEP')}
                        value={values.directorAsUbo.relationshipToPEP}
                        onChange={({target: {value}}) => updateDirectorUboField('relationshipToPEP', value)}
                      />
                    </div>
                  </>
                }

                <div className="space space__32">{' '}</div>
              </>
            )}

            { values.uboList.map((ubo, index) => (
              <div key={index} className={'settings__row flex__row flex__wrap settings__row-fullwidth ' + (ubo.status === 'verified' ? 'verified' : 'pending')}>
                <div className="field">
                  <div className="refund__item">
                    {ubo.status === 'verified' ? (
                      <div className="refund__icon verified">
                        <Icon
                          className={classNames('check')}
                          name='check'
                          size="24"
                        />
                      </div>
                    ) : (
                      <div className="refund__icon">
                        <Icon
                          className={classNames('clock')}
                          name='clock'
                          size="24"
                        />
                      </div>
                    )}

                    <div className="refund__content">
                      <strong>{ubo.firstName} {ubo.lastName}</strong>
                      <div className="d__block">
                        <strong>
                          {ubo.email}
                        </strong>
                      </div>
                      <span className="d__block">
                        <span className={ t(ubo.status === 'verified' ? 'status-green-dark' : 'status-red-dark')}>{ ubo.status === 'verified' ? 'Verified' : 'Not finished'}</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">
                    {t('Ownership')}
                  </div>

                  <div className="field__wrap field__wrap-textarea">
                    <InputThemeField
                      classWrapper="field"
                      classLabel="field__label"
                      classInput="field__input"
                      required
                      value={`${ubo.ownership}`}
                      onChange={({target: {value}}) => updateUboField(index, 'ownership', value)}
                      InputProps={{
                        endAdornment: '%'
                      }}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">
                    {t('Date of Birth')}
                  </div>

                  <div className="field__wrap field__wrap-date">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        views={["year", "month", "date"]}
                        openTo="year"
                        disableToolbar
                        disableFuture
                        variant="outlined"
                        format="dd.MM.yyyy"
                        required
                        margin="normal"
                        value={ubo.birthDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change Date of Birth',
                        }}
                        onChange={updateUboDateField(index, 'birthDate')}
                        invalidDateMessage={t('Invalid Date Format')}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">{t('True certified copy')}</div>

                  <div className="field__wrap field__wrap-select">
                    <TextField
                      required
                      select
                      variant="outlined"
                      value={ubo.trueCertifiedCopyType}
                      onChange={({target: {value}}) => updateUboField(index, 'trueCertifiedCopyType', value)}
                    >
                      { Object.entries(CUSTOMER_DOCUMENT_NAME_TYPES).map(type => (
                        <MenuItem value={type[0]} key={type[0]}>
                          {type[1]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <div className="field">
                  {!ubo.trueCertifiedCopyFile && (
                    <div className="field__wrap field__wrap-imageupload">
                      <ImageUpload
                        buttonText={t('Upload')}
                        buttonClassName='button'
                        onChange={onDropFile(index)}
                        maxFileSize={10485760}
                        withPreview
                        singleImagePick
                        singleFile
                        defaultImages={[]}
                        accept='image/*, application/pdf'
                      />
                    </div>
                  )}
                  {ubo.trueCertifiedCopyFile && (
                    <div className="settings__top">
                      <div className="settings__label">
                        {ubo.trueCertifiedCopyFile.img.name}
                      </div>
                      <button
                        size='small'
                        color='secondary'
                        onClick={removeUboFile(index)}
                      >
                        {t('Remove')}
                      </button>
                    </div>
                  )}
                </div>

                <div className="field">
                  <button
                    className="button-stroke w-full text__center"
                    type="button"
                    onClick={() => {
                      const ubolist = values.uboList;
                      ubolist.splice(index, 1);
                      setValues({
                        ...values,
                        uboList: ubolist,
                      })
                      toaster.success(t('Remove UBO successfully'));
                    }}
                  >
                    <Icon
                      className={classNames('close fill__red')}
                      name='close'
                      size="24"
                    />{" "}
                    {t('Remove UBO')}
                  </button>
                </div>

                <div className="field">
                  <div className="field__label">
                    {t('Politically Exposed Persons')}
                  </div>

                  <div className={
                    classNames(
                      'field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray',
                    )
                  }>
                    <RadioGroup
                      value={ubo.personType}
                      onChange={({target: {value}}) => updateUboField(index, 'personType', value)}
                    >
                      { PEP_OPTIONS.map((name) => (
                          <FormControlLabel
                            key={name}
                            label={t(name)}
                            value={name}
                            control={<Radio color="primary" />}
                          />
                        ))}
                    </RadioGroup>
                  </div>
                </div>

                { ubo.personType && ubo.personType !== 'notPoliticalPerson' &&
                  <>
                    <div className="settings__row flex__row flex__wrap have__fields">
                      <InputThemeField
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Exact description of the function')}
                        value={ubo.duties}
                        onChange={({target: {value}}) => updateUboField(index, 'duties', value)}
                      />

                      <InputThemeField
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Relationship to PEP')}
                        value={ubo.relationshipToPEP}
                        onChange={({target: {value}}) => updateUboField(index, 'relationshipToPEP', value)}
                      />
                    </div>
                  </>
                }

                <div className="space space__32">{' '}</div>
              </div>
            ))}

            <div className='verify-identities'>
              <div className="field">
                <div className="field__label">
                  {t('Add UBO')}
                </div>
              </div>

              <div className="settings__row flex__row flex__wrap">
                <div className="field">
                  <button className='button-stroke w-full text__center' onClick={() => setShowAddForm(true)} >
                    <Icon
                        className={classNames('check')}
                        name='check'
                        size="24"
                      />{" "}
                    {t('Add new')}
                  </button>
                </div>
              </div>
            </div>

            {showAddForm && (
              <>
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Your name')}
                  value={newUbo.firstName}
                  error={!!uboError.firstName}
                  helperText={uboError.firstName}

                  onChange={({ target: { value }}) => setNewUbo({ ...newUbo, firstName: value.trim()})}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Your surname')}
                  value={newUbo.lastName}
                  error={!!uboError.lastName}
                  helperText={uboError.lastName}

                  onChange={({ target: { value }}) => setNewUbo({ ...newUbo, lastName: value.trim()})}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Email address')}
                  type="email"
                  value={newUbo.email}
                  error={!!uboError.email}
                  helperText={uboError.email}

                  onChange={({ target: { value }}) => setNewUbo({ ...newUbo, email: value.trim()})}
                />
                <div className="field">
                  <button className="button" onClick={addNewUbo}>
                    {t('Add UBO')}
                  </button>
                </div>
              </>
            )}

            <div className="field">
              <div className="space space__32 mobile-show">{' '}</div>
              <CardInformation
                color="card__information-primary"
                title={t('Definition of a beneficial owner according to Liechtenstein legislation')}
              >
                <>
                  <p className='prefix-hyphen'>
                    {t('Foundations, trusteeships and establishments (with a structure similar to a foundation)')}
                  </p>
                  <p>{t('Art. 3 (1) lit. b DDO')}</p>
                  <span className="space space__8">{' '}</span>
                  <ul className='circle-bullet'>
                    <li>{t('Founder or settlor')}</li>
                    <li>{t('Member of the foundation board or board of directors of the trustee')}</li>
                    <li>{t('Protectors')}</li>
                    <li>{t('Beneficiaries')}</li>
                    <li>{t('Natural persons, who ultimately control the legal entity')}</li>
                  </ul>
                  <p className='prefix-hyphen'>
                    {t('Corporations and establishments (with a structure similar to a corporation)')}
                  </p>
                  <p>{t('Art. 3 (1) lit. a DDO')}</p>
                  <span className="space space__8">{' '}</span>
                  <ul className='circle-bullet'>
                    <li>{t('25 % or more of the company')}</li>
                    <li>{t('Exercise control')}</li>
                  </ul>
                  <span className="space space__8">{' '}</span>
                  <p>{t('Fall Back Clause Member of the executive body')}</p>
                </>
              </CardInformation>
            </div>
          </div>
        </div>

        <div className='settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between'>
          <button className='button-stroke' onClick={toPrevStep}>
            {t('Previous')}
          </button>

          <button className='button' onClick={toNextStep}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CompanyStep4
