import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputTheme from '../../components/InputTheme'
import { extractMsgFromErrByPropertyName } from '../../utils'
import { useTranslation } from 'react-i18next'
import ImageUpload from '../../components/ImageUpload'
import VolveApi from '../../services/volveApi'
import Icon from '../../components/Icon'
import { toaster } from '../../utils/toaster'
import { useMutation } from '@apollo/react-hooks'
import {
  CONTRIBUTE,
} from '../../queriesAndMutations'
import { values } from 'lodash'
import { CircularProgress } from '@material-ui/core'

const volveApi = new VolveApi();

const useStyles = makeStyles(() => ({
  input_border_date: {
    borderRadius: "4px",
    border: "1px solid #d2dde9",
    width: "100%",
    padding: "10px 15px",
    lineHeight: "20px",
    fontSize: ".9em",
    color: "rgba(73, 84, 99, 0.7)",
    transition: "all .4s",
    "&:focus": {
      boxShadow: "none",
      outline: "none",
      borderColor: "#b1becc",
    },
    "&:disabled": {
      background: "rgba(230, 239, 251, 0.2)",
    },

    "~": {
      error: {
        color: "#ff6868",
        marginBottom: "0",
        position: "relative",
        top: "7px",
      },
    },
  },
}));

const ExpenseAdd = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState({
    date: "",
    merchant: "",
    amount: "",
    paidWith: "cash",
    reportedCurrency: "",
    reportedExchangeRate: "1",
    reimbursable: true,
    currency: "",
    // category: "",
    country: 702,
    receiptUri: "",
    status: "submitted",
    comment: "",
  });
  const fileInputRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [contribute, contributeStatus] = useMutation(CONTRIBUTE);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      // eslint-disable-next-line
      const formValues = new FormData();
      Object.keys(value).forEach((field) => {
          formValues.append(field, value[field] !== undefined ? value[field] : "");
      });
      formValues.append('reportedCurrency', formValues.get('currency'))

      const { data: expense } = await volveApi.createExpense(formValues);

      const input = {
        currency: value.currency.toLowerCase(),
        amount: parseInt(value.amount),
        isContributionGto: false,
        paymentType: 'EXPENSE',
        expenseId: expense.id
      }

      await contribute({ variables: { input } });

      if (parseInt(value.amount) <= 30) {
        await volveApi.expenseAction(expense.id, 'approved')
      }

      toaster.success("Expense Created");

      setLoading(false)
      window.location.href = '/evolve/expenses'
    } catch (e) {
      toaster.error(e.message);
      setLoading(false)
    }
  };

  const handleDateChange = (date) =>
    setValue({
      ...value,
      date: date !== null ? date.toString() : date,
    });

  const renderDatePicker = () => {
    return (
      <div className="field">
        <div className="field__label">
          { t('Date') }
        </div>
        <div className="field__wrap field__wrap-date">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              views={["year", "month", "date"]}
              openTo="date"
              disableFuture
              variant="outlined"
              format="dd.MM.yyyy"
              margin="none"
              className={classes.input_border_date}
              id="date-picker-dialog"
              value={value.date}
              onChange={handleDateChange}
              invalidDateMessage={t('Invalid Date Format')}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  };

  // const renderPhoneInput = () => {
  //   const errorsTexts = extractMsgFromErrByPropertyName(error, "phone");

  //   return (
  //     <div className="field">
  //       <div className="field__label">{t('Phone number')}</div>

  //       <div className="field__wrap field__wrap-phone">
  //         <PhoneInput
  //           defaultCountry="li"
  //           onChange={(valuePhone) => setValue({ ...value, phone: valuePhone })}
  //           variant="outlined"
  //           disabled={loading}
  //           fullWidth
  //           value={value.phone || "li"}
  //         />
  //         <FormHelperText error={errorsTexts.length !== 0}>
  //           {(errorsTexts && errorsTexts.join(". ")) || ""}
  //         </FormHelperText>
  //       </div>
  //     </div>
  //   );
  // };

  const removeFile = () => {
    setValue({
      ...value,
      receipt: null,
    });
  }

  return (
    <>
      <div className="page__title h3">{ t('Expenses Add')}</div>

      <div className="transactions customer card">
        <div className="settings__item">

          <form onSubmit={submit} className="settings__fieldset">
            <div className="settings__row flex__row flex__wrap have__fields have__space">
              {renderDatePicker()}

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                propertyName="merchant"
                label={t('Merchant')}
                state={value}
                setState={setValue}
                loading={loading}
              />

              <div className="field">
                <div className="field__wrap field__wrap-imageupload">
                  {/* <ImageUpload
                    buttonText={t('Upload Receipt')}
                    buttonClassName='button'
                    onChange={onDropFile}
                    maxFileSize={10485760}
                    withPreview
                    singleImagePick
                    singleFile
                    accept='image/*, application/pdf'
                  /> */}
                  {/* <div className="file__label">
                    {t('Upload Receipt')}
                  </div> */}
                  <div className='file__wrap flex__column'>
                    <input
                      type="file"
                      ref={fileInputRef}
                      // eslint-disable-next-line no-return-assign
                      name="receiptUri"
                      onChange={(e) => {
                        setValue({
                          ...value,
                          receiptUri: e.target.files[0]
                        })
                      }}
                      // onClick={this.onUploadClick}
                      accept='image/*, application/pdf'
                      className="file__input"
                    />
                    <button
                      className="file__box"
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <Icon className="upload" name="upload" size="24" />{" "}
                      {t('Upload Receipt')}
                    </button>
                  </div>
                </div>

                {value.receiptUri && (
                  <div className="settings__top">
                    <div className="settings__label">
                      {value.receiptUri.name}
                    </div>

                    <button
                      className="button-stroke button-small settings__button"
                      onClick={removeFile}
                    >
                      {t('Remove')}
                    </button>
                  </div>
                )}
              </div>

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                propertyName="amount"
                label={t('Amount')}
                state={value}
                setState={setValue}
                loading={loading}
              />

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                propertyName="currency"
                label={t('Currency')}
                state={value}
                setState={setValue}
                loading={loading}
              />

              {/* <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                propertyName="category"
                label={t('Category')}
                state={value}
                setState={setValue}
                loading={loading}
              /> */}

              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"

                propertyName="country"
                label={t('Country')}
                state={value}
                setState={setValue}
                loading={loading}
              />
            </div>

            <div className="field">
              {loading && (
                <CircularProgress />
              )}
              {!loading && (
                <button type="submit" className="button settings__button">
                { t('Save') }
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ExpenseAdd;
