import React from 'react'
import PropTypes from 'prop-types'
// import "../styles/legacy/style.scss";
import { useQuery } from '@apollo/client'
import { UPDATE_COMMENT_KYC_LIST } from '../queriesAndMutations'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CardInformation from './InformationCard'

const AlertUpdateKyc = ({ typeKyc, kycId, customerTypeKyc }) => {
  const { data: { updateCommentKyc = {} } = {} } = useQuery(
    kycId ? UPDATE_COMMENT_KYC_LIST : null,
    {
      variables: {
        input: {
          type: customerTypeKyc,
          kycId,
          page: 0,
          pageSize: 1,
          reopenLogOnly: true,
        },
      },
    }
  );

  const { objects = {} } = updateCommentKyc;
  const { t } = useTranslation();

  return (
    <div className="card p__0">
      {Object.keys(objects).length > 0 && (
        <CardInformation
          color="card__information-red"
          title={t('Your KYC application needs attention!')}
        >
          <>
            { t('Here is the information what you need to change') }: {objects[0].comment}.
            <br />
            {" "}
            <br />
            <NavLink to={`/application-update/${typeKyc}/${kycId}`} className="button-stroke button-stroke__white">
              { t('Click here to update')}
            </NavLink>
          </>
        </CardInformation>
      )}
    </div>
  );
};

AlertUpdateKyc.propTypes = {
  typeKyc: PropTypes.string.isRequired,
  customerTypeKyc: PropTypes.string.isRequired,
};

export default AlertUpdateKyc;
