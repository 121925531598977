import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GET_INDIVIDUAL_LIVENESS_CHECK_SERVICE_INFO } from '../../../queriesAndMutations'
import { useMe, usePublicSettings } from '../../../myHooks'
import KycCustomerVideoIdentOnfido from '../../../components/KycCustomerVideoIdentOnfido'
import KycCustomerVideoIdentIdnow from '../../../components/KycCustomerVideoIdentIdnow'
import KycCustomerVideoIdentComplycube from '../../../components/KycCustomerVideoIdentComplycube'

const CardForm = () => {
  const { t } = useTranslation();
  const {
    data: { publicSettings } = {},
    loading: loadingPublicSettings
  } = usePublicSettings()
  const {
    data: { me } = {},
    loading: loadingUser,
    refetch: refetchUser,
  } = useMe({ fetchPolicy: 'network-only'})
  const {
    data: { getIndividualLivenessCheckServiceInfo: livenessCheckRequest } = {},
    loading: loadingLivenessCheck
  } = useQuery(GET_INDIVIDUAL_LIVENESS_CHECK_SERVICE_INFO, {
    variables: {
      createNewIfNotFound: true
    }
  })
  const [isRefetched, setIsRefetched] = useState(false)

  const redirectToDashboard = () => {
    const saleRedirectUrl = localStorage.getItem('saleRedirectUrl');

    if (saleRedirectUrl) {
      localStorage.removeItem('saleRedirectUrl');
      window.location.href = saleRedirectUrl;
      return;
    }

    window.location.href = publicSettings.force_user_till_first_transaction
      ? `${window.location.origin}/easy-onboarding/thank-you`
      : window.location.origin
  }

  // Show preloader on loading
  if (loadingPublicSettings || loadingUser || loadingLivenessCheck) {
    return <CircularProgress />
  }

  // If user have not submit KYC application
  // Then redirect user to the KYC form page
  if (me && !me.customer) {
    return <Redirect to="/easy-onboarding" />
  }

  // IF liveness check request has just been created from getIndividualLivenessCheckServiceInfo
  // THEN refetch user to get the latest with new liveness check request included
  // setIsRefetched to true to assure only refetch one time
  if (livenessCheckRequest.isNew && !isRefetched) {
    setIsRefetched(true)
    refetchUser()
  }

  // IF user have already done the liveness check
  // THEN redirect user to dashboard
  if (livenessCheckRequest) {
    if (livenessCheckRequest.serviceName === 'onfido') {
      if (
        livenessCheckRequest.livenessCheckObj &&
        livenessCheckRequest.livenessCheckObj.onfidoChecks &&
        livenessCheckRequest.livenessCheckObj.onfidoChecks.length > 0
      ) {
        return <Redirect to="/" />
      }
    } else if (livenessCheckRequest.serviceName === 'idnow') {
      if (
        livenessCheckRequest.livenessCheckObj &&
        livenessCheckRequest.livenessCheckObj.status === 'COMPLETED'
      ) {
        return <Redirect to="/" />
      }
    } else if (livenessCheckRequest.serviceName === 'complycube') {
      if (
        livenessCheckRequest.livenessCheckObj &&
        livenessCheckRequest.livenessCheckObj.complycubeChecks &&
        livenessCheckRequest.livenessCheckObj.complycubeChecks.length > 0
      ) {
        return <Redirect to="/" />
      }
    }
  }

  return (
    <div className="card easy-onboarding__individual-verifyid">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('Please verify your identity')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('To invest, we need to verify your identity. It only takes a few minutes.') }</p>
          </div>
        </div>
        <div className="settings__fieldset settings__fieldset_have_card">
          {publicSettings &&
            publicSettings.identification &&
            livenessCheckRequest &&
            livenessCheckRequest.serviceName === 'onfido'&& (
              <>
                {me && me.customer && (
                  <KycCustomerVideoIdentOnfido
                    customer={me.customer}
                    forceCrossDevice={
                      publicSettings.identification.ident_services.onfido &&
                      publicSettings.identification.ident_services.onfido.force_cross_device
                    }
                    onComplete={redirectToDashboard}
                  />
                )}
              </>
            )
          }
          {livenessCheckRequest &&
            livenessCheckRequest.serviceName === 'idnow'&& (
              <>
                {me && me.customer && (
                  <KycCustomerVideoIdentIdnow customer={me.customer} />
                )}
              </>
            )
          }

          {livenessCheckRequest &&
            livenessCheckRequest.serviceName === 'complycube'&& (
              <>
                {me && me.customer && (
                  <KycCustomerVideoIdentComplycube
                    object={me.customer}
                    onComplete={redirectToDashboard}
                    forceCrossDevice={
                      publicSettings.identification.ident_services.onfido &&
                      publicSettings.identification.ident_services.onfido.force_cross_device
                    }
                  />
                )}
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default CardForm;
