import React from 'react'
import moment from 'moment'
import { useQuery, useMutation } from '@apollo/client'
import {
  CircularProgress
} from '@material-ui/core'

import {
  GET_KOMPANY_DATA,
  REQUEST_KOMPANY_DATASET,
} from '../../queriesAndMutations'

const KompanyRequestStatus = ({
  company
}) => {
  const { data: { getKompanyData } = {}, refetch } = useQuery(GET_KOMPANY_DATA, {
    variables: {
      companyId: company.id
    }
  })
  const [requestKompanyDataset, requestKompanyDatasetStatus] = useMutation(REQUEST_KOMPANY_DATASET)
  const requestData = (type) => () => {
    requestKompanyDataset({
      variables: {
        companyId: company.id,
        type,
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  const {
    DATASET_MINI: datasetMini,
    DATASET_MASTER: datasetMaster,
    DATASET_FULL: datasetFull,
    DATASET_REFRESH: datasetRefresh,
    DATASET_SUPER: datasetSuper,
    DATASET_BENEFICIAL_OWNERS: datasetBeneficialOwners,
  } = getKompanyData ? getKompanyData.reduce((all, dataset) => ({
    ...all,
    [dataset.requestType]: dataset,
  }), {}) : {}
  const allDataset = {
    mini: {
      label: 'Mini',
      data: datasetMini,
    },
    master: {
      label: 'Master',
      data: datasetMaster,
    },
    full: {
      label: 'Full',
      data: datasetFull,
    },
    refresh: {
      label: 'Refresh',
      data: datasetRefresh,
    },
    super: {
      label: 'Super',
      data: datasetSuper,
    },
    beneficial_owners: {
      label: 'Beneficial Owners',
      data: datasetBeneficialOwners,
    },
  }

  if (!getKompanyData) {
    return <CircularProgress />
  }

  const printDataset = (data, level = 0) => {
    if (!data) {
      return null
    }

    const indent = 20;

    return Object.entries(data).map(([key, value]) => {
      if (typeof value === 'object') {
        return (
          <React.Fragment key={key}>
            <p style={{paddingLeft: level * indent}}>{key}:</p>
            {printDataset(value, level + 1)}
          </React.Fragment>
        )
      }

      return (
        <p key={key} style={{paddingLeft: level * indent}}>{key}: {value}</p>
      )
    })
  }

  return (
    <div>
      <h3>Data Request</h3>
      <div className="transactions__table">
        <div className="transactions__row">
          <div className="transactions__col">Set type</div>
          <div className="transactions__col">Status</div>
          <div className="transactions__col">Data</div>
        </div>

        {Object.entries(allDataset).map(([key, dataBlock]) => (
          <div className="transactions__row" key={key}>
            <div className="transactions__col">{ dataBlock.label }</div>
            <div className="transactions__col">
              {!dataBlock.data && (
                <button
                  className="button button-small"
                  onClick={requestData(key)}
                  disabled={requestKompanyDatasetStatus.loading}
                >
                  Send Request
                </button>
              )}
              {dataBlock.data && (
                <>
                  <p>Request time: {moment(+dataBlock.data.created_at).format('DD-MM-YYYY')}</p>
                  <p>By: {dataBlock.data.user.email}</p>
                </>
              )}
            </div>
            <div className="transactions__col">
              {!dataBlock.data && '-'}
              {dataBlock.data && printDataset(dataBlock.data.response)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default KompanyRequestStatus
