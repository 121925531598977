import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import qrcode from 'qrcode'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { NEW_2FA_SECRET, SET_2FA_SECRET } from '../../../queriesAndMutations'
import { useMe } from '../../../myHooks'
import { usePublicSettings } from '../../../myHooks/useSettings'
import { useTranslation } from 'react-i18next'
import InputTheme from '../../../components/InputTheme'
import InformationCard from '../../../components/InformationCard'

const TwoFactorAuth = ({ onClose }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    qrCodeUrl: null,
    stepNumber: 0,
    token: '',
  })

  const { data: { me: userData }, refetch: refetchMe } = useMe()
  const { data: { new2FASecret } = {} } = useQuery(NEW_2FA_SECRET)
  const [set2FASecret, { loading, error }] = useMutation(SET_2FA_SECRET)
  const { data: { publicSettings: { company } = {} } = {} } = usePublicSettings()

  const changeStep = (step) => setValue({
    ...value,
    stepNumber: step,
  })

  useEffect(() => {
    if (userData && new2FASecret && value.qrCodeUrl === null && company) {
      const secretToUrl = (secret) => `otpauth://totp/${encodeURIComponent((company || {}).name)}:${encodeURIComponent(userData.email)}?secret=${secret}`

      qrcode.toDataURL(secretToUrl(new2FASecret)).then((qrCode) => setValue({
        ...value,
        qrCodeUrl: qrCode,
      }))
    }
    // eslint-disable-next-line
  }, [new2FASecret, userData, company])

  const onSubmitToken = async (e) => {
    e.preventDefault()
    try {
      await set2FASecret({ variables: { secret: new2FASecret, token: value.token } })
      changeStep(3)
      refetchMe()
    } catch (e) {}
  }

  const renderFirstStep = () => {
    const onNext = () => changeStep(1)

    return (
      <div className="settings__wrapper">
        <div className="card__head">
          <div className="refund__content card__head-content">
            <p>
              { t('Please scan the QR Code below with any authenticator app on your phone that supports the standard QR Code security, eg Google authenticator, Microsoft authenticator, Authy') }
            </p>
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="field d__flex flex__align_center flex__justify_center">
            <img src={value.qrCodeUrl} alt="" />
          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button
            className="button-stroke"
            onClick={onClose}
          >
            { t('Cancel') }
          </button>

          <button className="button" onClick={onNext}>
            { t('Next step') }
          </button>
        </div>
      </div>
    )
  }

  const renderSecondStep = () => {
    const onBack = () => changeStep(0)

    return (
      <div className="settings__wrapper">
        <div className="card__head">
          <div className="refund__content card__head-content">
            <p>{ t('Please enter your 2fa code') }: </p>
          </div>
        </div>

        <form onSubmit={onSubmitToken}>
          <div className="settings__fieldset">
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName="token"
              label="Token"
              state={value}
              setState={setValue}
              error={error}
              loading={loading}
            />
          </div>

          <div className="space space__48">{' '}</div>
          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
            <button
              className="button-stroke"
              onClick={onBack}
            >
              { t('Previous step') }
            </button>
            <button type="submit" className="button">{ t('Next step') }</button>
          </div>
        </form>
      </div>
    )
  }

  const renderSuccess = () => (
    <div className="settings__wrapper">
      <InformationCard
        color="card__information-green"
        title={t('Congratulation')}
      >
        <>{ t('Success! 2FA was successfully enabled!')}</>
      </InformationCard>

      <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
        <button onClick={onClose} className="button">{ t('Close') }</button>
      </div>
    </div>
  )

  return (
    <>
      <span className="space space__16">{' '}</span>
      <div className="kyc__stepper kyc__stepper_32 w-full">
        <Stepper activeStep={value.stepNumber}>
          <Step key={0}>
            <StepLabel>{ t('Setting up 2fa') }</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>{ t('Confirm 2fa') }</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>{ t('Success') }</StepLabel>
          </Step>
        </Stepper>
      </div>
      <div className="space space__32">{' '}</div>
      <div className="kyc-dialog__body w-full">
        {value.stepNumber === 0 && renderFirstStep()}
        {value.stepNumber === 1 && renderSecondStep()}
        {value.stepNumber === 3 && renderSuccess()}
      </div>
    </>
  )
}

TwoFactorAuth.propTypes = {
  onClose: propTypes.func.isRequired,
}

export default TwoFactorAuth
