import React from 'react'
import { NavLink } from "react-router-dom";
import { useQuery } from '@apollo/client'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress
} from '@material-ui/core'
import {
  TRANSACTION_SUM,
  ADMIN_KYC_SUM,
  GET_DASHBOARD_LATEST_USER_STATISTIC,
} from '../../../queriesAndMutations'
import Icon from "../../../components/Icon";
import { getTextAvatarUrl } from "../../../utils";

const AdminAmountStatistics = () => {
  const { t } = useTranslation()
  const { data: { transactionsSum = {} } = {} } = useQuery(TRANSACTION_SUM)
  const { data: { kycSumAmount = 0 } = 0 } = useQuery(ADMIN_KYC_SUM)
  const { data: { getDashboardLatestUserStatistic } = {} } = useQuery(GET_DASHBOARD_LATEST_USER_STATISTIC)

  return (
    <div className="total card">
      <div className="card__head">
        <div className="title-primary card__title">
          { t('Amount Statistics') }
        </div>
      </div>

      <div className="income">
        <div className="income__list admin_amount_statistics flex__row flex__wrap">
          <div className="income__item income__item_kyc">
            <div className="income__icon" style={{ backgroundColor: "#B5E4CA"}}>
              <Icon
                className={classNames('activity')}
                name='activity'
                size="24"
              />{" "}
            </div>

            <div className="income__line">
              <div className="income__details">
                <div className="income__label">
                  { t('Tokens sold (approved status)') }
                </div>

                <div className="income__counter">
                  {transactionsSum.approved}
                </div>
              </div>
            </div>
          </div>

          <div className="income__item income__item_kyc">
            <div className="income__icon" style={{ backgroundColor: "#CABDFF"}}>
              <Icon
                className={classNames('pie-chart')}
                name='pie-chart'
                size="24"
              />{" "}
            </div>

            <div className="income__line">
              <div className="income__details">
                <div className="income__label">
                  { t('Tokens sold (pending status)') }
                </div>

                <div className="income__counter">
                  {transactionsSum.pending}
                </div>
              </div>
            </div>
          </div>

          <div className="income__item income__item_kyc">
            <div className="income__icon" style={{ backgroundColor: "#B1E5FC"}}>
              <Icon
                className={classNames('check-all')}
                name='check-all'
                size="24"
              />{" "}
            </div>

            <div className="income__line">
              <div className="income__details">
                <div className="income__label">
                  { t('Tokens in submitted KYC applications') }
                </div>

                <div className="income__counter">
                  {kycSumAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="users">
        {!getDashboardLatestUserStatistic && (
          <CircularProgress />
        )}
        {getDashboardLatestUserStatistic && (
          <>
            <div className="users__head">
              <div className="users__info">
                { t('Welcome') }
                {' '}
                <strong>{getDashboardLatestUserStatistic.total}</strong>
                {' '}
                {t('Users')}
              </div>
            </div>

            <div className="users__list">
              {getDashboardLatestUserStatistic.users.map(user => (
                <div className="users__item" key={user.id}>
                  <div className="users__avatar">
                    <img
                      src={getTextAvatarUrl(`${user.first_name} ${user.last_name}`)}
                      alt={`${user.first_name} ${user.last_name}`}
                      title={`${user.first_name} ${user.last_name}`} />
                  </div>

                  <div className="users__title">
                    {user.first_name}
                  </div>
                </div>
              ))}

              <NavLink to="/admin/kyc-list/individual" className="users__all">
                <div className="users__icon">
                  <Icon
                    className={classNames('arrow-right')}
                    name='arrow-right'
                    size="24"
                  />{" "}
                </div>

                <div className="users__text">
                  View all
                </div>
              </NavLink>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AdminAmountStatistics
