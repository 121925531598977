import gql from 'graphql-tag';

export const SYNC_USER = gql`
  mutation syncUser($syncMasterTenant: Boolean) {
    syncUser(syncMasterTenant: $syncMasterTenant)
  }
`;

export const GET_EVOLVE_ACCESS_TOKEN = gql`
  mutation getEvolveAccessToken {
    getEvolveAccessToken
  }
`;
