import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { compose } from "recompose";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  Grid,
  Grow,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import Drawer from "@material-ui/core/Drawer";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import ReorderOutlinedIcon from "@material-ui/icons/ReorderOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import StorageIcon from "@material-ui/icons/Storage";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import WidgetsSharpIcon from '@material-ui/icons/WidgetsSharp';

import { useCookies } from "react-cookie";
import { logout, getFileUrl, isFeatureEnabled, getTextAvatarUrl } from "../utils";
import { useMe, useExchangeRates } from "../myHooks";
import { USER_RIGHT_TYPES } from "../constants/user";
import { TOKEN_TYPES } from "../constants/settings";
import { usePublicSettings } from "../myHooks/useSettings";
import { useQuery, useMutation } from "@apollo/client";
import { tokenToEth } from "../utils/rate";
import {
  ME_INVEST_IN_BASE_CURRENCY,
  GET_AVAILABLE_FEATURES,
  GET_AUTH_TENANT_LIST,
  SET_USER_LOCALE,
} from "../queriesAndMutations";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import * as languageHelper from '../utils/lang';
import LockedFeatureIcon from './LockedFeatureIcon';

// UI CORE
import "../styles/legacy/kyc-ui-core.scss";
// UI COMPONENTS
import "../styles/legacy/06-components/header/_dashboard.scss";

const useStyles = makeStyles(() => ({
  logoAndUser: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  kycLink: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",

    "& span": {
      color: "#000",
      opacity: ".5",
      "&:hover": {
        color: "inherit",
        opacity: "1",
      },
    },
    "& svg": {
      height: "50%",
      margin: "0 5px 0 0",
    },
  },
  closeButton: {
    position: "absolute",
    right: 1,
    top: 1,
    color: "#000",
  },
  languageOption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
  },
  tenantOption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '10px',
  },
  tenantActiveOption: {
    border: '1px solid var(--color__background-primary)',
    borderRadius: '5px',
  },
  tenantLogo: {
    width: '100%',
  }
}));

const SETS = {
  main: [
    { title: "Dashboard", url: "/", icon: <DashboardOutlinedIcon /> },
    {
      title: "Buy Tokens",
      url: "/contribute",
      icon: <AccountBalanceWalletOutlinedIcon />,
    },
    { title: "Transactions", url: "/transactions", icon: <SyncAltIcon /> },
    { title: "Profile", url: "/profile", icon: <PersonOutlineIcon /> },
    { title: "Data Center", url: "/data-center", icon: <StorageIcon /> },
    {
      title: "Admin Panel",
      url: "/admin",
      rights: [
        USER_RIGHT_TYPES.ADMIN,
        USER_RIGHT_TYPES.GENERAL_ADMIN,
        USER_RIGHT_TYPES.COMPLIANCE_OFFICER,
        USER_RIGHT_TYPES.AUDITOR,
      ],
      icon: <SupervisorAccountOutlinedIcon />,
    },
    {
      title: "Token Registry",
      url: "/admin/token-registry",
      rights: [
        USER_RIGHT_TYPES.CUSTODIAN,
      ],
      icon: <SupervisorAccountOutlinedIcon />,
    },
  ],
  admin: [
    { title: "Dashboard", url: "/admin", icon: <DashboardOutlinedIcon /> },
    {
      title: "User list",
      url: "/admin/users",
      icon: <ReorderOutlinedIcon />,
    },
    {
      title: "Compliance",
      icon: <SupervisorAccountIcon />,
      hasChildren: true,
      children: [
        {
          title: "KYC list individual",
          url: "/admin/kyc-list/individual",
          icon: <FaceOutlinedIcon />,
        },
        {
          title: "KYC list company",
          url: "/admin/kyc-list/company",
          icon: <WorkOutlineOutlinedIcon />,
        },
      ]
    },
    {
      title: "Settings",
      url: "/admin/settings",
      icon: <SettingsOutlinedIcon />,
    },
    {
      title: "Others",
      hasChildren: true,
      icon: <WidgetsSharpIcon />,
      children: [
        {
          title: "Transactions",
          url: "/admin/transactions",
          icon: <SyncAltIcon />,
        },
        {
          title: "Exchange rates",
          url: "/admin/exchange-rates",
          icon: <TrendingUpIcon />,
        },
        {
          title: "Web3 Iban",
          url: "/admin/web3-iban",
          icon: <VideoCallIcon />,
        },
        {
          title: "Token Registry",
          url: "/admin/token-registry",
          icon: <MonetizationOnIcon />,
        },
        {
          title: "Log Event",
          url: "/admin/log-event",
          icon: <StorageIcon />,
        },
      ]
    },
  ],

  compliance_officer: [
    {
      title: "User list",
      url: "/admin/users",
      icon: <ReorderOutlinedIcon />,
    },
    {
      title: "KYC List individual",
      url: "/admin/kyc-list/individual",
      icon: <FaceOutlinedIcon />,
    },
    {
      title: "KYC List company",
      url: "/admin/kyc-list/company",
      icon: <WorkOutlineOutlinedIcon />,
    },
    {
      title: "Transactions",
      url: "/admin/transactions",
      icon: <SyncAltIcon />,
    },
  ],

  custodian: [
    {
      title: "Token Registry",
      url: "/admin/token-registry",
      icon: <MonetizationOnIcon />,
    },
  ],

  accounting: [
    {
      title: "Token Registry",
      url: "/admin/token-registry",
      icon: <MonetizationOnIcon />,
    },
    {
      title: "Transactions",
      url: "/admin/transactions",
      icon: <SyncAltIcon />,
    },
  ],

  auditor: [
    {
      title: "Compliance",
      icon: <SupervisorAccountIcon />,
      hasChildren: true,
      children: [
        {
          title: "KYC list individual",
          url: "/admin/kyc-list/individual",
          icon: <FaceOutlinedIcon />,
        },
        {
          title: "KYC list company",
          url: "/admin/kyc-list/company",
          icon: <WorkOutlineOutlinedIcon />,
        },
      ]
    },
    {
      title: "Others",
      hasChildren: true,
      icon: <WidgetsSharpIcon />,
      children: [
        {
          title: "Transactions",
          url: "/admin/transactions",
          icon: <SyncAltIcon />,
        },
        {
          title: "Token Registry",
          url: "/admin/token-registry",
          icon: <MonetizationOnIcon />,
        },
        {
          title: "Log Event",
          url: "/admin/log-event",
          icon: <StorageIcon />,
        },
      ]
    },
  ],

  user: [
    { title: "My profile", url: "/profile", icon: <PersonOutlineIcon /> },
    { title: "Transactions", url: "/transactions", icon: <SyncAltIcon /> },
  ],

  main_with_none_token_type: ["Profile", "Dashboard"],
};

const AppBar = ({
  // from HOCs
  location,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    isMenuActive: false,
  });

  const { data: { me: userData } = {} } = useMe();
  const [, setCookie] = useCookies(["jwt"]);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const [setUserLocale] = useMutation(SET_USER_LOCALE)

  const isTokenRegistryEnabled = isFeatureEnabled('TOKEN_REGISTRY', availableFeatures);
  const isLogEventEnabled = isFeatureEnabled('EVENT_LOG', availableFeatures);
  const onUserLogoClick = () => {
    setValues({ ...values, isMenuActive: !values.isMenuActive });
  };
  const siteLocales = ((publicSettings || {}).site_locales || '').split(',').filter(Boolean).map(x => String(x).trim())

  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const [isTenantDialogOpen, setIsTenantDialogOpen] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const mobileMenuSetter = (state) => () => setMobileMenuOpen(state);

  const onUserLogoAwayClick = () => {
    setValues({ ...values, isMenuActive: false });
  };

  const submenuManager = {
    'Compliance': useState(null),
    'Others': useState(null),
  };
  const handleOpenSubmenu = (menu) => ({ currentTarget }) => submenuManager[menu][1](currentTarget);
  const handleCloseSubmenu = (menu) => () => submenuManager[menu][1](null);
  const {
    data: { meInvestInBaseCurrency = {} } = {},
    loading: loadingMeInvestInBaseCurrency,
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);
  const { data: { getAuthTenantList = {} } = {} } = useQuery(GET_AUTH_TENANT_LIST);
  const { data: { exchangeRates } = {}, loading: loadingExchangeRates } =
    useExchangeRates();
  const tokenBalanceMock = {
    value: !loadingMeInvestInBaseCurrency
      ? meInvestInBaseCurrency.approved_invest
      : "0",
    currency: "TWZ",
    contribution: [
      {
        value:
          !loadingExchangeRates &&
          !loadingMeInvestInBaseCurrency &&
          exchangeRates
            ? tokenToEth(meInvestInBaseCurrency.approved_invest, exchangeRates)
            : "~",
        currency: "ETH",
      },
    ],
  };

  const additionalBar = [
    {
      url: "/application",
      value: (
        <NavLink to="/application" className={classes.kycLink}>
          <InsertDriveFileOutlinedIcon />
          <span>{ t('KYC Application') }</span>
        </NavLink>
      ),
    },
  ];

  const renderAdditionalBar = () => (
    <ul className="navbar-btns">
      {
        // eslint-disable-next-line react/no-array-index-key
        additionalBar.map((item, i) => (
          <li key={i}>{item.value}</li>
        ))
      }
    </ul>
  );

  const renderMainMenu = () => {
    let mainMenu;
    if (
      publicSettings &&
      publicSettings.token_type === "NONE" &&
      userData.rights === "COMMON"
    ) {
      mainMenu = SETS.main.filter((item) =>
        SETS.main_with_none_token_type.includes(item.title)
      );
    } else {
      mainMenu = SETS.main;
    }
    return (
      <div className="navbar-innr">
        <ul className="navbar-menu">
          {mainMenu.map((item) => {
            const isActive =
              item.url === "/"
                ? location.pathname === "/"
                : location.pathname.indexOf(item.url) === 0;

            if (
              !item.rights ||
              (item.rights &&
                (item.rights === userData.rights ||
                  item.rights.includes(userData.rights)))
            ) {
              if (item.title === 'Token Registry' && !isTokenRegistryEnabled) {
                return (
                  <li key={item.title}>
                    <NavLink to="#" className="disable">
                      {item.icon} {t(item.title)}
                      <LockedFeatureIcon />
                    </NavLink>
                  </li>
                )
              }

              if (
                publicSettings &&
                publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
                item.title === "Buy Tokens"
              ) {
                return null
              }

              return (
                <li key={item.title} className={isActive ? "active" : ""}>
                  <NavLink to={item.url}>
                    {item.icon}{" "}
                    {publicSettings &&
                    publicSettings.token_type === TOKEN_TYPES.UTILITY &&
                    item.title === "Buy Tokens"
                      ? t("Purchase")
                      : t(item.title)}
                  </NavLink>
                </li>
              );
            }

            return false;
          })}
        </ul>
        {renderAdditionalBar()}
      </div>
    );
  };

  const renderAdminMenu = (isMobile) => (
    <div className="navbar-innr">
      <ul className="navbar-menu">
        {location.pathname.indexOf("/admin") === 0 &&
          SETS.admin.map((item) => {
            const isActive =
              item.hasChildren
                ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
                : item.url === "/admin"
                  ? location.pathname === "/admin"
                  : location.pathname.indexOf(item.url) === 0;

            if (item.hasChildren) {
              if (isMobile) {
                return (
                  <li key={item.title} className={isActive ? "active" : ""}>
                    <NavLink to="#">{item.icon} {t(item.title)}</NavLink>
                    <ul className="navbar-menu">
                      {item.children.map((childItem) => {
                        const isActive = location.pathname.indexOf(childItem.url) === 0;
                        if (
                          (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                          (childItem.title === 'Log Event' && !isLogEventEnabled)
                        ) {
                          return (
                            <li key={childItem.title} className={isActive ? "active" : ""}>
                              <NavLink to="#" className="disable">
                                {childItem.icon} {t(childItem.title)}
                                {isTokenRegistryEnabled || <LockedFeatureIcon />}
                              </NavLink>
                            </li>
                          )
                        }

                        return (
                          <li key={childItem.title} className={isActive ? "active" : ""}>
                            <NavLink to={childItem.url}>
                              {childItem.icon} {t(childItem.title)}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li
                    key={item.title}
                    className={isActive ? 'active' : ''}
                    onMouseEnter={handleOpenSubmenu(item.title)}
                    onMouseLeave={handleCloseSubmenu(item.title)}
                  >
                    <NavLink
                      to="#"
                      onClick={handleOpenSubmenu(item.title)}
                    >
                      {item.icon} {t(item.title)}
                    </NavLink>
                    <Popper
                      open={!!submenuManager[item.title][0]}
                      anchorEl={submenuManager[item.title][0]}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper elevation={6}>
                            <ClickAwayListener onClickAway={handleCloseSubmenu}>
                              <MenuList className="navbar-submenu-dropdown">
                                {item.children.map((childItem) => (
                                  <MenuItem
                                    key={childItem.title}
                                    onClick={handleCloseSubmenu(item.title)}
                                    className={isActive ? 'active' : ''}
                                  >
                                    {(
                                      (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                                      (childItem.title === 'Log Event' && !isLogEventEnabled)
                                    ) ? (
                                      <NavLink to="#" className="disable">
                                        {t(childItem.title)}
                                        <LockedFeatureIcon />
                                      </NavLink>
                                    ) : (
                                      <NavLink to={childItem.url}>
                                        {t(childItem.title)}
                                      </NavLink>
                                    )}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </li>
                );
              }
            }

            return (
              <li key={item.title} className={isActive ? "active" : ""}>
                <NavLink to={item.url}>
                  {item.icon} {t(item.title)}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </div>
  );

  const renderComplianceOfficerMenu = (isMobile) => (
    <div className="navbar-innr">
      <ul className="navbar-menu">
        {location.pathname.indexOf("/admin") === 0 &&
          SETS.compliance_officer.map((item) => {
            const isActive =
              item.hasChildren
                ? item.children.reduce((all, childItem) => all ||(location.pathname.indexOf(childItem.url) === 0), false)
                : item.url === "/admin"
                  ? location.pathname === "/admin"
                  : location.pathname.indexOf(item.url) === 0;

            if (item.hasChildren) {
              if (isMobile) {
                return (
                  <li key={item.title} className={isActive ? "active" : ""}>
                    <NavLink to="#">{item.icon} {t(item.title)}</NavLink>
                    <ul className="navbar-menu">
                      {item.children.map((childItem) => {
                        if (
                          (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                          (childItem.title === 'Log Event' && !isLogEventEnabled)
                        ) {
                          return (
                            <li key={childItem.title}>
                              <NavLink to="#" className="disable">
                                {childItem.icon} {t(childItem.title)}
                                {isTokenRegistryEnabled || <LockedFeatureIcon />}
                              </NavLink>
                            </li>
                          )
                        }
                        const isActive = location.pathname.indexOf(childItem.url) === 0;

                        return (
                          <li key={childItem.title} className={isActive ? "active" : ""}>
                            <NavLink to={childItem.url}>
                              {childItem.icon} {t(childItem.title)}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li key={item.title} className={isActive ? "active" : ""}>
                    <NavLink
                      to="#"
                      onClick={handleOpenSubmenu(item.title)}
                    >
                      {item.icon} {t(item.title)}
                    </NavLink>
                    <Menu
                      elevation={0}
                      anchorEl={submenuManager[item.title][0]}
                      keepMounted
                      open={!!submenuManager[item.title][0]}
                      onClose={handleCloseSubmenu(item.title)}
                      className="navbar-submenu-dropdown"
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      {item.children.map((childItem) => (
                        <MenuItem
                          key={childItem.title}
                          onClick={handleOpenSubmenu(item.title)}
                          className={isActive ? 'active' : ''}
                        >
                          {(
                            (childItem.title === 'Token Registry' && !isTokenRegistryEnabled) ||
                            (childItem.title === 'Log Event' && !isLogEventEnabled)
                          ) ? (
                            <NavLink to="#" className="disable">
                              {t(childItem.title)}
                              <LockedFeatureIcon />
                            </NavLink>
                          ) : (
                            <NavLink to={childItem.url}>
                              {t(childItem.title)}
                            </NavLink>
                          )}
                        </MenuItem>
                      ))}
                    </Menu>
                  </li>
                );
              }
            }

            return (
              <li key={item.title} className={isActive ? "active" : ""}>
                <NavLink to={item.url}>
                  {item.icon} {t(item.title)}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </div>
  );

  const renderCustodianMenu = (isMobile) => (
    <div className="navbar-innr">
      <ul className="navbar-menu">
        {location.pathname.indexOf("/admin") === 0 &&
          SETS.custodian.map((item) => {
            const isActive =
              item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;
            if (
              (item.title === 'Token Registry' && !isTokenRegistryEnabled) ||
              (item.title === 'Log Event' && !isLogEventEnabled)
            ) {
              return (
                <li key={item.title}>
                  <NavLink to="#" className="disable">
                    {item.icon} {t(item.title)}
                    <LockedFeatureIcon />
                  </NavLink>
                </li>
              )
            }

            return (
              <li key={item.title} className={isActive ? "active" : ""}>
                <NavLink to={item.url}>
                  {item.icon} {t(item.title)}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </div>
  );

  const renderAuditorMenu = (isMobile) => (
    <div className="navbar-innr">
      <ul className="navbar-menu">
        {location.pathname.indexOf("/admin") === 0 &&
          SETS.auditor.map((item) => {
            const isActive =
              item.url === "/admin"
                ? location.pathname === "/admin"
                : location.pathname.indexOf(item.url) === 0;
            if (
              (item.title === 'Token Registry' && !isTokenRegistryEnabled) ||
              (item.title === 'Log Event' && !isLogEventEnabled)
            ) {
              return (
                <li key={item.title}>
                  <NavLink to="#" className="disable">
                    {item.icon} {t(item.title)}
                    <LockedFeatureIcon />
                  </NavLink>
                </li>
              )
            }

            return (
              <li key={item.title} className={isActive ? "active" : ""}>
                <NavLink to={item.url}>
                  {item.icon} {t(item.title)}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </div>
  );

  const renderDropDownUserMenu = () => (
    <ul className="user-links">
      {SETS.user.filter((item) => (
          !(publicSettings && publicSettings.token_type === 'NONE' && item.title === 'Transactions')
        )).map((item) => (
        <li key={item.title}>
          <NavLink to={item.url}>
            {item.icon}
            {t(item.title)}
          </NavLink>
        </li>
      ))}
      {getAuthTenantList && getAuthTenantList.length > 0 && (
        <li>
          {/* eslint-disable-next-line */}
          <a onClick={() => setIsTenantDialogOpen(true)}>
            Investhub
          </a>
        </li>
      )}
      {isFeatureEnabled('MULTI_LANGUAGE', availableFeatures) && (
        <li>
          {/* eslint-disable-next-line */}
          <a onClick={() => setIsLanguageDialogOpen(true)}>
            {t('Language')}:
            <img
              src={languageHelper.getCurrentFlagIcon((publicSettings || {}).default_locale)}
              alt={languageHelper.getLocale((publicSettings || {}).default_locale)}
              height="24"
              style={{
                verticalAlign: 'text-bottom'
              }}
            />
          </a>
        </li>
      )}
    </ul>
  );

  const maybeRenderNavigation = (isMobile = false) =>
    userData && (
      <div className={isMobile ? "navbar-mobile" : "navbar"}>
        {location.pathname.indexOf("/admin") !== 0 && renderMainMenu()}
        {[USER_RIGHT_TYPES.ADMIN, USER_RIGHT_TYPES.GENERAL_ADMIN].includes(
          userData.rights
        ) && renderAdminMenu(isMobile)}
        {[USER_RIGHT_TYPES.COMPLIANCE_OFFICER].includes(userData.rights) &&
          renderComplianceOfficerMenu(isMobile)}
        {[USER_RIGHT_TYPES.CUSTODIAN].includes(userData.rights) &&
          renderCustodianMenu(isMobile)}
        {[USER_RIGHT_TYPES.AUDITOR].includes(userData.rights) &&
          renderAuditorMenu(isMobile)}
      </div>
    );

  return (
    <>
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={mobileMenuSetter(false)}
      >
        {maybeRenderNavigation(true)}
      </Drawer>
      <div className="page-dashboard__header">
        <div className="page-dashboard__container">
          <div className="page-dashboard__header-sidebar">
            <div
              className="page-dashboard__header-hamburger"
              onClick={mobileMenuSetter(true)}
            >
              <div className="toggle-icon">
                <span className="toggle-line" />
                <span className="toggle-line" />
                <span className="toggle-line" />
                <span className="toggle-line" />
              </div>
            </div>
          </div>

          <div className="page-dashboard__header-logo">
            <NavLink to="/" className="topbar-logo">
              {(publicSettings || {}).logo_for_dark_bg_path ? (
                <img
                  className={`${(publicSettings || {}).logo_for_dark_bg_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                  src={getFileUrl((publicSettings || {}).logo_for_dark_bg_path)}
                  alt="logo"
                />
              ) : (
                <img
                  className={`${(publicSettings || {}).logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                  src={getFileUrl((publicSettings || {}).logo_path)}
                  alt="logo"
                />
              )}
            </NavLink>
          </div>

          <div className="page-dashboard__header-nav">
            {maybeRenderNavigation()}
          </div>

          <div className="page-dashboard__header-user">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            {userData && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <ClickAwayListener onClickAway={onUserLogoAwayClick}>
                <ul onClick={onUserLogoClick} className="topbar-nav">
                  <li className="topbar-nav-item relative">
                    <NavLink
                      className={`toggle-tigger user-thumb ${
                        values.isMenuActive ? "active-toggle" : ""
                      }`}
                      to="#"
                    >
                      <span className="user-img">
                        {/* <img src="https://via.placeholder.com/70" alt="" /> */}
                        <img
                          src={getTextAvatarUrl(`${userData.first_name} ${userData.last_name}`)}
                          alt={`${userData.first_name} ${userData.last_name} avatar`}
                        />
                      </span>

                      <span className="user-welcome d-none d-lg-inline-block">
                        <span>
                          { t('Welcome') } {userData.first_name} {userData.last_name}
                        </span>
                      </span>
                    </NavLink>
                    <div
                      className={`toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown ${
                        values.isMenuActive ? "active" : ""
                      } `}
                    >
                      <div className="user-status">
                        <h6 className="user-status-title">Token balance</h6>
                        <div className="user-status-balance">
                          {tokenBalanceMock.value}{" "}
                          <small>
                            {publicSettings && publicSettings.token_symbol}
                          </small>
                        </div>
                      </div>
                      {renderDropDownUserMenu()}

                      <ul className="user-links">
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                          <a
                            onClick={() => {
                              setCookie("jwt", "", { path: "/" });
                              logout()();
                            }}
                          >
                            <ExitToAppIcon />
                            { t('Logout') }
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={isLanguageDialogOpen}
        onClose={() => setIsLanguageDialogOpen(false)}
      >
        <div className="popup-body">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setIsLanguageDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <h4 className="popup-title">Change language</h4>
          <Grid container spacing={3}>
            {Object.values(languageHelper.supportedLanguages).filter((lang) => siteLocales.includes(lang.code)).map(lang => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                className={classes.languageOption}
                key={lang.code}
                onClick={() => {
                  languageHelper.setLocale(lang.code)
                  setUserLocale({ variables: { locale: lang.code } })
                  i18n.changeLanguage(lang.code)
                  setIsLanguageDialogOpen(false)
                }}
              >
                <img src={lang.flag} alt={lang.label} height="40"/>
                <span>{lang.label}</span>
              </Grid>
            ))}
          </Grid>
        </div>
      </Dialog>

      {getAuthTenantList && getAuthTenantList.length > 0 && (
        <Dialog
          open={isTenantDialogOpen}
          onClose={() => setIsTenantDialogOpen(false)}
        >
          <div className="popup-body">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setIsTenantDialogOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <h4 className="popup-title">Go to </h4>
            <Grid container spacing={3}>
              {getAuthTenantList.map(tenant => (
                <Grid
                  item
                  xs={getAuthTenantList.length > 1 ? 6 : 12}
                  sm={getAuthTenantList.length > 1 ? 4 : 12}
                  md={getAuthTenantList.length > 1 ? 3 : 12}
                  key={tenant.host}
                >
                  {tenant.host !== window.location.host && (
                    <a
                      className={classes.tenantOption}
                      href={`${process.env.REACT_APP_DAO_HOST}/sso/request?redirect=${decodeURIComponent(`https://${tenant.host}/sso/auth`)}`}
                    >
                      <img src={tenant.logoUrl} alt={tenant.name} className={classes.tenantLogo}/>
                      <span>{tenant.name}</span>
                    </a>
                  )}
                  {tenant.host === window.location.host && (
                    <div className={[classes.tenantOption, classes.tenantActiveOption].join(' ')}>
                      <img src={tenant.logoUrl} alt={tenant.name} className={classes.tenantLogo}/>
                      <span>{tenant.name}</span>
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <a href={process.env.REACT_APP_DAO_HOST}>
                <strong>Back to Investhub</strong>
              </a>
            </Grid>
          </div>
        </Dialog>
      )}
    </>
  );
};

AppBar.propTypes = {
  // from HOCs
  location: PropTypes.object,
};

export default compose(withRouter)(AppBar);
