import React from 'react'
import moment from 'moment-timezone'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
} from '@material-ui/core'

import {
  GET_USER_COMPENSATION_TRACKING,
  GET_USER_COMPENSATION_PAYOUT,
  SAVE_PAYOUT,
  CHECK_LIQUIDATION_DOCUMENT_HAS_NEEDED_SIGNATURES,
} from '../queriesAndMutations'
import { usePublicSettings } from '../myHooks'
import { toaster, isProduction } from '../utils'
import SendCompensationButton from './SendCompensationButton'

const AdminCompensatableTable = ({
  user,
}) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [savePayout, savePayoutStatus] = useMutation(SAVE_PAYOUT)
  const { data: { getUserCompensationTracking: trackingData } = {}, loading: trackingLoading, refetch: refetchTracking } = useQuery(GET_USER_COMPENSATION_TRACKING, {
    variables: {
      userId: user.id
    }
  })
  const { data: { getUserCompensationPayout: payoutList } = {}, loading: payoutLoading, refetch: refetchPayout } = useQuery(GET_USER_COMPENSATION_PAYOUT, {
    variables: {
      userId: user.id
    }
  })
  const { data: { checkLiquidationDocumentHasNeededSignatures: hasNeededSignatures } = {} } = useQuery(CHECK_LIQUIDATION_DOCUMENT_HAS_NEEDED_SIGNATURES, {
    variables: {
      userId: user.id
    }
  })

  if (!publicSettings) {
    return <CircularProgress />
  }

  const sendSavePayoutRequest = (txHash) => {
    savePayout({
      variables: {
        payoutTxHash: txHash,
        transactionIds: trackingData.transactions
          .filter((compensatable) => compensatable.status === 'NOT_PAID')
          .map((compensatable) => compensatable.id),
        userId: user.id,
      }
    }).then(({ data: { savePayout : result } }) => {
      refetchTracking()
      if (result) {
        refetchPayout()
      } else {
        toaster.warning('Transactions changed. It will take a few minutes to confirm the transaction')
      }
    }).catch(() => {})
  }

  const isSignatureRequired = !hasNeededSignatures

  return (
    <>
      <div className="settings__item">
        <div className="settings__anchor">{' '}</div>

        <div className="title-primary settings__title">
          { t('Compensatable') }
        </div>
        <div className="settings__fieldset">
          {trackingLoading && (
            <CircularProgress />
          )}

          {trackingData && (
            <>
              {trackingData.transactions.length > 0
                ? (
                  <div className="transactions__wrapper">
                    <div className="transactions__table kyc__table_transactions kyc__table_transactions_clear-col-3">
                    <div className="transactions__row">{' '}</div>
                      {trackingData.transactions.map((compensatable) => (
                        <div className="transactions__row row__force" key={compensatable.id}>
                          <div className="transactions__col">
                            <div className="transactions__label">
                              { t('Tranx No') }
                            </div>
                            { compensatable.transactionHash }
                          </div>
                          <div className="transactions__col">
                            <div className="transactions__label">
                              { t('Amount') }
                            </div>
                            { +compensatable.amount / (10 ** compensatable.decimal) }
                          </div>
                          <div className="transactions__col">
                            <div className="transactions__label">
                              { t('Transfered At') }
                            </div>
                            { moment(+compensatable.transferedAt).tz('Europe/Vaduz').format('DD-MM-YYYY z') }
                          </div>
                          <div className="transactions__col">
                            <div className="transactions__label">
                              { t('Status') }
                            </div>
                            { compensatable.status }
                          </div>
                          <div className="transactions__col">
                            <div className="transactions__label">
                              { t('Actions') }
                            </div>
                            <a
                              className="button button-small button-clear__height"
                              href={isProduction
                                ? `https://etherscan.io/tx/${compensatable.transactionHash}`
                                : `https://sepolia.etherscan.io/tx/${compensatable.transactionHash}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              { t('View transaction on Etherscan') }
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>{ t('No compensatable tranctions') }</p>
                )
              }

              {trackingData.totalCompensation > 0 && (
                <>
                  <div className="calendar__list drawer_report__list nospace__top">
                    <div className="calendar__item reset">
                      <div className="calendar__head">
                        <div className="calendar__category">
                          {t('Total compensatable token')}:
                        </div>

                        <div className="calendar__value">
                          {trackingData.totalToken} {publicSettings.token_symbol}
                        </div>
                      </div>
                    </div>
                    <div className="calendar__item reset">
                      <div className="calendar__head">
                        <div className="calendar__category">
                          {t('Total compensation')}:
                        </div>

                        <div className="calendar__value">
                          {trackingData.totalCompensation} USDC
                        </div>
                      </div>
                    </div>
                    {savePayoutStatus.loading && (
                      <CircularProgress />
                    )}
                  </div>

                  {!savePayoutStatus.loading && (
                    <SendCompensationButton
                      addressTo={user.wallet_address}
                      amount={trackingData.totalCompensation}
                      onSuccess={sendSavePayoutRequest}
                      forceDisable={isSignatureRequired}
                      disableText={trackingData.totalCompensation < 5000
                        ? 'Creditor has not signed the document yet.'
                        : 'Both creditor and admin had to signed the document before paying out'
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {payoutLoading && (
        <CircularProgress />
      )}

      <div className="settings__item">
        <div className="settings__anchor">{' '}</div>

        <div className="title-primary settings__title">
          { t('Payout history') }
        </div>
        {payoutList && payoutList.length > 0
          ? (

          <div className="settings__fieldset">
            <div className="transactions__wrapper">
              <div className="transactions__table kyc__table_transactions kyc__table_transactions_clear-col-3">
                <div className="transactions__row">{' '}</div>
                {payoutList.map((payout) => (
                  <div className="transactions__row row__force" key={payout.id}>
                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Tranx No') }
                      </div>
                      { payout.transactionHash }
                    </div>
                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Amount') }
                      </div>
                      { `${+payout.amount / (10 ** payout.decimal)} USDC` }
                    </div>
                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Payed At') }
                      </div>
                      { moment(+payout.createdAt).tz('Europe/Vaduz').format('DD-MM-YYYY z') }
                    </div>
                    <div className="transactions__col">
                      <div className="transactions__label">
                        { t('Compensate for') }
                      </div>
                      <div>
                        { payout.compensationTracking.map(tracking => (
                          <p key={tracking.transactionHash}>{tracking.transactionHash}</p>
                        )) }
                      </div>
                    </div>
                    <div className="transactions__col">
                      <div className="transactions__label">
                        {t('Actions')}
                      </div>
                      <a
                        className="button button-small button-clear__height"
                        href={isProduction
                          ? `https://etherscan.io/tx/${payout.transactionHash}`
                          : `https://sepolia.etherscan.io/tx/${payout.transactionHash}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        { t('View transaction on Etherscan') }
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          ) : (
              <p>{ t('No payout tranctions') }</p>
          )
        }
      </div>
    </>
  )
}

export default AdminCompensatableTable
