import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useCookies } from 'react-cookie'
import InputTheme from '../../../components/InputTheme'
import Icon from '../../../components/Icon'
import { CHANGE_PASSWORD_MUTATION } from '../../../queriesAndMutations'
import { logout } from '../../../utils'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import InformationCard from '../../../components/InformationCard'

const Password = () => {
  const [, setCookie] = useCookies(["jwt"]);
  const { t } = useTranslation();
  const [changePassword, { error, loading }] = useMutation(
    CHANGE_PASSWORD_MUTATION
  );
  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const [value, setValue] = useState(initialValues);

  const [showSubmitInfo, setShowSubmitInfo] = useState(false)
  // const isDataUpToDate = () => {
  //   const notEmptyField = Object.keys(value).find(
  //     (fieldName) => value[fieldName] !== ""
  //   );

  //   return Boolean(!notEmptyField);
  // };

  const submit = async (e) => {
    e.preventDefault();
    try {
      await changePassword({ variables: { input: value } });
      setCookie("jwt", "", { path: "/" });
      setShowSubmitInfo(true)
      logout()();
      setValue(initialValues);
    } catch (e) {}
  };

  return (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Password') }</div>
      <form onSubmit={submit} className="settings__fieldset">
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"

          propertyName="old_password"
          label={t("Old Password")}
          state={value}
          setState={setValue}
          type="password"
          error={error}
          loading={loading}
        />

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"

          propertyName="new_password"
          label={t("New Password")}
          state={value}
          setState={setValue}
          type="password"
          error={error}
          loading={loading}
        />

        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"

          propertyName="confirm_password"
          label={t("Confirm New Password")}
          state={value}
          setState={setValue}
          type="password"
          error={error}
          loading={loading}
        />

        <div className="field">
          <button type="submit" className="button settings__button">
            { t('Update') }
          </button>
        </div>
        {showSubmitInfo && (
          <div className="field">
            <div className="refund__item refund__item-middle_content box__information box__information-green">
              <div className="refund__icon">
                <Icon
                  className={classNames('check')}
                  name='check'
                  size="24"
                />{" "}
              </div>

              <div className="refund__content refund__content-link">
                { t('All Changes are saved') }
              </div>
            </div>
          </div>
        )}

        {/* {isDataUpToDate() && (
          <div className="field">
            <div className="refund__item refund__item-middle_content box__information box__information-green">
              <div className="refund__icon">
                <Icon
                  className={classNames('check')}
                  name='check'
                  size="24"
                />{" "}
              </div>

              <div className="refund__content refund__content-link">
                { t('All Changes are saved') }
              </div>
            </div>
          </div>
        )} */}

        <div className="field">
          <InformationCard
            color="card__information-red"
            title={t('Warning')}
          >
            <p>
              { t('Password should be minmum 8 letter and include lower and uppercase letter.') }
              { t('You will be automatically logged out once the password is changed.') }
            </p>
          </InformationCard>
        </div>
      </form>
    </div>
  );
};

export default Password;
