import React, { useEffect } from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { UPDATE_IDNOW_IDENT_TO_PENDING } from '../queriesAndMutations'
import { useTranslation } from 'react-i18next'

const IdnowIdentComplete = () => {
  const { t } = useTranslation()
  const client = useApolloClient();
  const [updateIdnowIdentToPending] = useMutation(UPDATE_IDNOW_IDENT_TO_PENDING)

  useEffect(() => {
    updateIdnowIdentToPending({
      variables: {
        input: Object.fromEntries(new URLSearchParams(window.location.search)),
      },
    }).then(async () => {
      await client.resetStore()
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="text-center">
      <p>{t('The Liveness Check is completed. Login here now.')}</p>
    </div>
  )
}

export default IdnowIdentComplete
