// node_modules library
import React, { useState } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

// node_modules component
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// internal library
import {
  GET_ONFIDO_WATCHLIST,
  RECHECK_WATCHLIST,
  GET_ONFIDO_WATCHLIST_COST,
  GET_COMPLYCUBE_WATCHLIST,
  RECHECK_COMPLYCUBE_WATCHLIST,
} from '../queriesAndMutations'
import {
  toaster,
} from '../utils'

// internal component
import TablePaginationActions from '../components/TablePagination'
import { ReportView } from '../components/OnfidoReports'
import { ReportView as ComplycubeReportView } from '../components/ComplycubeReports'
import Icon from '../components/Icon'
import { hasUserEnoughRights } from '../utils/me'
import { useMe } from '../myHooks'
import { USER_RIGHT_TYPES } from '../constants/user'

const CheckOwner = ({ type, owner }) => {
  switch (type) {
    case 'INDIVIDUAL':
    case 'COMPLYCUBE_INDIVIDUAL':
      return (
        <div>
          <div>
            {`KYC: ${owner.id}`}
          </div>
          <div>
            {owner.email}
          </div>
        </div>
      )

    case 'UBO':
    case 'COMPLYCUBE_UBO':
      return (
        <div>
          <div>
            {`KYB: ${owner.customerCompanyId}`}
          </div>
          <div>
            {`UBO: ${owner.email}`}
          </div>
        </div>
      )

    case 'DIRECTOR':
    case 'COMPLYCUBE_DIRECTOR':
      return (
        <div>
          <div>
            {`KYB: ${owner.customerCompanyId}`}
          </div>
          <div>
            {`Director: ${owner.email}`}
          </div>
        </div>
      )

    default:
      return null
  }
}

const ReportResult = ({ report }) => {
  if (report) {
    if (report.result === 'clear') {
      return <div className="status-green products__status">CLEAR</div>
    }

    return <div className="status-pending products__status">{ report.result.toUpperCase() }</div>
  }

  return <div className="status-pending products__status">Pending</div>
}

const ComplycubeReportResult = ({ report }) => {
  if (report) {
    if (report.outcome === 'clear') {
      return <div className="status-green products__status">CLEAR</div>
    }

    return <div className="status-pending products__status">{ report.outcome.toUpperCase() }</div>
  }

  return <div className="status-pending products__status">Pending</div>
}

const AdminMonitoring = () => {
  const typeOptions = {
    'INDIVIDUAL': 'Individual',
    'DIRECTOR': 'Company Director',
    'UBO': 'Company UBO',
  };

  const typeOptionsComplycube = {
    'COMPLYCUBE_INDIVIDUAL': 'Individual',
    'COMPLYCUBE_DIRECTOR': 'Company Director',
    'COMPLYCUBE_UBO': 'Company UBO',
  };
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe();
  const [search, setSearch] = useState(null)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [type, setType] = useState('INDIVIDUAL')
  const [selectedOwner, setSelectedOwner] = useState(null)
  const [checkedOwners, setCheckedOwners] = useState([])
  const [isAllWatchlistModalOpen, setIsAllWatchlistModalOpen] = useState(false)
  const [isConfirmRecheckWatchlistModalOpen, setIsConfirmRecheckWatchlistModalOpen] = useState(false)
  const [isConfirmBulkRecheckWatchlistModalOpen, setIsConfirmBulkRecheckWatchlistModalOpen] = useState(false)
  const [isConfirmRecheckAllWatchlistModalOpen, setIsConfirmRecheckAllWatchlistModalOpen] = useState(false)
  const [recheckWatchlist, recheckWatchlistStatus] = useMutation(RECHECK_WATCHLIST)
  const [recheckComplycubeWatchlist, recheckComplycubeWatchlistStatus] = useMutation(RECHECK_COMPLYCUBE_WATCHLIST)
  const isOnfido = Object.keys(typeOptions).includes(type)
  const { data: { getOnfidoWatchlist: result } = {}, loading, refetch } = useQuery(GET_ONFIDO_WATCHLIST, {
    variables: {
      input: {
        search,
        type,
        page: page + 1,
        pageSize,
      },
    },
    skip: !isOnfido
  })

  const { data: { getComplycubeWatchlist: complycubeResult } = {}, complycubeLoading, complycubeRefetch } = useQuery(GET_COMPLYCUBE_WATCHLIST, {
    variables: {
      input: {
        search,
        type,
        page: page + 1,
        pageSize,
      },
    },
    skip: isOnfido
  })

  const [getOnfidoWatchlistCost, getOnfidoWatchlistCostStatus] = useLazyQuery(GET_ONFIDO_WATCHLIST_COST, {
    fetchPolicy: 'network-only',
  })

  const changeRowsPerPage = ({ target: { value } }) => {
    setCheckedOwners([])
    setPage(0)
    setPageSize(+value)
  }
  const changePage = (event, page) => {
    setCheckedOwners([])
    setPage(page)
  }
  const changeType = (option) => {
    setCheckedOwners([])
    setType(option)
    setPage(0)
  }

  const onChangeSearch = ({ target: { value } }) => {
    setSearch(value);
  }

  const openAllWatchlistModal = (ownerId) => {
    if (isOnfido && (!result || !result.data)) {
      return
    }

    if (!isOnfido && (!complycubeResult || !complycubeResult.data)) {
      return
    }

    const selected = (isOnfido ? result.data : complycubeResult.data).find(owner => owner.id === ownerId)

    if (!selected) {
      return
    }

    setSelectedOwner(selected)
    setIsAllWatchlistModalOpen(true)
  }

  const closeAllWatchlistModal = () => {
    setIsAllWatchlistModalOpen(false)
    setSelectedOwner(null)
  }

  const openConfirmRecheckWatchlistModal = (ownerId) => {
    if (isOnfido && (!result || !result.data)) {
      return
    }

    if (!isOnfido && (!complycubeResult || !complycubeResult.data)) {
      return
    }

    const selected = (isOnfido ? result.data : complycubeResult.data).find(owner => owner.id === ownerId)

    if (!selected) {
      return
    }

    setSelectedOwner(selected)
    setIsConfirmRecheckWatchlistModalOpen(true)
  }

  const closeConfirmRecheckWatchlistModal = () => {
    setIsConfirmRecheckWatchlistModalOpen(false)
    setSelectedOwner(null)
  }

  const sendSingleRequestRecheckWatchlist = () => {
    if (isOnfido) {
      recheckWatchlist({
        variables: {
          input: {
            type,
            ownerIds: [selectedOwner.id]
          }
        }
      }).then(() => {
        closeConfirmRecheckWatchlistModal()
        toaster.success(t('Success'))
        refetch()
      }).catch(() => {})
    } else {
      recheckComplycubeWatchlist({
        variables: {
          input: {
            type,
            ownerIds: [selectedOwner.id]
          }
        }
      }).then(() => {
        closeConfirmRecheckWatchlistModal()
        toaster.success(t('Success'))
        complycubeRefetch()
      }).catch(() => {})
    }
  }

  const toggleCheckedOwner = (owner) => ({ target: { checked } }) => {
    if (checked) {
      setCheckedOwners([
        ...checkedOwners,
        owner,
      ])
    } else {
      setCheckedOwners(checkedOwners.filter(checkedOwner => checkedOwner.id !== owner.id))
    }
  }

  const openConfirmBulkRecheckWatchlistModal = () => {
    if (checkedOwners.length === 0) {
      return
    }

    getOnfidoWatchlistCost({
      variables: {
        type,
        ownerIds: checkedOwners.map(owner => owner.id)
      }
    })
    setIsConfirmBulkRecheckWatchlistModalOpen(true)
  }

  const closeConfirmBulkRecheckWatchlistModal = () => {
    setIsConfirmBulkRecheckWatchlistModalOpen(false)
  }

  const sendBulkRequestRecheckWatchlist = () => {
    if (isOnfido) {
      recheckWatchlist({
        variables: {
          input: {
            type,
            ownerIds: checkedOwners.map(owner => owner.id)
          }
        }
      }).then(() => {
        closeConfirmBulkRecheckWatchlistModal()
        setCheckedOwners([])
        toaster.success(t('Success'))
        refetch()
      }).catch(() => {})
    } else {
      recheckComplycubeWatchlist({
        variables: {
          input: {
            type,
            ownerIds: checkedOwners.map(owner => owner.id)
          }
        }
      }).then(() => {
        closeConfirmBulkRecheckWatchlistModal()
        setCheckedOwners([])
        toaster.success(t('Success'))
        complycubeRefetch()
      }).catch(() => {})
    }
  }

  const openConfirmRecheckAllWatchlistModal = () => {
    getOnfidoWatchlistCost({
      variables: {
        type,
      }
    })
    setIsConfirmRecheckAllWatchlistModalOpen(true)
  }

  const closeConfirmRecheckAllWatchlistModal = () => {
    setIsConfirmRecheckAllWatchlistModalOpen(false)
  }

  const sendRequestRecheckAllWatchlist = () => {
    recheckWatchlist({
      variables: {
        input: {
          type,
          recheckAll: true,
        }
      }
    }).then(() => {
      closeConfirmRecheckAllWatchlistModal()
      toaster.success(t('Success'))
      refetch()
    }).catch(() => {})
  }

  return (
    <>
      <div className="page__container">
        <div className="page__title h3">{ t('Monitoring')}</div>

        <div className="settings">

          <div className="settings__menu">
            <div className="page__title h5">{ t('Onfido')}</div>

            {Object.keys(typeOptions).map(option => (
              <button
                key={option}
                className={`settings__link ${type === option ? 'active' : ''}`}
                onClick={() => changeType(option)}
              >
                { t(typeOptions[option]) }
              </button>
            ))}

            <div className="page__title h5">{ t('Complycube')}</div>

            {Object.keys(typeOptionsComplycube).map(option => (
              <button
                key={option}
                className={`settings__link ${type === option ? 'active' : ''}`}
                onClick={() => changeType(option)}
              >
                { t(typeOptionsComplycube[option]) }
              </button>
            ))}
          </div>

          <div className="settings__wrapper">
            <div className="settings__list">
              <div className="transactions card">
                <div className="products__head">
                  <div className="title-blue card__title">{ t('Watchlist Enhanced List') }</div>
                </div>

                <div className="products__wrapper">
                  <div style={{ marginBottom: '10px' }}>
                    {isOnfido && me && hasUserEnoughRights(
                      me.rights,
                      USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                    ) && (
                      <button
                        className="button button-small"
                        onClick={openConfirmRecheckAllWatchlistModal}
                      >
                        {type === 'INDIVIDUAL' && 'Recheck all individuals'}
                        {type === 'DIRECTOR' && 'Recheck all directors'}
                        {type === 'UBO' && 'Recheck all UBOs'}
                      </button>
                    )}

                    {checkedOwners.length > 0 && (
                      <button
                        className="button button-small"
                        onClick={openConfirmBulkRecheckWatchlistModal}
                      >
                        Bulk recheck
                      </button>
                    )}

                    <form className="form" style={{marginTop: '10px'}}>
                      <input
                        type="search"
                        className="form__input"
                        placeholder={t('Type in search')}
                        value={search}
                        onChange={onChangeSearch}
                      />
                      <button className="form__button">
                        <Icon
                          className='searchh'
                          name='searchh'
                          size="24"
                        />{" "}
                      </button>
                    </form>

                    <br />
                  </div>

                  {/* START TABLE */}
                  <div className="products__table products__table-logevent">
                    <div className="products__row">
                      <div className="products__col" />
                      <div className="products__col">
                        { t('ID') }
                      </div>

                      <div className="products__col">
                        { t('User') }
                      </div>

                      <div className="products__col">
                        { t('Latest Check')}
                      </div>

                      <div className="products__col">
                        { t('Result') }
                      </div>

                      <div className="products__col">
                        { t('Breakdown') }
                      </div>

                      <div className="products__col">
                        { t('Action') }
                      </div>
                    </div>

                    { loading && (
                      <div className="products__row">
                        <div className="transactions__col transactions__col-full">
                          <CircularProgress />
                        </div>
                      </div>
                    )}

                    { isOnfido && result && result.data.length > 0 && result.data.map((owner) => (
                      <div
                        className="products__row"
                        key={owner.id}
                      >
                        <div className="products__col">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              className="checkbox__input"
                              checked={checkedOwners.map(owner => owner.id).includes(owner.id) }
                              onChange={toggleCheckedOwner(owner)}
                              value={owner.id}
                            />
                            <div className="checkbox__inner">
                              <div className="checkbox__tick">{' '}</div>
                              <div className="checkbox__text" />
                            </div>
                          </label>
                        </div>
                        <div className="products__col">
                          <div className="products__category">
                            {owner.id}
                          </div>
                        </div>

                        <div className="products__col">
                          <CheckOwner type={type} owner={owner} />
                        </div>

                        <div className="products__col">
                          {moment(owner.onfidoApplicant.onfidoChecks[0].created_at).tz('Europe/Vaduz').format('YYYY-MM-DD h:mm z')}
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal products__item-chip">
                            <ReportResult report={owner.onfidoApplicant.onfidoChecks[0].onfidoReports[0]} />
                          </div>
                        </div>

                        <div className="products__col">
                          <div>
                            {owner.onfidoApplicant.onfidoChecks[0].onfidoReports.length > 0 && (
                              <ReportView
                                breakdown={owner.onfidoApplicant.onfidoChecks[0].onfidoReports[0].breakdown}
                                breakdownCompare={
                                  owner.onfidoApplicant.onfidoChecks[1] &&
                                  owner.onfidoApplicant.onfidoChecks[1].onfidoReports[0]
                                  ? owner.onfidoApplicant.onfidoChecks[1].onfidoReports[0].breakdown
                                  : undefined
                                }
                              />
                            )}
                            {owner.onfidoApplicant.onfidoChecks[0].onfidoReports.length === 0 && (
                              <div className="products__item products__item-normal products__item-chip">
                                <div className="status-pending products__status">Pending</div>
                              </div>
                            )}
                          </div>

                          <button onClick={() => openAllWatchlistModal(owner.id)}>
                            { t('See all') }
                          </button>
                        </div>

                        <div className="products__col">
                          {recheckWatchlistStatus.loading && (
                            <CircularProgress />
                          )}
                          {!recheckWatchlistStatus.loading && me && hasUserEnoughRights(
                              me.rights,
                              USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                            ) && (
                            <button
                              className="button button-small"
                              onClick={() => openConfirmRecheckWatchlistModal(owner.id)}
                            >
                              Recheck
                            </button>
                          )}
                        </div>
                      </div>
                    ))}

                    { !isOnfido && complycubeResult && complycubeResult.data.length > 0 && complycubeResult.data.map((owner) => (
                      <div
                        className="products__row"
                        key={owner.id}
                      >
                        <div className="products__col">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              className="checkbox__input"
                              checked={checkedOwners.map(owner => owner.id).includes(owner.id) }
                              onChange={toggleCheckedOwner(owner)}
                              value={owner.id}
                            />
                            <div className="checkbox__inner">
                              <div className="checkbox__tick">{' '}</div>
                              <div className="checkbox__text" />
                            </div>
                          </label>
                        </div>
                        <div className="products__col">
                          <div className="products__category">
                            {owner.id}
                          </div>
                        </div>

                        <div className="products__col">
                          <CheckOwner type={type} owner={owner} />
                        </div>

                        <div className="products__col">
                          {moment(owner.complycubeClient.complycubeChecks[0].created_at).tz('Europe/Vaduz').format('YYYY-MM-DD h:mm z')}
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal products__item-chip">
                            <ComplycubeReportResult report={owner.complycubeClient.complycubeChecks[0].result} />
                          </div>
                        </div>

                        <div className="products__col">
                          <div>
                            {owner.complycubeClient.complycubeChecks.length > 0 && (
                              <ComplycubeReportView breakdown={owner.complycubeClient.complycubeChecks[0].result} />
                            )}
                            {owner.complycubeClient.complycubeChecks.length === 0 && (
                              <div className="products__item products__item-normal products__item-chip">
                                <div className="status-pending products__status">Pending</div>
                              </div>
                            )}
                          </div>

                          {/* <button onClick={() => openAllWatchlistModal(owner.id)}>
                            { t('See all') }
                          </button> */}
                        </div>

                        <div className="products__col">
                          {recheckComplycubeWatchlistStatus.loading && (
                            <CircularProgress />
                          )}
                          {!recheckComplycubeWatchlistStatus.loading && me && hasUserEnoughRights(
                              me.rights,
                              USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                            ) && (
                            <button
                              className="button button-small"
                              onClick={() => openConfirmRecheckWatchlistModal(owner.id)}
                            >
                              Recheck
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* END TABLE */}

                  { isOnfido && result && result.data.length > 0 && (
                    <TablePaginationActions
                      count={result.total}
                      page={page}
                      rowsPerPage={pageSize}
                      onChangePage={changePage}
                      onChangeRows={changeRowsPerPage}
                      rowsPerPageOptions={[10, 20, 50]}
                    />
                  )}

                  { !isOnfido && complycubeResult && complycubeResult.data.length > 0 && (
                    <TablePaginationActions
                      count={complycubeResult.total}
                      page={page}
                      rowsPerPage={pageSize}
                      onChangePage={changePage}
                      onChangeRows={changeRowsPerPage}
                      rowsPerPageOptions={[10, 20, 50]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      { isOnfido && selectedOwner && (
        <Dialog
          open={isAllWatchlistModalOpen}
          onClose={closeAllWatchlistModal}
        >
          <DialogTitle>
            {`${selectedOwner.email}'s watchlist checks`}
          </DialogTitle>
          <DialogContent>
            <div>
              {selectedOwner.onfidoApplicant.onfidoChecks.map((check, index) => (
                <div key={check.id}>
                  <strong style={{ marginBottom: '10px' }}>
                    {moment(check.created_at).tz('Europe/Vaduz').format('YYYY-MM-DD h:mm z')}
                  </strong>
                  {
                    selectedOwner.onfidoApplicant.onfidoChecks[index + 1]
                    ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingBottom: '10px',
                          marginBottom: '10px',
                          borderBottom: '1px solid black',
                        }}
                      >
                        {selectedOwner.onfidoApplicant.onfidoChecks[index + 1].onfidoReports.length > 0 && (
                          <ReportView breakdown={selectedOwner.onfidoApplicant.onfidoChecks[index + 1].onfidoReports[0].breakdown} />
                        )}
                        {selectedOwner.onfidoApplicant.onfidoChecks[index + 1].onfidoReports.length === 0 && 'PENDING'}

                        <ArrowRightAltIcon />

                        {check.onfidoReports.length > 0 && (
                          <ReportView
                            breakdown={check.onfidoReports[0].breakdown}
                            breakdownCompare={selectedOwner.onfidoApplicant.onfidoChecks[index + 1].onfidoReports[0].breakdown}
                          />
                        )}
                        {check.onfidoReports.length === 0 && 'PENDING'}
                      </div>
                    ) : (
                      <div>
                        {check.onfidoReports.length > 0 && (
                          <ReportView breakdown={check.onfidoReports[0].breakdown} />
                        )}

                        {check.onfidoReports.length === 0 && 'PENDING'}
                      </div>
                    )
                  }
                </div>
              ))}
            </div>
          </DialogContent>

        </Dialog>
      )}

      {selectedOwner && (
        <Dialog
          open={isConfirmRecheckWatchlistModalOpen}
          onClose={closeConfirmRecheckWatchlistModal}
        >
          <DialogTitle>
            {`Recheck ${selectedOwner.email}'s watchlist`}
          </DialogTitle>
          <DialogContent>
            <div>
              <p>
                {`Do you really want to submit request to recheck watchlist for ${selectedOwner.email}?`}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="button button-small button-stroke"
              onClick={closeConfirmRecheckWatchlistModal}
            >
              { t('Cancel') }
            </button>
            <button
              className="button button-small"
              onClick={() => sendSingleRequestRecheckWatchlist()}
            >
              { t('Submit') }
            </button>
          </DialogActions>

        </Dialog>
      )}

      {checkedOwners.length > 0 && (
        <Dialog
          open={isConfirmBulkRecheckWatchlistModalOpen}
          onClose={closeConfirmBulkRecheckWatchlistModal}
        >
          <DialogTitle>
            Bulk recheck watchlist
          </DialogTitle>
          <DialogContent>
            <div>
              <p>
                Do you really want to submit request to recheck watchlist for these users?
              </p>
              <ul>
                {checkedOwners.map(owner => (
                  <li key={owner.id}>- { owner.email }</li>
                ))}
              </ul>
              {!getOnfidoWatchlistCostStatus.loading && getOnfidoWatchlistCostStatus.data && (
                <p>
                  Cost:
                  {' '}
                  {getOnfidoWatchlistCostStatus.data.getOnfidoWatchlistCost.price}
                  {' '}
                  {getOnfidoWatchlistCostStatus.data.getOnfidoWatchlistCost.currency}
                </p>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="button button-small button-stroke"
              onClick={closeConfirmBulkRecheckWatchlistModal}
            >
              { t('Cancel') }
            </button>
            <button
              className="button button-small"
              onClick={() => sendBulkRequestRecheckWatchlist()}
            >
              { t('Submit') }
            </button>
          </DialogActions>

        </Dialog>
      )}

      <Dialog
        open={isConfirmRecheckAllWatchlistModalOpen}
        onClose={closeConfirmRecheckAllWatchlistModal}
      >
        <DialogTitle>
          Recheck watchlist for all
        </DialogTitle>
        <DialogContent>
          <div>
            <p>
              {type === 'INDIVIDUAL' && 'Do you really want to submit request to recheck watchlist for all individuals?'}
              {type === 'DIRECTOR' && 'Do you really want to submit request to recheck watchlist for all directors?'}
              {type === 'UBO' && 'Do you really want to submit request to recheck watchlist for all UBOs?'}
            </p>
            {getOnfidoWatchlistCostStatus.loading && (
              <CircularProgress />
            )}
            {!getOnfidoWatchlistCostStatus.loading && getOnfidoWatchlistCostStatus.data && (
              <p>
                Cost:
                {' '}
                {getOnfidoWatchlistCostStatus.data.getOnfidoWatchlistCost.price}
                {' '}
                {getOnfidoWatchlistCostStatus.data.getOnfidoWatchlistCost.currency}
              </p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="button button-small button-stroke"
            onClick={closeConfirmRecheckAllWatchlistModal}
          >
            { t('Cancel') }
          </button>
          <button
            className="button button-small"
            onClick={() => sendRequestRecheckAllWatchlist()}
          >
            { t('Submit') }
          </button>
        </DialogActions>

      </Dialog>
    </>
  )
}

export default AdminMonitoring
