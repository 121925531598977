import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { useMutation } from '@apollo/client'
import pick from 'lodash/pick'
import FormHelperText from '@material-ui/core/FormHelperText'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useMe } from '../../../myHooks'
import { PERSONAL_DATA_MUTATION } from '../../../queriesAndMutations'
import InputTheme from '../../../components/InputTheme'
import { extractMsgFromErrByPropertyName } from '../../../utils'
import { useTranslation } from 'react-i18next'
import InformationLine from '../../../components/InformationLine'

const useStyles = makeStyles(() => ({
  input_border_date: {
    borderRadius: "4px",
    border: "1px solid #d2dde9",
    width: "100%",
    padding: "10px 15px",
    lineHeight: "20px",
    fontSize: ".9em",
    color: "rgba(73, 84, 99, 0.7)",
    transition: "all .4s",
    "&:focus": {
      boxShadow: "none",
      outline: "none",
      borderColor: "#b1becc",
    },
    "&:disabled": {
      background: "rgba(230, 239, 251, 0.2)",
    },

    "~": {
      error: {
        color: "#ff6868",
        marginBottom: "0",
        position: "relative",
        top: "7px",
      },
    },
  },
}));

const PersonalData = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data: { me: userData },
    refetch,
  } = useMe();
  const [updatePersonalData, { loading, error }] = useMutation(
    PERSONAL_DATA_MUTATION
  );
  const [value, setValue] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    phone: "",
    birth_date: "",
    ...userData,
  });

  const [showSubmitInfo, setShowSubmitInfo] = useState(false)
  // const isDataUpToDate = () => {
  //   const mutableFields = [
  //     "phone",
  //     "birth_date",
  //     "first_name",
  //     "last_name",
  //     "middle_name",
  //   ];
  //   const changedField = Object.keys(pick(userData, mutableFields)).find(
  //     (fieldName) => userData[fieldName] !== value[fieldName]
  //   );
  //   return Boolean(!changedField);
  // };

  const submit = async (e) => {
    e.preventDefault();
    const input = pick(value, [
      "birth_date",
      "phone",
      "first_name",
      "last_name",
      "middle_name",
    ]);
    try {
      await updatePersonalData({ variables: { input } });
      await refetch();

      setShowSubmitInfo(true)
      // toaster.success("Personal data were successfully updated");
    } catch (e) {}
  };

  const handleDateChange = (date) =>
    setValue({
      ...value,
      birth_date: date !== null ? date.toString() : date,
    });

  const renderDatePicker = () => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, "birth_date");

    return (
      <div className="field">
        <div className="field__label">
          { t('Your birth date') }
        </div>
        <div className="field__wrap field__wrap-date">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              views={["year", "month", "date"]}
              openTo="year"
              disableFuture
              variant="outlined"
              format="dd.MM.yyyy"
              margin="none"
              className={classes.input_border_date}
              id="date-picker-dialog"
              value={value.birth_date}
              error={errorsTexts.length !== 0}
              helperText={(errorsTexts && errorsTexts.join(". ")) || ""}
              onChange={handleDateChange}
              invalidDateMessage={t('Invalid Date Format')}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  };

  const renderPhoneInput = () => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, "phone");

    return (
      <div className="field">
        <div className="field__label">{t('Phone number')}</div>

        <div className="field__wrap field__wrap-phone">
          <PhoneInput
            defaultCountry="li"
            onChange={(valuePhone) => setValue({ ...value, phone: valuePhone })}
            variant="outlined"
            disabled={loading}
            fullWidth
            value={value.phone || "li"}
          />
          <FormHelperText error={errorsTexts.length !== 0}>
            {(errorsTexts && errorsTexts.join(". ")) || ""}
          </FormHelperText>
        </div>
      </div>
    );
  };

  return (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Personal Data') }</div>

      <form onSubmit={submit} className="settings__fieldset">
        <div className="settings__row flex__row flex__wrap have__fields have__space">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"

            propertyName="first_name"
            label={t('First name')}
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"

            propertyName="last_name"
            label={t('Last name')}
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"

            propertyName="middle_name"
            label={t('Middle name')}
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />

          {renderPhoneInput()}
          {/* <Input propertyName="phone" label="Mobile number" state={value} setState={setValue} error={error} loading={loading} /> */}

          {renderDatePicker()}

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"

            propertyName="email"
            label={t('Email')}
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
            disabled
          />
        </div>

        <div className="field">
          <button type="submit" className="button settings__button">
            { t('Update Profile') }
          </button>
        </div>

        {showSubmitInfo && (
          <div className="field">
            <InformationLine
              informationColor="box__information-green"
              icon="check"
              text={ t('All Changes are saved') }
            />
          </div>
        )}

        {/* {isDataUpToDate() && (

        )} */}
      </form>
    </div>
  );
};

export default PersonalData;
