import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Checkbox,
} from '@material-ui/core'

import {
  GET_TOKEN_SWAP_LIST,
  DELETE_TOKEN_SWAP,
} from '../../queriesAndMutations'
import { usePublicSettings } from '../../myHooks'
import TablePaginationActions from '../../components/TablePagination'
import EditTokenSwapModal from './EditTokenSwapModal'
import Icon from '../../components/Icon'
import {
  getQueryVars,
} from '../../utils'

const AdminTokenSwapList = () => {
  const { t } = useTranslation()
  const [multiSelect, setMultiSelect] = useState([])
  const [choosedTokenSwap, setChoosedTokenSwap] = useState(null)
  const queryParam = getQueryVars()
  const [searchValue, setSearchValue] = useState('')
  const [reqOptions, setReqOptions] = useState({
    search: queryParam.search || "",
    page: 0,
    pageSize: 10,
  })
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const { data: { getTokenSwapList: data } = {}, loading, refetch } = useQuery(GET_TOKEN_SWAP_LIST, {
    variables: {
      input: {
        search: reqOptions.search,
        page: reqOptions.page,
        pageSize: reqOptions.pageSize,
      }
    }
  })
  const [deleteTokenSwap, deleteTokenSwapStatus] = useMutation(DELETE_TOKEN_SWAP)

  const submitSingleDelete = (userId) => () => {
    deleteTokenSwap({
      variables: {
        ids: [userId]
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  const submitMultipleDelete = () => {
    deleteTokenSwap({
      variables: {
        ids: multiSelect
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  const toggleMultiSelect = (id) => () => {
    if (multiSelect.includes(id)) {
      setMultiSelect(multiSelect.filter(i => i !== id))
    } else {
      setMultiSelect([...multiSelect, id])
    }
  }

  const onChangeReqOptions = ({target}) => {
    return setReqOptions({ ...reqOptions, search: target.value, page: 0 });
  };

  if (!data) {
    return null;
  }

  return (
    <div>
      <form className="form">
        <input
          type="search"
          className="form__input"
          placeholder={t('Type in search')}
          value={reqOptions.search}
          onChange={onChangeReqOptions}
        />
        <button className="form__button">
          <Icon
            className='searchh'
            name='searchh'
            size="24"
          />{" "}
        </button>
      </form>

      <br />
      {deleteTokenSwapStatus.loading && (
        <CircularProgress />
      )}
      {!deleteTokenSwapStatus.loading && (
        <button className="button button-danger" onClick={submitMultipleDelete} disabled={multiSelect.length <= 0}>Delete bulk</button>
      )}
      <EditTokenSwapModal choosedTokenSwap={choosedTokenSwap} setChoosedTokenSwap={setChoosedTokenSwap} publicSettings={publicSettings} onComplete={refetch} />
      <div className="transactions__wrapper products__table-kyclist__wrapper">
        <div className="transactions__table kyc__table_transactions kyc__table_kyclist">
          <div className="transactions__row">
            <div className="transactions__col" />

            <div className="transactions__col">
              {t('Email')}
            </div>

            <div className="transactions__col">
              {publicSettings.old_token_symbol ? `${publicSettings.old_token_symbol} amount` : 'Old token amount'}
            </div>

            <div className="transactions__col">
              {`${publicSettings.token_symbol} amount`}
            </div>

            <div className="transactions__col" />
          </div>

          {data.results.map(tokenSwap => (
            <div key={tokenSwap.id} className="transactions__row">
              <div className="transactions__col vert__middle">
                <Checkbox
                  color="primary"
                  value={tokenSwap.id}
                  checked={multiSelect.includes(+tokenSwap.id)}
                  onChange={toggleMultiSelect(+tokenSwap.id)}
                />
              </div>
              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Email')}
                </div>
                {tokenSwap.email}
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {publicSettings.old_token_symbol ? `${publicSettings.old_token_symbol} amount` : 'Old token amount'}
                </div>
                {tokenSwap.oldTokenAmount}
              </div>

              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {`${publicSettings.token_symbol} amount`}
                </div>
                {tokenSwap.newTokenAmount}
              </div>
              <div className="transactions__col vert__middle">
                {deleteTokenSwapStatus.loading && (
                  <CircularProgress />
                )}
                {!deleteTokenSwapStatus.loading && (
                  <button className="button button-danger" onClick={submitSingleDelete(+tokenSwap.id)}>Delete</button>
                )}

                <button className="button button-success" onClick={() =>
                    setChoosedTokenSwap({
                      id: tokenSwap.id,
                      email: tokenSwap.email,
                      oldTokenAmount: tokenSwap.oldTokenAmount,
                      newTokenAmount: tokenSwap.newTokenAmount,
                    })
                  }>Edit</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <TablePaginationActions
        count={data.meta.total}
        page={reqOptions.page}
        rowsPerPage={reqOptions.pageSize}
        onChangePage={(event, newPage) => { setReqOptions({ ...reqOptions, page: newPage }) }}
        onChangeRows={({ target: { value }}) => { setReqOptions({ ...reqOptions, page: 0, pageSize: +value}) }}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  )
}

export default AdminTokenSwapList
