import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from 'react-i18next';
import { CUSTOMER_INDIVIDUAL } from "../queriesAndMutations";
import { useMe, usePublicSettings } from "../myHooks";
import KycStatusLog from '../components/KycStatusLog'

const gotoDashboard = () => {
  window.location.href = window.location.origin;
}

const KycStatusCustomerCompany = ({ match }) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const { data: { me } = {} } = useMe({ fetchPolicy: 'network-only' })
  const { error, data } = useQuery(
    CUSTOMER_INDIVIDUAL,
    {
      variables: {
        id: match.params.customerId,
      },
      fetchPolicy: "network-only",
    }
  );
  const [status, setStatus] = useState('')

  const isCompleteLivenessCheck = me &&
    me.customer &&
    me.customer.onfidoApplicant &&
    me.customer.onfidoApplicant.onfidoChecks.length > 0 &&
    me.customer.onfidoApplicant.onfidoChecks.filter(checks => checks.onfidoReports.length > 1).length > 0;

  useEffect(() => {
    if (data) {
      setStatus('loaded')
    } else if (error) {
      setStatus('error')
    } else {
      setStatus('loading')
    }
  }, [data, error])

  return (
    <>
      <div className="page-dashboard__content">
        <div className="card">
          {status === 'loading' && (
            <div className="page-dashboard__content-header">
              <span className="success-dot" />
              <div className="h3 entry__title">
                { t('Please wait...')}
              </div>
            </div>
          )}
          {status === 'error' && (
            <div className="page-dashboard__content-header">
              <div className="h3 entry__title">
                { t('Somethings went wrong')}
              </div>
              <br />
              <p>{error.message.replace('GraphQL error: ', '')}</p>
            </div>
          )}
          { status === 'loaded' && (
            <>
              <div className="page-dashboard__content-header">
                <div className="h3 entry__title">
                  { t('Thank you for your Submission')}
                </div>
              </div>

              <div className="page-dashboard__content-header">
                <KycStatusLog />
              </div>

              <div className="page-dashboard__content-inner">
                <div className="inner-pagination__content-footer">
                  <button className="button button-stroke" onClick={gotoDashboard}>
                    {t('Back to Dashboard')}
                  </button>
                  {' '}
                  {publicSettings && me && me.customer && !isCompleteLivenessCheck && (
                    <a
                      href="/liveness-check-portal"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button text__center"
                    >
                      {t('Liveness check not started')}
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
    );
};

export default KycStatusCustomerCompany;
