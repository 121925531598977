import React, { useState } from 'react'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AdminKycList from '../components/AdminKycList'
import { CUSTOMER_TYPES } from '../constants/customer'
import AdminExportKyc from '../components/AdminCsvDialogs/AdminExportKyc'
import { hasUserEnoughRights } from '../utils/me'
import { toaster } from '../utils'
import { useMe } from '../myHooks'
import { USER_RIGHT_TYPES } from '../constants/user'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import {
  UPDATE_ALL_RESOURCES,
} from '../queriesAndMutations'
import TwoFactorAuthConfirmDialog from '../components/TwoFactorAuthConfirmDialog'
import Icon from '../components/Icon'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const AdminCompanyKyc = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isPythagoras, setIsPythagoras] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const { data: { me } = {} } = useMe();
  const { t } = useTranslation();
  // const [reqOptions, setReqOptions] = useState({
  //   statuses: [],
  // });
  const [isTwoFactorAuthConfirmDialogOpen, setIsTwoFactorAuthConfirmDialogOpen] = useState(false);
  const [updateAllResources, { loading: loadingUpdateAllResources}] = useMutation(UPDATE_ALL_RESOURCES);
  const triggerUpdateResources = (token) => {
    updateAllResources({
      variables: {
        two_fa_token: token,
        type: CUSTOMER_TYPES.COMPANY
      }
    }).then((response) => {
      const { data: { updateAllResources: isExecuting } } = response;

      if (isExecuting) {
        toaster.success('Update process is running.');
      } else {
        toaster.warning('Another update process is running.');
      }
      setIsTwoFactorAuthConfirmDialogOpen(false);
    }).catch(() => {})
  }

  // const onChangeFilterStatuses = (status) => () => {
  //   const newStatuses = reqOptions.statuses.includes(status)
  //     ? reqOptions.statuses.filter((v) => v !== status)
  //     : [...reqOptions.statuses, status];

  //   setReqOptions({ ...reqOptions, statuses: newStatuses });
  // };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogContent>
          <AdminExportKyc
            isPythagoras={isPythagoras}
            isBank={isBank}
            type={CUSTOMER_TYPES.COMPANY}
            onClose={() => {
              setDialogOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <TwoFactorAuthConfirmDialog
        open={isTwoFactorAuthConfirmDialogOpen}
        onClose={() => setIsTwoFactorAuthConfirmDialogOpen(false)}
        loading={loadingUpdateAllResources}
        onSubmit={triggerUpdateResources}
      />

      <div className="page__title h3">{ t('Company KYC') }</div>

      {me && hasUserEnoughRights(
          me.rights,
          USER_RIGHT_TYPES.COMPLIANCE_OFFICER
        ) && (
          <div className="create__card card">
            <MaterialAccordion className="accordion__kyc">
              <MaterialAccordionSummary
                className="accordion__kyc_head"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="download-filesbh-content"
                id="download-files-header"
              >
                <div className="title-primary card__title">{ t('Download Files') }</div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="accordion__kyc_body">
                <div className="field create__group create__group_inline">
                  {hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                  ) && (
                    <div className="field">
                      <button
                        className="button-stroke"
                        onClick={() => {
                          setIsPythagoras(false);
                          setIsBank(false);
                          setDialogOpen(true);
                        }}
                      >
                        <Icon
                          className={classNames('download')}
                          name='download'
                          size="24"
                        />{" "}
                        { t('Download as csv') }
                      </button>
                    </div>
                  )}
                  {hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                  ) && (
                    <div className="field">
                      <button
                        onClick={() => {
                          setIsPythagoras(true);
                          setIsBank(false);
                          setDialogOpen(true);
                        }}
                        className="button-stroke"
                      >
                        <Icon
                          className={classNames('download')}
                          name='download'
                          size="24"
                        />{" "}
                        { t('Download as csv for Pythagoras') }
                      </button>
                    </div>
                  )}
                  {hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                  ) && (
                    <div className="field">
                      <button
                        onClick={() => {
                          setIsPythagoras(false);
                          setIsBank(true);
                          setDialogOpen(true);
                        }}
                        className="button-stroke"
                      >
                        <Icon
                          className={classNames('download')}
                          name='download'
                          size="24"
                        />{" "}
                        { t('Download as csv for Bank') }
                      </button>
                    </div>
                  )}
                  {hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.ADMIN
                  ) && (
                    <div className="field">
                      <button
                        className="button-stroke"
                        onClick={() => setIsTwoFactorAuthConfirmDialogOpen(true)}
                      >
                        <Icon
                          className={classNames('upload')}
                          name='upload'
                          size="24"
                        />{" "}
                        { t('Update all KYB resources') }
                      </button>
                    </div>
                  )}
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>
          </div>
        )
      }

      <AdminKycList type={CUSTOMER_TYPES.COMPANY} />
    </>
  );
};

export default AdminCompanyKyc;
