import config from '../config'
import axios from "axios";

const getJwt = () => {
  let jwt = localStorage.getItem('jwt');

  if (!jwt) {
    const cookie = document.cookie.split('; ').find((c) => c.match(/^jwt=/));

    if (cookie) {
      jwt = (new URLSearchParams(cookie)).get('jwt');
    }
  }

  return jwt || '';
}
// eslint-disable-next-line import/prefer-default-export
export const getFileUrl = (filename) => {
  if (!window.location.origin.includes(':3000') && window.location.origin !== 'http://localhost:3001') {
    return `${window.location.origin}/file/${filename}`
  }

  return `${config.fileEndpoint}/${filename}?jwt=${getJwt()}`
}

export const getExternalFileUrl = (filename) => {
  if (window.location.origin !== 'http://localhost:3000' && window.location.origin !== 'http://localhost:3001') {
    return `${getFileUrl(filename)}?origin=${window.location.origin}`
  }

  return `${getFileUrl(filename)}&origin=${window.location.origin}`
};

export const getOriginalFileExtension = (filename) => {
  const regexp = /.+\.([a-zA-Z]{3,4})$/i
  const result = regexp.exec(filename)
  return (result || [])[1]
}

export const downloadFile = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((resp) => {
        const fileNameRegex =
          /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;
        const contentDisposition = resp.headers["content-disposition"];
        let fileName = `file`;
        if (contentDisposition) {
          const nameMatches = contentDisposition.match(fileNameRegex);
          if (nameMatches && nameMatches[2]) {
            fileName = nameMatches[2];
          }
        }
        const { data } = resp;
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
};
