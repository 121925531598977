import React, { useState } from 'react'
import { useQuery } from "@apollo/client";
import PropTypes from 'prop-types'
import reduce from 'lodash/reduce'
import {
  GET_AVAILABLE_FEATURES,
} from "../../queriesAndMutations";

import {
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LockedFeatureIcon from '../LockedFeatureIcon';
import FeatureNotAvailableMessage from '../FeatureNotAvailableMessage';
import { isFeatureEnabled, isAllRequiredFeaturesEnabled } from "../../utils";
import Input from "../Input";
import InputTheme from '../InputTheme';

const SETS = {
  disable_2fa_security: {
    default: true,
  },
  onboarding_type: {
    default: 'standard',
  },
  enable_auto_approve_kyc: {
    default: false,
  },
  enable_evolve: {
    default: true,
  },
  auto_approve_kyc_max_level: {
    default: 1,
  },
  enable_spot_check: {
    default: false,
  },
  spot_check_threshold: {
    default: 5,
  },
  enable_product_tour: {
    default: false,
  },
  enable_swap_token: {
    default: false,
  },
  enable_personal_discount: {
    default: false,
  },
  old_token_symbol: {
    default: '',
  },
  disable_registration: {
    default: false,
  },
  enable_limit_total_token: {
    default: false,
  },
  limit_total_token: {
    default: 200000,
  },
  allow_port_kyc_and_create_transaction_from_master_tenant: {
    default: false,
  },
  enable_admin_whitelisted_ips: {
    default: false,
  },
  admin_whitelisted_ips: {
    default: ''
  },
};

const AdminSettingsSystem = ({
  settings,
  loading,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation()
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] = settings[key] !== null ? settings[key] : SETS[key].default

        return memo
      },
      {}
    ),
  })

  // const onChangeInput = (name, state, setState) => (event) => {
  //   setState({ ...state, [name]: +event[name] })
  // }

  // const onChangeCheckbox = (name, state, setState) => (event) => {
  //   setState({ ...state, [name]: event.target.checked })
  // }

  const onUpdateBtnClick = () => {
    const newValues = {
      ...values,
      spot_check_threshold: +values.spot_check_threshold
    }

    onUpdate(newValues)
  };

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Security') }</div>

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">
              {t('Enable 2-factor authentication')}
              {' '}
              {!isFeatureEnabled('MANDATORY_2FA', availableFeatures) && (
                <LockedFeatureIcon />
              )}
            </div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).disable_2fa_security}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, disable_2fa_security: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('MANDATORY_2FA', availableFeatures)}
                  label={t('Enable')}
                  value={false}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('MANDATORY_2FA', availableFeatures)}
                  label={t('Disable')}
                  value={true}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">
              {t('Prevent user from register')}
            </div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).disable_registration}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, disable_registration: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">
              {t('Allow porting KYC and creating transaction from master tenant')}
            </div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).allow_port_kyc_and_create_transaction_from_master_tenant}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, allow_port_kyc_and_create_transaction_from_master_tenant: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">{ t('KYC') }</div>

        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">
              {t('Onboarding type')}
            </div>

            <div className="field__wrap field__wrap-radio field__wrap-radio_text_gray">
              <RadioGroup
                row
                value={(values || {}).onboarding_type}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, onboarding_type: value })
                }
              >
                <FormControlLabel
                  label={t('Standard onboarding')}
                  value="standard"
                  control={<Radio />}
                />

                <FormControlLabel
                  label={t('Easy onboarding')}
                  value="easy"
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Auto Approve KYC')}
              {' '}
              {!isFeatureEnabled('AUTO_APPROVE_KYC', availableFeatures) && (
                <LockedFeatureIcon />
              )}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_auto_approve_kyc}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_auto_approve_kyc: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('AUTO_APPROVE_KYC', availableFeatures)}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('AUTO_APPROVE_KYC', availableFeatures)}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        {(values || {}).enable_auto_approve_kyc && (
          <div className="settings__fieldset">
            <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
              <div className="field__label">
                {t('Auto Approve up to level')}
                {' '}
                {!isFeatureEnabled('AUTO_APPROVE_KYC', availableFeatures) && (
                  <LockedFeatureIcon />
                )}
              </div>

              <div className="field__wrap">
                <Input
                  propertyName="auto_approve_kyc_max_level"
                  state={values}
                  setState={setValues}
                  disabled={!editingAllowed}
                  loading={loading}
                  select
                >
                  {[1, 2, 3, 4, 5].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Input>
              </div>
            </div>
          </div>
        )}

        <br />

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Evolve')}
              {' '}
              {!isFeatureEnabled('EVOLVE', availableFeatures) && (
                <LockedFeatureIcon />
              )}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_evolve}
                onChange={({ target: { value } }) => {
                  console.log(value);
                  console.log(true);
                  setValues({ ...values, enable_evolve: value === 'true' })
                }}
              >
                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('EVOLVE', availableFeatures)}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed || !isFeatureEnabled('EVOLVE', availableFeatures)}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Spot check')}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_spot_check}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_spot_check: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        {values.enable_spot_check && (
          <>
            <br />

            <div className="settings__fieldset">
              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="field__wrap">
                  <InputTheme
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    propertyName="spot_check_threshold"
                    label={`${t('Spot check threshold')} (1-100)`}
                    state={values}
                    setState={setValues}
                    disabled={!editingAllowed}
                    type="number"
                  />

                </div>
              </div>
            </div>
          </>
        )}

        <br />

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Product tour')}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_product_tour}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_product_tour: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-red settings__title">{ t('Transactions') }</div>

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Place Swap Token Automatically')}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_swap_token}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_swap_token: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        {(values || {}).enable_swap_token && (
          <>
            <br />

            <div className="settings__fieldset">
              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="field__wrap">
                  <InputTheme
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    propertyName="old_token_symbol"
                    label={t('Old token symbol')}
                    state={values}
                    setState={setValues}
                    disabled={!editingAllowed}
                  />

                </div>
              </div>
            </div>
          </>
        )}

        <br />

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Enable Personal Discount')}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_personal_discount}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_personal_discount: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        <br />

        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Limit total allocated token')}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_limit_total_token}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_limit_total_token: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        {(values || {}).enable_limit_total_token && (
          <>
            <br />

            <div className="settings__fieldset">
              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="field__wrap">
                  <InputTheme
                    type="number"
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    propertyName="limit_total_token"
                    label={t('Allocated token amount')}
                    state={values}
                    setState={setValues}
                    disabled={!editingAllowed}
                    formatData={(value) => +value}
                  />

                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="settings__item">
        <div className="title-red settings__title">{ t('Admin Whitelisted IPs') }</div>
        <div className="settings__fieldset">
          <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
            <div className="field__label">
              {t('Enable admin whitelisted IPs')}
            </div>

            <div className="field__wrap">
              <RadioGroup
                row
                value={(values || {}).enable_admin_whitelisted_ips}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, enable_admin_whitelisted_ips: value === 'true' })
                }
              >
                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Enable')}
                  value={true}
                  control={<Radio />}
                />

                <FormControlLabel
                  disabled={!editingAllowed}
                  label={t('Disable')}
                  value={false}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
          </div>
        </div>

        {(values || {}).enable_admin_whitelisted_ips && (
          <>
            <br />

            <div className="settings__fieldset">
              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="field__wrap">
                  <InputTheme
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input field-textarea"
                    propertyName="admin_whitelisted_ips"
                    label={t('Whitelisted IPs')}
                    state={values}
                    setState={setValues}
                    disabled={!editingAllowed}
                    type="textarea"
                    minRows={8}
                    helperText="1 IP/line"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {isAllRequiredFeaturesEnabled([
        'MANDATORY_2FA',
        'AUTO_APPROVE_KYC',
      ], availableFeatures) || (
        <div className="settings__item">
          <div className="settings__fieldset">
            <FeatureNotAvailableMessage />
          </div>
        </div>
      )}

      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
          className="button settings__button"
        >
          { t('Update') }
        </button>
      )}
    </>
  );
};

AdminSettingsSystem.propTypes = {
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AdminSettingsSystem;
